import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Menu, Icon, Button, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  selectAuthState,
  selectCountryName,
  selectIsSignIn,
  setRedirectURLCallback,
} from "../../redux-toolkit/authReducer";
import {
  selectCustomerId,
  selectUserRole,
  selectUserRoleId,
} from "../../redux-toolkit/accountReducer";
import { Constants } from "../../helpers/Constants";
import "./MenuHeader.css";
import Boschicon from "../BoschIcon/Boschicon";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { ReactComponent as Regions } from "../../assets/svg/locator.svg";
import Styles from "./MenuHeader.module.css";
import { AuthService } from "../../services/AuthService";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import {
  clearEventIdFromCart,
  clearRegisteredEmployee,
} from "../../redux-toolkit/shoppingCartReducer";
import { turnOfAllProxy } from "../../redux-toolkit/dashboardEmployeeReducer";
import SwitchCountryModal from "./SwitchCountryModal";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import RDButton from "../RDComponents/RDButton";

interface MenuHeaderProps {
  isStickyMode?: boolean;
}

const MenuHeader: FC<MenuHeaderProps> = ({ isStickyMode }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isSignIn = useSelector(selectIsSignIn);
  const userRoleId = useSelector(selectUserRoleId);
  const customerId = useSelector(selectCustomerId);
  const [activeItem, setActivateItem] = useState<string>(
    t("Footer_TrainingServices")
  );
  const [level1Menu, setLevel1Menu] = useState<boolean>(false);
  const [level2Menu, setLevel2Menu] = useState<boolean>(false);
  const [myTrainingMenu, setMyTrainingMenu] = useState<boolean>(false);
  const [loginMenu, setLoginMenu] = useState<boolean>(false);
  const [supportMenu, setSupportMenu] = useState<boolean>(false);
  const isLoggedIn = useSelector(selectIsSignIn);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const language = useSelector(selectAuthState)?.language;
  const userRole = useSelector(selectUserRole);
  const countryName = useSelector(selectCountryName);
  const [isOpenSwitchRegion, setIsOpenSwitchRegion] = useState<boolean>(false);
  const authService: AuthService = AuthService.getInstance();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const login = async () => {
    // Mark the last location before login, then redirect after login.
    dispatchReduxToolkit(setRedirectURLCallback(window.location.href));
    
    await authService.loginAsync();
  };

  const collapseHeader = () => {
    setLevel1Menu(false);
    setLevel2Menu(false);
    setMyTrainingMenu(false);
    setLoginMenu(false);
    dispatchReduxToolkit(turnOfAllProxy());
  };

  const logout = async () => {
    dispatchReduxToolkit(clearEventIdFromCart());
    dispatchReduxToolkit(clearRegisteredEmployee());
    await authService.logoutAsync();
  };

  //redirect functions
  const redirectToDashboard = () => {
    history.push("/my-dashboard");
  };

  const redirectToCompanyAdministration = () => {
    history.push(`/my-dashboard/${Constants.MYDASHBOARD_WORKSHOP}`);
  };

  const redirectToShoppingCart = () => {
    history.push("/shopping-cart");
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setLevel1Menu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <div ref={ref}>
        <Menu pointing secondary stackable className="menu-ui">
          <Button
            basic
            className={Styles.menu_btn}
            onClick={() => {
              setLevel1Menu(!level1Menu);
            }}
            icon={
              level1Menu ? (
                <Icon className="icon-close">
                  <Close />
                </Icon>
              ) : (
                <Icon>
                  <MenuIcon />
                </Icon>
              )
            }
          ></Button>
          {isLoggedIn && !!customerId && (
            <Menu.Item
              className="menu-items menu-item-catalogue"
              content={t("MenuHeader_MyTraining")}
              active={myTrainingMenu && level1Menu}
              onClick={() => {
                setLevel1Menu(true);
                setLevel2Menu(false);
                setSupportMenu(false);
                setMyTrainingMenu(true);
              }}
            />
          )}
          <Menu.Item
            className="menu-items menu-item-catalogue"
            content={t("MenuHeader_TrainingServices")}
            active={level2Menu && level1Menu}
            onClick={() => {
              setLevel1Menu(true);
              setSupportMenu(false);
              setMyTrainingMenu(false);
              setLevel2Menu(true);
            }}
          />
          <Menu.Item
            className="menu-items menu-item-catalogue"
            content={t("MenuHeader_OurLocations")}
            active={window.location.pathname === "/training-centers"}
            onClick={() => {
              history.push("/training-centers");
            }}
          />
          <Menu.Item
            className="menu-items menu-item-catalogue"
            content={t("MenuHeader_Support")}
            active={supportMenu && level1Menu}
            onClick={() => {
              setLevel1Menu(true);
              setLevel2Menu(false);
              setMyTrainingMenu(false);
              setSupportMenu(true);
            }}
          />
          {/* <Menu.Item
              className="menu-items menu-item-catalogue"
              content={t("Footer_TrainingServices")}
              active={window.location.pathname === "/training-course-catalogue"}
              onClick={() => {
                  setActivateItem(t("Footer_TrainingServices"));
                  history.push("/training-course-catalogue");
              }}
            />
            <Menu.Item
              className="menu-items"
              content={t("Footer_EventsCalendar")}
              active={window.location.pathname === "/event-calendar" }
              onClick={() => {
                  setActivateItem(t("Footer_EventsCalendar"));
                  history.push("/event-calendar");
              }}
            />
            <Menu.Item
              className="menu-items"
              content={t("Footer_TrainingProgram")}
              active={window.location.pathname === "/training-program"}
              onClick={() => {
                  setActivateItem(t("Footer_TrainingProgram"))
                  history.push("/training-program")
              }}
            />
            <Menu.Item
              className="menu-items"
              content={t("Footer_TrainingCenters")}
              active={window.location.pathname === "/training-centers"}
              onClick={() => {
                  setActivateItem(t("Footer_TrainingCenters"));
                  history.push("/training-centers");
              }}
            />
              {isSignIn && (
                      <Menu.Item
                      className="menu-items"
                          content={t("Footer_Dashboard")}
                          active={window.location.pathname === "/my-dashboard"}
                          onClick={() =>{
                            setActivateItem(t("Footer_Dashboard"))
                            history.push("/my-dashboard")
                          }}
                          
                      />
                  )
              }
            
              {userRoleId === Constants.USER_ROLE_MANAGER_ID && isSignIn && 
                  (
                    <Menu.Item
                      className="menu-items"
                      active={window.location.pathname.split('/')[1] === "my-dashboard" && window.location.pathname.split('/')[2] !== undefined}
                      >
                        <Dropdown text={t("Footer_Administration")}
                          // icon={isDrop?"angle up":"angle down"}
                          trigger = {trigger}
                          icon={null}
                          onClick={() => {setIsDrop(true)}}
                          onClose= {() => {setIsDrop(false)}}
                          className="admin-options"
                        >
                        <Dropdown.Menu className="admin-board-options">
                          <Dropdown.Item 
                            className="admin-item"
                            text={t("Footer_Employees")}
                            onClick={() =>{
                              history.push("/my-dashboard/my-employees")
                            }}
                          />
                          <Dropdown.Item
                            className="admin-item"
                            text={t("Footer_Wholesalers")}
                            onClick={() =>{
                              history.push("/my-dashboard/my-suppliers")
                            }}
                          />
                          <Dropdown.Item 
                            className="admin-item"
                            text={t("Footer_Transactions")}
                            onClick={() =>{
                              history.push("/my-dashboard/my-transaction")
                            }}
                          />
            
                        </Dropdown.Menu>
                      </Dropdown>
                      </Menu.Item>
                    
                  )
              }

              {userRoleId === Constants.USER_ROLE_WHOLESALER_ID && isSignIn && 
                  (
                    <Menu.Item
                      className="menu-items"
                      content={t("Footer_Administration")}
                      active={window.location.pathname === "/my-dashboard/my-workshops"}
                      onClick={() => {
                        setActivateItem(t("Footer_Administration"))
                        history.push("/my-dashboard/my-workshops")
                      }}
                    /> 
                  )
              }

            <Menu.Item
              className="menu-items"
              content={t("MenuHeader_Support")}
              active={window.location.pathname === "/support"}
              onClick={() => {
                  setActivateItem(t("MenuHeader_Support"));
                  history.push("/support");
              }}
            /> */}
          {level1Menu && (
            <Grid
              columns={2}
              className={`header-level-1 ${Styles.header_level1}`}
            >
              <Grid.Column className={Styles.header_level1_col}>
                {isLoggedIn && !!customerId && (
                  <Grid.Row>
                    <Button
                      className={Styles.arrow_row}
                      id={`${myTrainingMenu && Styles.active_level1_menu}`}
                      basic
                      content={<span>{t("MenuHeader_MyTraining")}</span>}
                      style={{ fontSize: "1.5rem" }}
                      icon={<Boschicon name="bosch-forward-right" />}
                      onClick={() => {
                        setLoginMenu(false);
                        setSupportMenu(false);
                        setLevel2Menu(false);
                        setMyTrainingMenu(!myTrainingMenu);
                      }}
                    ></Button>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Button
                    className={Styles.arrow_row}
                    id={`${level2Menu && Styles.active_level1_menu}`}
                    basic
                    content={<span>{t("MenuHeader_TrainingServices")}</span>}
                    style={{ fontSize: "1.5rem" }}
                    icon={<Boschicon name="bosch-forward-right" />}
                    onClick={() => {
                      setLoginMenu(false);
                      setMyTrainingMenu(false);
                      setSupportMenu(false);
                      setLevel2Menu(!level2Menu);
                    }}
                  ></Button>
                </Grid.Row>
                <Grid.Row>
                  <Button
                    basic
                    content={<span>{t("MenuHeader_OurLocations")}</span>}
                    style={{ fontSize: "1.5rem" }}
                    onClick={() => {
                      history.push("/training-centers");
                    }}
                  ></Button>
                </Grid.Row>
                <Grid.Row className={Styles.last_row}>
                  <Button
                    basic
                    className={`${Styles.arrow_row} ${Styles.support}`}
                    id={`${supportMenu && Styles.active_level1_menu}`}
                    content={<span>{t("MenuHeader_Support")}</span>}
                    style={{ fontSize: "1.5rem" }}
                    icon={<Boschicon name="bosch-forward-right" />}
                    onClick={() => {
                      setLoginMenu(false);
                      setMyTrainingMenu(false);
                      setLevel2Menu(false);
                      setSupportMenu(!supportMenu);
                    }}
                  ></Button>
                </Grid.Row>
                {isLoggedIn && (
                  <>
                    <Grid.Row>
                      <Button
                        basic
                        className={`${Styles.arrow_link} ${Styles.first_row}`}
                        content={<span>{t("MyProfile_Profile")}</span>}
                        icon={<Boschicon name="bosch-forward-right" />}
                        style={{ fontSize: "1.5rem" }}
                        onClick={() => {
                          history.push("/myprofile");
                        }}
                      ></Button>
                    </Grid.Row>
                    <Grid.Row>
                      <Button
                        basic
                        className={Styles.arrow_link}
                        content={<span>{t("Header_ShoppingCart")}</span>}
                        icon={<Boschicon name="bosch-forward-right" />}
                        style={{ fontSize: "1.5rem" }}
                        onClick={() => {
                          history.push("/shopping-cart");
                        }}
                      ></Button>
                    </Grid.Row>
                  </>
                )}
                {isLoggedIn ? (
                  <Grid.Row>
                    <Button
                      basic
                      className={Styles.arrow_link}
                      content={<span>{t("MenuHeader_Log_out")}</span>}
                      icon={<Boschicon name="bosch-forward-right" />}
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        setIsOpenLogOutModal(true);
                      }}
                    ></Button>
                  </Grid.Row>
                ) : (
                  <Grid.Row>
                    <Button
                      basic
                      className={Styles.arrow_link}
                      content={<span>{t("Homepage_Login")}</span>}
                      icon={<Boschicon name="bosch-forward-right" />}
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        login();
                      }}
                    ></Button>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Button
                    basic
                    className={Styles.arrow_link}
                    content={<span>{t("MenuHeader_ContactsUs")}</span>}
                    icon={<Boschicon name="bosch-forward-right" />}
                    style={{ fontSize: "1.5rem" }}
                    onClick={() => {
                      history.push("/contacts");
                    }}
                  ></Button>
                </Grid.Row>
                <Grid.Row>
                  <Button
                    basic
                    className={`${Styles.locations}`}
                    content={countryName}
                    icon={
                      <Icon>
                        <Regions />
                      </Icon>
                    }
                    style={{ fontSize: "1.5rem" }}
                    onClick={() => {
                      setIsOpenSwitchRegion(true);
                    }}
                  ></Button>
                </Grid.Row>
              </Grid.Column>
              {level2Menu && (
                <Grid.Column className={Styles.header_level2_col}>
                  <Grid.Row>
                    <Button
                      basic
                      id={Styles.header_level2_col_title}
                      content={t("MenuHeader_TrainingServices")}
                      style={{ fontSize: "1.5rem" }}
                    ></Button>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      id="btn-focus-error"
                      basic
                      content={<span>{t("Footer_TrainingServices")}</span>}
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        history.push("/training-course-catalogue");
                      }}
                    ></Button>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      basic
                      content={<span>{t("EventCalendar_EventCalendar")}</span>}
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        history.push("/event-calendar");
                      }}
                    ></Button>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      basic
                      content={<span>{t("Dashboard_Employee_Program")}</span>}
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        history.push("/training-program");
                      }}
                    ></Button>
                  </Grid.Row>                  
                </Grid.Column>
              )}
              {myTrainingMenu && (
                <Grid.Column className={Styles.header_level2_col}>
                  <Grid.Row>
                    <Button
                      basic
                      id={Styles.header_level2_col_title}
                      content={t("MenuHeader_MyTraining")}
                      style={{ fontSize: "1.5rem" }}
                    ></Button>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      id="btn-focus-error"
                      basic
                      content={
                        <span>{t("Dashboard_Headline_YourOverview")}</span>
                      }
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        collapseHeader();
                        history.push("/dashboard/:activetab");
                      }}
                    ></Button>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      id="btn-focus-error"
                      basic
                      content={<span>{t("Dashboard_MenuItem_Curricula")}</span>}
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        collapseHeader();
                        history.push("/dashboard/my-training-program");
                      }}
                    ></Button>
                  </Grid.Row>
                  {/* <Grid.Row>
                          <Button
                            basic
                            content={<span>{t("Dashboard_MenuItem_Certificates")}</span>}
                            style={{ fontSize: "1.5rem" }}
                            onClick={() => {
                              collapseHeader();
                              history.push("/dashboard/my-waitlist")}}
                          ></Button>
                      </Grid.Row> */}
                  {userRoleId === 30 && (
                    <>
                      <Grid.Row>
                        <Button
                          basic
                          content={<span>{t("Footer_Workshops")}</span>}
                          style={{ fontSize: "1.5rem" }}
                          onClick={() => {
                            collapseHeader();
                            history.push("/dashboard/my-workshops");
                          }}
                        ></Button>
                      </Grid.Row>
                      <Grid.Row>
                        <Button
                          basic
                          content={<span>{t("Wallet")}</span>}
                          style={{ fontSize: "1.5rem" }}
                          onClick={() => {
                            collapseHeader();
                            history.push("/dashboard/my-wallet");
                          }}
                        ></Button>
                      </Grid.Row>
                    </>
                  )}
                  {userRoleId === 10 && (
                    <>
                      <Grid.Row>
                        <Button
                          basic
                          content={<span>{t("Dashboard_MenuItem_Team")}</span>}
                          style={{ fontSize: "1.5rem" }}
                          onClick={() => {
                            collapseHeader();
                            history.push("/dashboard/my-employees");
                          }}
                        ></Button>
                      </Grid.Row>
                      <Grid.Row>
                        <Button
                          basic
                          content={
                            <span>{t("Dashboard_MenuItem_External")}</span>
                          }
                          style={{ fontSize: "1.5rem" }}
                          onClick={() => {
                            collapseHeader();
                            history.push("/dashboard/my-suppliers");
                          }}
                        ></Button>
                      </Grid.Row>
                      <Grid.Row>
                        <Button
                          basic
                          content={
                            <span>{t("Dashboard_MenuItem_Wallet")}</span>
                          }
                          style={{ fontSize: "1.5rem" }}
                          onClick={() => {
                            collapseHeader();
                            history.push("/dashboard/my-wallet");
                          }}
                        ></Button>
                      </Grid.Row>
                    </>
                  )}
                </Grid.Column>
              )}
              {supportMenu && (
                <Grid.Column className={Styles.header_level2_col}>
                  <Grid.Row>
                    <Button
                      basic
                      id={Styles.header_level2_col_title}
                      content={t("MenuHeader_Support")}
                      style={{ fontSize: "1.5rem" }}
                    ></Button>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      basic
                      content={<span>{t("Breadcrumb_Download_Page")}</span>}
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        history.push("/download-files");
                      }}
                    ></Button>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      basic
                      content={<span>{t("MenuHeader_FAQ")}</span>}
                      style={{ fontSize: "1.5rem" }}
                      onClick={() => {
                        collapseHeader();
                        history.push("/support");
                      }}
                    ></Button>
                  </Grid.Row>
                </Grid.Column>
              )}
              <Grid.Column className={Styles.blank_col}></Grid.Column>
            </Grid>
          )}
          {isStickyMode !== undefined && isStickyMode === true ? (
            <></>
          ) : (
            <Menu.Item
              position="right"
              className={`${Styles.right_header_item} menu-items`}
              content={t("Homepage_MainTitle")}
            />
          )}
        </Menu>
      </div>
      {isOpenSwitchRegion && (
        <SwitchCountryModal
          isShow={isOpenSwitchRegion}
          onCancel={() => setIsOpenSwitchRegion(false)}
        />
      )}

      {/* <RDAlert
        size="mini"
        type="warning"
        isOpen={isOpenLogOutModal}
        messages={[t("Header_LogOutText")]}
        acceptButtonText={t("CurrentUserAccountDeactivationModal_Yes")}
        onAccept={() => {
          setIsLoggingOut(true);
          logout();
        }}
        cancelButtonText={t("CurrentUserAccountDeactivationModal_No")}
        onCancel={() => setIsOpenLogOutModal(false)}
        isAcceptLoading={isLoggingOut}
      /> */}

      <RDCustomAlert
        type="warning"
        size="mini"
        isOpen={isOpenLogOutModal}
        AcceptButton={
          <RDButton
            primary
            style={{ marginRight: "1rem" }}
            onClick={() => {
              setIsLoggingOut(true);
              logout();
            }}
            disabled={isLoggingOut}
            loading={isLoggingOut}
          >
            {t("CurrentUserAccountDeactivationModal_Yes")}
          </RDButton>
        }
        CancelButton={
          <RDButton
            secondary
            style={{ borderRadius: 0 }}
            onClick={() => setIsOpenLogOutModal(false)}
          >
            {t("CurrentUserAccountDeactivationModal_No")}
          </RDButton>
        }
      >
        <Grid>
          <Grid.Row>
            <p>{t("Header_LogOutText")}</p>
          </Grid.Row>
        </Grid>
      </RDCustomAlert>

      {/* <Modal
          onClose={() => setIsOpenLogOutModal(false)}
          open={isOpenLogOutModal}
          dimmer={{ className: "test-dimmer logout-modal warning" }}
          closeOnDimmerClick={false}
          size={"mini"}
          className={Styles.logout_modal}
        >
          <Modal.Content>
           <p>{t("Header_LogOutText")}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              loading={isLoggingOut}
              disabled={isLoggingOut}
              className="bwc-btn--primary"
              onClick={() => {
                setIsLoggingOut(true);
                logout();
              }}
            >
              {t("CurrentUserAccountDeactivationModal_Yes")}
              {""}
            </Button>
            <Button
              disabled={isLoggingOut}
              className="bwc-btn--secondary"
              onClick={() => setIsOpenLogOutModal(false)}
            >
              {t("CurrentUserAccountDeactivationModal_No")}
              {""}
            </Button>
          </Modal.Actions>
        </Modal> */}
    </Fragment>
  );
};

export default MenuHeader;
