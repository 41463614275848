import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Grid, Modal, Popup, Table } from "semantic-ui-react";

import { Constants } from "../../../helpers/Constants";
import { TrainingEventHelper } from "../../../helpers/TrainingEventHelper";
import {
  BatsCreditPrice,
  Price,
  TrainingCourse,
  TrainingEvent,
} from "../../../models/TrainingEvent";
import {
  selectAllowSelfEnroll,
  selectOnSubWorkshopBranch,
} from "../../../redux-toolkit/accountReducer";
import { selectLanguage } from "../../../redux-toolkit/authReducer";
import Boschicon from "../../BoschIcon/Boschicon";
import PriceTag from "../../TrainingCatalogue/PriceTag";
import WaitlistPopup from "../../TrainingCatalogue/WaitlistPopup";
import AddEventToCartComponent from "../AddEventToCart.Component";
import ExternalRegistrationComponent from "../../ExternalLink/ExternalRegistration.Component";
import "./AddScheduledEventFromCatalogToCartModal.css";
import RDButton from "../../RDComponents/RDButton";
import { CommonHelper } from "../../../helpers/CommonHelper";
import { useAccountSettings } from "../../../hooks/useAccountSettings";
import { useDateFormat } from "../../../hooks/useDateFormat";

interface EventDataModel {
  eventId: number;
  courseId: number;
  startDate: string;
  availableSeats: number;
  language: string;
  city: string;
  price: Price;
  creditPrice: BatsCreditPrice;
  trainingEvent: TrainingEvent;
}

interface AddScheduledEventFromCatalogToCartModalProps {
  onDisplayModal: boolean;
  courseData: any;
  onCloseModalTrigger: Function;
}

const AddScheduledEventFromCatalogToCartModal: FC<
  AddScheduledEventFromCatalogToCartModalProps
> = (props) => {
  const { t } = useTranslation();
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const [eventDatas, setEventDatas] = useState<EventDataModel[]>([]);
  const language = useSelector(selectLanguage);
  const allowSelfEnroll = useSelector(selectAllowSelfEnroll);
  const onSubWorkshopBranch = useSelector(selectOnSubWorkshopBranch);
  const [waitlistCourseItem, setWaitlistCourseItem] =
    useState<TrainingCourse | null>(null);
  const [waitlistEventItem, setWaitlistEventItem] =
    useState<TrainingEvent | null>(null);
  const [isOpenWaitlistPopup, setIsOpenWaitlistPopup] =
    useState<boolean>(false);
  const getDateFormat = useAccountSettings();
  const formatDate = useDateFormat();

  useEffect(() => {
    setOpenInformationModal(props.onDisplayModal);
    if (props.onDisplayModal) {
      let trainningEvents = props.courseData.trainingEvents as any[];
      let eventOptionData: EventDataModel[] = trainningEvents.map((item) => ({
        eventId: item.eventId,
        courseId: item.courseId,
        /*  startDate: CommonHelper.DateFormatBySetting(
          item.startDate,
          getDateFormat()
        ), */
       /*  startDate: CommonHelper.convertLocalizationDatetime(
          item?.startDate,
          getDateFormat(),
          language
        ), */
        startDate: formatDate(item?.startDate),
        availableSeats: item.availableSeats,
        language: props.courseData.localizationInfo.localized,
        city: `${item.venue?.city}`,
        price: item.batsPrice,
        creditPrice: item.batsCreditPrice,
        trainingCourseAddToCartModel: {
          trainingEvents: [
            {
              eventId: item.eventId,
              courseId: item.courseId,
            },
          ],
        },
        trainingEvent: {
          ...item,
          trainingCourse: { ...props.courseData },
        },
      }));
      setEventDatas(eventOptionData);
    }
  }, [props.onDisplayModal]);

  const closeInformationModal = () => {
    setOpenInformationModal(false);
    props.onCloseModalTrigger();
  };

  const onAction = (action: string, eventItem: TrainingEvent | null) => {
    if (action === Constants.TRAININGCOURSECATALOGUE_WAITLISTACTION) {
      setWaitlistCourseItem(props.courseData);
      setWaitlistEventItem(eventItem);
      setIsOpenWaitlistPopup(true);
    }
  };

  const addedToCartSuccessFunc = () => {
    setOpenInformationModal(false);
    props.onCloseModalTrigger();
  };

  const addToWaitlistSuccessFunc = () => {
    setOpenInformationModal(false);
    props.onCloseModalTrigger();
  };

  return (
    <Fragment>
      <Modal
        onClose={closeInformationModal}
        open={openInformationModal}
        dimmer={{ className: "test-dimmer addtocart-scheduledcoursemodal" }}
        size={eventDatas.length > 0 ? "small" : "tiny"}
      >
        <Modal.Header>
          <span>
            {TrainingEventHelper.getTrainingNameFromCourse(
              props.courseData,
              language,
              "-"
            )}
          </span>
          <Boschicon
            name="bosch-close"
            className="Boschicon close-icon"
            onClick={closeInformationModal}
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row className="row-info">
              {eventDatas.length > 0 ? (
                <Table
                  basic="very"
                  className="table-layout flex-table"
                  unstackable
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="mobile tablet">
                        {t("TrainingCatalogue_StartDate")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="align-right">
                        {t("TrainingCatalogue_Seats")}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("TrainingCatalogue_Language")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="tablet">
                        {t("TrainingCatalogue_Location")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="align-right mobile tablet">
                        {t("TrainingCatalogue_Price")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="align-right mobile tablet">
                        {t("TrainingCatalogue_Action")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {eventDatas.map((x) => {
                      return (
                        <Table.Row key={x.eventId}>
                          <Table.Cell className="mobile tablet">
                            {x.startDate}
                          </Table.Cell>
                          <Table.Cell className="align-right">
                            {x?.trainingEvent?.hasExternalRegistration
                              ? t("EventCalendar_ExternalLink_Seat_NA")
                              : x.availableSeats}
                          </Table.Cell>
                          <Table.Cell>{x.language}</Table.Cell>
                          <Table.Cell className="tablet">{x.city}</Table.Cell>
                          <Table.Cell className="align-right mobile tablet">
                            <PriceTag
                              price={x.price}
                              creditPrice={x.creditPrice}
                            />
                          </Table.Cell>
                          <Table.Cell className="align-right action-cell mobile tablet">
                            {x?.trainingEvent?.hasExternalRegistration ? (
                              <ExternalRegistrationComponent
                                isDisabled={
                                  !allowSelfEnroll || onSubWorkshopBranch
                                }
                                eventInfo={x?.trainingEvent}
                              />
                            ) : (
                              <AddEventToCartComponent
                                isDisabled={
                                  !allowSelfEnroll ||
                                  x.availableSeats <= 0 ||
                                  onSubWorkshopBranch
                                }
                                eventInfo={x.trainingEvent}
                                addedToCartSuccess={addedToCartSuccessFunc}
                              />
                            )}
                            <Popup
                              size="tiny"
                              content={t("Button_AddToWaitingList_Tooltips")}
                              trigger={
                                <Button
                                  disabled={
                                    !allowSelfEnroll || onSubWorkshopBranch
                                  }
                                  onClick={() =>
                                    onAction(
                                      Constants.TRAININGCOURSECATALOGUE_WAITLISTACTION,
                                      x.trainingEvent
                                    )
                                  }
                                  title={
                                    onSubWorkshopBranch
                                      ? t("EventTable_HeadOfficeRequire")
                                      : ""
                                  }
                                  icon={<Boschicon name="bell-plus" />}
                                />
                              }
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              ) : (
                <div className="common-modal-content-section">
                  <label style={{ fontSize: "1rem" }}>
                    {t("AddToCart_UnfortunatelyNotHaveScheduledEvent")}
                  </label>
                </div>
              )}
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {eventDatas.length > 0 ? (
            <div></div>
          ) : (
            <RDButton
              secondary
              color="blue"
              onClick={() =>
                onAction(Constants.TRAININGCOURSECATALOGUE_WAITLISTACTION, null)
              }
            >
              {t("TrainingCatalogue_AddToWaitingList")}
            </RDButton>
          )}
          <RDButton primary onClick={closeInformationModal}>
            {t("CommonButton_Close")}
          </RDButton>
        </Modal.Actions>
      </Modal>
      {waitlistCourseItem && (
        <WaitlistPopup
          className="add-to-waitlist-pop-up"
          courseItem={waitlistCourseItem}
          eventItem={waitlistEventItem}
          isOpen={isOpenWaitlistPopup}
          setIsOpen={setIsOpenWaitlistPopup}
          addedItemSuccess={addToWaitlistSuccessFunc}
        />
      )}
    </Fragment>
  );
};

export default AddScheduledEventFromCatalogToCartModal;
