import moment from "moment";
import { useSelector } from "react-redux";
import { DateType, TimeType } from "../models/dateFormat";
import {
  formatCDMDateTime,
  getBrowserDateFormat,
} from "../utils/dateFormat.util";

import {
  selectAccountDateFormat
} from "../redux-toolkit/accountReducer";

export const useAccountDateFormat = () => {
  const formatDate = (date: string) => {
    const dateMoment = moment(date);
    return dateMoment.format("DD/MM/YYYY");
  };
  return formatDate;
};

export const useDateFormat = () => {
  const dateFormat = useSelector(selectAccountDateFormat);

  const formatDate = (
    date: string,
    dateSeparator?: string,
    timeType?: TimeType
  ) => {
    return formatCDMDateTime({
      input: date,
      dateSeparator,
      dateType: !!dateFormat
        ? (dateFormat as DateType)
        : getBrowserDateFormat(),
      timeType,
    });
  };

  return formatDate;
};



