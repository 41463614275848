import { EventDetails, EventEnrollment } from '../models/EventDetail'
import { TrainingProgramEnrollmentStatus } from '../models/TrainingEvent'

export class EventEnrollmentHelper {

  static getLocation = (eventEnrollment: EventEnrollment, countryCode: string, additionalText: string) => {
    if (!eventEnrollment) return ''

    if(eventEnrollment && (eventEnrollment.isELearning || eventEnrollment.isWebBasedTraining) ){
      return `${additionalText}`;
    }

    if (!countryCode) return eventEnrollment?.eventDetail?.venue?.city

    if ( countryCode.toLocaleUpperCase() === 'AU' ||countryCode.toLocaleUpperCase() === 'NZ' )
      return eventEnrollment?.eventDetail?.venue?.headline

    return eventEnrollment?.eventDetail?.venue?.city
  } 

  static getTrainingEventDateDuration(eventEnrollment: EventEnrollment, additionalText: string){
    if(!eventEnrollment)
      return "-"; 

    if(eventEnrollment && (eventEnrollment.isELearning || eventEnrollment.isWebBasedTraining) ){
        return `${eventEnrollment?.eventDetail?.duration?.totalHours?.toFixed(2)} ${additionalText}` || "-";
    }

    return "dd/mm/yyyy";
  }
  static getStartDate(enrollmentStatus: TrainingProgramEnrollmentStatus | null){
    if(!!enrollmentStatus && !!enrollmentStatus.eventStartDate && !enrollmentStatus.eventStartDate.toString().startsWith("0001-01-01")){
      const dateParts = enrollmentStatus.eventStartDate.toString().slice(0,10).split("-");
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];
      return `${day}/${month}/${year}`;
    }
    return "-"
    
  }

  static getStartDateEvenDetails(enrollmentStatus: EventDetails | null){
    if(!!enrollmentStatus && !!enrollmentStatus.startDateTime && !enrollmentStatus.startDateTime.toString().startsWith("0001-01-01")){
      const dateParts = enrollmentStatus.startDateTime.toString().slice(0,10).split("-");
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];
      return `${day}/${month}/${year}`;
    }
    return "-"
    
  }
}
