export const TRANSLATIONS_DA_DK = {
	LandingPage_Title: "Velkommen til Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Vælg dit område / land",
	Header_ContactUs: "Kontakt os",
	Header_Search: "Søg",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Log ind eller opret din konto",
	Header_LogIn: "Log ind",
	Header_Cart: "Indkøbskurv",
	Header_ShoppingCart: "Indkøbskurv",
	Header_LogOut: "Log ud",
	Header_ChangeDetails: "Rediger detaljer",
	Footer_ExploreOurTraining: "Udforsk vores kursusløsninger",
	Footer_TrainingAtYourFingertips: "Undervisning lige ved hånden",
	Footer_DontMissAnyStory: "Gå ikke glip af historier",
	Footer_ExploreOurStories: "Udforsk vores historier",
	Footer_GetInTouchWithUs: "Kontakt os",
	Footer_SendUsAMessage: "Send os en besked",
	Footer_ToContactForm: "Til kontaktformularen",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "Generelle kontaktoplysninger",
	Footer_ProductSecurity_Psirt: "Produktsikkerhed (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innovationspatenter og licenser",
	Footer_PurchasingLogistics: "Indkøb og logistik",
	Footer_CorporateInformation: "Virksomhedsoplysninger",
	Footer_LegalNotice: "Juridisk meddelelse",
	Footer_DataProtectionNotice: "Meddelelse om databeskyttelse",
	Footer_PrivacySettings: "Indstillinger for beskyttelse af personlige oplysninger",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch A/S, alle rettigheder forbeholdes",
	Footer_StillLookingForSomething: "Leder du stadig efter noget?",
	Footer_TrainingServices: "Service Training",
	Footer_Courses: "Kurser",
	Footer_Programs: "Programmer",
	Footer_EventsCalendar: "Kalender",
	Footer_TrainingCenters: "Kursuscentre",
	Footer_Dashboard: "Dashboard",
	Footer_MyDashboard: "Mit dashboard",
	Footer_ShoppingCart: "Indkøbskurv",
	Footer_YourShoppingCart: "Din indkøbskurv",
	Footer_Administration: "Administration",
	Footer_Employees: "Medarbejdere",
	Footer_Wholesalers: "Grossister",
	Footer_Transactions: "Transaktioner",
	Footer_Workshops: "Værksteder",
	Footer_WorkLikeABoschApplyNow: "Arbejd  - Ansøg nu.",
	Footer_BoschGlobalCareers: "Bosch Globale ledige stillinger",
	Footer_PurchasingAndLogistics: "Indkøb og logistik",
	Footer_BecomeABusinessPartner: "Bliv forretningspartner.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Sådan udvikler vi vores kurser for at hjælpe din virksomhed",
	Footer_Language: "Vælg land",
	MenuPopup_MyCart: "Min indkøbskurv",
	Stories_OurTrainingStories: "Vores kursushistorier",
	Stories_ConnectingExperts: "Forbinder eksperter og entusiaster, drømmere og handlere, innovatorer og influencere – udforsk og oplev",
	Stories_TopStories: "Tophistorier",
	Stories_AtHome: "Hjemme",
	Stories_Careers: "Ledige stillinger",
	Stories_History: "Historie",
	Stories_IndustryAndTrades: "Industri og handel",
	Stories_Mobility: "Mobilitet",
	Stories_Research: "Forskning",
	Stories_Sustainability: "Bæredygtighed",
	Stories_AllStories: "Alle historier",
	Stories_BlogATrainersDay: "BLOG | En undervisers dag",
	Stories_ADayinLifeOfATrainer: "En dag i en undervisers liv",
	Stories_BoschServiceTraining: "Bosch Servicekurser",
	Stories_StoryWhatDrivesYouDrivesUs: "STORY | Det, der driver dig, driver os",
	Stories_StoryHowCanWeBenefitYourBusiness: "STORY | Hvordan kan vi gavne din virksomhed",
	Stories_HowOurTrainingEvolves: "Sådan udvikler vores kurser sig",
	Stories_AModernTrainingApproach: "Onlinekurser",
	Stories_StoryAVirtualOnlineTrainingWorld: "STORY | Dine onlinebaserede kursusmuligheder",
	Stories_SubscribeToRSSFeed: "Abonner på RSS-feed",
	Title_Main: "Velkommen til Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Om os",
	Homepage_AutomotiveAftermarket: "Mobility Aftermarket",
	Homepage_GetInTouch: "Kontaktoplysninger",
	Homepage_LatestNews: "Find ud af hvorfor vi er drevet af passion for viden",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Ledige stillinger",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Det, der driver dig, driver os",
	BoschServiceTraining_BoschServiceTraining: "Bosch Servicekurser",
	BoschServiceTraining_ShareThisOn: "Del dette på",
	BoschServiceTraining_TheChallenge: "Udfordringen: Behersk nye teknologier",
	BoschServiceTraining_WorldwidePresence: "Global tilstedeværelse",
	BoschServiceTraining_TechnicalTraining: "Tekniske kurser fra Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "De tilbud og muligheder, som vi tilbyder hos Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Der er egnede kurser til alle målgrupper – fra nybegyndere til professionelle. Tilbuddet omfatter endagskurser og kurser over flere dage. Kurserne tilbydes inden for forskellige tekniske områder og kursusserier (såsom benzinindsprøjtning, dieselindsprøjtning, elektriske systemer). På de tekniske kurser formidler Bosch omfattende knowhow om alle standard køretøjssystemer fra Bosch-systemer til andre producenter, som også diskuteres på kurserne. Indholdet er specielt tilpasset værkstedsmedarbejdernes krav.",
	BoschServiceTraining_TrainedEmployeesDesc: "Uddannede medarbejdere er en indlysende fordel for bilværksteder. Moderne biler bliver stadig mere komplekse, og nye teknologier udgør hele tiden nye udfordringer. Kun med den nødvendige ekspertise kan værkstedsmedarbejdere udføre diagnosticerings-, vedligeholdelses- og reparationsarbejde på aktuelle modeller på en effektiv og omkostningseffektiv måde.",
	BoschServiceTraining_RegardlessOfHow: "Uanset hvor dygtige og erfarne bilspecialisterne er, vil der altid være situationer, hvor de møder nye teknologisystemer eller ukendte fejl. I disse situationer kan fejlfinding og problemløsning blive særligt tidskrævende eller muligvis give dårlige resultater. I værste fald skal kunderne undvære deres bil betydeligt længere end forventet.",
	BoschServiceTraining_ItIsPrecisely: "Og det er lige her, Bosch' servicekurser kommer ind i billedet: De forbedrer servicekvaliteten og fremskynder processerne. Bosch' nuværende kurser hjælper dig til at opnå hurtigere lokalisering af problemer, reparation af flere køretøjer på samme tid og nedbringelse af dine kunders ventetider. De systematiske metoder, du lærer hos Bosch, gør dig hurtigere og mere produktiv.",
	BoschServiceTraining_WorldwidePresenceDesc1: "I mere end 125 år har Bosch været den foretrukne leverandør af originale udstyrskomponenter og -systemer til mange af verdens førende bilproducenter. Denne erfaring kommer med en unik viden om de nyeste teknologier, der danner grundlag for Bosch' support.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Bosch Service Training Centre findes overalt i verden: fra Spanien til Tyrkiet, Sydafrika og Australien til Brasilien - Bosch tilbyder sine medarbejdere praksisorienterede kurser, som altid er på linje med den nyeste teknologi.",
	BoschServiceTraining_AgileCompany: "Fleksibel virksomhed",
	BoschServiceTraining_Collaboration: "Samarbejde",
	BoschServiceTraining_ConnectedMobility: "Tilsluttet mobilitet",
	BoschServiceTraining_ArtificialIntelligence: "Kunstig intelligens",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Tankeledere i dialog",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnose & Testudstyr",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Grundlæggende Teknologi",
	BoschServiceTraining_Icon_Gasoline: "Benzin",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternative Drivlinier",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "El & Elektronik",
	BoschServiceTraining_Icon_BodyAndComfort: "Komfort & Sikkerhed",
	BoschServiceTraining_Icon_ChassisAndBraking: "Chassis & Bremser",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmission & Drivlinje",
	BoschServiceTraining_Icon_CommercialVehicles: "Truck",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Online",
	BoschServiceTraining_Icon_BusinessSkills: "Lederuddannelse",
	BoschServiceTraining_Icon_Accreditations: "Akkrediteringer",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Træningsprogrammer",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrænerFornavnEfternavn",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "En dag i en undervisers liv",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Hver dag er et eventyr",
	ADayInTheLifeOfATrainer_Summary: "Resumé",
	ADayInTheLifeOfATrainer_TheDayJourney: "Dagens program",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Hver dag er en travl dag, men det er altid en givende oplevelse. Fornøjelsen ved at træne eleverne i at vide, at de har nøglen til vores fremtid, er et ansvar, men samtidig så spændende. At se eleverne vokse med hver lektion på daglig og ugentlig basis, beviser for os, at vi arbejder hen imod et fælles mål om at forbedre deres viden og samtidig inkorporere den moderne teknologi, der driver fremtiden!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "I slutningen af ​​hver dag sætter vi os ned og reflekterer over dagens arbejde. Dagene går så hurtigt, men dette er i sig selv et bevis på, at det, vi opnår, ikke kun er givende for eleverne, men også for os som undervisere.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Vi kan gå hjem sidst på dagen tilfredse med, at vi har gjort et rigtig godt stykke arbejde!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Start",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Forberedelse og sidste kontrol af træningsmateriale. Kontroller mail",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Kursus start",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Velkomst til kursist. Daglig skemaoversigt. Lær kursisterne at kende ud fra det enkelte individ.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teori",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Der kræves en vis grad af interaktion med kursisterne for at gøre træningen så interessant som muligt. Sørg for at der er opgaver flettet sammen med teorien for yderligere at engagere gruppen.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Frokost pause",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Det praktiske",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Uddannelsen fortsætter i værkstedet med nogle praktiske opgaver. Som underviser er det altid et krav at være tilstede og tilgængelig som altid for enhver eventualitet og vejledning",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Opsummering",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Tilbage til teorilokalet for en kort snak for at diskutere dagens træning. Besvar eventuelle spørgsmål, der måtte være. Start forberedelsen til de næste træningssessioner.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Sidste dag",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Deltagerbeviser udleveres til deltagerne",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Hjem",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Hvordan kan vi gavne din virksomhed",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Hvordan vores træning udvikler sig",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Vi udvikler os konstant for at sikre, at vi leverer en høj kvalitet og en uddannelse der er \"up-to-date\"",
	BoschTrainingBenefits_TheBoschServiceTraining: "Bosch Service Training Center vil hjælpe dig med at udvikle din virksomhed gennem omfattende og yderst relevant portefølje af tekniske/forretningsmæssige trænings- og videreuddannelseskurser med certificerede kvalifikationer. De følgende sider giver dig et overblik over vores træningsprogrammer samt aktuelle videreuddannelsesmuligheder. Vi ser frem til at møde dig og dine medarbejdere på vores kurser og seminarer.",
	BoschTrainingBenefits_LongStandingExperience: "Langvarig erfaring med og omfattende knowhow om alle systemer inden for automobilteknologi.",
	BoschTrainingBenefits_QuickAndEfficient: "Hurtige og effektive løsninger på mange problemer.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Fordelene",
	BoschTrainingBenefits_LMS: "Learning Management System - Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions dækker hele spektret af bilområder. Specialiser dig inden for specifikke områder eller udvid din kompetence med uddannelsestilbuddene fra Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Erhvervet viden om reparation, vedligeholdelse og servicearbejde.",
	BoschTrainingBenefits_TheHighDegree: "Det høje niveau grad af praktisk indhold og små grupper garanterer målrettet læring",
	BoschTrainingBenefits_ParticipationInTraining: "Deltagelse i uddannelse øger medarbejdernes motivation og tilfredshed, samt overbeviser kvalitetsbevidste kunder",
	BoschTrainingBenefits_IncreasedSales: "Øget salg gennem cross-make system viden",
	BoschTrainingBenefits_MethodicalProcedures: "Metodiske procedurer reducerer ventetiden for kunderne",
	BoschTrainingBenefits_HighlyQualified: "Højt kvalificerede instruktører formidler systematiske procedurer",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identifikation af den kursusløsning, der passer til dig eller din virksomhed",
	BoschTrainingBenefits_DirectOnlineBooking: "Direkte onlinebooking og administration af kurser enten for dig selv eller dine medarbejdere",
	BoschTrainingBenefits_OnlineAccess: "Onlineadgang til læringsdokumentation",
	BoschTrainingBenefits_AccessToYourOwn: "Adgang til din egen personlige kursushistorik og certifikater",
	BoschTrainingBenefits_ToolsForManagement: "Værktøjer til styring af din egen karriereudvikling",
	BoschTrainingBenefits_ManagerHasFull: "Ledere har fuldt overblik over deres medarbejderkurser inde i systemet",
	BoschTrainingBenefits_Access24hours: "Adgang 24 timer, 7 dage om ugen",
	ModernTrainingApproach_KnowledgeIsKey: "Viden er en vigtig succesfaktor for alle værksteder. Derfor tilbyder Bosch et omfattende professionelt kursusprogram. Dette gør det muligt for kursusdeltagerne at erhverve præcis den rigtige viden på det rigtige tidspunkt og sted – viden, der er nødvendig for at kunne vedligeholde og reparere kundens køretøj professionelt. Bosch har tilføjet webcasting som et nyt modulelement i den allerede tilbudte e-læringspakke. De består af servicekursus-sessioner, der er designet specielt til streaming.",
	ModernTrainingApproach_ANewLearning: "En ny læringsmulighed",
	ModernTrainingApproach_YourOnlineTraining: "Dine onlinebaserede kursusmuligheder",
	ModernTrainingApproach_RecentlyTheWorld: "Verden ændrede sig for nylig. Se, hvad vi gør for at hjælpe.",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Onlinekurser",
	ModernTrainingApproach_NewTeachingMethod: "Ny undervisningsmetode",
	ModernTrainingApproach_InAdditionTo: "Ud over de eksisterende fysiske kurser har vi inkluderet live webcastkurser i kursusprogrammet. Vores underviser står til din rådighed med omfattende teoretisk viden i det virtuelle klasseværelse og med praktiske eksempler. Som altid vil du modtage en masse information om køretøjssystemer og de tilsvarende Bosch-produkter. Du har selvfølgelig også mulighed for at stille spørgsmål. Disse spørgsmål vil blive besvaret direkte i den live webcast. Live webcasts varer mellem 60 og 120 minutter. Der kræves en stabil internetforbindelse. Søg efter en passende webcast direkte i vores kursuskatalog ved at indtaste\ Webcast\ i søgefeltet.",
	ModernTrainingApproach_BoschWebcastingHeader: "Bosch-webcasts: Innovative og praktiske",
	ModernTrainingApproach_BoschWebcastingText: "For at deltage i en Bosch-webcast skal deltageren først etablere forbindelse. En individuel adgangskode sendes forud for sessionen for at aktivere login. Op til 200 deltagere kan deltage på samme tid fra et vilkårligt antal lokationer. Kurset ledes af en underviser, og der kan stilles spørgsmål til enhver tid via live chat-funktionen.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Det digitale supplement til undervisning i klasseværelset",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasting og klasseundervisning supplerer hinanden perfekt. I begge tilfælde varetager en kvalificeret underviser undervisningen. Webcasts er velegnede til emner, der kan transmitteres digitalt, uden at man skal arbejde på køretøjet. For eksempel til at lære visse ESI [tronic]-funktioner i et nyt førerassistancesystem at kende. Når det kommer til effektivt teamwork på værkstedet eller praktisk arbejde med en køretøjsteknologi, er fysisk undervisning i klasselokalet eller på værkstedet stadig det primære valg.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Streaming kan være billigere",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Omkostningerne pr. deltager er normalt lavere ved webcasting end med et sammenligneligt klasseværelsesbaseret kursus. Årsagen er, at flere deltagere kan deltage i kurset, og den indsats, der er forbundet med kurset, er betydeligt mindre (ingen rejser til og fra stedet og mindre tid påkrævet).",
	ModernTrainingApproach_BookAndProfitHeading: "Booking og betaling",
	ModernTrainingApproach_BookAndProfitText: "Webcasts kan bookes på vores website, ligesom vores andre kurser og læringstilbud. Du kan vælge enten at betale det individuelle gebyr for hver webcast eller tegne et årligt abonnement på et bestemt antal webcasts.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Sammenligning af Bosch Webcasting og klasseværelsesbaseret undervisning",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Klasseværelse",
	ModernTrainingApproach_WebcastingP1: "Op til 200 deltagere kan deltage på samme tid, uanset hvor i verden de befinder sig",
	ModernTrainingApproach_WebcastingP2: "Lav kompleksitet og relativt lave omkostninger, kræver ingen rejser",
	ModernTrainingApproach_WebcastingP3: "Ideel til specifikke og kortfattede selvstændige emner",
	ModernTrainingApproach_WebcastingP4: "Værksteder kan afholde en webcast for deres medarbejdere, uanset hvor de befinder sig",
	ModernTrainingApproach_WebcastingP5: "50-70 % lavere tidsforbrug end til klasseværelsesbaseret undervisning",
	ModernTrainingApproach_ClassroomTrainingP1: "Op til 16 deltagere mødes på samme tid",
	ModernTrainingApproach_ClassroomTrainingP2: "Rejseudgifter og generelt højere omkostninger pr. person",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideelt, når det gælder optimering af samarbejde eller undervisning direkte på et køretøj",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktiske øvelser og interaktion med underviseren",
	ModernTrainingApproach_ClassroomTrainingP5: "Underviseren kan bedre tilpasse den viden, der skal undervises i, til deltagernes faktiske behov",
	ModernTrainingApproach_OnlineTrainingOfferings: "Onlinebaserede kursustilbud (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "I dag er selvstændig læring på computeren en integreret del af videnoverførsel. Friheden til at vælge tid og sted, ubegrænset mulighed for gentagelse, individuel læringshastighed og interaktivt læringsindhold gør denne metode til et uundværligt studieværktøj.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Alle webbaserede kursuselementer (WBT) er omhyggeligt struktureret. Ud over at tilbyde en bred videnbase kan disse kurser fungere som effektiv forberedelse til kursusprogrammer på Bosch' kursuscentre.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Den virtuelle diagnosticeringssimulering giver brugerne mulighed for rollespil i daglige værkstedssituationer som f.eks. arbejdsgangen i en fejlfindingsprocedure ved hjælp af Bosch' diagnosticeringsanordninger i et virtuelt værksted.",
	ModernTrainingApproach_Advantages: "Fordele",
	ModernTrainingApproach_AdvantagesP1: "En hurtig og effektiv metode til levering af viden",
	ModernTrainingApproach_AdvantagesP2: "Varieret læring",
	ModernTrainingApproach_AdvantagesP3: "Kan bruges lige så ofte, man ønsker det",
	ModernTrainingApproach_AdvantagesP4: "Minimalt forbrug af tid og penge",
	ModernTrainingApproach_AdvantagesP5: "Individuel læringshastighed",
	ModernTrainingApproach_AdvantagesP6: "Fleksibel studietid",
	ModernTrainingApproach_AdvantagesP7: "Mulighed for at gentage",
	ModernTrainingApproach_AdvantagesP8: "Ingen rejseomkostninger",
	ModernTrainingApproach_AdvantagesP9: "Ingen konflikter om kapacitet eller planlægning",
	Dashboard_CoursesViewAndManageYourCourses: "KURSER | Se og administrer dine kurser",
	Dashboard_MyCourses: "Mine kurser",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "KURSUSPROGRAM | Se dine kursusprogrammer",
	Dashboard_MyTrainingProgram: "Mit kursusprogram",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Se og administrer din eLearning-portefølje",
	Dashboard_MyELearning: "Mit eLearning",
	Dashboard_AccountManageYourAccount: "KONTO | Administrer din konto",
	Dashboard_MyAccount: "Min konto",
	Dashboard_MyDashboard: "Dashboard",
	Dashboard_ManageYourAccount: "Administrer din konto",
	Dashboard_Workshops: "Værksteder",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRATION | Administrer dit værksted",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRATION | Administrer dine medarbjedere",
	Dashboard_Administration: "Administration",
	Dashboard_ViewYourCourses: "Se dine kurser",
	Dashboard_ViewYourTrainingPrograms: "Se dine kursusprogrammer",
	Dashboard_TrainingPrograms: "Kursusprogrammer",
	Dashboard_ViewAndEditYourDetails: "Se og rediger dine oplysninger",
	Dashboard_ViewYourELearningCourses: "Se dine eLearning-kurser",
	Dashboard_Pending: "Afventer",
	Dashboard_RequiresAdministratorSignOff: "(kræver administrator-signering)",
	Dashboard_HistoricalRecords: "Historik",
	Dashboard_Registered: "Registreret",
	Dashboard_WaitingList: "Venteliste",
	Dashboard_RecommendedForYou: "Anbefalet til dig",
	Dashboard_AccountDetails: "Kontooplysninger",
	Dashboard_ChangeProfileImage: "skift profilbillede",
	Dashboard_Title: "Titel",
	Dashboard_Role: "Rolle",
	Dashboard_FirstName: "Fornavn",
	Dashboard_LastName: "Efternavn",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobil",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Sprog",
	Dashboard_Save: "Gem",
	Dashboard_Student: "Kursusdeltager",
	Dashboard_WorkshopTrainingAdministrator: "Værksted Kursusadministrator",
	Dashboard_WholesalerTrainingAdministrator: "Grossist Kursusadministrator",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Virksomhedsadministration",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Administrer dine værksteder ...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Administrer dine medarbejdere ...",
	Dashboard_CompanyAdministration_Workshop: "Værksted",
	Dashboard_CompanyAdministration_Address: "Adresse",
	Dashboard_CompanyAdministration_Zip: "Postnummer",
	Dashboard_CompanyAdministration_City: "By",
	Dashboard_CompanyAdministration_Manage: "Administrer",
	Dashboard_AdministrationSection_AdministrationTitle: "Administration",
	Dashboard_AdministrationSection_ManageTheTeam: "Administrer teamet",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRATION | Administrer dine medarbejdere",
	Dashboard_AdministrationSection_Employees: "Medarbejdere",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRATION | Administrer dine grossister",
	Dashboard_AdministrationSection_Wholesalers: "Grossister",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRATION | Administrer dine transaktioner",
	Dashboard_AdministrationSection_Transactions: "Transaktioner",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administration af medarbejdere",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Administrer dine medarbejdere ...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Tilmeld",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Historik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Rediger",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Medarbejder",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Tilføj",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Opret",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registreret",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Afventer",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(kræver administrator-signering)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historiske kurser",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Venteliste",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Aktuel medarbejderliste",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Administration af medarbejdere",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Navn",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Medarbejderkonto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Se og rediger medarbejderdetaljer ...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Indstillinger",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Tillad personlig Bosch Automotive Training Solutions konto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Opgrader medarbejder til virksomhedsadministrator",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Tillad tilmelding til kurser",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Send e-mailinvitation",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Deaktiver konto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Gem",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Inviter",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Rediger",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Administration af leverandør",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Administrer dine leverandører ...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Godkendte leverandører",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Navn",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Slet",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Opret",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Tilføj",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Oprettelse af ny medarbejderkonto",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Opret detaljer for nye medarbejdere ...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Tilføj ny godkendt leverandøradministrator",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Opret detaljer for nye leverandører ...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Vælg godkendt leverandør fra rullemenuen ...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transaktioner",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Se og administrer dine transaktioner ...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Tilbage",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Fakturanummer",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Beskrivelse",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Betalingsmetode",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Dato",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Antal",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Download",
	TrainingCentres_TrainingCentres: "Kursuscenter",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Vores kursuscentre er placeret over hele verden",
	TrainingCentres_CountryCategory: "Landekategori",
	TrainingCentres_CityCategory: "Bykategori",
	TrainingCentres_Country: "Land",
	TrainingCentres_City: "By",
	TrainingCatalogue_TrainingCatalogue: "Kursuskatalog",
	TrainingCatalogue_LearningAtTheHighestLevel: "Læring på højeste niveau",
	TrainingCatalogue_StartDate: "Startdato",
	TrainingCatalogue_EndDate: "Slutdato",
	TrainingCatalogue_TrainingName: "Kursusnavn",
	TrainingCatalogue_Language: "Sprog",
	TrainingCatalogue_Days: "Dage",
	TrainingCatalogue_Seats: "Pladser",
	TrainingCatalogue_Price: "Pris",
	TrainingCatalogue_Location: "Sted",
	TrainingCatalogue_PartNumber: "Varenummer",
	TrainingCatalogue_TrainingCourse: "Kursus",
	TrainingCatalogue_Category: "Kategori",
	TrainingCatalogue_TrainingCategory: "Kursuskategori",
	TrainingCatalogue_CourseName: "Kursets navn",
	TrainingCatalogue_TotalDays: "Antal dage i alt",
	TrainingCatalogue_Venue: "Kursussted",
	TrainingCatalogue_Details: "Detaljer",
	TrainingCatalogue_Prerequisites: "Forudsætninger",
	TrainingCatalogue_CourseType: "Kursustype",
	TrainingCatalogue_LearningMethod: "Læringsmetode",
	TrainingCatalogue_AddToWaitingList: "Føj til venteliste",
	ShoppingCart_ShoppingCart: "Indkøbskurv",
	ShoppingCart_ReviewAndManage: "Gennemse og administrer dine abonnementer",
	ShoppingCart_YouHaveAddedItem: "Du har tilføjet følgende varer til din indkøbskurv",
	ShoppingCart_Item: "Vare",
	ShoppingCart_Quantity: "Antal",
	ShoppingCart_PricePerItem: "Pris pr. vare",
	ShoppingCart_TotalPrice: "Pris i alt",
	ShoppingCart_VatIsIncluded: "Ekskl. moms",
	ShoppingCart_Redeem: "Hvis du ikke har nogen kredit, skal du klikke her for at indløse den",
	ShoppingCart_Purchase: "Hvis du ikke har nogen kredit, skal du klikke her for at købe",
	ShoppingCart_Checkout: "Kassen",
	ShoppingCart_ContinueShopping: "Fortsæt med at handle",
	ShoppingCart_TotalPriceInclVat: "Samlet pris inkl. moms",
	ShoppingCart_Apply: "Anvend",
	ShoppingCart_ShoppingCartEmpty: "Din indkøbskurv er tom",
	ShoppingCart_VatIncluded: "Moms",
	ShoppingCart_ManageYourShopping: "Administrer din shopping",
	ShoppingCart_Participant: "Deltager",
	ShoppingCart_Complete: "Gennemfør",
	ShoppingCart_NetPrice: "Nettopris",
	ShoppingCart_Workshop: "Værksted",
	CheckOut_PreviewYourOrder: "Ordreoversigt",
	CheckOut_YourShoppingCart: "Indkøbskurv",
	CheckOut_Preview: "Vis udskrift",
	CheckOut_Payment: "Betaling",
	CheckOut_Summary: "Resume",
	CheckOut_Back: "Tilbage",
	CheckOut_PleaseReadAndAgreeOurTerms: "Læs og accepter vores vilkår og betingelser",
	CheckOut_IHaveReadTheTermsAndCondition: "Jeg har læst [vilkårene og betingelserne], og jeg forstår og accepterer dem",
	CheckOut_YesIAgree: "Ja – Jeg accepterer vilkårene og betingelserne",
	CheckOut_Continue: "Fortsæt",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Tillykke! Vi har modtaget din ordre",
	CheckOut_OrderConfirmation: "Du modtager endelig ordrebekræftelse, når din anmodning er blevet behandlet",
	CheckOut_WeWillContactYouDirectly: "Vi kontakter dig direkte, hvis vi har spørgsmål relateret til din anmodning",
	CheckOut_AddToCart: "Tilføj til indkøbskurv",
	CheckOut_CreditCart: "Kreditkort",
	CheckOut_DirectDebit: "Direkte debitering",
	CheckOut_TrainingCredits: "Kursuskreditter",
	CheckOut_PreferredWholesaler: "Foretrukken grossist",
	EventCalendar_EventCalendar: "Eventkalender",
	EventCalendar_TrainingEventsForAllYourNeeds: "Kursusevents til alle dine behov",
	TrainingCourse_TrainingCourse: "Kursuskatalog",
	TrainingCourse_LearningAtTheHighestLevel: "Læring på højeste niveau",
	TrainingProgram_Title: "Kursusprogramkatalog",
	TrainingProgram_SubTitle: "Læring på højeste niveau",
	AccountDeactivationModal_Title: "Er du sikker på, at du vil deaktivere denne konto?",
	AccountDeactivationModal_Line1: "Deaktivering af denne konto er permanent og fjerner alt indhold, herunder tjenesteanmodningstransaktioner, saldi for teammedlemmer og profilindstillinger.",
	AccountDeactivationModal_Line2Part1: "Hvis det er inden for en aktiv abonnementsperiode, henvises der til vores afbestillingspolitik i vores",
	AccountDeactivationModal_Line2Part2: "betingelser & vilkår,",
	AccountDeactivationModal_Line2Part3: "før du indsender din anmodning.",
	AccountDeactivationModal_Line3: "Er du sikker på, at du vil sende en anmodning om at deaktivere denne konto?",
	AccountDeactivationModal_Cancel: "Annuller",
	AccountDeactivationModal_Send: "Send",
	CurrentUserAccountDeactivationModal_Title: "Er du sikker på, at du vil deaktivere din konto?",
	CurrentUserAccountDeactivationModal_Line1: "Deaktivering af denne konto er permanent og fjerner alt indhold, herunder tjenesteanmodningstransaktioner, saldi for teammedlemmer og profilindstillinger.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Hvis det er inden for en aktiv abonnementsperiode, henvises der til vores afbestillingspolitik i vores",
	CurrentUserAccountDeactivationModal_Line3: "Er du sikker på, at du vil sende en anmodning om at deaktivere din konto?",
	CurrentUserAccountDeactivationModal_Yes: "Ja",
	CurrentUserAccountDeactivationModal_No: "Nej",
	CourseWithdrawalModal_Title: "Din aflysning er ikke endelig, før den er blevet godkendt af vores kursusadministration.",
	CourseWithdrawalModal_Line1: "Du vil modtage den endelige bekræftelse via e-mail, når den er godkendt. Hvis vi har spørgsmål, vil vi kontakte dig direkte. Er du sikker på, at du vil indsende din anmodning om aflysning?",
	CourseWithdrawalModal_Line2Part1: "Læs venligst vores",
	CourseWithdrawalModal_Line2Part2Link: "Afbestillingspolitik",
	CourseWithdrawalModal_Line2Part3: "Fortsæt med anmodning om aflysning?",
	CourseWithdrawalModal_Yes: "Ja",
	CourseWithdrawalModal_No: "Nej",
	Settings_Settings: "Indstillinger",
	Settings_ManageYourSettings: "Administrer dine indstillinger",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Loginoplysninger om mit Bosch Global ID",
	Settings_Edit: "Rediger",
	Settings_CompanyDetails: "Virksomhedsdetaljer",
	Settings_RequestAccountDeletion: "Anmod om sletning af konto",
	Settings_AccountDeletionText: "Hvis du sletter din konto, slettes alle dine data (herunder din adresse og betalingsdata) og disse vil ikke længere være tilgængelige.",
	Settings_DeleteAccountRequestConfirmation: "Bekræftelse af sletning",
	Settings_DeleteAccountRequestConfirmationText: "Vi har modtaget din anmodning om sletning af din konto og vil kontakte dig via e-mail eller telefon for endelig bekræftelse",
	RecommendedHotels_RecommendedHotels: "Anbefalede hoteller",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Vores kursuscentre er placeret over hele verden",
	Error_Common: "Noget gik galt!",
	Error_404: "Den side, du besøgte, findes ikke",
	Error_401: "Du har ikke adgang til denne side",
	Error_500: "Serveren rapporterer en fejl",
	Dashboard_Courses_Table_Course: "Kursus",
	Dashboard_Courses_Table_Date: "Dato [dage]",
	Dashboard_Courses_Table_Venue: "Lokation",
	Dashboard_Courses_Table_TrainingType: "Type",
	Dashboard_Courses_Table_Action: "Handling",
	Dashboard_Courses_Table_TasksToComplete: "Du skal udføre følgende opgaver",
	Dashboard_Courses_Table_Withdraw: "Afmeld",
	Dashboard_Courses_Table_Remove: "Fjern",
	Dashboard_Courses_Table_View: "Oversigt",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Ingen historik",
	Dashboard_Courses_Table_CompletionDate: "Afslutningsdato",
	Dashboard_Courses_Table_Actions_Assignments: "Opgaver",
	Dashboard_Courses_Table_Actions_Certification: "Certificering",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluering",
	Dashboard_Courses_Table_Actions_Assessments: "Test",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Tilføjet til kalender",
	Dashboard_Courses_Table_CourseDetails_Task: "Opgave",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Afleveringsdato",
	ContactUs_Headline: "Kontakt os",
	ContactUs_HowCanWeHelp: "Hvordan kan vi hjælpe dig?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Brev",
	ContactUs_Phone_Headline: "Ring til os!",
	ContactUs_Phone_Description: "Vi ser frem til dig opkald og til at assistere dig.",
	ContactUs_Phone_PhoneNumber: "Brug venligst kontaktformularen til at sende os en besked.",
	ContactUs_Phone_ChargeNotice: "Gebyrerne varierer afhængigt af din tjenesteudbyder og land.",
	ContactUs_Letter_Headline: "Send et brev!",
	ContactUs_Letter_Description: "Har du et spørgsmål, ønsker eller forslag? Vi ser frem til din henvendelse.",
	ContactUs_Email_Headline: "Send en e-mail!",
	ContactUs_Email_Description: "Send os din besked ved at udfylde formularen nedenfor. En af vores rådgivere vil efterfølgende kontakte dig.",
	ContactUs_Email_InputName: "Navn*",
	ContactUs_Email_InputCompany: "Virksomhed",
	ContactUs_Email_InputCountry: "Land*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Besked*",
	ContactUs_Email_DataProtection: "Databeskyttelsesmeddelelse*",
	ContactUs_Email_DataProtectionConfirm: "Jeg har læst privatlivspolitikken*",
	ContactUs_Email_SecurityCheck: "Sikkerhedskontrol",
	ContactUs_Email_SecurityCodeConfirm: "Indtast venligst de korrekte tegn, du ser i boksen ovenfor.",
	ContactUs_Email_SecurityCodeInvalid: "Captcha-tekstsvaret, du indtastede, er forkert.",
	ContactUs_Email_MandatoryNotice: "Udfyld venligst obligatoriske felter.",
	ContactUs_Email_SuccessMessage: "Din besked er blevet sendt",
	ContactUs_Email_TakenNoteDataProtection1: "Jeg har læst",
	ContactUs_Email_TakenNoteDataProtection2: "Fortrolighedspolitik*",
	ContactUs_Email_SubmitButton: "Indsend",
	ContactUs_Email_FieldErrorMessage: "Udfyld venligst obligatoriske felter.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Din konto er i øjeblikket ved at blive gennemgået!",
	AccountInReviewModal_Paragraph1: "Tak, fordi du har registreret en personlig konto på Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Vores træningspersonale er i øjeblikket ved at gennemgå og konfigurere din konto. Du vil modtage en endelig bekræftelse via mail, når din konto er blevet konfigureret.",
	AccountInReviewModal_Paragraph3: "I mellemtiden er du velkommen til at gennemse vores hjemmeside med begrænset adgang.",
	AccountInReviewModal_Paragraph4: "Hvis du har spørgsmål, eller hvis din konto ikke er behandlet inden for 48 timer eksklusive weekender og helligdage, kan du kontakte os",
	AccountInReviewModal_ContactUsLink: "her",
	AccountInReviewModal_OKButton: "Okay, lad mig få adgang!",
	Header_YesButton: "Ja",
	Header_NoButton: "Nej",
	Header_LogOutText: "Vil du logge ud?",
	Footer_EnquiryText: "Vi ser frem til din henvendelse,",
	Footer_Telephone: "Telefon,",
	Footer_TermsAndConditions: "Vilkår & betingelser",
	Footer_HowOurTrainingEvolves: "Hvordan vores træning udvikler sig",
	FooterSearch_SearchFieldPlaceholder: "Søg",
	TrainingCatalogue_Action: "Handling",
	TrainingCatalogue_Duration: "Varighed",
	TrainingCatalogue_Information: "Information",
	Settings_PersonalDetails: "Personlige detaljer",
	Reset_All_Filters: "Nulstil alle filtre",
	SearchField_AllResults: "Alle resultater",
	TrainingCentresLayout_ViewDetails: "Vis detaljer",
	Dropdown_Placeholder: "Vælg...",
	WaitlistPopup_SelectButtonText: "Vælg",
	WaitlistPopup_StartDate: "Start dato",
	WaitlistPopup_EndDate: "Slutdato",
	WaitlistPopup_Language: "Sprog",
	WaitlistPopup_City: "By",
	WaitlistPopup_Workshop: "Værksted",
	WaitlistPopup_Student: "Studerende",
	WaitlistPopup_PreferredLocation: "Foretrukken placering",
	WaitlistPopup_SignUpCourseWaitingList: "Tilmeld dig kursets venteliste",
	WaitlistPopup_SignUpWithoutSelectingDate: "Tilmeld dig venteliste uden at vælge en dato",
	WaitlistPopup_GeneralWaitList: "Generel venteliste",
	WatinglistPopup_CourseLabel: "Kursus",
	Common_ContinueButton: "Forsæt",
	UserAccountDeactivationModal_ConfirmLabel: "Bekræfte",
	Filter_FilterLabel: "Filter",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Du er allerede på ventelisten, vil du fjerne din eksisterende registrering og lave en ny?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Ja, fjern",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nej, annuller",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Kursus aflysning",
	WaitinglistPopup_SignUpButtonText: "Tilmelde",
	MyAccount_FirstNameRequied: "Fornavn er påkrævet",
	MyAccount_LastNameRequired: "Efternavn er påkrævet",
	MyAccount_RoleRequired: "Stilling er påkrævet",
	MyAccount_MobileRequired: "Mobil er påkrævet",
	MyAccount_EmailRequired: "E-mail er påkrævet",
	MyAccount_LanguageRequired: "Sprog er påkrævet",
	MyAccount_UpdateSuccessMessage: "Dine kontooplysninger er opdateret!",
	MyAccount_UpdateSuccessModal_ButtonText: "Ja, luk vinduet",
	Infor_CorporateInformation_Header: "Virksomhedsinformation",
	Infor_LegalNotice_Header: "Juridisk meddelelse",
	Infor_PrivacyPolicy_Header: "Databeskyttelsesmeddelelse (privatlivspolitik)",
	CreateEmployeeAccountModification_FormValidation_Role: "Stilling er påkrævet",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Fornavn er påkrævet",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Efternavn er påkrævet",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobil er påkrævet",
	CreateEmployeeAccountModification_FormValidation_Email: "E-mail er påkrævet",
	CreateEmployeeAccountModification_FormValidation_Language: "Sprog er påkrævet",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Invitation til at deltage i Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Du har tilføjet et nyt medlem til dit team!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Vil du have os til at dele en e-mailinvitation med dit nye teammedlem med instruktioner om, hvordan de kan oprette deres egen personlige konto til Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Nej",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Ja, send invitation",
	ShoppingCart_Venue_Fee: "Lokalegebyr",
	ShoppingCart_Trainning_Name: "Trænings navn",
	ShoppingCart_Available_Credits: "Tilgængelige Credits",
	ConfirmItsMeModal_Hi: "Hej",
	ConfirmItsMeModal_Description1: "Du har gennemført invitationen, som din virksomheds administrator delte, til at oprette din egen personlige konto på Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Inden du fortsætter, bedes du bekræfte eller opdatere dine kontooplysninger.",
	ConfirmItsMeModal_Description3: "Efter bekræftelse bliver du bedt om at logge ind igen for at fuldføre oprettelsen af ​​din konto.",
	ConfirmItsMeModal_CompanyDetails: "Virksomhedsoplysninger",
	ConfirmItsMeModal_YourDetails: "Dine detaljer",
	ConfirmItsMeModal_YourDetails_Title: "Titel",
	ConfirmItsMeModal_YourDetails_Function: "Funktion",
	ConfirmItsMeModal_YourDetails_Name: "Navn",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobil",
	ConfirmItsMeModal_YourDetails_Email: "E-mail",
	ConfirmItsMeModal_YourDetails_Language: "Sprog",
	ConfirmItsMeModal_ConfirmButton: "Bekræft",
	SelectWholesalerModal_WholesalerGroupLabel: "Leverandør",
	SelectWholesalerModal_WholesalerBranchLabel: "Butik",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Vælg venligst",
	ApplyButton: "Ansøg",
	SelectWholesalerModal_Title: "Vælg leverandør",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Vil du fjerne din registrering?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Din registrering er fjernet med succes!",
	WaitlistPopup_AddToWaitListSuccess: "Din registrering er tilføjet med succes!",
	ErrorCommon_Header: "FEJL",
	CommonButton_Close: "Luk",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Luk venligst dette vindue for at fortsætte!",
	EmbeddedVideoPlayer_StartButtonText: "Start",
	EmbeddedVideoPlayer_RefresherButtonText: "Opdater",
	DeactivateWholesalerModal_ConfirmMessage: "Er du sikker på, at du vil fjerne denne leverandør?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Send invitationsmail lykkedes!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Inviter igen",
	ContactUs_Mobile_DK: "+45 44 89 89 89",
	ContactUs_Address_DK: "Robert Bosch A/S,Telegrafvej 1,2750 Ballerup,Danmark",
	ConfirmItsMeModal_CompanyDetails_Name: "Virksomhedsnavn",
	ConfirmItsMeModal_CompanyDetails_Address: "Adresse",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Postnummer",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-mail",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Afmelding succesfuld",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Kontooprettelsen mislykkedes. Kontoen eksisterer allerede!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Opdatering af kontoen mislykkedes. Kontoen eksisterer allerede!",
	Footer_ExploreTrainingStories: "Udforsk alle vores træningshistorier",
	Breadcrumb_Home: "Home",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Historier",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "En dag i en træners liv",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch træningsfordele",
	Breadcrumb_Stories_TrainingApproach: "En moderne træningstilgang",
	Breadcrumb_Settings: "Indstillinger",
	Breadcrumb_ShoppingCart: "Indkøbskurv",
	Breadcrumb_Checkout: "Tjek ud",
	Breadcrumb_OrderSummary: "Ordreoversigt",
	Breadcrumb_Exception: "Undtagelse",
	Breadcrumb_Dashboard: "Dashboard",
	Breadcrumb_MyCourses: "Mine kurser",
	Breadcrumb_MyELearning: "Min e-læring",
	Breadcrumb_CompanyAdministration: "Virksomhedsadministration",
	Breadcrumb_EmployeeManagement: "Håndtering af medarbejdere",
	Breadcrumb_EmployeeAccount: "Medarbejderkonto",
	Breadcrumb_EmployeeCreation: "Oprettelse af medarbejdere",
	Breadcrumb_Administration: "Administration",
	Breadcrumb_EmployeeAdministration: "Administration af medarbejdere",
	Breadcrumb_WholesalerManagement: "Håndtering af Leverandører",
	Breadcrumb_NewWholesalerAccountCreation: "Oprettelse af ny leverandørkonto",
	Breadcrumb_Transactions: "Transaktioner",
	Breadcrumb_MyAccount: "Min konto",
	Breadcrumb_TrainingCentres: "Træningscentre",
	Breadcrumb_EventCalendar: "Kursuskalender",
	Breadcrumb_TrainingCourseCatalogue: "Kursuskatalog",
	Breadcrumb_RecommendedHotels: "Anbefalede hoteller",
	Breadcrumb_CorporateInformation: "Leverandør",
	Breadcrumb_LegalNotice: "Juridiske rettighedsforhold",
	Breadcrumb_DataProtectionNotice: "Databeskyttelsesnotifikation",
	Breadcrumb_PrivacyPolicy: "Databeskyttelsesnotifikation",
	Breadcrumb_TermCondition: "Betingelser & vilkår",
	Breadcrumb_Terms: "Betingelser",
	Breadcrumb_PrivacyStatement: "Databeskyttelsesnotifikation",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Leverandør",
	Breadcrumb_RegisterAccount: "Registrer konto",
	Stories_ExploreMore: "Udforsk mere",
	CheckOutLayout_ShoppingCartHeader: "Indkøbskurv",
	CheckOutLayout_AddedItemCartMessage: "Du har tilføjet følgende til din indkøbskurv",
	CheckOutLayout_CheckYourCartMessage: "Tjek din indkøbskurv",
	ModernTrainingApproach_DateText: "2/28/2022",
	ADayInLifeofATrainer_DateText: "2/28/2022",
	BoschTrainingBenefits_DateText: "2/28/2022",
	BoschServiceTraining_DateText: "2/28/2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Søg medarbejdere",
	CheckOutLayout_OrderWasNotPlaced_Message: "Undskyld! Din ordre blev ikke afgivet",
	CheckOutLayout_RefusalReason: "Årsag til afslag",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Betaling mislykkedes. Prøv venligst igen med en anden betalingsmetode",
	CheckOutLayout_SubmittingApiFailed_Message: "Indsendelse af API mislykkedes",
	CartItem_ChooseWorkshop_Message: "Start venligst med at vælge et værksted",
	CartItem_ChooseParticipant_Message: "Vælg venligst deltager",
	CompanyAdministrationTable_SearchBox_Placeholder: "Søg værksteder",
	CartItem_RemoveCartItem_ConfirmText: "Vil du fjerne denne vare fra indkøbskurven?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funktion",
	AddToCartSuccessModal_Message: "Din indkøbskurv er blevet opdateret!",
	AddToCartSuccessModal_ContinueButtonText: "Fortsæt med at handle",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Min indkøbsvogn",
	AddVoucher_Header: "Bekræft",
	AddVoucher_InputPlaceholder: "Indtast din kode og bekræft",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Desværre har vi ingen planlagte begivenheder af dette kursus i øjeblikket. Tilmeld dig venligst venteliste, så informerer vi dig, når et arrangement bliver tilgængeligt.",
	ADYEN_ERR_2_: "Transaktionen er blevet afvist",
	ADYEN_ERR_3_: "Henvisning fejl",
	ADYEN_ERR_4_: "Transaktionen blev ikke gennemført på grund af en fejl, der opstod ved overtagers ende.",
	ADYEN_ERR_5_: "Kortet, der blev brugt til transaktionen, er blokeret og er derfor ubrugeligt.",
	ADYEN_ERR_6_: "Kortet, der blev brugt til transaktionen, er udløbet. Derfor er det ubrugeligt.",
	ADYEN_ERR_7_: "Der opstod et beløbsmisforhold under transaktionsprocessen.",
	ADYEN_ERR_8_: "Det angivne kortnummer er forkert eller ugyldigt.",
	ADYEN_ERR_9_: "Det er ikke muligt at kontakte køberens bank for at godkende transaktionen.",
	ADYEN_ERR_10_: "Køberens bank understøtter ikke eller tillader ikke denne type transaktioner.",
	ADYEN_ERR_11_: "3D Secure-godkendelse blev ikke udført, eller den blev ikke udført med succes.",
	ADYEN_ERR_12_: "Kortet har ikke penge nok til at dække det skyldige beløb. Dette problem kan skyldes den månedlige beløpsgrænse på dit kort. Venligst prøv med et andet kort såsom et MasterCard, eller kontakt din bank for at nulstille din månedlige beløpsgrænse.",
	ADYEN_ERR_14_: "Mulig svindel.",
	ADYEN_ERR_15_: "Transaktionen blev annulleret.",
	ADYEN_ERR_16_: "Køberen annullerede transaktionen, før den gennemførte den.",
	ADYEN_ERR_17_: "Den angivne pinkode er forkert eller ugyldig.",
	ADYEN_ERR_18_: "Køberen angav en forkert pinkode mere end tre gange i træk.",
	ADYEN_ERR_19_: "Det er ikke muligt at validere det angivne pinkode.",
	ADYEN_ERR_20_: "Mulig svindel.",
	ADYEN_ERR_21_: "Transaktionen blev ikke indsendt korrekt til behandling.",
	ADYEN_ERR_22_: "Risikokontrollen markerede transaktionen som svigagtig (risikoscore> = 100); derfor afbrydes operationen.",
	ADYEN_ERR_23_: "Følgende afviste koder er knyttet til denne afvisningsårsag: Transaktion er ikke tilladt til udsteder/kortholder",
	ADYEN_ERR_24_: "Den angivne CVC (kortets sikkerhedskode) er ugyldig.",
	ADYEN_ERR_25_: "Følgende afvisningskoder er knyttet til denne afvisningsårsag: Ugyldigt kort i dette land",
	ADYEN_ERR_26_: "R1: Tilbagekaldelse af godkendelsesbekendtgørelse / \"R3: Tilbagekaldelse af alle tilladelsesordre\" / \"R0: Stop betalingsordre\"",
	ADYEN_ERR_27_: "Dette svar kortlægger alle de svarskoder, der ikke kan kortlægges pålideligt. Dette gør det lettere at fortælle generiske tilbagegang (f.eks. Mastercard \"05: Do not honor\" svar) fra mere specifikke.",
	ADYEN_ERR_28_: "Det tilladte tilbagetrækningsbeløb for køberens kort er overskredet.",
	ADYEN_ERR_29_: "Antallet af udbetalinger, der er tilladt for køberens kort, er overskredet.",
	ADYEN_ERR_31_: "Udsteder rapporterede transaktionen som mistanke om svig.",
	ADYEN_ERR_32_: "Adressedataene, som shopperen indtastede, er forkerte.",
	ADYEN_ERR_33_: "Køberens bank kræver, at køberen indtaster en online-pinkode.",
	ADYEN_ERR_34_: "Forhandlerens bank kræver en bankkonto for at gennemføre købet.",
	ADYEN_ERR_35_: "Forhandlerens bank kræver en opsparingskonto for at gennemføre købet.",
	ADYEN_ERR_36_: "Køberens bank kræver, at køberen indtaster en mobil PIN-kode.",
	ADYEN_ERR_37_: "Køberen opgav transaktionen, efter at de havde forsøgt en kontaktløs betaling og blev bedt om at prøve en anden kortindtastningsmetode (pinkode eller swipe).",
	ADYEN_ERR_38_: "Udstederen afviste godkendelsesfritagelsesanmodningen og kræver godkendelse til transaktionen. Prøv igen med 3D Secure.",
	ADYEN_ERR_39_: "Udstederen eller ordningen kunne ikke kommunikere resultatet via RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Fornavn må ikke indeholde specialtegn (f.eks.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Fornavn skal være mindre end 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Efternavn må ikke indeholde specialtegn (f.eks.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Efternavn skal være mindre end 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobilnummer må ikke indeholde specialtegn (f.eks.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobilnummer skal være mindre end 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telefonnummer må ikke indeholde specialtegn (f.eks.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telefonnummer skal være mindre end 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Email adressen er ugyldig",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-mail skal være mindre end 100",
	ConfirmGeolocationModal_ConfirmButton: "Fortsæt",
	ConfirmGeolocationModal_CancelButton: "Ændre",
	ConfirmGeolocationModal_CurrentCountrySelection: "Dit aktuelle landevalg er:",
	ConfirmGeolocationModal_AskChangeCountry: "Ønsker du at forblive på denne side eller ændre din landevalg?",
	Addtocart_Modal_AddToCartFailTitle: "Tilføjelse af deltager mislykkedes",
	Addtocart_Modal_AlreadyInCart: "Deltageren er allerede tilmeldt dette kursus",
	TrainingCatalogue_DateRange: "Datointerval",
	TrainingCatalogue_DateRange_placeholder: "Vælg datointerval",
	Dashboard_MyPendingCourses: "Mine afventende kurser",
	Dashboard_MyWaitingList: "Min venteliste",
	Dashboard_LatestNews: "Seneste nyt",
	SettingsMainPage_EditAccountButton: "Rediger konto",
	SettingsMainPage_EditBoschId: "Rediger Bosch ID",
	EventInfoView_EventDetailTab: "Begivenhedsdetaljer",
	EventInfoView_AssignmentsTab: "Afleveringer",
	EventInfoView_EvaluationTab: "Evaluering",
	EventInfoView_AttachmentTab: "Vedhæftede filer",
	EventInfoView_CourseDescriptionTab: "Beskrivelse",
	EventInfoView_DescriptionTab_General: "Generel",
	EventInfoView_DescriptionTab_PartLabel: "Del",
	AssignmentTab_Description: "Her finder du al den information, du skal bruge for at gennemføre dette kursus",
	AssignmentTab_Columns_Download: "Download",
	AssignmentTab_Columns_Upload: "Upload",
	AssignmentTab_Columns_TaskComplete: "Opgave fuldført",
	EventDetailTab_Location_Message_Video: "Dette er en onlinebegivenhed, som kan tilgås ved at bruge linket \"Start video\".",
	EventDetailTab_Location_Message_WebBased: "Dette er en online begivenhed, som kan tilgås ved at bruge linket \"Start WBT\".",
	EventDetailTab_Location_Message_WebCast: "Dette er en online begivenhed, som kan tilgås ved at bruge linket \"Tilmeld dig nu\".",
	EventDetailTab_Link_Video: "Start video",
	EventDetailTab_Link_Video_Remark: "*Start af videoen åbner et nyt vindue. Du vil blive omdirigeret tilbage til denne side, når vinduet er lukket.",
	EventDetailTab_Link_WebBased: "Start WBT",
	EventDetailTab_Link_WebBased_Remark: "*Start af WBT åbner et nyt vindue. Du vil blive omdirigeret tilbage til denne side, når vinduet er lukket.",
	EventDetailTab_Link_WebCast: "Deltag nu",
	EventDetailTab_Link_WebCast_Remark: "*Tilmeld dig nu-linket vil være aktivt 30 minutter før begivenhedens start.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Varighed (timer)",
	EventDetailTab_Title_Trainers: "Træner(e)",
	EventDetailTab_Title_Language: "Sprog",
	EventDetailTab_Title_Score: "Score (%)",
	EventDetailTab_Title_Calendar: "Tilføj til kalender",
	EventDetailTab_Title_Certificate: "Download certifikat",
	EventDetailTab_Title_Messages: "Beskeder",
	EventDetailTab_Title_StartDate: "Start dato",
	EventDetailTab_Title_AvailableFrom: "Ledig fra",
	EventDetailTab_Title_Time: "Tid",
	EventDetailTab_Title_EndDate: "Slutdato",
	EventDetailTab_Title_ExpiresOn: "Udløber",
	EventDetailTab_Title_Room: "Sted",
	EventDetailTab_Title_Method: "Metode",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Beliggenhed",
	AttachmentTab_EmptyScreenDescription: "Der er ingen vedhæftede filer til denne begivenhed.",
	AttachmentTab_Columns_Download: "Download",
	AttachmentTab_Description: "Her finder du nogle vigtige oplysninger, der kan hjælpe dig med dette kursus",
	EvaluationTab_Annotation: "Vi værdsætter din feedback og vil meget gerne høre fra dig for at se, hvordan vi har det. Tag dig tid til at udfylde følgende evaluering for at hjælpe os med løbende at forbedre vores service. Tak for hjælpen.",
	EvaluationTab_Notice: "Bedømmelserne er som følger",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Besvar venligst alle obligatoriske spørgsmål, før du indsender din evaluering",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Din feedback er blevet indsendt",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Evalueringsindsendelsen mislykkedes",
	TrainingCatalogueLayout_ExportButtonText: "Eksport",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Evalueringen blev afsluttet eller er udløbet.",
	EventDetailTab_Link_CopiedTooltip: "Kopieret til udklipsholder",
	EventDetailTab_QRCode_ExplanationText_WBT: "Scan for at starte WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Scan for at starte video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Scan for at starte webcast",
	Footer_TrainingCoure_Headline: "Uddannelseskurser leveret af Bosch",
	Footer_TrainingCourse_Content: "Som et verdenskendt Superbrand og med over et århundredes erfaring inden for innovation har Bosch sat standarderne globalt som leverandør af bilsystemer, reservedele og tekniske løsninger. En stor del af vores styrke kommer fra vores tætte partnerskaber med bilproducenter, for hvem vi designer, udvikler og producerer state-of-the-art systemer og komponenter over hele verden. Som følge heraf har Bosch cementeret en stærk historie inden for automotive eftermarkedsprodukter og support med en mangfoldig portefølje af reservedele, diagnostiske løsninger og værkstedstjenester. Læring og udvikling er sikre nøgler til succes, og denne filosofi er understøttet af en bred vifte af kurser inden for alle områder inden for bilteknologi, køretøjssystemer og diagnostisk ekspertise.",
	SearchField_NoRecordsFound: "Ingen registreringer fundet",
	TrainingCatalogue_FreeTextSearch: "Fri tekst",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Søg",
	CheckoutLayout_WholeSalerGroup: "Vælg Grossistgruppe",
	CreditInsufficientModal_Message: "Du har ikke tilstrækkelig kredit til at bruge denne betalingsmetode. Prøv venligst igen med en anden betalingsmetode.",
	ShoppingCart_Required_Credits: "Total pris",
	MaintainingInfo_Message1: "Hjemmesiden er i vedligeholdelsestilstand.",
	MaintainingInfo_Message2: "Vi kommer snart tilbage.",
	CountryValidationModal_Message: "Du kunne ikke logge ind på [CountryA], da din konto er blevet oprettet i [CountryB].",
	CountryValidationModal_ButtonText: "Fortsæt til [Country]",
	EventTable_LoginRequired: "Log ind for at registrere",
	TrainingPartTableLayout_Date: "Dato",
	TrainingPartTableLayout_StartTime: "Starttidspunkt",
	TrainingPartTableLayout_EndTime: "Sluttid",
	TrainingPartTableLayout_Duration: "Varigheder (timer)",
	TrainingPartTableLayout_TrainingMethod: "Træningsmetode",
	MyCoursesTableContents_ActiveHeader: "Aktive kurser",
	MyCoursesTableContents_ActiveTabHeader: "Aktiv",
	MyCoursesTableContents_ScheduledHeader: "Planlagte kurser",
	MyCoursesTableContents_ScheduledTabHeader: "Planlagt",
	MyCoursesTableContents_OnlineHeader: "Online kurser",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Aflyste kurser",
	MyCoursesTableContents_CancelledTabHeader: "Annulleret",
	OnlineCoursesTable_Title_Progress: "Fremskridt",
	ScheduledCoursesTable_Title_Date: "Dato [dage]",
	CoursesTable_Title_Completed: "Afsluttet",
	Footer_TrainingProgram: "Træningsprogrammer",
	Breadcrumb_TrainingProgram: "Træningsprogrammer",
	TrainingProgram_Title_Table: "Titel",
	TrainingProgram_TrainingProgram: "Træningsprogrammer",
	TrainingProgram_TargetGroup: "Målgruppe",
	TrainingProgram_MaxLength: "Varighed",
	TrainingProgram_Information: "Information",
	TrainingProgram_Detail: "Detaljer",
	TrainingProgram_Modules: "Moduler",
	TrainingProgram_Course: "Kurser",
	TrainingProgram_PartNumber: "Varenummer",
	TrainingProgram_Max_Length: "Maksimal længde",
	TrainingProgram_Duration: "Varighed",
	TrainingProgram_Exam: "Eksamen",
	TrainingProgram_Assign_Popup: "Tilmeld dig træningsprogram",
	TrainingProgram_Student: "Studerende",
	TrainingProgram_Assign: "Tildel",
	TrainingProgram_Popup_Remove_Title: "Aflysning af træning?",
	TrainingProgram_Description: "Beskrivelse",
	TrainingProgram_Days: "Dage",
	TrainingProgram_Type: "Type",
	TrainingProgram_Action: "Handling",
	TrainingProgram_Enrolled: "Er allerede tilmeldt dette træningsprogram",
	TrainingProgram_SelfAssign_Enrolled: "Du er allerede tilknyttet dette træningsprogram",
	TrainingProgram_Warning: "Tilmeld venligst en anden kursist eller vælg et andet træningsprogram.",
	TrainingProgram_Workshop: "Værksted",
	TrainingProgram_Durations: "År",
	TrainingProgram_Duration_DaysLabel: "Dage",
	TrainingProgram_HasExam_Yes: "Ja",
	TrainingProgram_HasExam_No: "Ingen",
	AssignModal_SuccessModal_Header: "Tillykke!",
	AssignModal_SuccessModal_Message: "Er blevet tilmeldt dette træningsprogram med succes.",
	AssignModal_SelfAssign_SuccessModal_Message: "Du er blevet tildelt træningsprogrammet.",
	Dashboard_MyTraningProgram: "Mit træningsprogram",
	Dashboard_MyTraningProgram_Title: "Titel",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Vis",
	Dashboard_MyTraningProgram_Register: "Tilmeld",
	Dashboard_MyTraningProgram_Records: "Historik",
	Dashboard_MyTrainingProgram_In_Progress: "I gang",
	Dashboard_MyTrainingProgram_Accredited: "Akkrediteret",
	Dashboard_Program_Modal_Withdraw: "Afmeld",
	Dashboard_Program_Modal_Grade: "Karakter",
	Dashboard_MyTrainingProgram_Registered: "Registreret",
	Dashboard_MyTrainingProgram_Completedd: "Afsluttet",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Ja",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nej",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Vær opmærksom på, at afmelding betyder, at du trækker dig fra hele træningsprogrammet, ikke kun fra et individuelt træningsforløb.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Ønsker du stadig at afmelde dig fra træningsprogrammet?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Afventer",
	WithdrawProgramModal_Withdraw_Success_Message: "Du er blevet afmeldt fra træningsprogrammet!",
	Common_Warning_Title: "Advarsel!",
	Common_Error_Title: "Ups, noget gik galt!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Tilføj til kurv",
	AddEventFromCatalogToCartModal_HoursText: "Timer",
	AddElearningToCartSuccessModal_Message: "er blevet tilføjet til din indkøbskurv!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Den valgte deltager er blevet tilmeldt dette kursus, eller kurset er allerede lagt i kurven.",
	EventDetailTab_SubjectForChange_Message: "Der tages forbehold for ændringer",
	Dashboard_MyTrainingProgram_Status_Failed: "Ikke bestået",
	Dashboard_MyTrainingProgram_Status_Passed: "Bestået",
	Dashboard_MyTrainingProgram_Status_Merit: "Merit",
	Dashboard_MyTrainingProgram_Status_Distinction: "Excellence",
	Dashboard_Employee_Courses: "Kurser",
	Dashboard_Employee_Program: "Træningsprogram",
	Dashboard_Employee_WaitList: "Venteliste",
	Dashboard_Employee_Account: "Konto",
	Course_Price_Free: "Gratis",
	Dashboard_Employee_Account_Update_Success_Message: "Du har opdateret dine medarbejderoplysninger!",
	Dashboard_Supplier: "Leverandør",
	Event_Detail_Comment: "Der er ingen beskeder",
	DownLoad_PDF: "PDF Download",
	TrainingCatalogue_Currency: "Valuta",
	My_Wallet: "Min Wallet",
	Wallet: "Wallet",
	Credits_Balance: "Balance",
	MyFullCalendar_Today: "I dag",
	Header_Available_Languages: "Sprog",
	Header_CurrentRegion: "Du er i",
	Header_ChangeRegions: "Vil du gerne skifte land?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Login / Registrer",
	Header_RegisterCourse: "Tilmeld",
	Homepage_Start: "Kom i gang",
	Homepage_Login: "Log ind",
	MainPage_Title: "Den ultimate værkstedsløsning",
	MainPage_Content: "Dit værksted, dit valg",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Gør det muligt for det moderne værksted at arbejde professionelt, pålideligt og effektivt inden for diagnostiske procedurer, fejlfinding, reparationer og servicering af alle typer køretøjer.",
	BoschNews_TrainingNews: "Nyheder",
	Service_Assist_Workshop: "Forbedre din oplevelse med Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Hvad kan jeg gøre med appen?",
	Enhance_Body_CDM3_Item_1: "Book kurser og vedligehold din læringsrejse",
	Enhance_Body_CDM3_Item_2: "Download undervisningsmateriale og kursusbeviser",
	Enhance_Body_CDM3_Item_3: "Start eLearning-kurser",
	Enhance_Body_CDM3_Item_4: "Få adgang til forskellige biltjenester inden for en mobilapplikation",
	Enhance_Body_CDM3_Item_5: "Live videotransmission med Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Gratis værktøj til automatisk bilscanning",
	Training_Stories: "Træningshistorier",
	SettingsMyAccount_JoinCompanyButton: "Meld dig ind i firmaet",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Meld dig ind i firmaet",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Indtast venligst din aktiveringskode nedenfor",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Bemærk! Du skal kontakte din virksomhedsadministrator for at få en aktiveringskode.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Din aktivering er blevet behandlet, og din konto er nu knyttet til din virksomhed.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Klik venligst på Fortsæt for at komme ind på hjemmesiden.",
	ContinueButton: "Fortsæt",
	WelcomeBatsModal_Title: "Din konto er i øjeblikket ved at blive gennemgået!",
	WelcomeBatsModal_ActivationLink: "Klik her, hvis du har en aktiveringskode for at blive medlem af en virksomhed!",
	WelcomeBatsModal_ActivationLinkNotes: "Bemærk! Du skal kontakte din virksomhedsadministrator for at få en aktiveringskode.",
	AccountInReviewModal_Description_Paragraph1: "Tak, fordi du har registreret en personlig konto på Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Vores dedikerede træningspersonale er i øjeblikket ved at gennemgå og konfigurere din konto. Du vil modtage en endelig bekræftelse via mail, når din konto er blevet konfigureret.",
	AccountInReviewModal_CloseButton: "Okay, lad mig komme ind!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Vi var ikke i stand til at validere din aktiveringskode og e-mailadresse.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Prøv venligst igen, eller kontakt din virksomhedsadministrator for at få hjælp.",
	TryAgain_Button: "Prøv igen",
	Error_Title: "Ups! Noget gik galt!",
	SettingsMyProfileActivationSuccessModal_Title: "Succes!",
	AccountInReviewModal_Description_Paragraph3: "I mellemtiden er du velkommen til at gennemse vores hjemmeside med begrænset adgang.",
	AccountInReviewModal_Description_Paragraph4: "Hvis du har spørgsmål, eller hvis din konto ikke behandles inden for 48 timer eksklusive weekender og helligdage, kan du kontakte os [her]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Noget vejledning til at hjælpe dig…",
	Support_FAQ_Headline: "Ofte stillede spørgsmål",
	Support_FAQs_section1_title: "Generel",
	Support_FAQs_section1_question1: "Hvad er Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions er en platform, der giver et omfattende overblik over tekniske træninger og gør det muligt at booke og administrere træninger.",
	Support_FAQs_section1_question2: "Kan jeg bruge min eksisterende Bosch-konto (singleKey ID) til at logge på Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Ja, den er tilgængelig med alle konti oprettet med \"Mit Bosch ID\".",
	Support_FAQs_section1_question3: "Kan jeg bruge en konto oprettet til Bosch Training Solutions med andre Bosch-tjenester eller -applikationer?",
	Support_FAQs_section1_question3_answer1: "Ja, du kan også bruge dit personlige Bosch ID til en række andre Bosch-applikationer - både professionelt og privat, såsom e-cykel eller smart home.",
	Support_FAQs_section2_title: "Træning",
	Support_FAQs_section2_question1: "Hvordan kan jeg booke en træning?",
	Support_FAQs_section2_question1_answer1: "Log ind med din eksisterende konto ELLER opret en konto på portalen. Gå til \"Training Services\" ELLER \"Begivenhedskalender\" vælg en træning og klik på knappen Tilføj til Indkøbskurv.",
	Support_FAQs_section2_question2: "Der er ingen passende uddannelsesdato. Hvad kan jeg gøre?",
	Support_FAQs_section2_question2_answer1: "Du har mulighed for at sætte dig selv på en \"generel\" venteliste (uden at vælge en dato), og vores team vil informere dig, så snart en nyt kursus er tilgængelig.",
	Support_FAQs_section2_question3: "Hvor kan jeg finde en oversigt over mine bookede kurser?",
	Support_FAQs_section2_question3_answer1: "Du kan finde en komplet oversigt over alle bookede og gennemførte kurser efter login i dit dashboard.",
	Support_FAQs_section3_title: "Indstillinger",
	Support_FAQs_section3_question1: "Hvordan kan jeg ændre min e-mail og adgangskode?",
	Support_FAQs_section3_question1_answer1: "Du skal logge ind på portalen og skifte til din personlige profil. (Inde i dit Dashboard kan du finde dine profiloplysninger, eller hvis du klikker på følgende knap i overskriften",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Der har du mulighed for at ændre detaljerne på dit Bosch ID",
	Support_FAQs_section3_question2: "Hvad kan jeg gøre, hvis jeg har glemt min adgangskode?",
	Support_FAQs_section3_question2_answer1: "Brug venligst login/registrer-knappen og udfyld din e-mailadresse",
	Support_FAQs_section3_question2_answer2: "Hvis du klikker på fortsæt, har du mulighed for at klikke på \"Glemt adgangskode\"",
	Support_FAQs_section3_question3: "Hvordan opretter man en værkstedskonto?",
	Support_FAQs_section3_question3_answer1: "Hvis du ønsker at kunne få en værkstedsrolle, så kontakt din lokale uddannelsesadministration f.eks. du kan bruge kontaktformularen på hjemmesiden.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Vælg dit land/område",
	MaintenanceBanner_Message_1: "Bosch Automotive Training Solutions Portal vil blive opdateret på [maintenance_schedule]. Opdateringen forventes at tage 30 minutter. Portalen vil ikke være tilgængelig under opdateringen.",
	MaintenanceBanner_Message_2: "\nFor yderligere hjælp, kontakt os venligst via e-mail [support_email]",
	MaintenancePage_Message_1: "Bosch Automotive Training Solutions-portalen bliver i øjeblikket opdateret",
	MaintenancePage_Message_2: "Vi forventer, at portalen er tilgængelig igen den [maintenance_online_date] kl. [maintenance_online_time]",
	MenuHeader_Support: "Support",
	Course_Detail_No_Prerequisites: "Ingen forudsætninger",
	TableCell_Hours: "Timer",
	Dashboard_MenuItem_Dashboard: "Dashboard",
	Dashboard_MenuItem_Curricula: "Læreplaner",
	Dashboard_MenuItem_Certificates: "Certifikater",
	Dashboard_MenuItem_Team: "Hold",
	Dashboard_MenuItem_Workshops: "Værksteder",
	Dashboard_MenuItem_External: "Ekstern",
	Dashboard_MenuItem_Wallet: "Wallet",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Dine kommende kurser",
	Dashboard_Headline_YourELearningCourses: "Dine eLearning-kurser",
	Dashboard_Headline_TeamOverview: "Hold overblik",
	Dashboard_Headline_YourOverview: "Dit overblik",
	Dashboard_Headline_YourWishlist: "Din ønskeseddel",
	Dashboard_Headline_ActivePrograms: "Aktive programmer",
	Dashboard_Headline_CompletedPrograms: "Gennemførte programmer",
	Dashboard_Headline_YourCertificates: "Dine certifikater",
	Dashboard_Headline_Transactions: "Transaktioner",
	Dashboard_Table_NoRecordsToDisplay: "Ingen registrering at vise",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "timer",
	Dashboard_ELearningCourses_TableHeader_Title: "Titel",
	Dashboard_ELearningCourses_TableHeader_Duration: "Varighed (timer)",
	Dashboard_ELearningCourses_ActionButton_View: "Oversigt",
	Dashboard_UpcomingCourses_TableHeader_Title: "Titel",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Dato [Days]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Lokation",
	Dashboard_UpcomingCourses_ActionButton_View: "Oversigt",
	Dashboard_YourOverview_TableHeader_Title: "Titel",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Dato / varighed",
	Dashboard_YourOverview_TableHeader_Location: "Lokation",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Oversigt",
	Dashboard_YourWishlist_TableHeader_Title: "Titel",
	Dashboard_YourWishlist_TableHeader_Action: "Handling",
	Dashboard_YourWishlist_ViewAllRecords: "Se alle poster",
	Dashboard_TeamOverview_TableHeader_Employee: "Medarbejder",
	Dashboard_TeamOverview_TableHeader_Title: "Titel",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Dato / varighed",
	Dashboard_TeamOverview_TableHeader_Location: "Lokation",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Oversigt",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Søg",
	Dashboard_ActiveProgram_TableHeader_Title: "Titel",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Oversigt",
	Dashboard_CompletedProgram_TableHeader_Title: "Titel",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Oversigt",
	Dashboard_Certificates_TableHeader_Title: "Titel",
	Dashboard_Certificates_TableHeader_Date: "Dato",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Handling",
	Dashboard_Certificates_ActionButton_Download: "Download",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Faktura nummer",
	Dashboard_Transactions_TableHeader_Description: "Beskrivelse",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Betalingsmetode",
	Dashboard_Transactions_TableHeader_Date: "Dato",
	Dashboard_Transactions_TableHeader_Amount: "Beløb",
	Dashboard_Transactions_ActionButton_Download: "Download",
	Breadcrumb_MyTraining: "MyTraining",
	Breadcrumb_MyTraining_CourseDetails: "Kursusdetaljer",
	Breadcrumb_MyTraining_Curricula: "Læreplaner",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Træningsprogram detaljer",
	Breadcrumb_MyTraining_Team: "Hold",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Se medarbejder",
	MyTraining_Headerbar_CourseDetails: "Kursusdetaljer",
	MyTraining_Headerbar_TrainingProgramDetails: "Træningsprogram detaljer",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Totale fremskridt",
	TrainingProgramDetails_ProgramItems_Headline: "Programpunkter",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Titel",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "karakter",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Dato",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Oversigt",
	TrainingProgramDetails_Status_InProgress: "Igangværende",
	TrainingProgramDetails_Status_Completed: "Afsluttet",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Varighed",
	CourseDetails_Label_Trainer: "Træner",
	CourseDetails_Label_Language: "Sprog",
	CourseDetails_Label_Score: "Score:",
	CourseDetails_Label_StartDate: "Startdato",
	CourseDetails_Label_EndDate: "Slutdato",
	CourseDetails_Label_Venue: "Mødested",
	CourseDetails_Label_Address: "Adresse",
	CourseDetails_Label_Grade: "karakter",
	CourseDetails_Action_Withdraw: "Træk tilbage",
	CourseDetails_Action_Evaluate: "Evaluer kursus",
	CourseDetails_Action_Evaluate_Description: "Evaluer venligst dette kursus for at generere dit certifikat!",
	CourseDetails_Action_DownloadCertificate: "Download certifikat",
	CourseDetails_Action_JoinWebcast: "Deltag i webcast",
	CourseDetails_Action_StartCourse: "Start kursus",
	CourseDetails_Action_RestartCourse: "Genstart kursus",
	CourseDetails_Action_RepeatCourse_Description: "Gentag og bestå dette kursus for at downloade dit certifikat!",
	CourseDetails_PartSpecificDetails_Headline: "Del specifikke detaljer",
	CourseDetails_PartSpecificDetails_Tab_Part: "Del",
	CourseDetails_PartSpecificDetails_Description: "Beskrivelse",
	CourseDetails_PartSpecificDetails_StartDate: "Startdato",
	CourseDetails_PartSpecificDetails_EndDate: "Slutdato",
	CourseDetails_PartSpecificDetails_StartTime: "Starttidspunkt",
	CourseDetails_PartSpecificDetails_EndTime: "Sluttidspunkt",
	CourseDetails_PartSpecificDetails_Method: "Metode",
	Dashboard_Team_TableHeader_Name: "Navn",
	Dashboard_Team_TableHeader_Role: "Rolle",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Personlig konto",
	Dashboard_Team_ActionButton_Select: "Vælg",
	Dashboard_Team_PersonalAccount_Activated: "Aktiveret",
	Dashboard_Team_PersonalAccount_NotActivated: "Ikke aktiveret",
	Dashboard_Team_ActionButton_AddMember: "Tilføj medlem",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Søg",
	Dashboard_External_TableHeader_Name: "Navn",
	Dashboard_External_ActionButton_AddNew: "Tilføj ny",
	Dashboard_External_Headline: "Godkendt ekstern adgang",
	Dashboard_Workshops_Headline: "Værksteder",
	Dashboard_Workshops_TableHeader_Company: "Firma",
	Dashboard_Workshops_TableHeader_Address: "Adresse",
	Dashboard_Workshops_TableHeader_Postcode: "Postnummer",
	Dashboard_Workshops_TableHeader_City: "By",
	Dashboard_Workshops_ActionButton_Select: "Vælg",
	Wallet_ServiceCredits: "Service Credits",
	Wallet_Headline_CreditBalance: "Kredit balance",
	Wallet_Headline_CreditActivity: "Kreditaktivitet",
	Wallet_Headline_BillingTransactions: "Faktureringstransaktioner",
	Wallet_BillingTransaction_TableHeader_Order: "Bestille",
	Wallet_BillingTransaction_TableHeader_Date: "Dato",
	Wallet_BillingTransaction_TableHeader_Amount: "Beløb",
	Wallet_BillingTransaction_TableHeader_Action: "Handling",
	Wallet_CreditActivity_TableHeader_Date: "Dato",
	Wallet_CreditActivity_TableHeader_Description: "Beskrivelse",
	Wallet_CreditActivity_TableHeader_User: "Bruger",
	Wallet_CreditActivity_TableHeader_Amount: "Beløb",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, alle rettigheder forbeholdes",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Produktsikkerhed (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovationer, patenter og licenser",
	FooterAdditionalLinks_Navigation_Logistics: "Indkøb og logistik",
	MenuHeader_MyTraining: "MyTraining",
	MenuHeader_TrainingServices: "Træningstjenester",
	MenuHeader_OurLocations: "Vores lokationer",
	MenuHeader_ContactsUs: "Kontakt os",
	MenuHeader_Logout: "Log ud",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "Mit Bosch Id",
	MyProfile_AccountDeletion: "Sletning af konto",
	MyProfile_Edit: "Skift e-mail eller rediger adgangskode",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Rediger brugerprofil",
	BackButton: "Tilbage",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Vi ser frem til dit opkald og hjælper dig gerne. Vi er til rådighed for dig 24 timer, 7 dage om ugen",
	ContactUs_Email_SecurityCodeConfirm_V3: "Indtast de korrekte tegn, som angivet i boksen ovenfor.",
	ContactUs_Email_MandatoryNotice_V3: "Udfyld alle de påkrævede felter.",
	ContactUs_Email_SendButton: "Sende",
	Breadcrumb_TrainingCenters: "Træningscentre",
	Training_Centres_Book_Btn: "Book et kursus",
	Training_centres_booking_code: "Booking kode",
	Training_centers_header_bar_title: "Vores træningscentre",
	Training_centers_filter_item: "Center",
	TeamEdit_PersonalAccountToolTop: "Aktivering af denne funktion giver medarbejderen adgang til denne hjemmeside. Brugeren vil være i stand til at oprette en personlig konto og være en del af din virksomhedskonto. Når den er aktiveret, kan du sende en invitation med en aktiveringskode til medarbejderen. Invitationen sendes til e-mailadressen.",
	TeamEdit_Update: "Aktivering af virksomhedsadministratorfunktionen giver automatisk denne medarbejder fuld adgang til din virksomhedskonto. Aktivering af denne funktion kræver, at brugeren logger ud og tilbage på webstedet, hvis brugeren allerede er logget ind.",
	TeamEdit_PersonalAccount: "Tillad personlig Bosch Training Solutions-webkonto",
	TeamEdit_SendInvitation: "Send invitation",
	TeamEdit_Deactivate: "Deaktiver",
	TeamEdit_Telephone: "telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Mobil skal være tal",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telefon skal være tal",
	TeamEdit_Activate: "Aktiv",
	TeamEdit_NotActivated: "Ikke aktiveret",
	TeamEdit_StatusPopup: "Status vil blive vist som 'Aktiv', hvis brugeren har oprettet en personlig konto. Hvis status er sat til 'Ikke aktiv', betyder det, at brugeren ikke har gennemført din invitation.",
	Training_Catalog_Courses: "Kurser",
	Training_Catalog_Course: "Kursus",
	Reset_Filter: "Nulstil filter",
	TrainingCatalogue_CourseCatalogue: "Kursuskatalog",
	CheckoutHeader_CartItemCount_Text: "Du har [count] vare i din indkøbskurv",
	CheckoutHeader_CartItemsCount_Text: "Du har [count] varer i din indkøbskurv",
	ShoppingCart_PriceItem: "Varepris",
	ShoppingCartItemParticipants_AddParticipant_Button: "Tilføj deltagere",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotal",
	ShoppingCartTotalPrice_TaxesLabel: "Moms",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Inkluderer moms på [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Tilgængelige online betalingsmetoder",
	ShoppingCartItemRow_VenueFeeLabel: "Kursussted gebyr [fee_amount] pr. deltager",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Tilføj deltager",
	AddParticipantsModal_SelectEmployeeLabel: "Tilføj deltager",
	AddParticipantsModal_SelectWorkshopLabel: "Vælg værksted",
	ShoppingCartTotalPrice_ContinueButtonText: "Fortsæt til kassen",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Deltager er ikke tilføjet!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Du har et eller flere kurser i din indkøbskurv uden deltager.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Tilføj venligst en deltager for at fortsætte til betaling.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Rediger deltagere ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Navn",
	AddParticipantsModal_ParticipantTable_Email: "E-mail",
	AddParticipantsModal_ParticipantTable_Action: "Handling",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Ingen deltagere...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "E-mail-adressen er ikke konfigureret på den valgte deltager",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Den valgte deltager har ikke en e-mailadresse konfigureret på deres profil. E-mailadresse er obligatorisk for tilmelding til et kursus.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Tilføj e-mailadresse for at fortsætte eller annuller for at vælge en anden deltager.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Tilføj e-mail",
	CancelButton: "Annuller",
	AddParticipantsModal_AddEmployeeButtonText: "Tilføj medarbejder",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Du er ved at slette et kursus fra din indkøbskurv",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Du forsøger at fjerne et kursus med tilføjede deltagere fra din indkøbskurv.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Klik på \"Fortsæt\", hvis du vil fortsætte, eller klik på \"Annuller\".",
	ShoppingCartItemHeader_Participants: "Deltager(e)",
	ShoppingCartItemHeader_ParticipantPrice: "Pris pr sæde",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Vis i [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Vis i Credits",
	ShoppingCart_CreditsPriceUnit: "Credits",
	CheckOutHeader_Step1_Question: "Hvordan vil du gerne betale?",
	CheckOutHeader_Step2_Question: "Gennemgå din ordre",
	CheckOutHeader_Step3_Question: "Gennemfør din ordre",
	CheckOutHeader_Step4_Question: "Din reservation lykkedes!",
	CheckOutHeader_Step_Payment: "Betaling",
	CheckOutHeader_Step_Review: "Anmeldelse",
	CheckOutHeader_Step_Place_Order: "Angiv bestilling",
	CheckOutRightPanel_Review_Order_Btn: "Gennemgå ordre",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Angiv bestilling",
	CheckoutRightPanelContent_OrderSummary_Title: "Ordreoversigt",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Rediger indkøbskurv",
	ShoppingCart_Participants: "Deltagere",
	CheckOutStepPayment_Payment_Methods_Title: "Vælg betalingsmetode",
	CheckOutStepPayment_Credit_Title: "Kredit eller debet",
	CheckOutStepPayment_Wholesaler_Title: "Grossist",
	CheckOutStepPayment_ServiceCredit_Title: "Service Credits",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Du har [available_credit] Credits",
	CheckOutStepPayment_BoschAccount_Title: "Bosch konto",
	Checkout_Second_PaymentMethod_Headline: "Betalingsmetode",
	CheckOutStepReview_Edit: "Redigere",
	CheckOutStepReview_Terms_Label: "Vilkår, betingelser og afbestillingspolitik",
	CheckOutStepReview_Terms_Text: "Jeg har læst, forstået og accepterer [vilkår, betingelser og annulleringspolitik] for salg.",
	SelectSecondPaymentMethodModal_Title: "Vælg sekundær betalingsmetode",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Betal [amount]",
	StepPaymentWholesaler_Title: "Vælg grossist",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Accepter vilkår og betingelser",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Læs og accepter vores vilkår, betingelser og annulleringspolitik, før du fortsætter.",
	CheckoutDescription_ThankYou: "Tak, fordi du har afgivet en ordre på Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Vores bits og bytes er allerede begyndt at behandle din ordre. I mellemtiden skal du holde din motor i tomgang.",
	CheckoutDesctiption_Text: "Vi sender dig en endelig bekræftelse via mail, inklusive alle detaljer om din reservation.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Du er ved at fjerne det valgte værksted",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Ved at fjerne den valgte værksted vil alle tilføjede deltagere blive nulstillet. Denne ændring vil påvirke alle kurser i din indkøbskurv.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klik på \"Fortsæt\" hvis du vil fortsætte, eller klik på \"Annuller\" for at stoppe denne handling.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Du har utilstrækkelige Credits. Din Credits saldo er [available_credit] Credits, men du skal bruge [cart_total_credits] Credits for at fortsætte med at betale. Vælg venligst en anden betalingsmetode eller fjern varer fra din indkøbskurv.",
	Training_centers_filter_items: "Centre",
	Training_Programs_Filter_Item: "Programmer",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "År",
	Training_Program_Detail_Title: "Træningskursus programmer",
	AccountButton_Workshops_MenuItem: "Værksteder",
	AccountButton_Wallet_MenuItem: "Wallet",
	Header_MyBoschProfile_Header: "MyBosch profil",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Ledig",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Add/Edit participant",
	ShoppingCart_CreditPriceUnit: "Credit",
	Common_IncludedVAT: "Incl. Moms",
	TrainingCatalogue_Length: "Længde",
	TrainingCatalogue_Method: "Metode",
	Course_Price_Credits: "Credits",
	Catalogue_Grid_Register_Button: "Register",
	Catalogue_Credit_Tilte: "Klik her for at se priser i Credits​",
	Catalogue_Price_Tilte: "Klik her for at se priser i EUR",
	Catalogue_Grid_Vat: "moms ekskl.",
	EventCalendar_Seats: "Pladser",
	MenuHeader_Calendar: "Kalender",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Minutter",
	ListFilter_NoResult: "Intet resultat",
	Course_Description: "Kursusbeskrivelse",
	Training_course_detail: "Grundlæggende om sociale medier",
	Training_course_detail_add_waiting: "Tilføj til generel venteliste",
	Register_now_btn: "Registrer nu !",
	Register_now_btn_add_event_to_cart: "Registrer nu !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Se alle poster",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Se mindre",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Se alle poster",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Se mindre",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Se alle poster",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Se mindre",
	TeamCreate_TitleError: "Titel er påkrævet",
	Dashboard_Headerbar_BookCourse: "Tilmeld kursus",
	Dashboard_Headerbar_ExitProxy: "Afslut proxy",
	Dashboard_Headerbar_EvaluateCourse: "Evaluer kursus",
	Dashboard_Headerbar_EvaluateCourse_Note: "Evaluer venligst dette kursus for at generere dit certifikat!",
	Dashboard_Headerbar_DownloadCertificate: "Download certifikat",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Træk tilbage",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Træk tilbage",
	Dashboard_Headerbar_WelcomeMessage: "Velkommen til dit læringsdashboard",
	Dashboard_Headerbar_ProxingAs: "Proxy som",
	Dashboard_TrainingProgram_Status_Completed: "Afsluttet",
	Dashboard_TrainingProgram_Status_InProgress: "I gang",
	External_SelectWholesalerModal_Title: "Tilføj ekstern brugeradgang",
	External_SelectWholesalerModal_Company_Label: "Firma",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Vælg venligst",
	External_SelectWholesalerModal_Outlet_Placeholder: "Vælg venligst",
	External_SelectWholesalerModal_ApplyButton: "ansøge",
	External_SelectWholesalerModal_CancelButton: "Anuller",
	External_RemoveWholesalerModal_ConfirmLabel: "Bekræftelse",
	External_SelectWholesalerModal_ConfirmMessage: "Er du sikker på, at du vil fjerne denne grossist?",
	External_SelectWholesalerModal_YesButton: "Ja",
	External_SelectWholesalerModal_NoButton: "Nej",
	Training_program_team_member: "Medarbejder",
	Training_program_assign_training_program: "Tildel træningsprogram",
	Assign_modal_student_confirmation_text1: "Du er ved at tildele [Training Program Name] til din læringsrejse.",
	Assign_modal_student_confirmation_text2: "Vil du fortsætte?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "Maj",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Jul",
	MyFullCalendar_Aug: "Aug",
	MyFullCalendar_Sep: "Sep",
	MyFullCalendar_Oct: "Okt",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dec",
	MenuHeader_CourseCatalog: "Kursuskatalog",
	MenuHeader_Event: "Begivenhedskalender",
	MenuHeader_Program: "træningsprogram",
	MenuHeader_Services: "Træningstjenester",
	MenuHeader_Log_out: "Log ud",
	Filter_Location: "Lokation",
	TeamEdit_UpgradeToCompanyAdministrator: "Opgrader til virksomhedsadministrator",
	TeamEdit_PersonalWebAccountStatusIs: "Personlig webkontostatus er",
	Header_CreateNewEmployee: "Opret ny medarbejder",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Ingen ledige pladser!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Der er ingen ledige pladser på den valgte begivenhed.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Vælg en anden begivenhed, eller fjern en allerede tilføjet deltager for at fortsætte.",
	Common_SuccessModal_Title: "Tillykke!",
	TrainingProgramDetails_Merit_StatusText: "Merit",
	TrainingProgramDetails_Resit_StatusText: "Gentag",
	EmployeeProfile_UserExistErrorMessage: "Kontoen eksisterer allerede!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Betaling mislykkedes. Prøv igen, eller brug en anden betalingsmetode.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Du har deaktiveret den valgte medarbejder!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Gemt!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Dine ændringer er blevet gemt.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Det er ikke muligt at trække sig tilbage på eLearning-kurser. Kontakt os venligst, hvis der er behov for yderligere oplysninger.",
	Dashboard_Team_PersonalAccount_Pending: "Verserende",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Medarbejder oprettet!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Din medarbejderoprettelse er blevet gennemført.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Denne indstilling giver dig mulighed for at begrænse en medarbejder fra at tilmelde sig til træningsbegivenheder. Kun virksomhedsadministratorer vil kunne tilmelde medarbejderen til træningsarrangementer, når denne indstilling er fravalgt.",
	Training_catalog_sort_by: "Sorter efter",
	CourseDetail_EvaluationRestriction_Title: "Træningsevaluering ikke mulig!",
	CourseDetail_EvaluationRestriction_Message: "Evalueringer af træning kan kun indsendes af den studerende selv. \nInstruer venligst eleven om at logge ind på Bosch Automotive Training Solutions og fuldføre uddannelsesevalueringen.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Hvis eleven ikke allerede har en konto, kan en invitation deles i teammedlemmets brugerprofilindstillinger.",
	Breadcrumb_Download_Page: "Download",
	Download_Table_Category_Cell: "Kategori",
	Download_Table_Title_Cell: "Titel",
	Download_File_Filter_Item: "Fil",
	Download_File_Filter_Items: "Filer",
	Download_Header: "Download område",
	DownloadArea_Button_Download: "Download",
	Popup_Title_Error: "Fejl",
	Popup_Title_Success: "Succes",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Advarsel!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Efter potentielle begivenhedsregistreringer, der allerede findes, vil ikke blive annulleret og skal annulleres individuelt.",
	TrainingProgram_PDF_Export: "PDF -eksport",
	TrainingProgram_PDF_Export_Participant: "Deltagere:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Generelt fremskridt:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Træningstitel",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "karakter",
	TrainingProgram_PDF_Export_TableHeader_Date: "Dato",
	AccountButton_CompanyChange_MenuItem: "Virksomhedsændring",
	SelectCompanyModal_Title: "Vælg firma",
	CloseButton: "Tæt",
	Change_Button: "Lave om",
	ChangeManagerModal_Content: "Vælg bruger",
	ChangeCompanyModal_HeadOffice: "(Hovedkontor)",
	SelectCompanyModal_Content: "Vælg firma for at logge ind ...",
	AlertSuccess_Title: "Succes",
	CheckOutStepPayment_SecondaryPayment_Title: "Vælg sekundær betalingsmetode",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Tilføj sekundær betalingsmetode",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Tilføj venligst sekundær betalingsmetode nedenfor, eller vælg en anden betalingsmetode ovenfor.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Du har utilstrækkelige servicekreditter på din konto til at betale for dine indkøbsvognartikler. For at fortsætte med at bruge den valgte betalingsmetode skal du betale forskellen. Forskellen er [difference_amount] (ekskl. Moms).",
	ShoppingCartTotalPrice_TotalInCredit: "Samlet pris i kreditter",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Dine tilgængelige kreditter",
	ShoppingCartTotalPrice_YourDifference: "Forskel",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal ekskl. moms",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal inkl. moms",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Sekundær betalingsmetode",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Rediger betalingsmetode",
	Checkout_Second_PaymentMethod: "Anden betalingsmetode",
	Breadcrumb_TrainingCourseDetails: "Detaljer om kursus",
	DownloadArea_FailedToDownload_ErrorMessage: "Dokumentet blev ikke downloaded. Prøv igen eller kontakt din virksomhedsadministrator for at få hjælp.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Downloadfil er ikke tilgængelig. Prøv igen eller kontakt din virksomhedsadministrator for at få hjælp.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Download fil er forbudt. Prøv igen eller kontakt din virksomhedsadministrator for at få hjælp.",
	SSO_Redirecting_Message: "Du omdirigerer til Bosch Training Portal...",
	SSO_Redirecting_ParameterErrorMessage: "Land og/eller sprog er ikke angivet. Prøv venligst igen, eller kontakt din virksomhedsadministrator for at få hjælp.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Anmodning(er) om tilmelding ikke accepteret.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Dette kan skyldes at være at du er inden for kursets tilmeldings frist for et eller flere af kurserne. Brug venligst 'Kontakt os'-området, hvis du har spørgsmål eller ønsker.",
	TimeSlotPicker_DateFormat_Title: "Datoformat",
	UpdateAccountSettingsConfirmationModal_Title: "Du er ved at ændre datoformatet!",
	UpdateAccountSettingsConfirmationModal_Message1: "Datoformatet vil blive vist i henhold til dit valg og kan til enhver tid ændres.",
	UpdateAccountSettingsConfirmationModal_Message2: "Ønsker du at fortsætte?",
	ProfileSummary_Settings_Title: "Indstillinger",
	UpdateAccountSettings_DateFormat_Title: "Indstillinger for datoformat",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Det lykkedes ikke at opdatere datoformatet.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Datoformatindstillingen er blevet opdateret.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Download certifikat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Træningsprogram certifikat",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Det lykkedes ikke at downloade dokumentet. Prøv venligst igen. Hvis problemet ikke afhjælpes, kontakt ved hjælpe af kontakt formularen.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Tærsklen afmelding er overskredet",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Tærsklen for at trække sig fra dette kursus er overskredet og kræver manuel behandling af vores uddannelsesadministration.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Endelig bekræftelse sendes på e-mail.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Se venligst vores [afbestillingspolitik] for yderligere detaljer.",
	TrainingCourse_Course_NotAvailable_Title: "Træningen er ikke tilgængelig",
	TrainingCourse_Course_NotAvailable_Message: "Kursus er ikke tilgængelig. Prøv venligst igen, eller kontakt din virksomhedsadministrator for at få hjælp.",
	TrainingCourse_NotAvailable_Message_1: "Du prøver at vise et træningsforløb som ikke er tilgængeligt.",
	TrainingCourse_NotAvailable_Message_2: "Gå tilbage til vores [kursuskatalog] for at finde alle tilgængelige kurser.",
	LanguageSelection_SelectALanguage: "Vælg sprog",
	LanguageSelection_SelectYourCountry: "Vælg land",
	Checkout_PaymentMethod_Headline: "Betalingsmetode",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalender",
	Download_Categories_General: "Generelt",
	Download_Categories_Information: "Information",
	Download_Categories_Userguides: "Brugervejledninger",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Vilkår, betingelser og afbestillingspolitik",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Annuller",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Fortsæt",
	Duration_Seconds: "Sekunder",
	Duration_Day: "Dag",
	Duration_Hour: "Time",
	Duration_Minute: "Minutter",
	Duration_Second: "Sekunder",
	EventCalendar_ExternalLink_Tooltips: "Køb via [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Afbestille",
	EventCalendar_ExternalLink_Continue: "Fortsæt",
	EventCalendar_ExternalLinkModal_Title: "Du er ved at blive omdirigeret...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Den valgte begivenhed sælges via [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Hvis du fortsætter, omdirigeres du til en ekstern hjemmeside.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Vil du fortsætte?",
	Team_ContactPerson_FirstName_Tooltips: "Elevnavne kan kun ændres af Bosch træningsadministrationsteamet. Kontakt os venligst via kontaktformularen på denne hjemmeside for yderligere support.",
	Team_ContactPerson_LastName_Tooltips: "Elevnavne kan kun ændres af Bosch træningsadministrationsteamet. Kontakt os venligst via kontaktformularen på denne hjemmeside for yderligere support.",
	Withdraw_ExternalLink_Cancel: "Afbestille",
	Withdraw_ExternalLink_Confirm: "Bekræfte",
	Withdraw_ExternalLinkModal_Title: "Du er ved at blive omdirigeret",
	Withdraw_ExternalLinkModal_Message: "Udbetalinger til dette kursus håndteres af en ekstern udbyder.",
	Withdraw_ExternalLinkModal_Confirmation: "Ved at klikke på \"Bekræft\" åbnes en ny fane og omdirigere dig til et eksternt websted.",
	Button_AddToWaitingList_Tooltips: "Tilføj til venteliste",
	Button_AddToCart_Tooltips: "Tilføj til kurv",
	Button_TrainingCourseDetail_Tooltips: "Se detaljer",
	Button_TrainingProgramDetail_Tooltips: "Se detaljer",
	Button_AssignTrainingProgram_Tooltips: "Tildel træningsprogram",
	Button_DeleteFromCart_Tooltips: "Slet fra kurven",
	Button_RemoveParticipant_Tooltips: "Fjern deltager",
	Button_DownloadBillingTransaction_Tooltips: "Hent",
	Button_RemoveExternal_Tooltips: "Fjern",
	Breadcrumb_TrainingServices: "Trænings Service",
	Breadcrumb_Support: "Support",
	Breadcrumb_FAQs: "FAQ",
	Breadcrumb_TrainingProgramDetail: "Trænings Service Detaljer",
	Breadcrumb_MyTraining_MyTrainingEvents: "My Training Events",
	Breadcrumb_MyTraining_External: "Ekstern",
	Breadcrumb_MyTraining_Wallet: "Wallet",
	Breadcrumb_MyTraining_Workshop: "Værksted",
	Breadcrumb_MyTraining_Workshop_Team: "Team",
	Breadcrumb_MyTraining_Team_Profile: "Profil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Rediger Profil",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Tilføj Medarbejder",
	SideNavigation_Title: "Training Solutions",
	SideNavigation_AccountButton_RegisterOrLogin: "registrer / Log ind",
	SideNavigation_Language: "Sprog",
	SideNavigation_SelectLanguage: "Vælg Sprog",
	SideNavigation_AccountButton_Logout: "Log ud",
	SideNavigation_ConntactUs: "Kontakt os",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Tilføj information",
	FooterAdditionalLinks_Navigation_LegalNotice: "Juridisk meddelelse",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Databeskyttelsesmeddelelse",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Privatlivsindstillinger",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Vilkår og betingelser",
	Common_Version_Text: "Version: {{version}}",
	Content_Header_Dashboard_MyTrainingEvents: "My Training Events",
	Content_Header_Dashboard_MyTrainingPrograms: "My Training Programs",
	Content_Header_Dashboard_Team: "Team",
	Content_Header_Dashboard_Workshops: "Værksted",
	Content_Header_Dashboard_External: "Ekstern",
	Content_Header_Dashboard_Wallet: "Wallet",
	Content_Header_Dashboard_MyProfile: "My Profile",
	RedeemVoucher_RemoveButton: "Fjern",
	RedeemVoucher_ApplyButton: "Anvend",
	Checkout_VoucherApplied_Label: "Din kode",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Ugyldig Voucher kode",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Voucher koden der var tilføjet , er ugyldig",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Prøv igen eller fortsæt uden Voucher kode.",
	RedeemVoucherModal_CancelButton: "Annuller",
	RedeemVoucherModal_TryAgainButton: "Prøv igen",
	CreditsConfirmationModal_ContinueButton: "Fortsæt",
	CreditsConfirmationModal_CancelButton: "Annuller",
	CreditsConfirmationModal_Title: "En Voucher er blevet anvendt",
	CreditsConfirmationModal_Message_1: "Betaling med Service Credits er ikke mulig, når der er blevet anvendt en Voucher med rabat på din indkøbsvogn.",
	CreditsConfirmationModal_Message_2: "Klik på \"Annuller\" for at gå tilbage, eller klik på \"Fortsæt\" for at fjerne rabatkoden fra din indkøbsvogn.",
	TrainingCourseCatalog_PricePerSeat: "Pris per plads",
	TrainingCourseCatalog_Duration: "Varighed:",
	Homepage_Title_BoschTrainingSolutions: "Bosch Training Solutions",
	ConsentMarketing_Title: "Marketing og nyheder",
	ConsentMarketing_ConfirmButton: "Bekræft",
	ConsentMarketing_CancelButton: "Annuller",
	ConsentMarketing_Subscribe: "Abonner",
	ConsentMarketing_Unsubscribe: "Afmeld",
	ConsentMarketing_SubscribeSuccessfully: "Du er nu blevet tilmeldt.",
	ConsentMarketing_UnsubscribeSuccessfully: "Du er nu blevet afmeldt.",
	TrainingCourseCatalog_Courses: "Kurser:",
	TrainingCourseCatalog_Courses_Info: "Kurser",
	Course_Elearning_Series_Detail_Title: "e-Learning serie",
	Course_Elearning_Series_Detail_Label_Date: "Dato",
	Course_Elearning_Series_Course_List: "Kursus emner"
};