export const TRANSLATIONS_DE_CH = {
	LandingPage_Title: "Willkommen beim Automotive Campus",
	LandingPage_SubTitle_ChooseCountry: "Land auswählen",
	Header_ContactUs: "Kontakt",
	Header_Search: "Suche",
	Header_Menu: "Menü",
	Header_LogInOrCreateYourAccount: "Neu registrieren",
	Header_LogIn: "Login",
	Header_Cart: "Einkaufswagen",
	Header_ShoppingCart: "Einkaufswagen",
	Header_LogOut: "Logout",
	Header_ChangeDetails: "Einstellungen",
	Footer_ExploreOurTraining: "Entdecken Sie unser Training",
	Footer_TrainingAtYourFingertips: "Ihr direkter Weg zum Training",
	Footer_DontMissAnyStory: "Verpassen Sie keine Geschichten",
	Footer_ExploreOurStories: "Entdecken Sie unsere Geschichten",
	Footer_GetInTouchWithUs: "Kontaktieren Sie uns",
	Footer_SendUsAMessage: "Nachricht senden",
	Footer_ToContactForm: "Kontaktformular",
	Footer_InventedForLife: "Technik fürs Leben",
	Footer_GeneralContactInfo: "Generelle Kontaktinformationen",
	Footer_ProductSecurity_Psirt: "Produktsicherheit (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Patente und Lizenzen",
	Footer_PurchasingLogistics: "Einkauf und Logistik",
	Footer_CorporateInformation: "Impressum",
	Footer_LegalNotice: "Rechtliche Hinweise",
	Footer_DataProtectionNotice: "Datenschutzbestimmungen",
	Footer_PrivacySettings: "Privatsphäre- Einstellungen",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch GmbH, alle Rechte vorbehalten",
	Footer_StillLookingForSomething: "Noch nicht das Richtige gefunden",
	Footer_TrainingServices: "Trainingskatalog",
	Footer_Courses: "Trainingskatalog",
	Footer_Programs: "Ausbildungsprogramm",
	Footer_EventsCalendar: "Trainingstermine",
	Footer_TrainingCenters: "Trainingszentren",
	Footer_Dashboard: "Mein Bereich",
	Footer_MyDashboard: "Mein Bereich",
	Footer_ShoppingCart: "Einkaufswagen",
	Footer_YourShoppingCart: "Ihr Einkaufswagen",
	Footer_Administration: "Werkstattbereich",
	Footer_Employees: "Team",
	Footer_Wholesalers: "Zusatzfunktionen",
	Footer_Transactions: "Transaktionen",
	Footer_Workshops: "Werkstatt",
	Footer_WorkLikeABoschApplyNow: "Bewerben Sie sich jetzt",
	Footer_BoschGlobalCareers: "Bosch Karriere",
	Footer_PurchasingAndLogistics: "Einkauf und Logistik",
	Footer_BecomeABusinessPartner: "Partner werden",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Vorteile des Bosch Service Trainings",
	Footer_Language: "Länderauswahl",
	MenuPopup_MyCart: "Mein Einkaufswagen",
	Stories_OurTrainingStories: "Unsere Trainingsgeschichte",
	Stories_ConnectingExperts: "Wir verbinden Experten und Enthusiasten, Träumer und Macher, Innovatoren und Influencer.",
	Stories_TopStories: "Top Geschichten",
	Stories_AtHome: "Zuhause",
	Stories_Careers: "Karriere",
	Stories_History: "Historie",
	Stories_IndustryAndTrades: "Industrie und Handwerk",
	Stories_Mobility: "Mobilität",
	Stories_Research: "Forschung",
	Stories_Sustainability: "Nachhaltigkeit",
	Stories_AllStories: "Alle Geschichten",
	Stories_BlogATrainersDay: "BLOG | Tag des Trainers",
	Stories_ADayinLifeOfATrainer: "Ein Tag im Leben eines Trainers",
	Stories_BoschServiceTraining: "Bosch Service Training",
	Stories_StoryWhatDrivesYouDrivesUs: "STORY | Technik erleben",
	Stories_StoryHowCanWeBenefitYourBusiness: "STORY | Vorteile des Bosch Service Trainings",
	Stories_HowOurTrainingEvolves: "Wie Training Sie unterstützen kann",
	Stories_AModernTrainingApproach: "Online Trainingswelt",
	Stories_StoryAVirtualOnlineTrainingWorld: "STORY | Online Trainings Möglichkeiten",
	Stories_SubscribeToRSSFeed: "Anmelden zum RSS Feed",
	Title_Main: "Bosch Automotive Campus",
	Homepage_AboutUs: "Über uns",
	Homepage_AutomotiveAftermarket: "Mobility Aftermarket",
	Homepage_GetInTouch: "Kontakt",
	Homepage_LatestNews: "Finden Sie heraus, warum uns Wissensdurst antreibt.",
	Homepage_MainTitle: "Bosch Automotive Campus",
	Homepage_OurOpenPositions: "Job-Angebote bei Bosch",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Das was uns antreibt",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service Training",
	BoschServiceTraining_ShareThisOn: "Teilen:",
	BoschServiceTraining_TheChallenge: "Die Herausforderung: Neue Technologien beherrschen",
	BoschServiceTraining_WorldwidePresence: "Weltweite Präsenz",
	BoschServiceTraining_TechnicalTraining: "Technische Trainings von Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Unser Angebot",
	BoschServiceTraining_TechnicalCoursesDesc: "Für jede Zielgruppe, ob Anfänger oder Profi, gibt es bei Bosch passende Trainings. Das Angebot umfasst ein- und mehrtägige Kurse. In den technischen Trainings wird ein umfassendes Systemwissen für alle marktüblichen Kfz-Systeme im Pkw- und Nfz-Bereich vermittelt . Neben den Systemen aus dem Hause Bosch werden in den Trainings auch Systeme anderer Hersteller behandelt. Die Inhalte sind besonders praxisorientiert und speziell auf die Anforderungen von Werkstattmitarbeitern abgestimmt. Das Angebot von Bosch umfasst auch kaufmännische Trainings , die den teilnehmenden Werkstätten ein breites Spektrum an Handlungsmöglichkeiten bieten, um in einer wettbewerbsintensiven Branche nachhaltig bestehen und wachsen zu können.",
	BoschServiceTraining_TrainedEmployeesDesc: "Für Kfz-Werkstätten sind geschulte Mitarbeiter ein deutlicher Gewinn. Denn die Fahrzeuge werden heutzutage immer komplexer – und neue Technologien stellen immer auch neue Herausforderungen dar. Nur mit dem nötigen Fachwissen sind Werkstattmitarbeiter in der Lage, Diagnose-, Wartungs- und Reparaturarbeiten an den aktuellen Fahrzeugmodellen effektiv und kostengünstig durchzuführen.",
	BoschServiceTraining_RegardlessOfHow: "Ganz egal, wie gut und erfahren Kfz-Fachleute sind – es gibt immer Situationen, in denen sie mit neuen Systemen aus der Kfz-Technologie oder unbekannten Fehlern in Berührung kommen. Die Fehlersuche und Problemlösung kann sich dabei als besonders zeitintensiv erweisen oder möglicherweise zu keinem Erfolg führen. Der Kunde muss dann im schlimmsten Fall deutlich länger als erwartet auf sein Fahrzeug verzichten.",
	BoschServiceTraining_ItIsPrecisely: "Genau an diesem Punkt setzt Bosch mit seinem Angebot im Bereich Service Training an: Servicequalität verbessern und Prozesse beschleunigen. Das aktuelle Trainingsangebot von Bosch unterstützt Sie dabei, Probleme schneller zu lokalisieren, mehr Fahrzeuge im selben Zeitraum zu reparieren und die Wartezeiten Ihrer Kunden zu verringern. So sorgen die methodischen Verfahren, die Sie bei Bosch erlernen, für ein schnelleres und produktiveres Arbeiten.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Seit mehr als 125 Jahren ist Bosch bevorzugter Zulieferer von Erstausrüstungsteilen, Komponenten und Systemen für viele der weltweit führenden Automobilhersteller. Diese Erfahrung sorgt für einzigartige Kenntnisse in den neuesten Technologien und bildet die Grundlage für Ihre Unterstützung durch Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Bosch Service Training Center gibt es weltweit – von Spanien über die Türkei, Südafrika, Australien bis nach Brasilien – in jedem bietet Bosch dem Werkstattmitarbeiter praxisorientierte und immer den neusten Technologien entsprechende Trainings an.",
	BoschServiceTraining_AgileCompany: "Agiles Unternehmen",
	BoschServiceTraining_Collaboration: "Zusammenarbeit",
	BoschServiceTraining_ConnectedMobility: "Vernetzte Mobiliät",
	BoschServiceTraining_ArtificialIntelligence: "Künstliche Intelligenz",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Vordenker im Dialog",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostics",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Automotive Basiswissen",
	BoschServiceTraining_Icon_Gasoline: "Benzineinspritzung",
	BoschServiceTraining_Icon_Diesel: "Dieseleinspritzung",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternative Antriebe",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elektrik und Elektronik",
	BoschServiceTraining_Icon_BodyAndComfort: "Karosserie- , Komfort- und Fahrerassistenz-Systeme",
	BoschServiceTraining_Icon_ChassisAndBraking: "Mechanik und Bremse",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Geriebe und Antrieb",
	BoschServiceTraining_Icon_CommercialVehicles: "Truck",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Online Trainings und Live Webcast Trainings",
	BoschServiceTraining_Icon_BusinessSkills: "Service- und Unternehmerakademie",
	BoschServiceTraining_Icon_Accreditations: "Akkreditierung",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Ausbildungsreihen",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerVornameNachname",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Ein Tag im Leben eines Trainers",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Jeder Tag ist ein Abenteuer",
	ADayInTheLifeOfATrainer_Summary: "Zusammenfassung",
	ADayInTheLifeOfATrainer_TheDayJourney: "Tagesablauf",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Mit jedem neuen Tag stellen wir uns der Herausforderung, unseren Kunden die neueste Technik, die neuesten Werkzeuge und das aktuellste Wissen zu vermitteln. Zu sehen, wie unsere Kunden mit jedem Training wachsen beweist uns, dass wir auf das gemeinsame Ziel hinarbeiten, die moderne Technologie in die Werkstätten zu integrieren und die Zukunft voranzutreiben.",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Das Reflektieren eines Trainingstages gehört zu den täglichen Aufgaben eines Trainers. Durch die gewählten Methoden, Lernstationen und Diskussionsrunden vergeht ein Trainingstag wie im Flug.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Dadurch entsteht ein wertschöpfendes Lernumfeld in dem sowohl Sie als Kunde, aber auch wir als Trainer stetig wachsen.",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Start",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Vorbereitung und Kontrolle des Trainingsmaterials. Wichtige E-Mails bearbeiten.",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Das Training beginnt",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Teilnehmer empfangen. Präsentation des Tagesablaufs. Kurze Vorstellung. Theorie",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Theorieteil",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Interaktion mit der Gruppe, um das Training so interessant wie möglich zu gestalten.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Mittagspause",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praxisteil",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Fortsetzung des Trainings in der Werkstatt mit praktischen Aufgaben. Als Trainer ist es immer eine Herausforderung, immer für alle Eventualitäten und Fragen präsent und verfügbar zu sein",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Zwischenpause",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Zurück in den Klassenraum - kurze Durchsprache des weiteren Tagesablaufs und Beantwortung von Fragen. Nach Trainingsende Vorbereitung des nächsten Tages.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Letzer Tag",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Letzte Fragen beantworten, kurzes Feedback, Ausgabe der Teilnahmebescheinigungen.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Home,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Wie zahlt sich Training für Ihr Unternehmen aus",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Wie sich unser Training stetig weiter entwickelt",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Wir entwickeln uns kontinuierlich weiter und stellen sicher, dass unsere Trainings immer auf dem neuesten Stand sind.",
	BoschTrainingBenefits_TheBoschServiceTraining: "Das Bosch Service Training Center möchte Sie bei der Weiterentwicklung Ihres Unternehmens durch ein umfangreiches und hochrelevantes Angebot an technisch/kaufmännischen Aus- und Weiterbildungen mit zertifizierten Abschlüssen unterstützen. Auf den folgenden Seiten erhalten Sie einen Überblick über unser Aus- und Weiterebildungsprogramme, sowie die aktuellen Weiterbildungsmöglichkeiten. Wir würden uns freuen, Sie und Ihre Mitarbeiter bei unseren Trainings begrüßen zu können.",
	BoschTrainingBenefits_LongStandingExperience: "Jahrelange Erfahrung und umfassendes Wissen zu allen Marken und Systemen im Bereich Automobiltechnik",
	BoschTrainingBenefits_QuickAndEfficient: "Schnelle und effiziente Lösungen zu zahlreichen Problemstellungen",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Vorteile",
	BoschTrainingBenefits_LMS: "Learning Management System - Automotive Campus",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Der Bosch Automotive Campus deckt die gesamte Bandbreite des Automotive-Bereiches ab. Spezialisieren Sie sich auf bestimmte Bereiche oder erweitern Sie Ihre Kompetenz mit den Trainingsangeboten von Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Gelerntes Wissen erleichtert Reparaturen, Wartungen und Servicearbeiten",
	BoschTrainingBenefits_TheHighDegree: "Hoher Praxisanteil sowie eine kleine Gruppengröße garantiert zielorientiertes Lernen",
	BoschTrainingBenefits_ParticipationInTraining: "Teilnahme an Trainings steigert Motivation und Zufriedenheit der Mitarbeiter und die Zusatzqualifikationen überzeugen Kunden",
	BoschTrainingBenefits_IncreasedSales: "Umsatzsteigerung durch markenübergreifendes Systemwissen",
	BoschTrainingBenefits_MethodicalProcedures: "Methodische Vorgehensweise verkürzt die Wartezeit für Kunden",
	BoschTrainingBenefits_HighlyQualified: "Hochqualifizierte Trainer vermitteln eine methodische Vorgehensweise",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Erkennen der richtigen Trainingslösung für Sie oder Ihr Unternehmen",
	BoschTrainingBenefits_DirectOnlineBooking: "Direkte Online-Buchung und Verwaltung von Trainings für Sie oder Ihre Mitarbeiter",
	BoschTrainingBenefits_OnlineAccess: "Onlinezugang zu Lernmaterial",
	BoschTrainingBenefits_AccessToYourOwn: "Zugriff auf Ihre persönliche Trainingshistorie und Zertifikate",
	BoschTrainingBenefits_ToolsForManagement: "Steuerung der Mitarbeiterentwicklung",
	BoschTrainingBenefits_ManagerHasFull: "Der Manager hat Überblick über die Trainings seiner Mitarbeiter",
	BoschTrainingBenefits_Access24hours: "Zugang 24 Stunden 7 Tage die Woche",
	ModernTrainingApproach_KnowledgeIsKey: "Wissen ist ein wesentlicher Erfolgsfaktor für jede Werkstatt. Bosch bietet deshalb ein umfassendes Trainingsprogramm an. So können sich unsere Kunden zur richtigen Zeit und am richtigen Ort genau das richtige Wissen aneignen, das nötig ist, um das Fahrzeug des Kunden professionell warten und reparieren zu können.  Webcasting wurde dabei als weiterer Bestandteil des bereits angebotenen E-Learning-Angebots hinzugefügt.",
	ModernTrainingApproach_ANewLearning: "Eine neue Lernmöglichkeit",
	ModernTrainingApproach_YourOnlineTraining: "Ihre Online Trainingsmöglichkeiten",
	ModernTrainingApproach_RecentlyTheWorld: "Die Welt verändert sich - wir unterstützen Sie!",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Online Trainingswelt",
	ModernTrainingApproach_NewTeachingMethod: "Neue Lernmethode",
	ModernTrainingApproach_InAdditionTo: "Zusätzlich zu den bestehenden Präsenztrainings haben wir Live-Webcast-Trainings in das Programm aufgenommen. Unser Trainer steht Ihnen mit umfangreichem theoretischem Wissen im virtuellen Klassenzimmer oder mit praktischen Beispielen zur Verfügung. Sie erhalten wie gewohnt viele Informationen zu Fahrzeugsystemen und den entsprechenden Bosch-Produkten. Natürlich haben Sie auch die Möglichkeit, Fragen zu stellen, diese werden direkt im Live-Webcast beantwortet. Die Live-Webcasts dauern zwischen 60 und 120 Minuten, eine stabile Internetverbindung ist erforderlich. Suchen Sie direkt in unserem Trainingskatalog nach einem passenden Webcast, indem Sie im Suchfeld \Webcast\ eingeben.",
	ModernTrainingApproach_BoschWebcastingHeader: "Innovativ und praktisch: Bosch-Webcasting",
	ModernTrainingApproach_BoschWebcastingText: "Wer an einem Bosch-Webcasting teilnehmen will, muss sich zunächst einwählen. Dazu wird jedem Teilnehmer vorher ein Link zum Einloggen zugesandt. Bis zu 200 Teilnehmer an verschiedenen Orten können gleichzeitig an einem Webcasting teilnehmen. Das Training wird von einem Trainer geleitet, an den die Teilnehmer jederzeit per Live-Chat Fragen richten können.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Die digitale Ergänzung zu Präsenztrainings",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasting und Präsenztrainings ergänzen einander, in beiden Fällen leitet ein qualifizierter Trainer das Training. Webcasting bietet sich für Themen an, die sich auch digital übermitteln lassen, ohne dass Arbeiten am Fahrzeug nötig sind. Etwa um bestimmte ESI[tronic]-Features oder ein neues Fahrassistenzsystem kennenzulernen. Wenn es um effizientes Teamwork in der Werkstatt oder um die konkrete Arbeit an der Fahrzeugtechnik geht, sind Präsenztrainings nach wie vor die erste Wahl.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Manchmal ist Streamen günstiger",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Die Kosten pro Teilnehmer sind beim Webcasting meist niedriger als bei einem vergleichbaren Präsenztraining. Der Grund: Mehr Teilnehmer können davon profitieren und der damit verbundene Aufwand ist deutlich geringer (keine An- und Abreise, geringerer Zeitbedarf).",
	ModernTrainingApproach_BookAndProfitHeading: "Buchen und profitieren",
	ModernTrainingApproach_BookAndProfitText: "Live Webcasts können Sie wie unsere anderen Trainingsangebote auf unserer Webseite buchen.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Live Webcasts und Präsenz- Trainings im Vergleich",
	ModernTrainingApproach_Webcasting: "Bosch-Webcasting",
	ModernTrainingApproach_Classroom: "Bosch-Präsenztrainings",
	ModernTrainingApproach_WebcastingP1: "Bis zu 200 Teilnehmer können an verschiedenen Orten auf der ganzen Welt gleichzeitig teilnehmen",
	ModernTrainingApproach_WebcastingP2: "Geringer Aufwand und relativ geringe Kosten – keine Anreise erforderlich",
	ModernTrainingApproach_WebcastingP3: "Optimal für spezifische und prägnante Einzelthemen",
	ModernTrainingApproach_WebcastingP4: "Werkstätten können überall und jederzeit ein Webcasting für Ihre Mitarbeiter durchführen",
	ModernTrainingApproach_WebcastingP5: "Zwischen 50 und 70 % geringerer Zeitaufwand als bei Präsenztrainings",
	ModernTrainingApproach_ClassroomTrainingP1: "Bis zu 16 Teilnehmer treffen sich am gemeinsamen Ort",
	ModernTrainingApproach_ClassroomTrainingP2: "Aufwand für An- und Abreise, meist höhere Pro-Kopf-Kosten",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideal, wenn es um die Optimierung der Zusammenarbeit oder um das Lernen direkt am Fahrzeug geht",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktische Übungen und Interaktion mit dem Trainer",
	ModernTrainingApproach_ClassroomTrainingP5: "Der Trainer kann das Wissen, das vermittelt werden soll, besser an die tatsächlichen Bedürfnisse der Teilnehmer anpassen",
	ModernTrainingApproach_OnlineTrainingOfferings: "Online Trainings Angebot (WBT/VDS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Selbstständiges Lernen am Computer ist heute ein fester Bestandteil der Wissensvermittlung. Die freie Zeit- und Ortswahl, unbegrenzte Wiederholbarkeit, individuelle Lerngeschwindigkeit und interaktive Lerninhalte machen diese Methode zu einem unverzichtbaren Lernwerkzeug.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Jedes Web-based Training (WBT) wurde sorgfältig gestaltet. Diese Kurse bieten nicht nur eine breite Wissensbasis, sondern können auch eine effektive Vorbereitung auf Trainings in Bosch-Trainingszentren sein.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Die virtuelle Diagnosesimulation bietet dem Anwender Rollenspielmöglichkeiten für tägliche Werkstattsituationen wie den Ablauf einer Fehlersuche mit Bosch-Diagnosegeräten in einer virtuellen Werkstatt.",
	ModernTrainingApproach_Advantages: "Vorteile",
	ModernTrainingApproach_AdvantagesP1: "Eine schnelle und effizente Methode um Wissen bereit zu stellen",
	ModernTrainingApproach_AdvantagesP2: "Abwechslungsreiches Lernen",
	ModernTrainingApproach_AdvantagesP3: "Kann mehrmals verwendet werden",
	ModernTrainingApproach_AdvantagesP4: "spart Zeit und Geld",
	ModernTrainingApproach_AdvantagesP5: "Individuelle Geschwindigkeit beim Lernen",
	ModernTrainingApproach_AdvantagesP6: "Flexible Lernzeit",
	ModernTrainingApproach_AdvantagesP7: "Wiederholbarkeit",
	ModernTrainingApproach_AdvantagesP8: "Keine Reisekosten",
	ModernTrainingApproach_AdvantagesP9: "Keine Kapazitäts- oder Terminkonflikte",
	Dashboard_CoursesViewAndManageYourCourses: "TRAININGS | Anzeigen und Managen von Trainings",
	Dashboard_MyCourses: "Meine Trainings",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "AUSBILDUNGSREIHEN | Anzeigen und Verwalten",
	Dashboard_MyTrainingProgram: "Meine Ausbildungsreihen",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Anzeigen und Verwalten",
	Dashboard_MyELearning: "Meine Online Trainings",
	Dashboard_AccountManageYourAccount: "KONTO | Verwalten Sie Ihr Konto",
	Dashboard_MyAccount: "Mein Konto",
	Dashboard_MyDashboard: "Mein Dashboard",
	Dashboard_ManageYourAccount: "Verwalten Sie Ihr Konto",
	Dashboard_Workshops: "Werkstätten",
	Dashboard_AdministrationManageYourWorkshop: "Werkstattbereich | Verwalten Sie Ihre Werkstatt",
	Dashboard_AdministrationManageYourTeams: "Werkstattbereich | Verwalten Sie Ihr Team",
	Dashboard_Administration: "Werkstattbereich",
	Dashboard_ViewYourCourses: "Trainings anzeigen",
	Dashboard_ViewYourTrainingPrograms: "Ausbildungen anzeigen",
	Dashboard_TrainingPrograms: "Trainingsprogramm",
	Dashboard_ViewAndEditYourDetails: "Anpassen Ihrer Daten",
	Dashboard_ViewYourELearningCourses: "eLearning Details anzeigen",
	Dashboard_Pending: "ausstehend",
	Dashboard_RequiresAdministratorSignOff: "(benötigt Genehmigung)",
	Dashboard_HistoricalRecords: "Historie",
	Dashboard_Registered: "angemeldet",
	Dashboard_WaitingList: "Warteliste",
	Dashboard_RecommendedForYou: "Vorschläge",
	Dashboard_AccountDetails: "Profildaten",
	Dashboard_ChangeProfileImage: "Profilbild ändern",
	Dashboard_Title: "Titel",
	Dashboard_Role: "Rolle",
	Dashboard_FirstName: "Vorname",
	Dashboard_LastName: "Nachname",
	Dashboard_Telephone: "Mobil",
	Dashboard_Mobile: "Telefon",
	Dashboard_Email: "E-Mail",
	Dashboard_Language: "Sprache",
	Dashboard_Save: "speichern",
	Dashboard_Student: "Mitarbeiter",
	Dashboard_WorkshopTrainingAdministrator: "Werkstatt-Administration",
	Dashboard_WholesalerTrainingAdministrator: "Zusatzfunktion",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Firmenadministration",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Verwalten Sie Ihre Werkstatt",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Verwalten Sie Ihr Team",
	Dashboard_CompanyAdministration_Workshop: "Werkstatt",
	Dashboard_CompanyAdministration_Address: "Firmenanschrift",
	Dashboard_CompanyAdministration_Zip: "PLZ",
	Dashboard_CompanyAdministration_City: "Stadt",
	Dashboard_CompanyAdministration_Manage: "verwalten",
	Dashboard_AdministrationSection_AdministrationTitle: "Administration",
	Dashboard_AdministrationSection_ManageTheTeam: "Verwalten Sie Ihr Team",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "Werkstattbereich | Verwalten Sie Ihr Team",
	Dashboard_AdministrationSection_Employees: "Team",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "Werkstattbereich | Zusatzfunktion",
	Dashboard_AdministrationSection_Wholesalers: "Zusatzfunktion",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "Werkstattbereich | Verwalten Sie Ihre Transaktionen",
	Dashboard_AdministrationSection_Transactions: "Transaktionen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Team Administration",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Verwalten Sie Ihr Team",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "buchen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Historie",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "ändern",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Team",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "hinzufügen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "erstellen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "angemeldet",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "offen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(benötigt eine Admin Freigabe)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historie",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Warteliste",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Aktuelle Mitarbeiterliste",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Mitarbeiterverwaltung",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Name",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Mitarbeiterkonto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Mitarbeiterdaten anzeigen/bearbeiten",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Einstellungen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Persönlichen Zugang erlauben und Einladung versenden",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Vertretung",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "darf selbst Trainings buchen",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Einladungsmail versenden",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Account deaktivieren",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "speichern",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "senden",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "bearbeiten",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Übersicht",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Verwalten Sie die Zugriffsrechte auf Ihren Account",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Liste ausgewählter Kontakte",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Name",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "löschen",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "erstellen",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "hinzufügen",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Konto",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Neues Teammitglied anlegen",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Wählen Sie einen Kontakt",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Details",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Kontakt auswählen",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transaktionen",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Managen und Anzeigen von Transaktionen",
	Dashboard_AdministrationSection_TransactionsSection_Back: "zurück",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Rechnungsnummer",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Beschreibung",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Zahlungsmethode",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Datum",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Betrag",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Downloadbereich",
	TrainingCentres_TrainingCentres: "Trainingszentren",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Unsere Trainingszentren finden Sie auf der ganzen Welt",
	TrainingCentres_CountryCategory: "Land",
	TrainingCentres_CityCategory: "Stadt",
	TrainingCentres_Country: "Land",
	TrainingCentres_City: "Stadt",
	TrainingCatalogue_TrainingCatalogue: "Trainingskatalog",
	TrainingCatalogue_LearningAtTheHighestLevel: "Lernen auf höchstem Niveau",
	TrainingCatalogue_StartDate: "Startdatum",
	TrainingCatalogue_EndDate: "Enddatum",
	TrainingCatalogue_TrainingName: "Trainingstitel",
	TrainingCatalogue_Language: "Sprache",
	TrainingCatalogue_Days: "Tage",
	TrainingCatalogue_Seats: "freie Plätze",
	TrainingCatalogue_Price: "Preis (ohne MwSt.)",
	TrainingCatalogue_Location: "Standort",
	TrainingCatalogue_PartNumber: "Sachnummer",
	TrainingCatalogue_TrainingCourse: "Training",
	TrainingCatalogue_Category: "Kategorie",
	TrainingCatalogue_TrainingCategory: "Trainingskategorie",
	TrainingCatalogue_CourseName: "Trainingsname",
	TrainingCatalogue_TotalDays: "Dauer (in Tagen)",
	TrainingCatalogue_Venue: "Ort",
	TrainingCatalogue_Details: "Details",
	TrainingCatalogue_Prerequisites: "Voraussetzungen",
	TrainingCatalogue_CourseType: "Trainingstyp",
	TrainingCatalogue_LearningMethod: "Methode",
	TrainingCatalogue_AddToWaitingList: "zu Warteliste hinzufügen",
	ShoppingCart_ShoppingCart: "Einkaufswagen",
	ShoppingCart_ReviewAndManage: "anzeigen und bearbeiten Ihrer Bestellung",
	ShoppingCart_YouHaveAddedItem: "Folgende Artikel wurden dem Einkaufwagen hinzugefügt",
	ShoppingCart_Item: "Artikel",
	ShoppingCart_Quantity: "Stück",
	ShoppingCart_PricePerItem: "Preis pro Artikel",
	ShoppingCart_TotalPrice: "Gesamtpreis",
	ShoppingCart_VatIsIncluded: "exkl. MwSt.",
	ShoppingCart_Redeem: "Wenn Sie kein Guthaben haben, klicken Sie hier, um es einzulösen",
	ShoppingCart_Purchase: "Wenn Sie kein Guthaben haben, klicken Sie hier, um zu kaufen",
	ShoppingCart_Checkout: "zur Kasse",
	ShoppingCart_ContinueShopping: "mit dem Einkauf fortfahren",
	ShoppingCart_TotalPriceInclVat: "Gesamtpreis inkl. MwSt.",
	ShoppingCart_Apply: "senden",
	ShoppingCart_ShoppingCartEmpty: "Ihr Einkaufswagen ist leer",
	ShoppingCart_VatIncluded: "MwSt. (inkl.)",
	ShoppingCart_ManageYourShopping: "Verwalten Sie Ihre Einkäufe",
	ShoppingCart_Participant: "Teammitglied",
	ShoppingCart_Complete: "zurück zur Übersicht",
	ShoppingCart_NetPrice: "Nettopreis",
	ShoppingCart_Workshop: "Werkstatt",
	CheckOut_PreviewYourOrder: "Bestellvorschau",
	CheckOut_YourShoppingCart: "Einkaufswagen",
	CheckOut_Preview: "Vorschau",
	CheckOut_Payment: "Zahlung",
	CheckOut_Summary: "Zusammenfassung",
	CheckOut_Back: "zurück",
	CheckOut_PleaseReadAndAgreeOurTerms: "Bitte lesen Sie unsere Allgemeinen Geschäftsbedingungen und stimmen diesen zu",
	CheckOut_IHaveReadTheTermsAndCondition: "Ich habe die [terms and conditions] gelesen, verstanden und stimme diesen zu",
	CheckOut_YesIAgree: "Ja, ich stimme den Allgemeinen Geschäftsbedingungen zu",
	CheckOut_Continue: "Zurück zur Startseite",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Vielen Dank! Sie haben den Bestellvorgang erfolgreich abgeschlossen.",
	CheckOut_OrderConfirmation: "In Kürze erhalten Sie per E-Mail eine Bestätigung mit allen wichtigen Informationen.",
	CheckOut_WeWillContactYouDirectly: "Bei Rückfragen setzen wir uns mit Ihnen in Verbindung.",
	CheckOut_AddToCart: "Zum Einkaufswagen hinzufügen",
	CheckOut_CreditCart: "Kreditkarte",
	CheckOut_DirectDebit: "Rechnung",
	CheckOut_TrainingCredits: "Credits",
	CheckOut_PreferredWholesaler: "Bevorzugter Großhändler",
	EventCalendar_EventCalendar: "Trainingstermine",
	EventCalendar_TrainingEventsForAllYourNeeds: "Trainings für alle Ihre Bedürfnisse",
	TrainingCourse_TrainingCourse: "Trainingskatalog",
	TrainingCourse_LearningAtTheHighestLevel: "Lernen auf höchstem Niveau",
	TrainingProgram_Title: "Ausbildungsreihen",
	TrainingProgram_SubTitle: "Lernen auf höchstem Niveau",
	AccountDeactivationModal_Title: "Sind Sie sicher, dass Sie dieses Konto deaktivieren möchten?",
	AccountDeactivationModal_Line1: "Die Deaktivierung dieses Kontos ist dauerhaft und entfernt alle Inhalte, einschließlich Transaktionen, Historie, Teammitglieder und Profileinstellungen.",
	AccountDeactivationModal_Line2Part1: "Wenn es sich innerhalb eines aktiven Abonnementzeitraums befindet, lesen Sie bitte unsere Widerrufsbelehrung in unserem",
	AccountDeactivationModal_Line2Part2: "Allgemeine Geschäftsbedingungen",
	AccountDeactivationModal_Line2Part3: "bevor Sie ihre Anfrage senden",
	AccountDeactivationModal_Line3: "Sind Sie sicher, dass Sie eine Anfrage zur Deaktivierung Ihres Konto übermitteln möchten?",
	AccountDeactivationModal_Cancel: "abbrechen",
	AccountDeactivationModal_Send: "senden",
	CurrentUserAccountDeactivationModal_Title: "Sind Sie sicher, dass Sie Ihr Konto deaktivieren möchten?",
	CurrentUserAccountDeactivationModal_Line1: "Die Deaktivierung Ihres Kontos ist dauerhaft und entfernt alle Inhalte, einschließlich Transaktionen, Buchungen, Historie, Trainings von Teammitgliedern und Profileinstellungen.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Für den Fall, dass aktive Buchungen vorliegen, lesen Sie bitte unsere Widerrufsbelehrung in unserem",
	CurrentUserAccountDeactivationModal_Line3: "Sind Sie sicher, dass Sie die Anfrage zur Löschung Ihres Accounts übermitteln möchten?",
	CurrentUserAccountDeactivationModal_Yes: "Ja",
	CurrentUserAccountDeactivationModal_No: "Nein",
	CourseWithdrawalModal_Title: "Bitte beachten Sie, dass Sie nur direkt stornieren können wenn Sie sich außerhalb unserer Stornierungsfrist befinden. Innerhalb bedarf es einer Bestätigung der Trainingsadministration.",
	CourseWithdrawalModal_Line1: "Sind Sie sicher, dass Sie die Stornierung senden möchten?",
	CourseWithdrawalModal_Line2Part1: "Unsere aktuellen",
	CourseWithdrawalModal_Line2Part2Link: "Stornierungsbedingungen -",
	CourseWithdrawalModal_Line2Part3: "fortfahren?",
	CourseWithdrawalModal_Yes: "Ja",
	CourseWithdrawalModal_No: "Nein",
	Settings_Settings: "Einstellungen",
	Settings_ManageYourSettings: "Verwalten Sie Ihre Einstellungen",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "Informationen Bosch Global ID / Single Key ID",
	Settings_Edit: "bearbeiten",
	Settings_CompanyDetails: "Firmendetails",
	Settings_RequestAccountDeletion: "Anfrage Account löschen",
	Settings_AccountDeletionText: "Wenn Sie Ihren Account löschen, werden alle Ihre Daten (einschließlich Ihrer Adress- und Zahlungsdaten) gelöscht und stehen nicht mehr zur Verfügung.",
	Settings_DeleteAccountRequestConfirmation: "Stornierungsbestätigung",
	Settings_DeleteAccountRequestConfirmationText: "Wir haben Ihre Anfrage zur Kontolöschung erhalten und werden Sie per E-Mail oder Telefon kontaktieren, um dies final zu bestätigen.",
	RecommendedHotels_RecommendedHotels: "Hotelempfehlung",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Unserer Trainingszentren finden Sie auf der ganzen Welt",
	Error_Common: "Etwas ist schief gelaufen!",
	Error_404: "Die Seite existiert nicht",
	Error_401: "Sie haben keinen Zugriff auf diese Seite",
	Error_500: "Der Server meldet einen Fehler",
	Dashboard_Courses_Table_Course: "Training",
	Dashboard_Courses_Table_Date: "Datum [Duration]",
	Dashboard_Courses_Table_Venue: "Ort",
	Dashboard_Courses_Table_TrainingType: "Trainingstyp",
	Dashboard_Courses_Table_Action: "Aktion",
	Dashboard_Courses_Table_TasksToComplete: "Folgende Aufgaben sind offen:",
	Dashboard_Courses_Table_Withdraw: "stornieren",
	Dashboard_Courses_Table_Remove: "löschen",
	Dashboard_Courses_Table_View: "anzeigen",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Keine Daten gefunden",
	Dashboard_Courses_Table_CompletionDate: "abgeschlossen",
	Dashboard_Courses_Table_Actions_Assignments: "Zuweisungen",
	Dashboard_Courses_Table_Actions_Certification: "Zertifikate",
	Dashboard_Courses_Table_Actions_Evaluations: "Feedback/Bewertung",
	Dashboard_Courses_Table_Actions_Assessments: "Tests",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Zu Kalender hinzufügen",
	Dashboard_Courses_Table_CourseDetails_Task: "Aufgaben",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Fälligkeitsdatum",
	ContactUs_Headline: "Kontakt",
	ContactUs_HowCanWeHelp: "Wie können wir Ihnen helfen?",
	ContactUs_EmailTab: "E-Mail",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Brief",
	ContactUs_Phone_Headline: "Anruf",
	ContactUs_Phone_Description: "Wir freuen uns auf Ihren Anruf und helfen Ihnen gerne weiter.",
	ContactUs_Phone_PhoneNumber: "Bitte nutzen Sie das Kontaktformular, um uns eine Nachricht zu senden.",
	ContactUs_Phone_ChargeNotice: "Die Gebühren variieren je nach Dienstanbieter und Land.",
	ContactUs_Letter_Headline: "per Post",
	ContactUs_Letter_Description: "Sie haben Fragen, Wünsche oder Anregungen? Wir freuen uns auf Ihre Anfrage.",
	ContactUs_Email_Headline: "E-Mail",
	ContactUs_Email_Description: "Senden Sie uns Ihre Nachricht, indem Sie das untenstehende Formular ausfüllen. Unsere Administration wird sich gerne mit Ihnen in Verbindung setzen.",
	ContactUs_Email_InputName: "Name*",
	ContactUs_Email_InputCompany: "Firma*",
	ContactUs_Email_InputCountry: "Land*",
	ContactUs_Email_InputEmail: "E-Mail*",
	ContactUs_Email_InputMessage: "Nachricht*",
	ContactUs_Email_DataProtection: "Datenschutzbestimmungen*",
	ContactUs_Email_DataProtectionConfirm: "Ich habe die Datenschutzbestimmungen gelesen*",
	ContactUs_Email_SecurityCheck: "Sicherheitsüberprüfung",
	ContactUs_Email_SecurityCodeConfirm: "Bitte tragen Sie hier die Zeichen/Buchstaben ein, die Sie in dem oberen Feld sehen",
	ContactUs_Email_SecurityCodeInvalid: "Die von Ihnen eingegebenen Zeichen sind nicht korrekt.",
	ContactUs_Email_MandatoryNotice: "Bitte füllen Sie alle Pflichtfelder aus.",
	ContactUs_Email_SuccessMessage: "Ihre Nachricht wurde erfolgreich übermittelt.",
	ContactUs_Email_TakenNoteDataProtection1: "Ich habe die Datenschutzbestimmungen gelesen und bin einverstanden",
	ContactUs_Email_TakenNoteDataProtection2: "Datenschutzbestimmungen*",
	ContactUs_Email_SubmitButton: "senden",
	ContactUs_Email_FieldErrorMessage: "Bitte füllen Sie alle Pflichtfelder aus.",
	ContactUs_Email_SuccessModal_OK: "Ok",
	AccountInReviewModal_Title: "Ihr Konto wird derzeit überprüft.",
	AccountInReviewModal_Paragraph1: "Vielen Dank für die Registrierung eines Kontos beim Bosch Automotive Campus.",
	AccountInReviewModal_Paragraph2: "Unsere Administration überprüft und konfiguriert derzeit Ihr Konto.",
	AccountInReviewModal_Paragraph3: "In der Zwischenzeit können Sie gerne unser Trainingsangebot durchstöbern.",
	AccountInReviewModal_Paragraph4: "Wenn Sie Fragen haben können Sie uns gern kontaktieren:",
	AccountInReviewModal_ContactUsLink: "hier",
	AccountInReviewModal_OKButton: "ok",
	Header_YesButton: "ja",
	Header_NoButton: "nein",
	Header_LogOutText: "Sind Sie sicher, dass sie sich ausloggen möchten?",
	Footer_EnquiryText: "Wir freuen uns auf Ihre Anfrage.",
	Footer_Telephone: "Telefon",
	Footer_TermsAndConditions: "Allgemeine Geschäftsbedingungen",
	Footer_HowOurTrainingEvolves: "Wie sich unser Training entwickelt",
	FooterSearch_SearchFieldPlaceholder: "Suche",
	TrainingCatalogue_Action: "Aktion",
	TrainingCatalogue_Duration: "Dauer",
	TrainingCatalogue_Information: "Information",
	Settings_PersonalDetails: "Persönliche Details",
	Reset_All_Filters: "Alle Filter zurücksetzten",
	EventTable_Days: "Tage",
	SearchField_AllResults: "Alle Ergebnisse",
	TrainingCentresLayout_ViewDetails: "Details anzeigen",
	Dropdown_Placeholder: "wählen",
	WaitlistPopup_SelectButtonText: "auswählen",
	WaitlistPopup_StartDate: "Startdatum",
	WaitlistPopup_EndDate: "Enddatum",
	WaitlistPopup_Language: "Sprache",
	WaitlistPopup_City: "Ort",
	WaitlistPopup_Workshop: "Firma",
	WaitlistPopup_Student: "Mitarbeiter",
	WaitlistPopup_PreferredLocation: "bevorzugter Ort",
	WaitlistPopup_SignUpCourseWaitingList: "zur Warteliste anmelden",
	WaitlistPopup_SignUpWithoutSelectingDate: "zur allgemeinen Warteliste (Interessent) anmelden",
	WaitlistPopup_GeneralWaitList: "Generelle Warteliste",
	WatinglistPopup_CourseLabel: "Trainings",
	Common_ContinueButton: "fortfahren",
	UserAccountDeactivationModal_ConfirmLabel: "bestätigen",
	Filter_FilterLabel: "Filter",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Sie sind bereits auf der Warteliste für dieses Training, möchten Sie dies löschen oder einen anderen Wartelisteneintrag vornehmen?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Ja, löschen",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nein, abbrechen",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Trainingsstornierung",
	WaitinglistPopup_SignUpButtonText: "anmelden",
	MyAccount_FirstNameRequied: "Vorname wird benötigt",
	MyAccount_LastNameRequired: "Nachname wird benötigt",
	MyAccount_RoleRequired: "Funktion wird benötigt",
	MyAccount_MobileRequired: "Telefon wird benötigt",
	MyAccount_EmailRequired: "E-Mail wird benötigt",
	MyAccount_LanguageRequired: "Sprache wird benötigt",
	MyAccount_UpdateSuccessMessage: "Ihre Daten wurden erfolgreich aktualisiert.",
	MyAccount_UpdateSuccessModal_ButtonText: "Ja, schließen",
	Infor_CorporateInformation_Header: "Unternehmensinformationen",
	Infor_LegalNotice_Header: "Rechtliche Hinweise",
	Infor_PrivacyPolicy_Header: "Datenschutzerklärung (Datenschutzhinweise)",
	CreateEmployeeAccountModification_FormValidation_Role: "Funktion wird benötigt",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Vorname wird benötigt",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Nachname wird benötigt",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Telefon wird benötigt",
	CreateEmployeeAccountModification_FormValidation_Email: "E-Mail wird benötigt",
	CreateEmployeeAccountModification_FormValidation_Language: "Sprache wird benötigt",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Einladung Anmeldung Bosch Automotive Campus",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Sie haben erfolgreich ein neues Mitglied Ihrem Team hinzugefügt.",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Möchten Sie, dass wir eine E-Mail-Einladung an Ihren neuen Mitarbeiter (mit Anweisungen, wie dieser sein eigenes Konto erstellen kann) senden?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "nein",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "ja, bitte einladen",
	ShoppingCart_Venue_Fee: "Gebühren Trainingsort",
	ShoppingCart_Trainning_Name: "Trainingsname",
	ShoppingCart_Available_Credits: "Verfügbare Punkte",
	ConfirmItsMeModal_Hi: "Hi",
	ConfirmItsMeModal_Description1: "Sie haben Ihre Registrierung durch eine Einladung erfolgreich abgeschlossen.",
	ConfirmItsMeModal_Description2: "Bevor Sie fortfahren: Bitte aktualisieren Sie Ihre Kontoinformationen. Vielen Dank",
	ConfirmItsMeModal_Description3: "Bitte Aus- und wieder Einloggen, um den Vorgang abzuschliessen.",
	ConfirmItsMeModal_CompanyDetails: "Firmendetails",
	ConfirmItsMeModal_YourDetails: "Ihre Details",
	ConfirmItsMeModal_YourDetails_Title: "Titel",
	ConfirmItsMeModal_YourDetails_Function: "Funktion",
	ConfirmItsMeModal_YourDetails_Name: "Name",
	ConfirmItsMeModal_YourDetails_Mobile: "Telefon",
	ConfirmItsMeModal_YourDetails_Email: "E-Mail",
	ConfirmItsMeModal_YourDetails_Language: "Sprache",
	ConfirmItsMeModal_ConfirmButton: "bestätigen",
	SelectWholesalerModal_WholesalerGroupLabel: "Firma",
	SelectWholesalerModal_WholesalerBranchLabel: "Kontakt",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Bitte auswählen",
	ApplyButton: "Senden",
	SelectWholesalerModal_Title: "Wählen Sie einen Kontakt",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Möchten Sie Ihre Anmeldung löschen?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Die Anmeldung wurde erfolgreich storniert.",
	WaitlistPopup_AddToWaitListSuccess: "Sie wurden erfolgreich hinzugefügt.",
	ErrorCommon_Header: "Fehler",
	CommonButton_Close: "schließen",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Bitte schließen Sie dieses Fenster um fortzufahren.",
	EmbeddedVideoPlayer_StartButtonText: "Start",
	EmbeddedVideoPlayer_RefresherButtonText: "aktualisieren",
	DeactivateWholesalerModal_ConfirmMessage: "Sind Sie sicher, dass Sie diesen Kontakt löschen möchten?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Einladungs-E-Mail wurde erfolgereich versendet",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Nochmal einladen",
	ContactUs_Mobile_CH: "DE: 0800 342 46 46 ; AT/CH: +49 7153 666 146",
	ContactUs_Address_CH: "Robert Bosch GmbH, Robert-Bosch-Straße 4, 73207 Plochingen, Deutschland",
	ConfirmItsMeModal_CompanyDetails_Name: "Firmenname",
	ConfirmItsMeModal_CompanyDetails_Address: "Firmenanschrift",
	ConfirmItsMeModal_CompanyDetails_Postcode: "PLZ",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-Mail",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Die Stornierung des Trainings war erfolgreich.",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Fehler beim Erstellen eines Kontos. Das Konto existiert bereits!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Fehler beim Aktualisieren eines Kontos. Das Konto existiert bereits!",
	Footer_ExploreTrainingStories: "Entdecken Sie unsere Trainings",
	Breadcrumb_Home: "Home",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Geschichten",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "Ein Tag im Leben eines Trainers",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch Training Vorteile",
	Breadcrumb_Stories_TrainingApproach: "Ein moderner Ansatz",
	Breadcrumb_Settings: "Einstellungen",
	Breadcrumb_ShoppingCart: "Einkaufswagen",
	Breadcrumb_Checkout: "Check out",
	Breadcrumb_OrderSummary: "Zusammenfassung",
	Breadcrumb_Exception: "Ausnahme",
	Breadcrumb_Dashboard: "Dashboard",
	Breadcrumb_MyCourses: "Meine Trainings",
	Breadcrumb_MyELearning: "Meine eLearnings",
	Breadcrumb_CompanyAdministration: "Firmenadministration",
	Breadcrumb_EmployeeManagement: "Mitarbeitermanagement",
	Breadcrumb_EmployeeAccount: "Mitarbeiterkonto",
	Breadcrumb_EmployeeCreation: "Mitarbeiter anlegen",
	Breadcrumb_Administration: "Administration",
	Breadcrumb_EmployeeAdministration: "Mitarbeiteradministration",
	Breadcrumb_WholesalerManagement: "Zusätzliches",
	Breadcrumb_NewWholesalerAccountCreation: "Neuen Kontakt auswählen",
	Breadcrumb_Transactions: "Transaktionen",
	Breadcrumb_MyAccount: "Profil",
	Breadcrumb_TrainingCentres: "Trainingszentren",
	Breadcrumb_EventCalendar: "Trainingstermine",
	Breadcrumb_TrainingCourseCatalogue: "Trainingskatalog",
	Breadcrumb_RecommendedHotels: "Empfohlene Hotels",
	Breadcrumb_CorporateInformation: "Impressum",
	Breadcrumb_LegalNotice: "Rechtliche Hinweise",
	Breadcrumb_DataProtectionNotice: "Datenschutzhinweise",
	Breadcrumb_PrivacyPolicy: "Datenschutzhinweise",
	Breadcrumb_TermCondition: "Allgemeine Geschäftsbedinungen",
	Breadcrumb_Terms: "AGBs",
	Breadcrumb_PrivacyStatement: "Datenschutzbestimmungen",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Impressum",
	Breadcrumb_RegisterAccount: "Konto Registrierung",
	Stories_ExploreMore: "Mehr erfahren",
	CheckOutLayout_ShoppingCartHeader: "Einkaufswagen",
	CheckOutLayout_AddedItemCartMessage: "Sie haben folgendes zu Ihrem Einkaufswagen hinzugefügt",
	CheckOutLayout_CheckYourCartMessage: "Überprüfen Sie Ihren Einkaufswagen",
	ModernTrainingApproach_DateText: "28022022",
	ADayInLifeofATrainer_DateText: "1032022",
	BoschTrainingBenefits_DateText: "2032022",
	BoschServiceTraining_DateText: "3032022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Teammitglied suchen",
	CheckOutLayout_OrderWasNotPlaced_Message: "Bitte entschuldigen Sie - Ihre Buchung konnte nicht abgeschlossen werden.",
	CheckOutLayout_RefusalReason: "Ablehnungsgrund",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Bezahlung fehlgeschlagen. Bitte versuchen Sie es mit einer anderen Zahlungsmethode erneut",
	CheckOutLayout_SubmittingApiFailed_Message: "Senden der API fehlgeschlagen",
	CartItem_ChooseWorkshop_Message: "Bitte Werkstatt und Teilnehmer auswählen.",
	CartItem_ChooseParticipant_Message: "Bitte wählen Sie einen Teilnehmer aus um fortzufahren",
	CompanyAdministrationTable_SearchBox_Placeholder: "Werkstatt suchen",
	CartItem_RemoveCartItem_ConfirmText: "Möchten Sie diese Position aus Ihrem Einkaufswagen löschen?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funktion",
	AddToCartSuccessModal_Message: "Ihr Einkaufswagen wurde aktualisiert.",
	AddToCartSuccessModal_ContinueButtonText: "Einkauf fortsetzen",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Mein Einkaufswagen",
	AddVoucher_Header: "Gutscheincode (falls vorhanden)",
	AddVoucher_InputPlaceholder: "Gutscheincode eingeben",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Leider haben wir derzeit keine geplanten Termine. Bitte tragen Sie sich in die allgemeine Warteliste ein und wir werden Sie, sobald ein Termin verfügbar ist, informieren.",
	ADYEN_ERR_2_: "Die Transaktion wurde abgelehnt.",
	ADYEN_ERR_3_: "Verweisfehler",
	ADYEN_ERR_4_: "Die Transaktion wurde aufgrund eines Fehlers auf der Seite nicht durchgeführt.",
	ADYEN_ERR_5_: "Die für die Transaktion verwendete Karte ist gesperrt und daher unbrauchbar.",
	ADYEN_ERR_6_: "Die für die Transaktion verwendete Karte ist abgelaufen und daher unbrauchbar.",
	ADYEN_ERR_7_: "Während des Transaktionsprozesses ist eine Betragsabweichung aufgetreten.",
	ADYEN_ERR_8_: "Die angegebene Kartennummer ist falsch oder ungültig.",
	ADYEN_ERR_9_: "Es ist nicht möglich, die Bank des Käufers zu kontaktieren, um die Transaktion zu autorisieren.",
	ADYEN_ERR_10_: "Ihre Bank unterstützt oder erlaubt diese Art von Transaktion nicht.",
	ADYEN_ERR_11_: "Die 3D Secure-Authentifizierung wurde nicht oder nicht erfolgreich ausgeführt.",
	ADYEN_ERR_12_: "Die Karte hat nicht genug Geld, um den zu zahlenden Betrag zu decken. Dieses Problem kann aufgrund eines monatlich zu zahlenden Betragslimits auf Ihrer Karte auftreten. Versuchen Sie bitte, eine andere Karte zu verwenden, oder wenden Sie sich an Ihre Bank, um das monatliche Limit Ihrer Karte zu erhöhen.",
	ADYEN_ERR_14_: "Möglicher Betrug.",
	ADYEN_ERR_15_: "Die Transaktion wurde abgebrochen.",
	ADYEN_ERR_16_: "Der Käufer hat die Transaktion abgebrochen, bevor er sie abgeschlossen hat.",
	ADYEN_ERR_17_: "Die angegebene PIN ist falsch oder ungültig.",
	ADYEN_ERR_18_: "Es wurde dreimal hintereinander eine falsche PIN angegeben.",
	ADYEN_ERR_19_: "Es ist nicht möglich, die angegebene PIN-Nummer zu validieren.",
	ADYEN_ERR_20_: "Möglicher Betrug.",
	ADYEN_ERR_21_: "Die Transaktion wurde nicht korrekt zur Verarbeitung übermittelt.",
	ADYEN_ERR_22_: "Die Risikoprüfung hat die Transaktion als betrügerisch gekennzeichnet (Risiko-Score >= 100); daher wird die Operation abgebrochen.",
	ADYEN_ERR_23_: "Die folgenden abgelehnten Codes werden diesem Ablehnungsgrund zugeordnet:  Transaktion für Aussteller/Karteninhaber nicht zulässig",
	ADYEN_ERR_24_: "Der angegebene CVC (Kartensicherheitscode) ist ungültig.",
	ADYEN_ERR_25_: "Die folgenden Ablehnungscodes sind diesem Ablehnungsgrund zugeordnet:  Ungültige Karte in diesem Land",
	ADYEN_ERR_26_: "R1: Widerruf des Autorisierungsauftrags / „R3: Widerruf aller Autorisierungsaufträge“ / „R0: Zahlungsstoppauftrag“",
	ADYEN_ERR_27_: "Diese Antwort bildet alle Antwortcodes ab, die nicht zuverlässig zugeordnet werden können. Dies macht es einfacher, generische Ablehnungen (z. B. Mastercard-Antwort „05: Nicht honorieren“) von spezifischeren zu unterscheiden.",
	ADYEN_ERR_28_: "Der für die Karte zulässige Auszahlungsbetrag wurde überschritten.",
	ADYEN_ERR_29_: "Die Anzahl der für die Kundenkarte zulässigen Abhebungen wurde überschritten.",
	ADYEN_ERR_31_: "Die Transaktion wurde als Betrugsverdacht gemeldet.",
	ADYEN_ERR_32_: "Die vom Käufer eingegebenen Adressdaten sind falsch.",
	ADYEN_ERR_33_: "Die Bank des Käufers verlangt vom Käufer die Eingabe einer Online-PIN.",
	ADYEN_ERR_34_: "Die Bank des Käufers benötigt ein Girokonto, um den Kauf abzuschließen.",
	ADYEN_ERR_35_: "Die Bank des Käufers benötigt ein Sparkonto, um den Kauf abzuschließen.",
	ADYEN_ERR_36_: "Die Bank des Käufers verlangt vom Käufer die Eingabe einer Handy-PIN.",
	ADYEN_ERR_37_: "Der Käufer brach die Transaktion ab, nachdem er versucht hatte, kontaktlos zu bezahlen, und wurde aufgefordert, eine andere Karteneingabemethode (PIN oder Durchziehen) auszuprobieren.",
	ADYEN_ERR_38_: "Der Aussteller hat die Authentifizierungsausnahmeanfrage abgelehnt und verlangt eine Authentifizierung für die Transaktion. Versuchen Sie es erneut mit 3D Secure.",
	ADYEN_ERR_39_: "Der Ausstellende oder das System war nicht in der Lage, das Ergebnis über RReq mitzuteilen",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Der Vorname darf keine Sonderzeichen enthalten (z. B.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Der Vorname muss kleiner als 75 Zeichen sein",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Der Nachname darf keine Sonderzeichen enthalten (z. B.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Der Nachname muss kleiner als 75 Zeichen sein",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Die Handynummer darf keine Sonderzeichen enthalten (z. B.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Die Handynummer muss kleiner als 75 Zeichen sein",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Die Telefonnummer darf keine Sonderzeichen enthalten (z. B.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Die Telefonnummer muss kleiner als 75 Zeichen sein",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "E-Mailadresse ist nicht korrekt",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-Mailadresse muss kleiner als 100 Zeichen sein",
	ConfirmGeolocationModal_ConfirmButton: "fortsetzen",
	ConfirmGeolocationModal_CancelButton: "ändern",
	ConfirmGeolocationModal_CurrentCountrySelection: "Ihre aktuelle Länderauswahl ist:",
	ConfirmGeolocationModal_AskChangeCountry: "Möchten Sie auf dieser Seite bleiben oder Ihre Ländereinstellung ändern?",
	Addtocart_Modal_AddToCartFailTitle: "Für diese Person liegt bereits eine Anmeldung vor.",
	Addtocart_Modal_AlreadyInCart: "Für diese Person liegt bereits eine Anmeldung vor.",
	TrainingCatalogue_DateRange: "Datumsbereich",
	TrainingCatalogue_DateRange_placeholder: "Zeitspanne",
	Dashboard_MyPendingCourses: "Meine ausstehenden Trainings",
	Dashboard_MyWaitingList: "Meine Warteliste",
	Dashboard_LatestNews: "Neuigkeiten",
	SettingsMainPage_EditAccountButton: "Konto bearbeiten",
	SettingsMainPage_EditBoschId: "Bosch ID / SingleKey ID bearbeiten",
	EventInfoView_EventDetailTab: "Trainingsdetails",
	EventInfoView_AssignmentsTab: "Zuweisungen",
	EventInfoView_EvaluationTab: "Feedback",
	EventInfoView_AttachmentTab: "Anhänge",
	EventInfoView_CourseDescriptionTab: "Beschreibung",
	EventInfoView_DescriptionTab_General: "Details",
	EventInfoView_DescriptionTab_PartLabel: "Teil",
	AssignmentTab_Description: "Hier finden Sie alle Informationen, die Sie benötigen, um dieses Training zu absolvieren.",
	AssignmentTab_Columns_Download: "Download",
	AssignmentTab_Columns_Upload: "Upload",
	AssignmentTab_Columns_TaskComplete: "Aufgabe abgeschlossen",
	EventDetailTab_Location_Message_Video: "Dies ist eine Online-Veranstaltung, die Sie über den Link „Video starten“ starten können.  (Bitte nutzen Sie zum Starten des Videos Chrome oder Edge und nicht Firefox)",
	EventDetailTab_Location_Message_WebBased: "Dies ist eine Online-Veranstaltung, die Sie über den Link „WBT starten“ starten können. (Bitte nutzen Sie zum Starten des WBT Chrome oder Edge und nicht Firefox)",
	EventDetailTab_Location_Message_WebCast: "Dies ist eine Online-Veranstaltung, die Sie über den Link „Jetzt teilnehmen“ starten können.",
	EventDetailTab_Link_Video: "Video starten",
	EventDetailTab_Link_Video_Remark: "*Beim Starten des Videos öffnet sich ein neues Fenster. Sie werden auf diese Seite zurückgeleitet, sobald das zusätzliche Fenster geschlossen wird.",
	EventDetailTab_Link_WebBased: "WBT starten",
	EventDetailTab_Link_WebBased_Remark: "*Beim Starten des WBT öffnet sich ein neues Fenster. Sie werden auf diese Seite zurückgeleitet, sobald das zusätzliche Fenster geschlossen wird.",
	EventDetailTab_Link_WebCast: "Jetzt beitreten",
	EventDetailTab_Link_WebCast_Remark: "* Der Link „Jetzt teilnehmen“ wird 30 Minuten vor Beginn des Events aktiv.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Dauer (Std.)",
	EventDetailTab_Title_Trainers: "Trainer",
	EventDetailTab_Title_Language: "Sprache",
	EventDetailTab_Title_Score: "Ergebnis (%)",
	EventDetailTab_Title_Calendar: "Zu Kalender hinzufügen",
	EventDetailTab_Title_Certificate: "Download Zertifikat",
	EventDetailTab_Title_Messages: "Nachricht",
	EventDetailTab_Title_StartDate: "Startdatum",
	EventDetailTab_Title_AvailableFrom: "Verfügbar ab",
	EventDetailTab_Title_Time: "Zeit",
	EventDetailTab_Title_EndDate: "Enddatum",
	EventDetailTab_Title_ExpiresOn: "Läuft aus",
	EventDetailTab_Title_Room: "Raum",
	EventDetailTab_Title_Method: "Methode",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Ort",
	AttachmentTab_EmptyScreenDescription: "Es sind keine Anhänge verfügbar.",
	AttachmentTab_Columns_Download: "Download",
	AttachmentTab_Description: "Wichtige Informationen zu diesem Training.",
	EvaluationTab_Annotation: "Wir würden uns über ein Feedback von Ihnen freuen. Bitte nehmen Sie sich kurz Zeit, die folgende Bewertung auszufüllen, damit wir unseren Service für Sie kontinuierlich verbessern können. Vielen Dank.",
	EvaluationTab_Notice: "Die Bewertungen sind wie folgt",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Bitte beantworten Sie alle Pflichtfragen, bevor Sie Ihre Veranstaltungsbewertung absenden",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Ihr Feedback wurde erfolgreich übermittelt",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Die Übermittlung der Bewertung ist fehlgeschlagen",
	TrainingCatalogueLayout_ExportButtonText: "Export",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Die Evaluation wurde abgeschlossen oder ist abgelaufen.",
	EventDetailTab_Link_CopiedTooltip: "In die Zwischenablage kopiert",
	EventDetailTab_QRCode_ExplanationText_WBT: "QR Code scannen um WBT zu starten",
	EventDetailTab_QRCode_ExplanationText_Video: "QR Code scannen um Video zu starten",
	EventDetailTab_QRCode_ExplanationText_Webcast: "QR Code scannen um Webcast zu starten",
	Footer_TrainingCoure_Headline: "Trainings für Kraftfahrzeugtechnik von Bosch",
	Footer_TrainingCourse_Content: "In unseren Bosch Traingsstandorten für den deutschsprachigen Raum – Plochingen, Kassel und Wien - bietet Bosch Kfz-Werkstattmitarbeitern seit vielen Jahren praxisorientierte technische Trainings in modernen Theorie- und Praxisräumen mit einem umfangreichen Fuhrpark von Trainingsfahrzeugen an. Das vielseitige Trainingsprogramm für die Diagnose, Fehlersuche und Reparatur von Pkw und Nfz wird regelmäßig erweitert und die Trainingsinhalte den aktuellen technischen Entwicklungen und den praktischen Bedürfnissen der Kfz-Werkstätten angepasst. So ist das Schulungsangebot z.B. im Bereich Hochvolt bis zur Stufe 3E ausgebaut worden. Weitere Schulungsschwerpunkte liegen aktuell u.a. in den Bereichen Elektrische Bordnetze, Kalibrierung von Fahrerassistenzsystemen, sowie bei Themen rund um die Nutzfahrzeugtechnik.  Seit 2020 werden die Praxistrainings durch Live Webcasts ergänzt. In diesen nehmen die Werkstattmitarbeiter „live online“ in einem virtuellen Klassenzimmer an dem Training teil und können mit dem Trainer über Kamera und Mikrofon kommunizieren. Somit lassen sich bei unseren Kunden Reisekosten/-zeiten reduzieren, ohne dass technische Informationen verloren gehen.",
	SearchField_NoRecordsFound: "Keine Suchergebnisse gefunden",
	TrainingCatalogue_FreeTextSearch: "Stichwortsuche",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Suche",
	CheckoutLayout_WholeSalerGroup: "Auswahl Großhändler",
	CreditInsufficientModal_Message: "Ihr Credit Guthaben ist aktuell nicht ausreichend. Bitte wählen Sie eine andere Zahlungsmethode",
	ShoppingCart_Required_Credits: "Gesamtpreis",
	MaintainingInfo_Message1: "Die Webseite wird gerade für Sie überarbeitet.",
	MaintainingInfo_Message2: "Wir sind bald wieder für Sie da.",
	CountryValidationModal_Message: "Sie konnten sich nicht in [CountryA] anmelden, da ihr Benutzerkonto bereits in [CountryB] besteht.",
	CountryValidationModal_ButtonText: "Fortfahren mit  [Country]",
	EventTable_LoginRequired: "Einloggen zum Registrieren",
	TrainingPartTableLayout_Date: "Datum",
	TrainingPartTableLayout_StartTime: "Startzeit",
	TrainingPartTableLayout_EndTime: "Endzeit",
	TrainingPartTableLayout_Duration: "Dauer (Std.)",
	TrainingPartTableLayout_TrainingMethod: "Trainingsmethode",
	MyCoursesTableContents_ActiveHeader: "aktive Trainings",
	MyCoursesTableContents_ActiveTabHeader: "aktiv",
	MyCoursesTableContents_ScheduledHeader: "Präsenztrainings",
	MyCoursesTableContents_ScheduledTabHeader: "Präsenz",
	MyCoursesTableContents_OnlineHeader: "Online Trainings",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "stornierte Trainings",
	MyCoursesTableContents_CancelledTabHeader: "storniert",
	OnlineCoursesTable_Title_Progress: "Fortschritt",
	ScheduledCoursesTable_Title_Date: "Datum [Dauer]",
	CoursesTable_Title_Completed: "abgeschlossen",
	Footer_TrainingProgram: "Ausbildungsreihen",
	Breadcrumb_TrainingProgram: "Ausbildungsreihen",
	TrainingProgram_Title_Table: "Titel",
	TrainingProgram_TrainingProgram: "Ausbildungsreihen",
	TrainingProgram_TargetGroup: "Zielgruppe",
	TrainingProgram_MaxLength: "empfohlene Dauer",
	TrainingProgram_Information: "Information",
	TrainingProgram_Detail: "Details",
	TrainingProgram_Modules: "Module",
	TrainingProgram_Course: "Trainings",
	TrainingProgram_PartNumber: "Seminarnummer",
	TrainingProgram_Max_Length: "Maximale Dauer",
	TrainingProgram_Duration: "Anzahl Trainingstage",
	TrainingProgram_Exam: "Prüfung",
	TrainingProgram_Assign_Popup: "Anmeldung zur Ausbildungsreihe",
	TrainingProgram_Student: "Teilnehmer",
	TrainingProgram_Assign: "anmelden",
	TrainingProgram_Popup_Remove_Title: "Stornieren?",
	TrainingProgram_Description: "Beschreibung",
	TrainingProgram_Days: "Trainingstage",
	TrainingProgram_Type: "Typ",
	TrainingProgram_Action: "Aktion",
	TrainingProgram_Enrolled: "ist bereits zu dieser Ausbildungsreihe angemeldet.",
	TrainingProgram_SelfAssign_Enrolled: "Sie sind bereits zu dieser Ausbildungsreihe angemeldet.",
	TrainingProgram_Warning: "Bitte wählen Sie einen anderen Teilnehmer oder eine andere Ausbildungsreihe aus.",
	TrainingProgram_Workshop: "Werkstatt",
	TrainingProgram_Durations: "Jahre",
	TrainingProgram_Duration_DaysLabel: "Tage",
	TrainingProgram_HasExam_Yes: "Ja",
	TrainingProgram_HasExam_No: "Nein",
	AssignModal_SuccessModal_Header: "Vielen Dank!",
	AssignModal_SuccessModal_Message: "wurde erfolgreich zu dieser Ausbildungsreihe gebucht.",
	AssignModal_SelfAssign_SuccessModal_Message: "Sie wurden erfolgreich zu dieser Ausbildungsreihe gebucht.",
	Dashboard_MyTraningProgram: "Meine Ausbildungsreihen",
	Dashboard_MyTraningProgram_Title: "Bezeichnung",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "anzeigen",
	Dashboard_MyTraningProgram_Register: "buchen",
	Dashboard_MyTraningProgram_Records: "Historie",
	Dashboard_MyTrainingProgram_In_Progress: "in Ausbildung",
	Dashboard_MyTrainingProgram_Accredited: "anerkannt",
	Dashboard_Program_Modal_Withdraw: "storniert",
	Dashboard_Program_Modal_Grade: "Prüfung",
	Dashboard_MyTrainingProgram_Registered: "angemeldet",
	Dashboard_MyTrainingProgram_Completedd: "abgeschlossen",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Ja",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nein",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Wenn Sie mit \"Ja\" fortfahren, stornieren Sie die komplette Ausbildungsreihe, nicht nur einen Trainingstermin.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Möchten Sie sich wirklich von der Ausbildungsreihe abmelden?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "ausstehend",
	WithdrawProgramModal_Withdraw_Success_Message: "Sie haben sich erfolgreich von der Ausbildungsreihe abgemeldet.",
	Common_Warning_Title: "Achtung!",
	Common_Error_Title: "Sorry, hier ist etwas schiefgelaufen!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Zum Einkaufswagen hinzufügen",
	AddEventFromCatalogToCartModal_HoursText: "Stunden",
	AddElearningToCartSuccessModal_Message: "wurde zu Ihrem Einkaufswagen hinzugefügt.",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Der ausgewählte Teilnehmer wurde zu diesem Training bereits angemeldet oder das Training wurde bereits zum Einkaufswagen hinzugefügt.",
	EventDetailTab_SubjectForChange_Message: "Änderungen vorbehalten",
	Dashboard_MyTrainingProgram_Status_Failed: "nicht bestanden",
	Dashboard_MyTrainingProgram_Status_Passed: "bestanden",
	Dashboard_MyTrainingProgram_Status_Merit: "Leistung",
	Dashboard_MyTrainingProgram_Status_Distinction: "Auszeichnung",
	Dashboard_Employee_Courses: "Trainings",
	Dashboard_Employee_Program: "Ausbildungsreihen",
	Dashboard_Employee_WaitList: "Warteliste",
	Dashboard_Employee_Account: "Profil",
	Course_Price_Free: "-",
	Dashboard_Employee_Account_Update_Success_Message: "Sie haben die Informationen erfolgreich aktualisiert!",
	Dashboard_Supplier: "Zusatzinfos",
	Event_Detail_Comment: "Keine aktuellen Benachrichtigungen",
	DownLoad_PDF: "PDF Download",
	TrainingCatalogue_Currency: "Währung",
	My_Wallet: "Credits",
	Wallet: "Credits",
	Credits_Balance: "verfügbare Credits",
	MyFullCalendar_Today: "Heute",
	Header_Available_Languages: "Verfügbare Sprachen",
	Header_CurrentRegion: "Sie sind aktuell",
	Header_ChangeRegions: "Möchten Sie das Land wechseln?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Login / registrieren",
	Header_RegisterCourse: "Training buchen",
	Homepage_Start: "Neuregistrierung",
	Homepage_Login: "Login",
	MainPage_Title: "Uns bewegt Fachwissen",
	MainPage_Content: "Trainingsprogramm für Kraftfahrzeug-Technik",
	MainPage_ImageTitle: "Bosch Automotive Campus \n Trainings für Ihre Werkstatt",
	MainPage_IntroContent: "Ermöglicht der modernen Werkstatt ein professionelles, zuverlässiges und effizientes Arbeiten bei Diagnoseverfahren, Fehlersuche, Reparatur und Wartung von Fahrzeugen aller Art.",
	BoschNews_TrainingNews: "Trainings-News",
	Service_Assist_Workshop: "Neu - die Workshop Service Assist App",
	Enhance_Title_CDM3_v2: "Was kann ich mit der App machen?",
	Enhance_Body_CDM3_Item_1: "Trainings buchen, Historie einsehen (demnächst verfügbar)",
	Enhance_Body_CDM3_Item_2: "Trainingsmaterial und Zertifikate herunterladen (demnächst verfügbar)",
	Enhance_Body_CDM3_Item_3: "Online Tranings starten (demnächst verfügbar)",
	Enhance_Body_CDM3_Item_4: "Zugriff auf verschiedene Automotive-Dienste",
	Enhance_Body_CDM3_Item_5: "Live-Videoübertragung mit Visual Connect Pro (Technische Hotline)",
	Enhance_Body_CDM3_Item_6: "Kostenloses Tool für den automatischen Fahrzeugscan (Technische Hotline)",
	Training_Stories: "Trainings",
	SettingsMyAccount_JoinCompanyButton: "Firma beitreten",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Firma beitreten",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Bitte geben Sie unten Ihren Aktivierungscode ein",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Den Aktivierungscode haben Sie mit der Einladung zum Campus erhalten.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Ihre Aktivierung wurde erfolgreich verarbeitet und Ihr Konto ist jetzt mit Ihrem Unternehmen verknüpft.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Um Ihr Profilupdate abzuschließen, melden Sie bitte sich ab- und wieder an.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line3: "Bitte klicken Sie auf Weiter, um sich abzumelden und den Vorgang abzuschließen.",
	ContinueButton: "weiter",
	WelcomeBatsModal_Title: "Ihr Konto wird derzeit überprüft.",
	WelcomeBatsModal_ActivationLink: "Klicken Sie hier, wenn Sie einen Aktivierungscode haben, um sich mit Ihrem Unternehmen zu verknüpfen.",
	WelcomeBatsModal_ActivationLinkNotes: "Den Aktivierungscode haben Sie mit der Einladung zum Campus erhalten.",
	AccountInReviewModal_Description_Paragraph1: "Vielen Dank für die Registrierung eines persönlichen Kontos bei Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Unsere Trainingsadministration überprüft derzeit Ihr Konto und konfiguriert es. Sie erhalten eine Bestätigung per E-Mail, sobald Ihr Konto freigegeben wurde.",
	AccountInReviewModal_CloseButton: "Loslegen",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Wir konnten Ihren Aktivierungscode und Ihre E-Mail-Adresse nicht validieren.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Bitte versuchen Sie es erneut oder wenden Sie sich an Ihren Manager, um Unterstützung zu erhalten.",
	TryAgain_Button: "Versuchen Sie es erneut",
	Error_Title: "Etwas ist schiefgelaufen!",
	SettingsMyProfileActivationSuccessModal_Title: "Erfolg!",
	AccountInReviewModal_Description_Paragraph3: "In der Zwischenzeit können Sie gerne unsere Website mit eingeschränktem Zugriff nutzen.",
	AccountInReviewModal_Description_Paragraph4: "Wenn Sie Fragen haben oder Ihr Konto nicht innerhalb von 48 Stunden bearbeitet wird, außer an Wochenenden und Feiertagen, können Sie uns [here] kontaktieren.",
	Support_Headline: "Bosch Training Solutions",
	Support_SubHeadline: "Hier einige Informationen für Sie.",
	Support_FAQ_Headline: "Häufig gestellte Fragen",
	Support_FAQs_section1_title: "Allgemein",
	Support_FAQs_section1_question1: "Was ist Bosch Automotive Campus / Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Automotive Campus / Bosch Training Solutions ist eine Plattform, welche einen umfassenden Überblick über unser Trainingsangebot bietet und die Buchung und Verwaltung von Trainings ermöglicht.",
	Support_FAQs_section1_question2: "Kann ich mein bestehendes Bosch-Konto (Bosch ID / SingleKey ID) verwenden, um mich im Bosch Automotive Campus / Bosch Training Solutions anzumelden?",
	Support_FAQs_section1_question2_answer1: "Ja, der Zugriff ist auf alle Bosch-Account möglich, die mit \"Meine Bosch-ID\" \"SingleKey ID\" erstellt wurden. Bitte beachten Sie, dass die bei der Registrierung benutzte eMail-Adresse personenbezogen ist.",
	Support_FAQs_section1_question3: "Kann ich ein für Bosch Automotive Campus / Bosch Training Solutions erstelltes Konto (Bosch ID / SingleKey ID) auch bei anderen Bosch-Diensten oder -Anwendungen verwenden?",
	Support_FAQs_section1_question3_answer1: "Ja, Sie können Ihre persönliche Bosch ID / SingleKey-ID auch für eine Vielzahl weiterer Bosch-Anwendungen nutzen – sowohl beruflich als auch privat, wie zum Beispiel E-Bike oder Smart Home.",
	Support_FAQs_section2_title: "Training",
	Support_FAQs_section2_question1: "Wie kann ich ein Training buchen?",
	Support_FAQs_section2_question1_answer1: "Melden Sie sich mit Ihrem bestehenden Account an oder registrieren Sie sich im Bosch Automotive Campus neu. Gehen Sie über „Training Services“ zu \"Trainingskatalog\" oder „Trainingstermine“, wählen Sie ein Training und das gewünschte Datum aus und klicken Sie auf das Symbol „Einkaufswagen“. Als User: gehen Sie in Ihren Einkaufswagen und schliessen Sie den Kauf ab. Als Manager: gehen Sie in Ihren Einkaufswagen, fügen das entsprechende Teammitglied hinzu und schliessen Sie den Kauf ab. Hinweis: Teilnehmerplätze im Einkaufswagen sind nicht reserviert!",
	Support_FAQs_section2_question2: "Es ist kein passender Trainingstermin verfügbar. Was für Möglichkeiten habe ich?",
	Support_FAQs_section2_question2_answer1: "Sie haben die Möglichkeit, sich auf eine „allgemeine“ Warteliste (ohne Terminauswahl) einzutragen und unser Team informiert Sie, sobald ein neues Training verfügbar ist.",
	Support_FAQs_section2_question3: "Wo finde ich eine Übersicht meiner gebuchtenTrainings?",
	Support_FAQs_section2_question3_answer1: "Eine vollständige Übersicht aller gebuchten & abgeschlossenen Trainings finden Sie nach dem Login in 'Mein Bereich' 'Meine Übersicht'.",
	Support_FAQs_section3_title: "Einstellungen",
	Support_FAQs_section3_question1: "Wie kann ich meine E-Mail-Adresse und mein Passwort ändern?",
	Support_FAQs_section3_question1_answer1: "Loggen Sie sich im Automotive Campus ein und gehen Sie in Ihren persönlichen Bereich: 'Mein Bereich' -> 'MyBosch' oder indem Sie auf die folgende Schaltfläche rechts oben klicken",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Hier haben Sie die Möglichkeit, Ihre persönlichen Daten zu ändern. Beachten Sie bitte den Unterschied zwischen der eMail-Adresse als Kontakt und Ihrem SingleKey-Account.",
	Support_FAQs_section3_question2: "Was kann ich tun, wenn ich mein Passwort vergessen habe?",
	Support_FAQs_section3_question2_answer1: "Bitte klicken Sie auf  'Login' und geben Sie Ihre bei SingleKey ID registrierte E-Mail-Adresse ein.",
	Support_FAQs_section3_question2_answer2: "Wenn Sie auf 'Weiter' klicken, haben Sie die Möglichkeit, auf 'Passwort vergessen?' zu klicken.",
	Support_FAQs_section3_question3: "Wie kann man die Managerfunktion einrichten?",
	Support_FAQs_section3_question3_answer1: "Wenn Sie die Rolle 'Manager' erhalten möchten, wenden Sie sich bitte per Mail an das Automotive Campus Team automotive.campus@de.bosch.com .",
	LandingPage_Header_Title: "Bosch Training Solutions",
	LandingPage_ChooseCountry_Title: "Wählen Sie Ihr Land / Ihre Region",
	MaintenanceBanner_Message_1: "Das Bosch Training Solutions / Automotive Campus Portal wird am [maintenance_schedule] aktualisiert. Das Update wird voraussichtlich 30 Minuten dauern. Das Portal wird während des Updates nicht verfügbar sein.",
	MaintenanceBanner_Message_2: "\nFür weitere Unterstützung kontaktieren Sie uns bitte per E-Mail Automotive.Campus@de.bosch.com",
	MaintenancePage_Message_1: "Das Bosch Training Solutions / Automotive Campus Portal wird derzeit aktualisiert",
	MaintenancePage_Message_2: "Wir gehen davon aus, dass das Portal am [maintenance_online_date] um [maintenance_online_time] wieder verfügbar sein wird.",
	MenuHeader_Support: "Support",
	Course_Detail_No_Prerequisites: "siehe Trainingsbeschreibung",
	TableCell_Hours: "Stunden",
	Dashboard_MenuItem_Dashboard: "Dashboard",
	Dashboard_MenuItem_Curricula: "Ausbildungsübersicht",
	Dashboard_MenuItem_Certificates: "Zertifikate",
	Dashboard_MenuItem_Team: "Team",
	Dashboard_MenuItem_Workshops: "Werkstatt",
	Dashboard_MenuItem_External: "Extern",
	Dashboard_MenuItem_Wallet: "Credits",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Geplante Trainings",
	Dashboard_Headline_YourELearningCourses: "Online Trainings",
	Dashboard_Headline_TeamOverview: "Übersicht Team",
	Dashboard_Headline_YourOverview: "Meine Übersicht",
	Dashboard_Headline_YourWishlist: "Meine Warteliste",
	Dashboard_Headline_ActivePrograms: "aktive Ausbildungsreihen",
	Dashboard_Headline_CompletedPrograms: "abgeschlossene Ausbildungsreihen",
	Dashboard_Headline_YourCertificates: "Meine Zertifikate",
	Dashboard_Headline_Transactions: "Transaktionen",
	Dashboard_Table_NoRecordsToDisplay: "Kein Eintrag vorhanden",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "Stunden",
	Dashboard_ELearningCourses_TableHeader_Title: "Titel",
	Dashboard_ELearningCourses_TableHeader_Duration: "Dauer (Std.)",
	Dashboard_ELearningCourses_ActionButton_View: "anzeigen",
	Dashboard_UpcomingCourses_TableHeader_Title: "Titel",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Datum / Dauer",
	Dashboard_UpcomingCourses_TableHeader_Location: "Ort",
	Dashboard_UpcomingCourses_ActionButton_View: "anzeigen",
	Dashboard_YourOverview_TableHeader_Title: "Titel",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Datum / Dauer",
	Dashboard_YourOverview_TableHeader_Location: "Ort",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "anzeigen",
	Dashboard_YourWishlist_TableHeader_Title: "Titel",
	Dashboard_YourWishlist_TableHeader_Action: "Aktion",
	Dashboard_YourWishlist_ViewAllRecords: "alles anzeigen",
	Dashboard_TeamOverview_TableHeader_Employee: "Team",
	Dashboard_TeamOverview_TableHeader_Title: "Titel",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Datum / Dauer",
	Dashboard_TeamOverview_TableHeader_Location: "Ort",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "anzeigen",
	Dashboard_TeamOverview_SearchBox_Placeholder: "suchen",
	Dashboard_ActiveProgram_TableHeader_Title: "Titel",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "anzeigen",
	Dashboard_CompletedProgram_TableHeader_Title: "Titel",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "anzeigen",
	Dashboard_Certificates_TableHeader_Title: "Titel",
	Dashboard_Certificates_TableHeader_Date: "Datum",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Aktion",
	Dashboard_Certificates_ActionButton_Download: "Download",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Rechnungsnummer",
	Dashboard_Transactions_TableHeader_Description: "Beschreibung",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Zahlungsmethode",
	Dashboard_Transactions_TableHeader_Date: "Datum",
	Dashboard_Transactions_TableHeader_Amount: "Betrag",
	Dashboard_Transactions_ActionButton_Download: "Download",
	Breadcrumb_MyTraining: "Meine Trainings",
	Breadcrumb_MyTraining_CourseDetails: "Details",
	Breadcrumb_MyTraining_Curricula: "Ausbildungen",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Details",
	Breadcrumb_MyTraining_Team: "Team",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Team anzeigen",
	MyTraining_Headerbar_CourseDetails: "Details",
	MyTraining_Headerbar_TrainingProgramDetails: "Details",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Gesamtfortschritt",
	TrainingProgramDetails_ProgramItems_Headline: "ausbildungsrelevante Trainings",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Titel",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Note",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Datum",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "anzeigen",
	TrainingProgramDetails_Status_InProgress: "begonnen",
	TrainingProgramDetails_Status_Completed: "abgeschlossen",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Dauer",
	CourseDetails_Label_Trainer: "Trainer",
	CourseDetails_Label_Language: "Sprache",
	CourseDetails_Label_Score: "Ergebnis:",
	CourseDetails_Label_StartDate: "Beginn",
	CourseDetails_Label_EndDate: "Ende",
	CourseDetails_Label_Venue: "Veranstaltungsort",
	CourseDetails_Label_Address: "Anschrift",
	CourseDetails_Label_Grade: "Note",
	CourseDetails_Action_Withdraw: "zurücktreten",
	CourseDetails_Action_Evaluate: "Training bewerten",
	CourseDetails_Action_Evaluate_Description: "Bitte bewerten Sie dieses Training, um Ihr Zertifikat zu erstellen.",
	CourseDetails_Action_DownloadCertificate: "Download Zertifikat",
	CourseDetails_Action_JoinWebcast: "Link",
	CourseDetails_Action_StartCourse: "starten",
	CourseDetails_Action_RestartCourse: "erneut starten",
	CourseDetails_Action_RepeatCourse_Description: "Um Ihr Zertifikat herunterzuladen, muss das Training wiederholt und bestanden werden.",
	CourseDetails_PartSpecificDetails_Headline: "Detailabschnitt",
	CourseDetails_PartSpecificDetails_Tab_Part: "Abschnitt",
	CourseDetails_PartSpecificDetails_Description: "Beschreibung",
	CourseDetails_PartSpecificDetails_StartDate: "Beginn",
	CourseDetails_PartSpecificDetails_EndDate: "Ende",
	CourseDetails_PartSpecificDetails_StartTime: "Beginn um",
	CourseDetails_PartSpecificDetails_EndTime: "Ende ca.",
	CourseDetails_PartSpecificDetails_Method: "Methode",
	Dashboard_Team_TableHeader_Name: "Name",
	Dashboard_Team_TableHeader_Role: "Rolle",
	Dashboard_Team_TableHeader_Email: "E-Mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Persönliches Konto",
	Dashboard_Team_ActionButton_Select: "anzeigen",
	Dashboard_Team_PersonalAccount_Activated: "Account aktiviert",
	Dashboard_Team_PersonalAccount_NotActivated: "Account nicht aktiviert",
	Dashboard_Team_ActionButton_AddMember: "Teammitglied hinzufügen",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "suchen",
	Dashboard_External_TableHeader_Name: "Name",
	Dashboard_External_ActionButton_AddNew: "hinzufügen",
	Dashboard_External_Headline: "Zugriff genehmigt",
	Dashboard_Workshops_Headline: "Werkstatt",
	Dashboard_Workshops_TableHeader_Company: "Firma",
	Dashboard_Workshops_TableHeader_Address: "Anschrift",
	Dashboard_Workshops_TableHeader_Postcode: "Postleitzahl",
	Dashboard_Workshops_TableHeader_City: "Stadt",
	Dashboard_Workshops_ActionButton_Select: "wählen",
	Wallet_ServiceCredits: "Credits",
	Wallet_Headline_CreditBalance: "Kontostand",
	Wallet_Headline_CreditActivity: "Kontoaktivität",
	Wallet_Headline_BillingTransactions: "Abrechnungstransaktion",
	Wallet_BillingTransaction_TableHeader_Order: "Bestellung",
	Wallet_BillingTransaction_TableHeader_Date: "Datum",
	Wallet_BillingTransaction_TableHeader_Amount: "Betrag",
	Wallet_BillingTransaction_TableHeader_Action: "Aktion",
	Wallet_CreditActivity_TableHeader_Date: "Datum",
	Wallet_CreditActivity_TableHeader_Description: "Beschreibung",
	Wallet_CreditActivity_TableHeader_User: "User",
	Wallet_CreditActivity_TableHeader_Amount: "Betrag",
	FooterAdditionalLinks_Copyright: "© Robert Bosch GmbH, alle Rechte vorbehalten",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Produktsicherheit (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovationen, Patente und Lizenzen",
	FooterAdditionalLinks_Navigation_Logistics: "Einkauf und Logistik",
	MenuHeader_MyTraining: "Mein Bereich",
	MenuHeader_TrainingServices: "Training Services",
	MenuHeader_OurLocations: "Standorte",
	MenuHeader_ContactsUs: "Kontaktieren Sie uns",
	MenuHeader_Logout: "Logout",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "Meine Bosch ID / SingleKey ID",
	MyProfile_AccountDeletion: "Account löschen",
	MyProfile_Edit: "Benutzerdaten SingleKey ID bearbeiten",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Benutzerdaten Automotive Campus bearbeiten",
	BackButton: "zurück",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Wir freuen uns auf Ihre Anfrage.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Geben Sie den Code ein.",
	ContactUs_Email_MandatoryNotice_V3: "Füllen Sie alle erforderlichen Felder aus.",
	ContactUs_Email_SendButton: "senden",
	Breadcrumb_TrainingCenters: "Trainingsstandorte",
	Training_Centres_Book_Btn: "Training buchen",
	Training_centres_booking_code: "Buchungsnummer",
	Training_centers_header_bar_title: "Unsere Trainingsstandorte",
	Training_centers_filter_item: "Center",
	TeamEdit_PersonalAccountToolTop: "Durch die Aktivierung dieser Funktion kann das Teammitglied dann ein persönliches Konto erstellen und Teil des Firmenaccounts werden. Nach der Aktivierung können Sie dem Teammitglied eine Einladung an die von Ihnen ausgewählte eMail Adresse mit einem Aktivierungscode senden.",
	TeamEdit_Update: "Durch die Aktivierung dieser Funktion erhält dieses Teammitglied vollen Zugriff auf den Firmenaccount. Damit  diese Funktion aktiv wird, muss sich dieses Teammitglied,  wenn es bereits eingeloggt ist, abmelden und nochmals neu einloggen.",
	TeamEdit_PersonalAccount: "Persönlichen Zugriff erlauben",
	TeamEdit_SendInvitation: "Einladung senden",
	TeamEdit_Deactivate: "deaktivieren",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "nur Zahlen",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "nur Zahlen",
	TeamEdit_Activate: "aktiv",
	TeamEdit_NotActivated: "nicht aktiv",
	TeamEdit_StatusPopup: "Der Status wird als 'aktiv' angezeigt, wenn der Benutzer erfolgreich ein persönliches Konto erstellt hat. Wenn der Status auf 'nicht aktiv' gesetzt ist, bedeutet dies, dass der Benutzer Ihre Einladung nicht abgeschlossen hat.",
	Training_Catalog_Courses: "Trainings",
	Training_Catalog_Course: "Training",
	Reset_Filter: "Auswahl / Filter zurücksetzen",
	TrainingCatalogue_CourseCatalogue: "Trainingskatalog",
	CheckoutHeader_CartItemCount_Text: "Sie haben [count] Artikel in Ihrem Einkaufswagen",
	CheckoutHeader_CartItemsCount_Text: "Sie haben [count] Artikel in Ihrem Einkaufswagen",
	ShoppingCart_PriceItem: "Preis",
	ShoppingCartItemParticipants_AddParticipant_Button: "Teilnehmer:in hinzufügen",
	ShoppingCartTotalPrice_SubTotalLabel: "Zwischensumme",
	ShoppingCartTotalPrice_TaxesLabel: "Mehrwertsteuer",
	ShoppingCartTotalPrice_TotalLabel: "Gesamtsumme",
	ShoppingCartTotalPrice_IncludedVATLabel: "inklusive Mehrwertsteuer von [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "verfügbare Zahlungsmethoden",
	ShoppingCartItemRow_VenueFeeLabel: "sonstige Gebühren [fee_amount] pro teilnehmende Person",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Teilnehmer:in hinzufügen",
	AddParticipantsModal_SelectEmployeeLabel: "Teilnehmer:in hinzufügen",
	AddParticipantsModal_SelectWorkshopLabel: "Firma auswählen",
	ShoppingCartTotalPrice_ContinueButtonText: "weiter zur Kasse",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Teilnehmer:in wurde nicht hinzugefügt.",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Sie haben ein oder mehrere Trainings in Ihrem Einkaufwagen ohne zugeordnete Person.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Bitte fügen Sie eine Person hinzu, um den Einkauf fortzusetzen.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Teilnehmer:in bearbeiten ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Name",
	AddParticipantsModal_ParticipantTable_Email: "E-Mail",
	AddParticipantsModal_ParticipantTable_Action: "Aktion",
	AddParticipantsModal_ParticipantTable_NoParticipant: "keine Teilnehmer",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "E-Mail Adresse für die ausgewählte Person ist nicht konfiguriert",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Die ausgewählte Person hat keine eMail-Adresse in seinem Profil eingetragen. Diese ist notwendig, um das Training zu buchen.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Fügen Sie eine eMail-Adresse hinzu oder brechen Sie den Einkauf ab, um eine andere Person auszuwählen.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "eMail-Adresse hinzufügen",
	CancelButton: "abbrechen",
	AddParticipantsModal_AddEmployeeButtonText: "hinzufügen",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Sie sind dabei, einen Artikel aus Ihrem Einkaufswagen zu entfernen.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Sie versuchen, ein Training mit Teilnehmern aus Ihrem Einkaufswagen zu entfernen.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Klicken Sie auf „Fortfahren“, wenn Sie fortfahren möchten oder klicken Sie auf „abbrechen“.",
	ShoppingCartItemHeader_Participants: "Teilnehmer",
	ShoppingCartItemHeader_ParticipantPrice: "Preis pro teilnehmende Person",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "in [currency] anzeigen",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "in Credits anzeigen",
	ShoppingCart_CreditsPriceUnit: "Credits",
	CheckOutHeader_Step1_Question: "Zahlungsmethode",
	CheckOutHeader_Step2_Question: "Überprüfen Sie Ihre Bestellung",
	CheckOutHeader_Step3_Question: "Schliessen Sie Ihre Bestellung ab",
	CheckOutHeader_Step4_Question: "Bestellung erfolgreich abgeschlossen.",
	CheckOutHeader_Step_Payment: "Zahlungsmethode",
	CheckOutHeader_Step_Review: "Bestellung prüfen",
	CheckOutHeader_Step_Place_Order: "Bestellung aufgeben",
	CheckOutRightPanel_Review_Order_Btn: "Überprüfen Sie die Bestellung",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Bestellung aufgeben",
	CheckoutRightPanelContent_OrderSummary_Title: "Bestellübersicht",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Einkaufswagen bearbeiten",
	ShoppingCart_Participants: "Teilnehmer",
	CheckOutStepPayment_Payment_Methods_Title: "Zahlungsmethode wählen",
	CheckOutStepPayment_Credit_Title: "Kreditkarte",
	CheckOutStepPayment_Wholesaler_Title: "Großhändler",
	CheckOutStepPayment_ServiceCredit_Title: "Credits",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Sie haben [available_credit] Credits",
	CheckOutStepPayment_BoschAccount_Title: "Rechnung",
	Checkout_Second_PaymentMethod_Headline: "Zahlungsmethode",
	CheckOutStepReview_Edit: "bearbeiten",
	CheckOutStepReview_Terms_Label: "Allgemeine Geschäftsbedingungen und Widerrufsbelehrung",
	CheckOutStepReview_Terms_Text: "Ich habe die AGBs und Widerrufsbelehrung unter [terms, conditions and cancellation policy] gelesen, verstanden und stimme ihnen zu.",
	SelectSecondPaymentMethodModal_Title: "Wählen Sie eine zusätzliche Zahlungsmethode aus.",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Zuzahlung [amount]",
	StepPaymentWholesaler_Title: "Größhändler auswählen",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Nutzungsbedingungen akzeptieren",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Bitte lesen und akzeptieren Sie unsere Allgemeinen Geschäfts- und Stornierungsbedingungen, bevor Sie fortfahren.",
	CheckoutDescription_ThankYou: "Vielen Dank für Ihre Bestellung.",
	CheckoutDescription_Message: "Ihre Bestellung wird bearbeitet - bitte warten.",
	CheckoutDesctiption_Text: "Sie erhalten per E-Mail eine endgültige Bestätigung mit allen Einzelheiten Ihrer Buchung.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Sie sind dabei, die ausgewählte Werkstatt zu entfernen",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Durch das Entfernen der ausgewählten Werkstatt werden alle hinzugefügten Teammitglieder zurückgesetzt. Diese Änderung wirkt sich auf alle Trainings in Ihrem Warenkorb aus.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klicken Sie auf „weiter“, wenn Sie fortfahren möchten, oder auf „abbrechen“, um diese Aktion abzubrechen.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Ihr aktuelles Guthaben reicht nicht aus, es beträgt [available_credit] Credits. Sie benötigen [cart_total_credits] Credits, um mit der Zahlung fortzufahren. Bitte wählen Sie eine andere Zahlungsmethode oder entfernen Sie Artikel aus Ihrem Einkaufswagen.",
	Training_centers_filter_items: "Standorte",
	Training_Programs_Filter_Item: "Programme",
	Training_Program_Filter_Item: "Programm",
	Training_Program_Year: "Jahr(e)",
	Training_Program_Detail_Title: "Trainingsprogramm",
	AccountButton_Workshops_MenuItem: "Werkstatt",
	AccountButton_Wallet_MenuItem: "Credits",
	Header_MyBoschProfile_Header: "Mein Bosch Profil",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Verfügbar",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Teilnehmer:in hinzufügen/bearbeiten",
	ShoppingCart_CreditPriceUnit: "Credit",
	Common_IncludedVAT: "inkl. MwSt.",
	TrainingCatalogue_Length: "Dauer",
	TrainingCatalogue_Method: "Methode",
	Course_Price_Credits: "Credits",
	Catalogue_Grid_Register_Button: "auswählen",
	Catalogue_Credit_Tilte: "Klicken Sie hier, um die Preise in Credits anzuzeigen",
	Catalogue_Price_Tilte: "Klicken Sie hier, um die Preise in EUR anzuzeigen",
	Catalogue_Grid_Vat: "zzgl. MwSt.",
	EventCalendar_Seats: "freie Plätze",
	MenuHeader_Calendar: "Kalender",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Minuten",
	ListFilter_NoResult: "Kein Ergebnis",
	Course_Description: "Trainingsbeschreibung",
	Training_course_detail: "Social-Media-Grundlagen",
	Training_course_detail_add_waiting: "Zur allgemeinen Warteliste hinzufügen",
	Register_now_btn: "Jetzt buchen",
	Register_now_btn_add_event_to_cart: "Jetzt buchen",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Alle Datensätze anzeigen",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Weniger anzeigen",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Alle Datensätze anzeigen",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Weniger anzeigen",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Alle Datensätze anzeigen",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Weniger anzeigen",
	TeamCreate_TitleError: "Titel ist erforderlich",
	Dashboard_Headerbar_BookCourse: "Training buchen",
	Dashboard_Headerbar_ExitProxy: "Zurück zur Übersicht",
	Dashboard_Headerbar_EvaluateCourse: "Training bewerten",
	Dashboard_Headerbar_EvaluateCourse_Note: "Bitte bewerten Sie dieses Training, um Ihr Zertifikat zu erstellen.",
	Dashboard_Headerbar_DownloadCertificate: "Zertifikat herunterladen",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "stornieren",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "stornieren",
	Dashboard_Headerbar_WelcomeMessage: "Willkommen auf Ihrem Automotive Campus Dashboard",
	Dashboard_Headerbar_ProxingAs: "Übersicht",
	Dashboard_TrainingProgram_Status_Completed: "abgeschlossen",
	Dashboard_TrainingProgram_Status_InProgress: "in Bearbeitung",
	External_SelectWholesalerModal_Title: "Externen Benutzerzugriff hinzufügen",
	External_SelectWholesalerModal_Company_Label: "Firma",
	External_SelectWholesalerModal_Outlet_Label: "Filiale",
	External_SelectWholesalerModal_Company_Placeholder: "Bitte auswählen",
	External_SelectWholesalerModal_Outlet_Placeholder: "Bitte auswählen",
	External_SelectWholesalerModal_ApplyButton: "anwenden",
	External_SelectWholesalerModal_CancelButton: "abbrechen",
	External_RemoveWholesalerModal_ConfirmLabel: "Bestätigung",
	External_SelectWholesalerModal_ConfirmMessage: "Möchten Sie diesen Großhändler wirklich entfernen?",
	External_SelectWholesalerModal_YesButton: "Ja",
	External_SelectWholesalerModal_NoButton: "Nein",
	Training_program_team_member: "Teammitglied",
	Training_program_assign_training_program: "Ausbildungsreihe zuweisen",
	Assign_modal_student_confirmation_text1: "Zuweisung für [Training Program Name]",
	Assign_modal_student_confirmation_text2: "Möchten Sie fortfahren?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mrz",
	MyFullCalendar_Apr: "Apr",
	MyFullCalendar_May: "Mai",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Juli",
	MyFullCalendar_Aug: "Aug",
	MyFullCalendar_Sep: "Sept",
	MyFullCalendar_Oct: "Okt",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dez",
	MenuHeader_CourseCatalog: "Trainingskatalog",
	MenuHeader_Event: "Trainingstermine",
	MenuHeader_Program: "Trainingsprogramm",
	MenuHeader_Services: "Trainingsübersicht",
	MenuHeader_Log_out: "ausloggen",
	Filter_Location: "Standort",
	TeamEdit_UpgradeToCompanyAdministrator: "Managerrechte vergeben",
	TeamEdit_PersonalWebAccountStatusIs: "Status des persönlichen Kontos",
	Header_CreateNewEmployee: "Neues Teammitglied anlegen",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Keine Plätze verfügbar!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Für das ausgewählte Training sind keine Plätze verfügbar.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Bitte wählen Sie ein anderes Training aus oder entfernen Sie einen bereits hinzugefügten Teilnehmer, um fortzufahren.",
	Common_SuccessModal_Title: "Herzlichen Glückwunsch!",
	TrainingProgramDetails_Merit_StatusText: "vorausgesetzt",
	TrainingProgramDetails_Resit_StatusText: "wiederholen",
	EmployeeProfile_UserExistErrorMessage: "Das Konto existiert bereits.",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Bezahlung fehlgeschlagen. Bitte versuchen Sie es erneut oder verwenden Sie eine andere Zahlungsmethode.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Sie haben das ausgewählte Teammitglied erfolgreich deaktiviert!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Gespeichert",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Ihre Änderungen wurden gespeichert.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Bei eLearnings ist ein Rücktritt nicht möglich.",
	Dashboard_Team_PersonalAccount_Pending: "Aktivierung Account ausstehend",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Teammitglied erstellt.",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Die Erstellung eines neuen Teammitglieds war erfolgreich.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Mit dieser Einstellung können Sie die Buchungsrechte des Teammitglieds einschränken / erlauben. Wenn diese Einstellung abgewählt wurde, kann nur ein Mitarbeiter mit Managerrechten Teammitglieder zu Trainings anmelden.",
	Training_catalog_sort_by: "Sortiere nach",
	CourseDetail_EvaluationRestriction_Title: "Trainingsauswertung nicht möglich.",
	CourseDetail_EvaluationRestriction_Message: "Bewertungen können nur vom Teinehmer selbst abgegeben werden. \nBitte weisen Sie den Mitarbeiter an, sich beim Bosch Automotive Campus anzumelden und die Trainingsbewertung abzuschließen.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Wenn der Mitarbeiter noch kein Konto hat, kann eine Einladung in den Benutzerdateneinstellungen des Teammitglieds geteilt werden.",
	Breadcrumb_Download_Page: "Download",
	Download_Table_Category_Cell: "Kategorie",
	Download_Table_Title_Cell: "Name",
	Download_File_Filter_Item: "Datei",
	Download_File_Filter_Items: "Dateien",
	Download_Header: "Downloadbereich",
	DownloadArea_Button_Download: "Download",
	Popup_Title_Error: "Fehler",
	Popup_Title_Success: "Erfolg",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Achtung",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Bereits bestehende Anmeldungen werden nicht automatisch storniert und müssen (falls gewünscht) selbst storniert werden.",
	TrainingProgram_PDF_Export: "PDF Export",
	TrainingProgram_PDF_Export_Participant: "Teilnehmer",
	TrainingProgram_PDF_Export_Status: "Status",
	TrainingProgram_PDF_Export_Progress: "Fortschritt",
	TrainingProgram_PDF_Export_TableHeader_Title: "Trainings",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Abschluss",
	TrainingProgram_PDF_Export_TableHeader_Date: "Datum",
	AccountButton_CompanyChange_MenuItem: "Firmenwechsel",
	SelectCompanyModal_Title: "Firma auswählen",
	CloseButton: "schliessen",
	Change_Button: "wechseln",
	ChangeManagerModal_Content: "User auswählen",
	ChangeCompanyModal_HeadOffice: "Zentrale",
	SelectCompanyModal_Content: "Bitte wählen Sie eine Firma aus, um sich einzuloggen",
	AlertSuccess_Title: "Erfolg",
	CheckOutStepPayment_SecondaryPayment_Title: "Zweite Zahlungsart auswählen",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Zweite Zahlungsart hinzufügen",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Bitte fügen Sie eine zweite Zahlungsart hinzu oder wählen Sie eine andere Zahlungsart aus",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Es stehen nicht genügend Credits zur Verfügung, um Ihre Artikel im Warenkorb zu bezahlen. Um die zur Verfügung stehenden Credits zu verwenden, haben Sie die Möglichkeit, die Differenz mit einer anderen Zahlungsart zu begleichen. Die Differenz beträgt [difference_amount] (zzgl. MwSt.).",
	ShoppingCartTotalPrice_TotalInCredit: "Gesamtkosten in Credits",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Ihre verfügbaren Credits",
	ShoppingCartTotalPrice_YourDifference: "Differenz",
	ShoppingCartTotalPrice_SubtotalExTax: "Zwischensumme zzgl. MwSt.",
	ShoppingCartTotalPrice_SubtotalInTax: "Zwischensumme inkl. MwSt.",
	CheckOutStepPayment_Second_Payment_Methods_Title: "zweite Zahlungsart",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Zahlungsart ändern",
	Checkout_Second_PaymentMethod: "zweite Zahlungsart",
	Breadcrumb_TrainingCourseDetails: "Trainingsdetails",
	DownloadArea_FailedToDownload_ErrorMessage: "Download fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Die ausgewählte Datei ist nicht verfügbar. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Die ausgewählte Datei ist nicht verfügbar, Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns.",
	SSO_Redirecting_Message: "Sie werden zum Automotive Campus weitergeleitet...",
	SSO_Redirecting_ParameterErrorMessage: "Land und/oder Sprache sind nicht angegeben. Bitte versuchen Sie es erneut oder wenden Sie sich an den Administrator Ihres Unternehmens.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Anmeldeanfrage(n) nicht akzeptiert.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Dies kann daran liegen, dass die Anmeldefrist für einen oder mehrere Kurse noch nicht erreicht ist. Bitte nutzen Sie den Bereich „Kontakt“, wenn Sie Fragen oder Wünsche haben.",
	TimeSlotPicker_DateFormat_Title: "Datumsformat",
	UpdateAccountSettingsConfirmationModal_Title: "Sie sind dabei, das Datumsformat zu ändern!",
	UpdateAccountSettingsConfirmationModal_Message1: "Das Datumsformat wird entsprechend Ihrer Auswahl angezeigt und kann jederzeit geändert werden.",
	UpdateAccountSettingsConfirmationModal_Message2: "Möchten Sie fortfahren?",
	ProfileSummary_Settings_Title: "Einstellungen",
	UpdateAccountSettings_DateFormat_Title: "Einstellungen für das Datumsformat",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Das Datumsformat konnte nicht aktualisiert werden.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Die Einstellung des Datumsformats wurde erfolgreich aktualisiert.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Download Zertifikat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Zertifikat",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Das Dokument konnte nicht heruntergeladen werden. Bitte versuchen Sie es erneut oder kontaktieren Sie uns.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Stornierungsfrist abgelaufen",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Die Frist für die Stornierung dieses Kurses ist abgelaufen und benötigt eine Bestätigung unseres Trainingteams.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "Nach der Bearbeitung wird Ihnen die finale Bestätigung per eMail zugeschickt.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Lesen Sie hierzu auch unserer aktuellen [cancellation policy].",
	TrainingCourse_Course_NotAvailable_Title: "Der Kurs ist nicht verfügbar.",
	TrainingCourse_Course_NotAvailable_Message: "Der Kurs ist nicht verfügbar. Bitte versuchen Sie es erneut oder wenden Sie sich an den Administrator Ihres Unternehmens.",
	TrainingCourse_NotAvailable_Message_1: "Sie greifen auf einen Kurs zu, der nicht verfügbar ist.",
	TrainingCourse_NotAvailable_Message_2: "Bitte versuchen Sie es erneut oder greifen Sie auf [training courses] zu, um weitere Trainings zu entdecken.",
	LanguageSelection_SelectALanguage: "Sprache auswählen",
	LanguageSelection_SelectYourCountry: "Land auswählen",
	Checkout_PaymentMethod_Headline: "Zahlungsmethode",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalender",
	Download_Categories_General: "Generell",
	Download_Categories_Information: "Information",
	Download_Categories_Userguides: "Benutzerinfo",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Widerrufsbelehrung",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "stornieren",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Fortfahren?",
	Duration_Seconds: "Sekunden",
	Duration_Day: "Tag",
	Duration_Hour: "Stunde (n)",
	Duration_Minute: "Minute (n)",
	Duration_Second: "Sekunde",
	EventCalendar_ExternalLink_Tooltips: "Kauf über",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Abbrechen",
	EventCalendar_ExternalLink_Continue: "Fortfahren",
	EventCalendar_ExternalLinkModal_Title: "Sie werden weitergeleitet…",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Das ausgewählte Training wird angeboten von",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Wenn Sie fortfahren, werden Sie auf eine externe Seite weitergeleitet.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Möchten Sie fortfahren?",
	Team_ContactPerson_FirstName_Tooltips: "Der Name eines Teammitgliedes kann nur durch das Automotive Campus Team geändert werden. Bitte kontaktieren Sie uns über das Kontaktformular oder per eMail an automotive.campus@de.bosch.com .",
	Team_ContactPerson_LastName_Tooltips: "Der Name eines Teammitgliedes kann nur durch das Automotive Campus Team geändert werden. Bitte kontaktieren Sie uns über das Kontaktformular oder per eMail an automotive.campus@de.bosch.com .",
	Withdraw_ExternalLink_Cancel: "Abbrechen",
	Withdraw_ExternalLink_Confirm: "Bestätigen",
	Withdraw_ExternalLinkModal_Title: "Sie werden weitergeleitet.",
	Withdraw_ExternalLinkModal_Message: "Die Rechnungsstellung erfolgt durch einen externen Trainingsanbieter.",
	Withdraw_ExternalLinkModal_Confirmation: "Mit \"Bestätigen\" wird ein neuer Tab geöffnet und Sie werden an eine externe Internetseite weitergeleitet.",
	Button_AddToWaitingList_Tooltips: "Zur Warteliste hinzufügen",
	Button_AddToCart_Tooltips: "Zum Warenkorb hinzufügen",
	Button_TrainingCourseDetail_Tooltips: "Details anzeigen",
	Button_TrainingProgramDetail_Tooltips: "Details anzeigen",
	Button_AssignTrainingProgram_Tooltips: "Trainingsprogramm zuweisen",
	Button_DeleteFromCart_Tooltips: "Aus Warenkorb löschen",
	Button_RemoveParticipant_Tooltips: "Teilnehmer entfernen",
	Button_DownloadBillingTransaction_Tooltips: "Herunterladen",
	Button_RemoveExternal_Tooltips: "Entfernen",
	Breadcrumb_TrainingServices: "Training Services",
	Breadcrumb_Support: "Support",
	Breadcrumb_FAQs: "FAQs",
	Breadcrumb_TrainingProgramDetail: "Training Programm Details",
	Breadcrumb_MyTraining_MyTrainingEvents: "Meine Trainings",
	Breadcrumb_MyTraining_External: "Extern",
	Breadcrumb_MyTraining_Wallet: "Credits",
	Breadcrumb_MyTraining_Workshop: "Werkstatt",
	Breadcrumb_MyTraining_Workshop_Team: "Team",
	Breadcrumb_MyTraining_Team_Profile: "Profil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Profil bearbeiten",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Mitarbeiter anlegen",
	SideNavigation_Title: "Training Solutions",
	SideNavigation_AccountButton_RegisterOrLogin: "Registrieren / Login",
	SideNavigation_Language: "Sprache",
	SideNavigation_SelectLanguage: "Sprache auswählen",
	SideNavigation_AccountButton_Logout: "Logout",
	SideNavigation_ConntactUs: "Kontaktieren Sie uns",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Impressum",
	FooterAdditionalLinks_Navigation_LegalNotice: "Rechtlicher Hinweis",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Datenschutzhinweise",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Datenschutzeinstellungen",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Allgemeine Geschäftsbedinungen",
	Common_Version_Text: "Version:",
	Content_Header_Dashboard_MyTrainingEvents: "Meine Trainings Veranstaltungen",
	Content_Header_Dashboard_MyTrainingPrograms: "Mein Trainings Programm",
	Content_Header_Dashboard_Team: "Team",
	Content_Header_Dashboard_Workshops: "Werkstatt",
	Content_Header_Dashboard_External: "Extern",
	Content_Header_Dashboard_Wallet: "Credits",
	Content_Header_Dashboard_MyProfile: "Mein Profil",
	RedeemVoucher_RemoveButton: "Entfernen",
	RedeemVoucher_ApplyButton: "Anwenden",
	Checkout_VoucherApplied_Label: "Ihr Code",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Ungültiger Gutscheincode",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Angewendeter Gutscheincode ist ungültig.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Bitte erneut versuchen oder ohne Gutscheincode fortfahren.",
	RedeemVoucherModal_CancelButton: "Absagen",
	RedeemVoucherModal_TryAgainButton: "Erneut versuchen",
	CreditsConfirmationModal_ContinueButton: "Weiter",
	CreditsConfirmationModal_CancelButton: "Abbrechen",
	CreditsConfirmationModal_Title: "Gutschein wurde eingelöst",
	CreditsConfirmationModal_Message_1: "Zahlung mit Service Credits ist nicht möglich, wenn ein Gutschein auf Ihren Warenkorb angewendet wurde.",
	CreditsConfirmationModal_Message_2: "Klicken Sie auf  'Abbrechen' um zurück zu gehen oder klicken Sie auf  'Weiter', um den Gutschein aus Ihrem Warenkorb zu entfernen.",
	TrainingCourseCatalog_PricePerSeat: "Preis pro Teilnehmer",
	TrainingCourseCatalog_Duration: "Dauer:",
	Homepage_Title_BoschTrainingSolutions: "Automotive Campus",
	ConsentMarketing_Title: "Marketing und Neuigkeiten",
	ConsentMarketing_ConfirmButton: "Bestätigen",
	ConsentMarketing_CancelButton: "Abbrechen",
	ConsentMarketing_Subscribe: "Abonnieren",
	ConsentMarketing_Unsubscribe: "Abmelden",
	ConsentMarketing_SubscribeSuccessfully: "Sie sind jetzt angemeldet.",
	ConsentMarketing_UnsubscribeSuccessfully: "Sie sind jetzt abgemeldet.",
	TrainingCourseCatalog_Courses: "Kurse:",
	TrainingCourseCatalog_Courses_Info: "Kurse",
	Course_Elearning_Series_Detail_Title: "eLearning Paket",
	Course_Elearning_Series_Detail_Label_Date: "Datum",
	Course_Elearning_Series_Course_List: "Artikel"
};