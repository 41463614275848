import RDCommonErrorModal from "../../../components/RDComponents/RDCommonErrorModal/RDCommonErrorModal";
import RDDialog from "../../../components/RDComponents/RDDialog/RDDialog";
import CustomLoader from "../../../Common-components/loading/CustomLoader";
import { DATA_PROTECTION_NOTICE_ROUTE } from "../../../constants/routes/defaultRoutes.constants";
import ResponseResultBase from "../../../models/ResponseResultBase";
import {
  selectCiamId,
  selectOriginComp,
  setAcceptConsentMarketing,
  updateMarketingAccepted,
} from "../../../redux-toolkit/accountReducer";
import { AppDispatch, useAppDispatch } from "../../../redux-toolkit/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import style from "./ConsentMarketingModal.module.scss";
import { selectLanguage } from "../../../redux-toolkit/authReducer";

type Props = {
  onClose: () => void;
};

const ConsentMarketingModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const language = useSelector(selectLanguage);
  const [isShowErrorModal, setShowErrorModal] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);
  const currentAccount = useSelector(selectOriginComp);
  const customerId = currentAccount?.customerId;

  const getMessages = (): string => {
    const baseOpenTag = `<a target="_blank" href="{link}" class="${style.link}">`;
    const baseEndTag = "</a>";

    const openTag1 = baseOpenTag.replace(
      "{link}",
      `${window.location.origin}${DATA_PROTECTION_NOTICE_ROUTE.path.replace(
        ":c?",
        language
      )}`
    );
    const openTag2 = baseOpenTag.replace(
      "{link}",
      "https://request.privacy-bosch.com/entity/AA/lang/en-GB/"
    );

    return t("ConsentMarketingModal_Message")
      .replaceAll("{endTag}", baseEndTag)
      .replace("{openTag1}", openTag1)
      .replace("{openTag2}", openTag2);
  };

  const onAcceptConsentMarketing = async () => {
    try {
      setShowLoader(true);

      const response = await dispatch(
        updateMarketingAccepted({
          ciamId,
          customerId,
          accepted: true,
        })
      );

      if (!response || !response.payload) {
        setShowErrorModal(true);
        return;
      }

      const payload = response.payload as ResponseResultBase<any>;

      if (!payload.success) {
        setShowErrorModal(true);
        return;
      }

      dispatch(setAcceptConsentMarketing(true));
      onClose();
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setShowLoader(false);
    }
  };

  const onCancelConsentMarketing = async () => {
    try {
      setShowLoader(true);

      const response = await dispatch(
        updateMarketingAccepted({
          ciamId,
          customerId,
          accepted: false,
        })
      );

      if (!response || !response.payload) {
        setShowErrorModal(true);
        return;
      }

      const payload = response.payload as ResponseResultBase<any>;

      if (!payload.success) {
        setShowErrorModal(true);
        return;
      }

      dispatch(setAcceptConsentMarketing(false));
      onClose();
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <RDDialog
        variant="info"
        confirmLabel={t("ConsentMarketing_ConfirmButton")}
        cancelLabel={t("ConsentMarketing_CancelButton")}
        onConfirm={onAcceptConsentMarketing}
        onCancel={onCancelConsentMarketing}
      >
        <p // dangerouslySetInnerHTML={{
        //   __html: getMessages(),
        // }}
        >
          {t("ConsentMarketingModal_Message")}
        </p>
      </RDDialog>
      {isShowErrorModal ? (
        <RDCommonErrorModal
          onClose={() => {
            setShowErrorModal(false);
            onClose();
          }}
        />
      ) : (
        <></>
      )}
      {isShowLoader ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default ConsentMarketingModal;
