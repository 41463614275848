import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import style from "./ChangeCompanyModal.module.css";
import { Impersonation } from "../../models/Account";
import RDCustomAlert from "../RDComponents/RDCustomAlert";
import RDButton from "../RDComponents/RDButton";
import RDDropdown from "../RDComponents/RDDropdown";
import {
  getAccountSimpleAsync,
  selectAccount,
  selectCiamId,
  selectImList,
  selectOriginComp,
  setOnSubWorkshopBranch,
} from "../../redux-toolkit/accountReducer";
import { AppDispatch, useAppDispatch } from "../../redux-toolkit/store";
import {
  resetContactPersons,
  setIsForceReloadContactPersons,
} from "../../redux-toolkit/contactReducer";

interface ChangeCompanyModalProps {
  setCloseModalTrigger: Function;
}

const ChangeCompanyModal: FC<ChangeCompanyModalProps> = ({
  setCloseModalTrigger,
}) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const [switching, setSwitching] = useState<boolean>(false);
  const ciamId = useSelector(selectCiamId);
  const origin = useSelector(selectOriginComp);
  const account = useSelector(selectAccount);
  const workshops = useSelector(selectImList);
  const [managerList, setManagerList] = useState<[] | Impersonation[]>([]);
  const [disabledChange, setDisabledChange] = useState<boolean>(false);
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [companyCiamId, setCompanyCiamId] = useState<string>(ciamId);
  const [customerId, setCustomerId] = useState<number | undefined>();

  const onHandleCancleChangeCompanyModal = () => {
    setCloseModalTrigger();
  };

  const onHandleConfirmChangeCompanyModal = () => {
    if (companyCiamId !== origin?.ciamId) {
      dispatchReduxToolkit(setOnSubWorkshopBranch(true));
    } else {
      dispatchReduxToolkit(setOnSubWorkshopBranch(false));
    }

    if (companyCiamId !== ciamId) {
      setSwitching(true);
      const loadNewBranchPromise = dispatchReduxToolkit(
        getAccountSimpleAsync(companyCiamId)
      );
      loadNewBranchPromise
        .then((res) => {})
        .finally(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          // clear all contacts list
          dispatchReduxToolkit(resetContactPersons(undefined));
        });
    } else {
      setCloseModalTrigger();
    }
  };

  useEffect(() => {
    if (managerList.length === 1) {
      setCompanyCiamId(managerList[0].ciamId);
      setDisabledChange(false);
    } else {
      setDisabledChange(true);
    }
  }, [customerId]);

  useEffect(() => {
    if (!!workshops) {
      setManagerList(
        workshops.filter(
          (x: Impersonation) => x.customerId === account?.customerId
        )
      );
    }
  }, [workshops]);

  useEffect(() => {
    setCustomerId(account?.customerId);
  }, [account]);

  useEffect(() => {
    if (!!workshops) {
      setCompanyOptions(
        workshops
          .filter(
            (value: Impersonation, index: any, self: any) =>
              index ===
              self.findIndex((t: any) => t.customerId === value.customerId)
          )
          .map((x: Impersonation) => {
            return {
              key: x.customerId,
              text:
                !origin || origin.customerId !== x.customerId
                  ? `${x.workshopName1 || ""} ${x.workshopName2 || ""}`
                  : `${x.workshopName1 || ""} ${x.workshopName2 || ""} ${t(
                      "ChangeCompanyModal_HeadOffice"
                    )}`,
              value: x.customerId,
            };
          })
      );
    }
  }, [workshops, origin]);

  return (
    <RDCustomAlert
      type="info"
      isOpen
      title={t("SelectCompanyModal_Title")}
      CancelButton={
        <RDButton
          secondary
          className={style.cancel_button}
          onClick={onHandleCancleChangeCompanyModal}
          disabled={switching}
        >
          {t("CancelButton")}
        </RDButton>
      }
      AcceptButton={
        <RDButton
          primary
          onClick={onHandleConfirmChangeCompanyModal}
          loading={switching}
          disabled={switching || disabledChange}
        >
          {t("Change_Button")}
        </RDButton>
      }
    >
      <Grid className={style.modal_content_grid}>
        <Grid.Row className={style.row}>
          <RDDropdown
            title={t("SelectCompanyModal_Title")}
            placeholder="-"
            options={companyOptions}
            value={customerId}
            search
            onChange={(event: any, data: any) => {
              if (!!workshops) {
                setCustomerId(data.value);
                setManagerList(
                  workshops.filter(
                    (x: Impersonation) => x.customerId === data.value
                  )
                );
              }
            }}
          />
        </Grid.Row>
        <Grid.Row className={style.row}>
          <RDDropdown
            title={t("ChangeManagerModal_Content")}
            placeholder="-"
            options={managerList.map((x: Impersonation) => {
              return {
                key: x.ciamId,
                text: `${x.contactFirstName || ""} ${x.contactLastName || ""}`,
                value: x.ciamId,
              };
            })}
            value={companyCiamId}
            defaultValue={companyCiamId}
            search
            onChange={(event: any, data: any) => {
              setCompanyCiamId(data.value);
              setDisabledChange(false);
            }}
          />
        </Grid.Row>
      </Grid>
    </RDCustomAlert>
  );
};

export default ChangeCompanyModal;
