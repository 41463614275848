export const TRANSLATIONS_ES_ES = {
	LandingPage_Title: "Bienvenido a Bosch Automotive Training Solutions (BATS)",
	LandingPage_SubTitle_ChooseCountry: "Elija su país",
	Header_ContactUs: "Contactar",
	Header_Search: "Buscar",
	Header_Menu: "Menú",
	Header_LogInOrCreateYourAccount: "Inicie sesión o cree su cuenta",
	Header_LogIn: "Iniciar sesión",
	Header_Cart: "Carrito",
	Header_ShoppingCart: "Carro de la compra",
	Header_LogOut: "Cerrar sesión",
	Header_ChangeDetails: "Cambiar detalles",
	Footer_ExploreOurTraining: "Explorar nuestra formación",
	Footer_TrainingAtYourFingertips: "Formación al alcance de tu mano",
	Footer_DontMissAnyStory: "No te pierdas ninguna noticia",
	Footer_ExploreOurStories: "Explorar nuestras noticias",
	Footer_GetInTouchWithUs: "Póngase en contacto con nosotros",
	Footer_SendUsAMessage: "Envíenos un mensaje",
	Footer_ToContactForm: "Formulario de contacto",
	Footer_InventedForLife: "Innovacion para la vida",
	Footer_GeneralContactInfo: "Información de contacto general",
	Footer_ProductSecurity_Psirt: "Seguridad del producto (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innovaciones, patentes y licencias",
	Footer_PurchasingLogistics: "Compras y logística",
	Footer_CorporateInformation: "Información corporativa",
	Footer_LegalNotice: "Aviso legal",
	Footer_DataProtectionNotice: "Aviso de protección de datos",
	Footer_PrivacySettings: "Configuración de privacidad",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch España, S.L.U., todos los derechos reservados",
	Footer_StillLookingForSomething: "Todavía buscando algo",
	Footer_TrainingServices: "Servicios de Formación",
	Footer_Courses: "Cursos",
	Footer_Programs: "Programas",
	Footer_EventsCalendar: "Calendario de formaciones",
	Footer_TrainingCenters: "Centros de formación",
	Footer_Dashboard: "Panel de control",
	Footer_MyDashboard: "Mi panel de control",
	Footer_ShoppingCart: "Carrito de compra",
	Footer_YourShoppingCart: "Su carrito de compra",
	Footer_Administration: "Administración",
	Footer_Employees: "Empleados",
	Footer_Wholesalers: "Distribuidores",
	Footer_Transactions: "Transacciones",
	Footer_Workshops: "Talleres",
	Footer_WorkLikeABoschApplyNow: "Trabajo  - Aplicar ahora.",
	Footer_BoschGlobalCareers: "Bosch Global Careers",
	Footer_PurchasingAndLogistics: "Compras y Logística",
	Footer_BecomeABusinessPartner: "Conviértete en un socio de negocios.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Cómo evoluciona nuestra formación para ayudar a su negocio",
	Footer_Language: "Selección de país",
	MenuPopup_MyCart: "Mi carrito",
	Stories_OurTrainingStories: "Nuestras historias de formación",
	Stories_ConnectingExperts: "Conectar expertos y entusiastas soñadores y hacedores innovadores e influencers - explorar y experimentar",
	Stories_TopStories: "Top Historias",
	Stories_AtHome: "En casa",
	Stories_Careers: "Carreras",
	Stories_History: "Historia",
	Stories_IndustryAndTrades: "Industria y comercio",
	Stories_Mobility: "Movilidad",
	Stories_Research: "Investigación",
	Stories_Sustainability: "Sostenibilidad",
	Stories_AllStories: "Todas las historias",
	Stories_BlogATrainersDay: "BLOG | Un día de formador",
	Stories_ADayinLifeOfATrainer: "Un día en la vida de un formador",
	Stories_BoschServiceTraining: "Bosch Service Training",
	Stories_StoryWhatDrivesYouDrivesUs: "HISTORIA | Lo que nos impulsa",
	Stories_StoryHowCanWeBenefitYourBusiness: "HISTORIA | Cómo podemos beneficiar su negocio",
	Stories_HowOurTrainingEvolves: "Cómo evoluciona nuestra formación",
	Stories_AModernTrainingApproach: "El mundo de la formación en línea",
	Stories_StoryAVirtualOnlineTrainingWorld: "Historia | Sus oportunidades de formación en línea",
	Stories_SubscribeToRSSFeed: "Suscribirse a RSS feed",
	Title_Main: "Soluciones de formación Bosch Automotive",
	Homepage_AboutUs: "Sobre nosotros",
	Homepage_AutomotiveAftermarket: "Mobility Aftermarket",
	Homepage_GetInTouch: "Ponerse en contacto",
	Homepage_LatestNews: "Descubre por qué nos mueve la sed de conocimiento",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Nuestras posiciones abiertas",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Lo que nos impulsa",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service Training",
	BoschServiceTraining_ShareThisOn: "Compartir esto en",
	BoschServiceTraining_TheChallenge: "El reto: dominar las nuevas tecnologías",
	BoschServiceTraining_WorldwidePresence: "Presencia mundial",
	BoschServiceTraining_TechnicalTraining: "Cursos de formación técnica de Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Las ofertas y oportunidades que ofrecemos en Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Hay cursos de formación adecuados para cada individuo - ya sea principiante o profesional. La oferta incluye cursos de formación de un día y cursos de formación durante varios días. Los cursos de formación se ofrecen en diferentes áreas técnicas y series de formación (como sistemas eléctricos de inyección diésel de gasolina). En los cursos de formación técnica Bosch imparte amplios conocimientos en todos los sistemas de vehículos estándar de los sistemas de Bosch de otros fabricantes también se discuten en los cursos de formación. El contenido se ajusta especialmente a las necesidades de los técnicos del taller.",
	BoschServiceTraining_TrainedEmployeesDesc: "Tener técnicos formados es beneficio para el taller. Porque los vehículos actuales son cada vez más complejos y las nuevas tecnologías presentan constantemente nuevos desafíos. Sólo la experiencia necesaria permite a los empleados del taller realizar trabajos de mantenimiento y reparación diagnósticos en los modelos de vehículos actuales de una manera eficiente y rentable.",
	BoschServiceTraining_RegardlessOfHow: "Independientemente de lo buenos y experimentados técnicos que sean - siempre hay situaciones en las que entrarán en contacto con nuevos sistemas de tecnología de vehículos o averías desconocidas. En este sentido, el uso de información técnica y la resolución de problemas puede llegar a ser especialmente intensiva en el tiempo o, posiblemente, no den resultados satisfactorios. En el peor de los casos, los clientes deben prescindir de su vehículo mucho más tiempo del previsto.",
	BoschServiceTraining_ItIsPrecisely: "Es precisamente en este punto que Bosch ofrece su servicio de Formación Técnica: mejorarando la calidad del servicio y acelerarando los procesos del taller. La formación actual de Bosch proporciona soporte para localizar problemas más rápidamente reparando más vehículos al mismo tiempo y reduciendo los tiempos de espera de sus clientes. Así, los procedimientos sistemáticos que aprenden en Bosch aseguran un trabajo más rápido y productivo.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Durante más de 125 años, Bosch ha sido el proveedor preferido de componentes y sistemas de componentes de equipos originales para muchos de los principales fabricantes de automóviles en todo el mundo. Esta experiencia viene con un conocimiento único de las últimas tecnologías que sirve como base para el apoyo de Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Los Centros de Formación Tecnica de Bosch se puede encontrar en todo el mundo: de España a Turquía Sudáfrica Australia a Brasil - Bosch ofrece a sus empleados una formación orientada a la práctica que siempre está en línea con las tecnologías de vanguardia.",
	BoschServiceTraining_AgileCompany: "Compañía ágil",
	BoschServiceTraining_Collaboration: "Colaboración",
	BoschServiceTraining_ConnectedMobility: "Movilidad Conectada",
	BoschServiceTraining_ArtificialIntelligence: "Inteligencia Artificial",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Líderes de pensamiento en diálogo",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnosis y equipos de taller",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Fundamentos de la tecnología del automóvil",
	BoschServiceTraining_Icon_Gasoline: "Gasolina",
	BoschServiceTraining_Icon_Diesel: "Diésel",
	BoschServiceTraining_Icon_AlternativeDrives: "Nuevos tipos de propulsión",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Electricidad y Electrónica",
	BoschServiceTraining_Icon_BodyAndComfort: "Carrocería y confort",
	BoschServiceTraining_Icon_ChassisAndBraking: "Chasis y frenado",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmisión y tren de rodaje",
	BoschServiceTraining_Icon_CommercialVehicles: "Vehículos Industriales",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Cursos en línea",
	BoschServiceTraining_Icon_BusinessSkills: "Habilidades empresariales",
	BoschServiceTraining_Icon_Accreditations: "Acreditaciones",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programas formación",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "FormadorNombreapellido",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Un día en la vida de un formador",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Lo cotidiano es una aventura",
	ADayInTheLifeOfATrainer_Summary: "Resumen",
	ADayInTheLifeOfATrainer_TheDayJourney: "El viaje del día",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Cada día es un día ajetreado, pero siempre es una experiencia gratificante. El placer de formar a los estudiantes sabiendo que tienen la llave de nuestro futuro es una responsabilidad pero al mismo tiempo tan emocionante. Ver a los estudiantes crecer con cada lección a diario y semanalmente nos demuestra que estamos trabajando hacia un objetivo común para mejorar sus conocimientos al mismo tiempo que incorpora la tecnología mordern que impulsa el futuro!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Al final de cada día nos sentamos y reflexionamos sobre los días de trabajo. Los días pasan tan rápido, pero esto es prueba de que lo que estamos logrando no sólo es gratificante para los estudiantes, sino también para nosotros como formadores",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Podemos ir a casa al final del día satisfechos de que hemos hecho un gran trabajo!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Inicio",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Preparación y comprobación final del material de formación. Consultar correo importante",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Inicio de la formación",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Bienvenida. Presentación del horario diario. Conozca al grupo comprendiendo la dinámica de cada individuo.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teoría",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Se requiere una cierta cantidad de interacción con el grupo para que el formación sea lo más interesante posible. Asegúrese de que hay tareas entrelazadas con la teoría para involucrar aún más al grupo",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Hora del almuerzo",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Práctica",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "La formación continúa en el taller con algunas tareas prácticas. Como formador, es siempre un requisito estar presente y disponible como siempre para cualquier eventualidad y orientación",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Recapitular",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "De vuelta al aula para hablar de la formación del día. Contesta cualquier pregunta que pueda surgir. Comience la preparación para las próximas sesiones de formación.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Día Final",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Los certificados de asistencia se presentan a los participantes.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "A casa,",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "¿Cómo podemos ayudarle a mejorar su negocio",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Cómo evoluciona nuestra formación",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Estamos en constante evolución para garantizar que ofrecemos una formación de calidad actualizada",
	BoschTrainingBenefits_TheBoschServiceTraining: "El Centro de Formación de Servicio de Bosch desea ayudarle a desarrollar su negocio a través de su extensa y relevante cartera de cursos de formación técnica/empresarial y formación continua con calificaciones certificadas. Las siguientes páginas le proporcionan una visión general de nuestro programa de formación, así como las oportunidades actuales de educación adicional. Esperamos conocerlo a usted y a sus empleados en nuestros cursos y seminarios de formación.",
	BoschTrainingBenefits_LongStandingExperience: "Reconocida experiencia en el sector y amplio conocimiento de todos los sistemas  de la tecnología del automóvil.",
	BoschTrainingBenefits_QuickAndEfficient: "Soluciones rápidas y eficientes para muchos problemas.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Las ventajas y beneficios",
	BoschTrainingBenefits_LMS: "Sistema de gestión del aprendizaje - Soluciones de formación de Bosch",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions cubre toda la gama de áreas automotrices. Especialícese en áreas específicas o amplíe su competencia con las ofertas de formación de Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Conocimientos adquiridos instalaciones de reparación de mantenimiento y trabajo de servicio.",
	BoschTrainingBenefits_TheHighDegree: "El alto grado de contenido práctico y los pequeños grupos garantizan un aprendizaje orientado a los objetivos",
	BoschTrainingBenefits_ParticipationInTraining: "La participación en la formación aumenta la motivación y satisfacción de los empleados y las cualificaciones complementarias convencen a los clientes",
	BoschTrainingBenefits_IncreasedSales: "Aumento de las ventas a través del conocimiento del sistema de fabricación cruzada",
	BoschTrainingBenefits_MethodicalProcedures: "Los procedimientos metódicos reducen el tiempo de espera para los clientes",
	BoschTrainingBenefits_HighlyQualified: "Instructores altamente calificados transmiten procedimientos sistemáticos",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identificación de la solución de formación adecuada para usted o su empresa",
	BoschTrainingBenefits_DirectOnlineBooking: "Reserva online directa y gestión de cursos para usted o sus empleados",
	BoschTrainingBenefits_OnlineAccess: "Acceso en línea a la documentación de aprendizaje",
	BoschTrainingBenefits_AccessToYourOwn: "Acceso a su propio historial de formación personal y certificados",
	BoschTrainingBenefits_ToolsForManagement: "Herramientas para la gestión de su propio desarrollo profesional",
	BoschTrainingBenefits_ManagerHasFull: "El gerente tiene una visión completa de los cursos de sus empleados dentro del sistema",
	BoschTrainingBenefits_Access24hours: "Acceso 24 horas los 7 días de la semana",
	ModernTrainingApproach_KnowledgeIsKey: "El conocimiento es un factor clave de éxito para cualquier taller. Es por eso que Bosch ofrece un programa de desarrollo de formación profesional integral. Esto permite a los estudiantes adquirir exactamente el conocimiento adecuado en el momento y lugar adecuados - conocimiento necesario para poder mantener y reparar el vehículo del cliente profesionalmente. Bosch ha añadido el webcasting como un nuevo módulo parte del paquete de e-learning ya ofrecido. Consisten en sesiones de formación de servicio diseñadas específicamente para streaming.",
	ModernTrainingApproach_ANewLearning: "Una nueva oportunidad de aprendizaje",
	ModernTrainingApproach_YourOnlineTraining: "Sus oportunidades de formación en línea",
	ModernTrainingApproach_RecentlyTheWorld: "Recientemente el mundo ha cambiado ver lo que estamos haciendo para ayudar",
	ModernTrainingApproach_TheOnlineTrainingWorld: "El mundo de la formación en línea",
	ModernTrainingApproach_NewTeachingMethod: "Nuevo método de enseñanza",
	ModernTrainingApproach_InAdditionTo: "Además de la formación presencial existente, hemos incluido la formación en vivo por Internet en el programa de formación. Nuestro formador está a su disposición con amplios conocimientos teóricos en el aula virtual o con ejemplos prácticos. Como de costumbre, recibirá mucha información sobre los sistemas de vehículos y los correspondientes productos Bosch. Por supuesto, usted también tiene la oportunidad de hacer preguntas. Estas preguntas serán respondidas directamente en el webcast en vivo. Las transmisiones en directo duran entre 60 y 120 minutos. Se requiere una conexión a Internet estable. Busque un webcast adecuado directamente en nuestro catálogo de formación introduciendo Webcast en el campo de búsqueda.",
	ModernTrainingApproach_BoschWebcastingHeader: "Webcasting de Bosch: Innovador y práctico",
	ModernTrainingApproach_BoschWebcastingText: "Para participar en un webcast de Bosch primero el participante debe marcar. Se envía un código de acceso individual antes de la sesión para habilitar el inicio de sesión. Hasta 200 participantes pueden participar al mismo tiempo desde cualquier lugar. La formación es dirigida por un formador y las preguntas se pueden enviar en cualquier momento a través de la función de chat en vivo.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "El suplemento digital a la formación presencial",
	ModernTrainingApproach_TheDigitalSupplementText: "La transmisión web y la formación en el aula se complementan perfectamente. En ambos casos, un formador cualificado dirige la formación. Los webcasts son adecuados para temas que se pueden transmitir digitalmente sin la necesidad de trabajar en el vehículo. Por ejemplo, conocer ciertas características de ESI[tronic] de un nuevo sistema de asistencia al conductor. Cuando se trata de trabajo con equipos en el taller o un trabajo práctico en una tecnología de vehículos, la formación cara a cara sigue siendo la primera opción.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Streaming puede ser más barato",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Los costos por participante son generalmente más bajos con la transmisión web que con un curso de aula comparable. La razón es que más participantes pueden beneficiarse de ella y el esfuerzo asociado con la formación es significativamente menor (no hay costes de desplazamiento ni de hospedaje).",
	ModernTrainingApproach_BookAndProfitHeading: "Libro y beneficios",
	ModernTrainingApproach_BookAndProfitText: "Webcasts se pueden reservar en nuestro sitio web al igual que nuestros otros cursos de formación y ofertas de aprendizaje. Usted tiene la opción de pagar la tarifa individual por cada webcast o de realizar una suscripción anual para un cierto número de webcasts.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Bosch Webcasting y formación en el aula comparados",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Aula",
	ModernTrainingApproach_WebcastingP1: "Hasta 200 participantes pueden participar al mismo tiempo desde cualquier parte del mundo",
	ModernTrainingApproach_WebcastingP2: "Poca complejidad y costos relativamente bajos",
	ModernTrainingApproach_WebcastingP3: "Ideal para temas individuales específicos y concisos",
	ModernTrainingApproach_WebcastingP4: "Los talleres pueden realizar un webcast para sus empleados en cualquier lugar y en cualquier momento",
	ModernTrainingApproach_WebcastingP5: "Entre un 50-70% menos de tiempo requerido que para los cursoss en el aula",
	ModernTrainingApproach_ClassroomTrainingP1: "Hasta 16 participantes se reúnen al mismo tiempo",
	ModernTrainingApproach_ClassroomTrainingP2: "Gastos de viaje y, en general, gastos más elevados por persona",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideal para optimizar la colaboración o el aprendizaje directamente en un vehículo",
	ModernTrainingApproach_ClassroomTrainingP4: "Ejercicios prácticos e interacción con el formador",
	ModernTrainingApproach_ClassroomTrainingP5: "El formador puede adaptar mejor los conocimientos que se enseñarán de acuerdo con las necesidades reales de los participantes",
	ModernTrainingApproach_OnlineTrainingOfferings: "Ofertas de formación en línea (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Hoy en día el aprendizaje independiente en el ordenador es una parte integral de la transferencia de conocimientos. La libertad de elegir el tiempo y el lugar de repetibilidad ilimitada velocidad individual de aprendizaje y contenidos de aprendizaje interactivo hacen de este método una herramienta de estudio indispensable.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Cada elemento de formación Basada en la Web (WBT) ha sido cuidadosamente estructurado. Además de proporcionar una amplia base de conocimientos, estos cursos pueden servir como una preparación eficaz para los programas de formación en los centros de formación de Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "La simulación de diagnóstico virtual ofrece al usuario posibilidades de juego de rol para situaciones diarias de taller, como el flujo de trabajo de un procedimiento de solución de problemas utilizando dispositivos de diagnóstico de Bosch en un taller virtual.",
	ModernTrainingApproach_Advantages: "Ventajas",
	ModernTrainingApproach_AdvantagesP1: "Un método rápido y eficiente de proporcionar conocimiento",
	ModernTrainingApproach_AdvantagesP2: "Aprendizaje variado",
	ModernTrainingApproach_AdvantagesP3: "Se puede utilizar tan a menudo como desee",
	ModernTrainingApproach_AdvantagesP4: "Tiempo y dinero mínimos",
	ModernTrainingApproach_AdvantagesP5: "Velocidad individual del aprendizaje",
	ModernTrainingApproach_AdvantagesP6: "Tiempo de estudio flexible",
	ModernTrainingApproach_AdvantagesP7: "Repetibilidad",
	ModernTrainingApproach_AdvantagesP8: "Sin gastos de viaje",
	ModernTrainingApproach_AdvantagesP9: "No hay capacidad ni conflictos de programación",
	Dashboard_CoursesViewAndManageYourCourses: "CURSOS | Ver y gestionar sus cursos",
	Dashboard_MyCourses: "Mis Cursos",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAMA DE FORMACIÓN | Vea sus programas de formación",
	Dashboard_MyTrainingProgram: "Mi programa de formación",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Ver y administrar su cartera de eLearning",
	Dashboard_MyELearning: "Mi eLearning",
	Dashboard_AccountManageYourAccount: "CUENTA | Administrar su cuenta",
	Dashboard_MyAccount: "Mi Cuenta",
	Dashboard_MyDashboard: "Mi tablero de instrumentos",
	Dashboard_ManageYourAccount: "Administrar su cuenta",
	Dashboard_Workshops: "Talleres",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRACIÓN | Gestione su taller",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRACIÓN | Administre sus equipos",
	Dashboard_Administration: "Administración",
	Dashboard_ViewYourCourses: "Ver sus cursos",
	Dashboard_ViewYourTrainingPrograms: "Vea sus programas de formación",
	Dashboard_TrainingPrograms: "Programas de Formación",
	Dashboard_ViewAndEditYourDetails: "Ver y editar sus Detalles",
	Dashboard_ViewYourELearningCourses: "Vea sus cursos de eLearning",
	Dashboard_Pending: "Pendiente",
	Dashboard_RequiresAdministratorSignOff: "(requiere la autorización del administrador)",
	Dashboard_HistoricalRecords: "Registros Históricos",
	Dashboard_Registered: "Registrado",
	Dashboard_WaitingList: "Lista de espera",
	Dashboard_RecommendedForYou: "Recomendado para usted",
	Dashboard_AccountDetails: "Detalles de la cuenta",
	Dashboard_ChangeProfileImage: "cambiar imagen de perfil",
	Dashboard_Title: "Título",
	Dashboard_Role: "Rol",
	Dashboard_FirstName: "Nombre",
	Dashboard_LastName: "Apellido",
	Dashboard_Telephone: "Teléfono",
	Dashboard_Mobile: "Móvil",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Idioma",
	Dashboard_Save: "Guardar",
	Dashboard_Student: "Estudiante",
	Dashboard_WorkshopTrainingAdministrator: "Administrador de Formación del Taller",
	Dashboard_WholesalerTrainingAdministrator: "Administrador de Formación de Distribuidores",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administración de la Empresa",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Gestiona tus talleres de...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Administre a sus empleados...",
	Dashboard_CompanyAdministration_Workshop: "Taller",
	Dashboard_CompanyAdministration_Address: "Dirección",
	Dashboard_CompanyAdministration_Zip: "Código Postal",
	Dashboard_CompanyAdministration_City: "Ciudad",
	Dashboard_CompanyAdministration_Manage: "Gestionar",
	Dashboard_AdministrationSection_AdministrationTitle: "Administración",
	Dashboard_AdministrationSection_ManageTheTeam: "Gestionar el equipo",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRACIÓN | Administre a sus empleados",
	Dashboard_AdministrationSection_Employees: "Empleados",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRACIÓN | Administre sus Distribuidores",
	Dashboard_AdministrationSection_Wholesalers: "Distribuidores",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRACIÓN | Administre sus transacciones",
	Dashboard_AdministrationSection_Transactions: "Transacciones",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administración de Empleados",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Administre a sus empleados...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registrarse",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Registros",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Editar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Empleado",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Agregar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Crear",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrado",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Pendiente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(requiere la autorización del administrador)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Registros Históricos",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Lista de espera",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Lista de empleados actuales",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Dirección de Empleados",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nombre",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Cuenta de empleado",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Ver y editar los detalles de los empleados...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Ajustes",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Permitir cuenta personal",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Actualización del proxy de administración de talleres para empleados",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Permitir que el empleado se registre para eventos de formación",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Enviar invitación por correo electrónico",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Desactivar la cuenta",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Guardar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Enviar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Editar",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Gestión de distribuidores",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Administrar a sus distribuidores...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Lista de distribuidores autorizados",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nombre",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Suprimir",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Crear",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Agregar",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Creación de cuenta de nuevo empleado",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Crear detalles para nuevos empleados...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Añadir nuevo administrador distribuidor aprobado",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Crear detalles para nuevos distribuidores...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Elija Distribuidor aprobado del menú desplegable...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transacciones",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Ver y gestionar sus transacciones...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Volver",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Número de factura",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Descripción",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Método de pago",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Fecha",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Importe",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Descargar",
	TrainingCentres_TrainingCentres: "Centros de Formación",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Nuestros centros de formación están situados en todo el mundo",
	TrainingCentres_CountryCategory: "Categoría de país",
	TrainingCentres_CityCategory: "Categoría de ciudad",
	TrainingCentres_Country: "País",
	TrainingCentres_City: "Ciudad",
	TrainingCatalogue_TrainingCatalogue: "Catálogo de cursos de formación",
	TrainingCatalogue_LearningAtTheHighestLevel: "Aprendizaje al más alto nivel",
	TrainingCatalogue_StartDate: "Fecha de inicio",
	TrainingCatalogue_EndDate: "Fecha final",
	TrainingCatalogue_TrainingName: "Nombre del curso",
	TrainingCatalogue_Language: "Idioma",
	TrainingCatalogue_Days: "Días",
	TrainingCatalogue_Seats: "Plazas",
	TrainingCatalogue_Price: "Precio",
	TrainingCatalogue_Location: "Ubicación",
	TrainingCatalogue_PartNumber: "Referencia",
	TrainingCatalogue_TrainingCourse: "Curso de formación",
	TrainingCatalogue_Category: "Categoría",
	TrainingCatalogue_TrainingCategory: "Categaría del curso",
	TrainingCatalogue_CourseName: "Nombre del curso",
	TrainingCatalogue_TotalDays: "Total de días",
	TrainingCatalogue_Venue: "Lugar",
	TrainingCatalogue_Details: "Detalles",
	TrainingCatalogue_Prerequisites: "Requisitos previos",
	TrainingCatalogue_CourseType: "Tipo de curso",
	TrainingCatalogue_LearningMethod: "Método de aprendizaje",
	TrainingCatalogue_AddToWaitingList: "Añadir a la lista de espera",
	ShoppingCart_ShoppingCart: "Carrito de compra",
	ShoppingCart_ReviewAndManage: "Revisar y administrar sus suscripciones",
	ShoppingCart_YouHaveAddedItem: "Usted ha añadido los siguientes artículos a su carrito",
	ShoppingCart_Item: "Artículo",
	ShoppingCart_Quantity: "Cantidad",
	ShoppingCart_PricePerItem: "Precio por artículo",
	ShoppingCart_TotalPrice: "Precio total",
	ShoppingCart_VatIsIncluded: "IVA no incluido",
	ShoppingCart_Redeem: "Si no tienes crédito haz clic aquí para canjearlo",
	ShoppingCart_Purchase: "Si no tienes crédito haz clic aquí para comprar",
	ShoppingCart_Checkout: "Terminar",
	ShoppingCart_ContinueShopping: "Seguir comprando",
	ShoppingCart_TotalPriceInclVat: "Precio total incl. IVA",
	ShoppingCart_Apply: "Aplicar",
	ShoppingCart_ShoppingCartEmpty: "Su carrito de compra está vacío",
	ShoppingCart_VatIncluded: "IVA(Incluido)",
	ShoppingCart_ManageYourShopping: "Gestionar sus compras",
	ShoppingCart_Participant: "Participante",
	ShoppingCart_Complete: "Completar",
	ShoppingCart_NetPrice: "Precio neto",
	ShoppingCart_Workshop: "Taller",
	CheckOut_PreviewYourOrder: "Vista previa de su pedido",
	CheckOut_YourShoppingCart: "Carrito",
	CheckOut_Preview: "Vista previa",
	CheckOut_Payment: "Pago",
	CheckOut_Summary: "Resumen",
	CheckOut_Back: "Volver",
	CheckOut_PleaseReadAndAgreeOurTerms: "Por favor, lea y acepte nuestros términos y condiciones",
	CheckOut_IHaveReadTheTermsAndCondition: "He leído los [términos y condiciones] y los entiendo y acepto",
	CheckOut_YesIAgree: "Sí - Acepto los términos y condiciones",
	CheckOut_Continue: "Continuar",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "¡Enhorabuena! Hemos recibido su pedido",
	CheckOut_OrderConfirmation: "Recibirá la confirmación final del pedido una vez que su solicitud haya sido procesada correctamente",
	CheckOut_WeWillContactYouDirectly: "Nos pondremos en contacto con usted directamente en caso de que tengamos alguna pregunta relacionada con su solicitud",
	CheckOut_AddToCart: "Añadir a la cesta",
	CheckOut_CreditCart: "Carro de crédito",
	CheckOut_DirectDebit: "Débito directo",
	CheckOut_TrainingCredits: "Créditos de formación",
	CheckOut_PreferredWholesaler: "Distribuidor Preferente",
	EventCalendar_EventCalendar: "Calendario de formación",
	EventCalendar_TrainingEventsForAllYourNeeds: "Formaciones para todas sus necesidades",
	TrainingCourse_TrainingCourse: "Catálogo de cursos de formación",
	TrainingCourse_LearningAtTheHighestLevel: "Aprendizaje al más alto nivel",
	TrainingProgram_Title: "Catálogo del Programa de Formación",
	TrainingProgram_SubTitle: "Aprendizaje al más alto nivel",
	AccountDeactivationModal_Title: "¿Estás seguro de que quieres desactivar esta cuenta?",
	AccountDeactivationModal_Line1: "Desactivar esta cuenta es permanente y eliminará todo el contenido, incluidos los servicios de solicitud de transacciones y saldos de los miembros del equipo y la configuración del perfil.",
	AccountDeactivationModal_Line2Part1: "Si está dentro de un período de suscripción activo, consulte nuestra política de cancelación en nuestro",
	AccountDeactivationModal_Line2Part2: "términos y condiciones",
	AccountDeactivationModal_Line2Part3: "antes de enviar su solicitud.",
	AccountDeactivationModal_Line3: "¿Está seguro de que desea enviar una solicitud para desactivar esta cuenta?",
	AccountDeactivationModal_Cancel: "Cancelar",
	AccountDeactivationModal_Send: "Enviar",
	CurrentUserAccountDeactivationModal_Title: "¿Estás seguro de que quieres desactivar tu cuenta?",
	CurrentUserAccountDeactivationModal_Line1: "La desactivación de su cuenta es permanente y eliminará todo el contenido, incluidos los servicios de solicitud de transacciones y saldos de los miembros del equipo y la configuración del perfil.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Si está dentro de un período de suscripción activo, consulte nuestra política de cancelación en nuestro",
	CurrentUserAccountDeactivationModal_Line3: "¿Estás seguro de que quieres enviar una solicitud para desactivar tu cuenta?",
	CurrentUserAccountDeactivationModal_Yes: "Sí",
	CurrentUserAccountDeactivationModal_No: "No",
	CourseWithdrawalModal_Title: "Cancelación del curso",
	CourseWithdrawalModal_Line1: "¿Está seguro que desea anular su participación en el curso?",
	CourseWithdrawalModal_Line2Part1: "Las cancelaciones pueden suponer una penalización que será cargada según nuestras",
	CourseWithdrawalModal_Line2Part2Link: "condiciones",
	CourseWithdrawalModal_Line2Part3: "de nuestro servicio",
	CourseWithdrawalModal_Yes: "Si, anular",
	CourseWithdrawalModal_No: "No, cancelar",
	Settings_Settings: "Ajustes",
	Settings_ManageYourSettings: "Administrar la configuración",
	Settings_Profile: "Perfil",
	Settings_MyBoschGlobalIdLoginInformation: "Información de inicio de sesión de mi Bosch ID",
	Settings_Edit: "Editar",
	Settings_CompanyDetails: "Detalles de la empresa",
	Settings_RequestAccountDeletion: "Solicitud de eliminación de cuenta",
	Settings_AccountDeletionText: "Si elimina su cuenta, todos sus datos (incluyendo su dirección y datos de pago) serán borrados y ya no estarán disponibles.",
	Settings_DeleteAccountRequestConfirmation: "Borrar confirmación de solicitud de cuenta",
	Settings_DeleteAccountRequestConfirmationText: "Hemos recibido su solicitud de eliminación de cuenta y nos pondremos en contacto con usted por correo electrónico o teléfono para la confirmación final",
	RecommendedHotels_RecommendedHotels: "Hoteles recomendados",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Nuestros centros de formación están situados en todo el mundo",
	Error_Common: "¡Algo ha ido mal!",
	Error_404: "La página visitada no existe",
	Error_401: "Usted no tiene acceso a esta página",
	Error_500: "El servidor informa de un error",
	Dashboard_Courses_Table_Course: "Curso",
	Dashboard_Courses_Table_Date: "Fecha [Días]",
	Dashboard_Courses_Table_Venue: "Lugar",
	Dashboard_Courses_Table_TrainingType: "Tipo de formación",
	Dashboard_Courses_Table_Action: "Acción",
	Dashboard_Courses_Table_TasksToComplete: "Usted tiene siguientes tareas para completar",
	Dashboard_Courses_Table_Withdraw: "Desapuntarse",
	Dashboard_Courses_Table_Remove: "Quitar",
	Dashboard_Courses_Table_View: "Vista",
	Dashboard_Courses_Table_NoRecordsToDisplay: "No hay registros que mostrar",
	Dashboard_Courses_Table_CompletionDate: "Fecha de finalización",
	Dashboard_Courses_Table_Actions_Assignments: "Asignaciones",
	Dashboard_Courses_Table_Actions_Certification: "Certificación",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluaciones",
	Dashboard_Courses_Table_Actions_Assessments: "Evaluaciones",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Añadir al calendario",
	Dashboard_Courses_Table_CourseDetails_Task: "Tarea",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Fecha de vencimiento",
	ContactUs_Headline: "Contactar",
	ContactUs_HowCanWeHelp: "¿En qué podemos ayudarle?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Teléfono",
	ContactUs_LetterTab: "Carta",
	ContactUs_Phone_Headline: "Llámenos!",
	ContactUs_Phone_Description: "Esperamos su llamada y estaremos encantados de atenderle.",
	ContactUs_Phone_PhoneNumber: "Por favor, utilice el formulario de contacto para enviarnos un mensaje.",
	ContactUs_Phone_ChargeNotice: "Los cargos varían dependiendo de su proveedor de servicios y país.",
	ContactUs_Letter_Headline: "¡Enviar una carta!",
	ContactUs_Letter_Description: "¿Tiene preguntas, peticiones o sugerencias? Esperamos con interés su consulta",
	ContactUs_Email_Headline: "Enviar un correo electrónico!",
	ContactUs_Email_Description: "Envíenos su mensaje rellenando el siguiente formulario. Uno de nuestros asesores estará encantado de ponerse en contacto con usted.",
	ContactUs_Email_InputName: "Nombre*",
	ContactUs_Email_InputCompany: "Empresa",
	ContactUs_Email_InputCountry: "País*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Mensaje*",
	ContactUs_Email_DataProtection: "Aviso de protección de datos*",
	ContactUs_Email_DataProtectionConfirm: "He leído la Política de Privacidad.*",
	ContactUs_Email_SecurityCheck: "Control de seguridad",
	ContactUs_Email_SecurityCodeConfirm: "Por favor, introduzca los caracteres correctos que ve en el cuadro de arriba.",
	ContactUs_Email_SecurityCodeInvalid: "La respuesta de texto que ha introducido es incorrecta.",
	ContactUs_Email_MandatoryNotice: "Por favor, rellene los campos obligatorios.",
	ContactUs_Email_SuccessMessage: "Su mensaje ha sido enviado",
	ContactUs_Email_TakenNoteDataProtection1: "He leído el",
	ContactUs_Email_TakenNoteDataProtection2: "Política de privacidad.*",
	ContactUs_Email_SubmitButton: "Enviar",
	ContactUs_Email_FieldErrorMessage: "Por favor, rellene los campos obligatorios.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "¡Su cuenta está actualmente en revisión!",
	AccountInReviewModal_Paragraph1: "Gracias por registrar una cuenta personal en Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Nuestro personal de formación dedicado está revisando y configurando su cuenta. Recibirá la confirmación final por correo, una vez que su cuenta haya sido configurada correctamente.",
	AccountInReviewModal_Paragraph3: "Mientras tanto, no dude en navegar por nuestro sitio web con acceso limitado.",
	AccountInReviewModal_Paragraph4: "Si tiene alguna pregunta, o si su cuenta no se procesa dentro de las 48 horas, excluyendo fines de semana y festivos, puede ponerse en contacto con nosotros aquí",
	AccountInReviewModal_ContactUsLink: "aquí",
	AccountInReviewModal_OKButton: "¡Está bien, déjame entrar!",
	Header_YesButton: "Sí",
	Header_NoButton: "No",
	Header_LogOutText: "¿Quieres cerrar la sesión?",
	Footer_EnquiryText: "Esperamos con interés su consulta,",
	Footer_Telephone: "Teléfono,",
	Footer_TermsAndConditions: "Términos y condiciones",
	Footer_HowOurTrainingEvolves: "Cómo evoluciona nuestra formación",
	FooterSearch_SearchFieldPlaceholder: "Buscar",
	TrainingCatalogue_Action: "Acción",
	TrainingCatalogue_Duration: "Duración",
	TrainingCatalogue_Information: "Información",
	Settings_PersonalDetails: "Detalles personales",
	Reset_All_Filters: "Restablecer todos los filtros",
	EventTable_Days: "Días",
	SearchField_AllResults: "Todos los resultados",
	TrainingCentresLayout_ViewDetails: "Ver detalles",
	Dropdown_Placeholder: "Elegir...",
	WaitlistPopup_SelectButtonText: "Seleccionar",
	WaitlistPopup_StartDate: "Fecha de inicio",
	WaitlistPopup_EndDate: "Fecha final",
	WaitlistPopup_Language: "Lenguaje",
	WaitlistPopup_City: "Ciudad",
	WaitlistPopup_Workshop: "Taller",
	WaitlistPopup_Student: "Estudiante",
	WaitlistPopup_PreferredLocation: "Ubicación preferida",
	WaitlistPopup_SignUpCourseWaitingList: "Regístrese en la lista de espera del curso",
	WaitlistPopup_SignUpWithoutSelectingDate: "Regístrese en la lista de espera sin seleccionar una fecha",
	WaitlistPopup_GeneralWaitList: "Lista de espera general",
	WatinglistPopup_CourseLabel: "Curso",
	Common_ContinueButton: "Continuar",
	UserAccountDeactivationModal_ConfirmLabel: "Confirmar",
	Filter_FilterLabel: "Filtrar",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Usted ya está en la lista de espera, ¿quiere eliminar su registro existente y hacer uno nuevo?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Sí, quitar",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "No, Cancelar",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Cancelación del curso",
	WaitinglistPopup_SignUpButtonText: "Inscribirse",
	MyAccount_FirstNameRequied: "Se requiere el nombre",
	MyAccount_LastNameRequired: "Se requiere el apellido",
	MyAccount_RoleRequired: "Se requiere su función",
	MyAccount_MobileRequired: "Se requiere un móvil",
	MyAccount_EmailRequired: "Se requiere un email",
	MyAccount_LanguageRequired: "Ser requiere el idioma",
	MyAccount_UpdateSuccessMessage: "¡Los detalles de su cuenta se actualizan correctamente!",
	MyAccount_UpdateSuccessModal_ButtonText: "Sí, cerrar",
	Infor_CorporateInformation_Header: "Información Corporativa",
	Infor_LegalNotice_Header: "Aviso legal",
	Infor_PrivacyPolicy_Header: "Aviso de protección de datos (Política de privacidad)",
	CreateEmployeeAccountModification_FormValidation_Role: "Se requiere su función",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Se requiere el nombre",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Se requiere apellido",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Se requiere un móvil",
	CreateEmployeeAccountModification_FormValidation_Email: "Se requiere un email",
	CreateEmployeeAccountModification_FormValidation_Language: "Ser requiere el idioma",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Invitación a unirse a Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "¡Has añadido con éxito un nuevo miembro a tu equipo!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "¿Desea que compartamos una invitación por correo electrónico con su nuevo miembro del equipo, con instrucciones de cómo pueden crear su propia cuenta personal para Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "No",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Sí, enviar una invitación",
	ShoppingCart_Venue_Fee: "Tasa por lugar de celebración",
	ShoppingCart_Trainning_Name: "Trainning Name",
	ShoppingCart_Available_Credits: "Créditos disponibles",
	ConfirmItsMeModal_Hi: "Hola",
	ConfirmItsMeModal_Description1: "Ha completado con éxito la invitación compartida por el administrador de su empresa para crear su propia cuenta personal en Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Antes de continuar, confirme o actualice los detalles de su cuenta.",
	ConfirmItsMeModal_Description3: "Después de confirmar, se le pedirá que inicie sesión de nuevo para completar la creación de su cuenta.",
	ConfirmItsMeModal_CompanyDetails: "Detalles de la empresa",
	ConfirmItsMeModal_YourDetails: "Sus Detalles",
	ConfirmItsMeModal_YourDetails_Title: "Título",
	ConfirmItsMeModal_YourDetails_Function: "Función",
	ConfirmItsMeModal_YourDetails_Name: "Nombre",
	ConfirmItsMeModal_YourDetails_Mobile: "Móvil",
	ConfirmItsMeModal_YourDetails_Email: "Correo electrónico",
	ConfirmItsMeModal_YourDetails_Language: "Lenguaje",
	ConfirmItsMeModal_ConfirmButton: "Confirmar",
	SelectWholesalerModal_WholesalerGroupLabel: "Mayorista",
	SelectWholesalerModal_WholesalerBranchLabel: "Sucursal",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Por favor seleccione",
	ApplyButton: "Aplicar",
	SelectWholesalerModal_Title: "Elija su mayorista",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "¿Quiere eliminar su registro?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "¡Su registro se eliminó con éxito!",
	WaitlistPopup_AddToWaitListSuccess: "¡Su registro se ha añadido con éxito!",
	ErrorCommon_Header: "ERROR",
	CommonButton_Close: "Cerrar",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "¡Cierre esta ventana para continuar!",
	EmbeddedVideoPlayer_StartButtonText: "Comienzo",
	EmbeddedVideoPlayer_RefresherButtonText: "Refresco",
	DeactivateWholesalerModal_ConfirmMessage: "¿Está seguro de que desea eliminar este  distribuidor?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "¡Envío de correo electrónico de invitación exitoso!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Re invitar",
	ContactUs_Mobile_ES: "formacion.automovil@bosch.com",
	ContactUs_Address_ES: "Robert Bosch Espana S.L.U.,Calle de los Hermanos Garcia Noblejas 19,28037 Madrid, Spain",
	ConfirmItsMeModal_CompanyDetails_Name: "Nombre de la empresa",
	ConfirmItsMeModal_CompanyDetails_Address: "Dirección",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Código postal de la empresa",
	ConfirmItsMeModal_CompanyDetails_Phone: "Teléfono",
	ConfirmItsMeModal_CompanyDetails_Email: "Correo electrónico",
	CourseWithdrawalModal_WithdrawSuccessMessage: "¡Cancelación de la asistencia al curso exitosa!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Creación de cuenta fallida. ¡La cuenta ya existe!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Actualización  de cuenta fallida. ¡La cuenta ya existe!",
	Footer_ExploreTrainingStories: "Explora todas nuestras historias de formación",
	Breadcrumb_Home: "Inicio",
	Breadcrumb_Contact: "Contactar",
	Breadcrumb_Stories: "Historias",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "Un día en la vida de un entrenador",
	Breadcrumb_Stories_BoschTrainingBenefits: "Beneficios de la formación de Bosch",
	Breadcrumb_Stories_TrainingApproach: "Un enfoque moderno de la formación",
	Breadcrumb_Settings: "Ajustes",
	Breadcrumb_ShoppingCart: "Carrito de compra",
	Breadcrumb_Checkout: "Revisar",
	Breadcrumb_OrderSummary: "Resumen del pedido",
	Breadcrumb_Exception: "Excepción",
	Breadcrumb_Dashboard: "Panel de control",
	Breadcrumb_MyCourses: "Mis Cursos",
	Breadcrumb_MyELearning: "Mi eLearning",
	Breadcrumb_CompanyAdministration: "Administración de la Empresa",
	Breadcrumb_EmployeeManagement: "Dirección de Empleados",
	Breadcrumb_EmployeeAccount: "Cuenta de empleado",
	Breadcrumb_EmployeeCreation: "Creación de empleados",
	Breadcrumb_Administration: "Administración",
	Breadcrumb_EmployeeAdministration: "Administración de Empleados",
	Breadcrumb_WholesalerManagement: "Gestión de distribuidores",
	Breadcrumb_NewWholesalerAccountCreation: "Creación de cuenta de distriibuidor",
	Breadcrumb_Transactions: "Transacciones",
	Breadcrumb_MyAccount: "Mi Cuenta",
	Breadcrumb_TrainingCentres: "Centros de Formación",
	Breadcrumb_EventCalendar: "Calendario de formaciones",
	Breadcrumb_TrainingCourseCatalogue: "Catálogo de cursos de formación",
	Breadcrumb_RecommendedHotels: "Hoteles recomendados",
	Breadcrumb_CorporateInformation: "Información Corporativa",
	Breadcrumb_LegalNotice: "Aviso legal",
	Breadcrumb_DataProtectionNotice: "Aviso de protección de datos",
	Breadcrumb_PrivacyPolicy: "Política de privacidad",
	Breadcrumb_TermCondition: "Términos y condiciónes",
	Breadcrumb_Terms: "Términos",
	Breadcrumb_PrivacyStatement: "Declaración de privacidad",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Proveedor",
	Breadcrumb_RegisterAccount: "Registrar cuenta",
	Stories_ExploreMore: "Saber más",
	CheckOutLayout_ShoppingCartHeader: "Carrito de compra",
	CheckOutLayout_AddedItemCartMessage: "Has añadido lo siguiente a tu carrito",
	CheckOutLayout_CheckYourCartMessage: "Consultar carrito",
	ModernTrainingApproach_DateText: "1102021",
	ADayInLifeofATrainer_DateText: "1102021",
	BoschTrainingBenefits_DateText: "1102021",
	BoschServiceTraining_DateText: "1102021",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Buscar empleados",
	CheckOutLayout_OrderWasNotPlaced_Message: "¡Lo siento! Su pedido no fue realizado",
	CheckOutLayout_RefusalReason: "Motivo de rechazo",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Pago fallido. Por favor reintentar con un método de pago diferente",
	CheckOutLayout_SubmittingApiFailed_Message: "Fallo al enviar API",
	CartItem_ChooseWorkshop_Message: "Por favor elija taller para elegir participante",
	CartItem_ChooseParticipant_Message: "Por favor elija participante para continuar",
	CompanyAdministrationTable_SearchBox_Placeholder: "Búsqueda de talleres",
	CartItem_RemoveCartItem_ConfirmText: "¿Quieres quitar este artículo del carrito?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Función",
	AddToCartSuccessModal_Message: "El carrito de compras ha sido actualizado",
	AddToCartSuccessModal_ContinueButtonText: "Continuar comprando",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Cesta de la compra",
	AddVoucher_Header: "Confirmar",
	AddVoucher_InputPlaceholder: "Introduzca el código y confirme",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Desafortunadamente, no tenemos eventos programados de este curso de formación en este momento. Regístrese en la lista de espera y le informaremos una vez que haya un evento disponible.",
	ADYEN_ERR_2_: "La transacción fue rechazada.",
	ADYEN_ERR_3_: "Error de referencias",
	ADYEN_ERR_4_: "La transacción no se llevó a cabo debido a un error que ocurrió por parte del adquirente.",
	ADYEN_ERR_5_: "La tarjeta utilizada para la transacción está bloqueada, por lo tanto, inutilizable.",
	ADYEN_ERR_6_: "La tarjeta utilizada para la transacción ha caducado. Por tanto, es inutilizable.",
	ADYEN_ERR_7_: "Se produjo una discrepancia en la cantidad durante el proceso de transacción.",
	ADYEN_ERR_8_: "El número de tarjeta especificado es incorrecto o inválido.",
	ADYEN_ERR_9_: "No es posible ponerse en contacto con el banco del comprador para autorizar la transacción.",
	ADYEN_ERR_10_: "El banco del comprador no admite o no permite este tipo de transacción.",
	ADYEN_ERR_11_: "La autenticación 3D Secure no se ejecutó o no se ejecutó correctamente.",
	ADYEN_ERR_12_: "La tarjeta no tiene suficiente dinero para cubrir el importe a pagar.",
	ADYEN_ERR_14_: "Posible fraude.",
	ADYEN_ERR_15_: "La transacción fue cancelada.",
	ADYEN_ERR_16_: "El comprador canceló la transacción antes de completarla.",
	ADYEN_ERR_17_: "El PIN especificado es incorrecto o no es válido.",
	ADYEN_ERR_18_: "El comprador especificó un PIN incorrecto más de tres veces seguidas.",
	ADYEN_ERR_19_: "No es posible validar el número PIN especificado.",
	ADYEN_ERR_20_: "Posible fraude.",
	ADYEN_ERR_21_: "La transacción no se envió correctamente para su procesamiento.",
	ADYEN_ERR_22_: "La verificación de riesgo marcó la transacción como fraudulenta (puntuación de riesgo> = 100); por lo tanto, la operación se cancela.",
	ADYEN_ERR_23_: "Los siguientes códigos rechazados se asignan a este motivo de rechazo: Transacción no permitida al emisor / titular de la tarjeta",
	ADYEN_ERR_24_: "El CVC (código de seguridad de la tarjeta) especificado no es válido.",
	ADYEN_ERR_25_: "Los siguientes códigos de rechazo se asignan a este motivo de rechazo: Tarjeta no válida en este país",
	ADYEN_ERR_26_: "R1: Revocación de la orden de autorización / \"R3: Revocación de todas las órdenes de autorización\" / \"R0: Orden de suspensión de pago\"",
	ADYEN_ERR_27_: "Esta respuesta asigna todos los códigos de respuesta que no se pueden asignar de manera confiable. Esto hace que sea más fácil distinguir los rechazos genéricos (por ejemplo, la respuesta \"05: No respetar\" de Mastercard) de los más específicos.",
	ADYEN_ERR_28_: "Se superó el importe a retirar permitido para la tarjeta del comprador.",
	ADYEN_ERR_29_: "Se superó el número de retiros permitidos para la tarjeta del comprador.",
	ADYEN_ERR_31_: "El emisor informó la transacción como presunta fraude.",
	ADYEN_ERR_32_: "Los datos de la dirección que indico el comprador son incorrectos.",
	ADYEN_ERR_33_: "El banco del comprador requiere que el comprador ingrese un PIN en línea.",
	ADYEN_ERR_34_: "El banco del comprador requiere una cuenta corriente para completar la compra.",
	ADYEN_ERR_35_: "El banco del comprador requiere una cuenta de ahorros para completar la compra.",
	ADYEN_ERR_36_: "El banco del comprador requiere que el comprador ingrese un PIN móvil.",
	ADYEN_ERR_37_: "El comprador abandonó la transacción después de intentar un pago sin exito y se le solicitó que probara un método de entrada de tarjeta diferente (PIN o deslizamiento).",
	ADYEN_ERR_38_: "El emisor rechazó la solicitud de exención de autenticación y requiere autenticación para la transacción. Vuelva a intentarlo con 3D Secure.",
	ADYEN_ERR_39_: "El emisor o el sistema no pudieron comunicar el resultado a través de RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "El nombre no debe incluir carácteres especiales (@#$&!...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "El nombre debe tener menos de 75 caracteres.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "El apellido no debe incluir carácteres especiales (@#$&!...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "El apellido debe tener menos de 75 caracteres.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "El número de teléfono móvil no debe incluir carácteres especiales (@#$&!...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "El número de teléfono móvil debe tener menos de 45 dígitos.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "El número de teléfono no debe incluir carácteres especiales (@#$&!...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "El número de teléfono debe tener menos de 45 dígitos.",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "El correo electrónico es inválido",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "El correo electrónico debe tener menos de 100 caracteres",
	ConfirmGeolocationModal_ConfirmButton: "Continuar",
	ConfirmGeolocationModal_CancelButton: "Cambiar",
	ConfirmGeolocationModal_CurrentCountrySelection: "Sua seleção de país atual é:",
	ConfirmGeolocationModal_AskChangeCountry: "¿Desea permanecer en este sitio o cambiar su preferencia de país?",
	Addtocart_Modal_AddToCartFailTitle: "Error al agregar participante",
	Addtocart_Modal_AlreadyInCart: "Participante ya registrado en este Evento",
	TrainingCatalogue_DateRange: "Intervalo de fechas",
	TrainingCatalogue_DateRange_placeholder: "Seleccione el rango de fechas del filtro",
	Dashboard_MyPendingCourses: "Mis cursos pendientes",
	Dashboard_MyWaitingList: "Mi lista de espera",
	Dashboard_LatestNews: "Últimas noticias",
	SettingsMainPage_EditAccountButton: "Editar cuenta",
	SettingsMainPage_EditBoschId: "Editar Bosch ID",
	EventInfoView_EventDetailTab: "Detalles evento",
	EventInfoView_AssignmentsTab: "Asignaciones",
	EventInfoView_EvaluationTab: "Evaluación",
	EventInfoView_AttachmentTab: "Anexos",
	EventInfoView_CourseDescriptionTab: "Descripción",
	EventInfoView_DescriptionTab_General: "General",
	EventInfoView_DescriptionTab_PartLabel: "Sección / Parte",
	AssignmentTab_Description: "Aquí encontrará toda la información que necesita para completar este curso",
	AssignmentTab_Columns_Download: "Descargar",
	AssignmentTab_Columns_Upload: "Cargar",
	AssignmentTab_Columns_TaskComplete: "Tarea completada",
	EventDetailTab_Location_Message_Video: "Este es un evento online que se puede acceder mediante el enlace \"Lanzar Video\".",
	EventDetailTab_Location_Message_WebBased: "Este es un evento en línea que se puede acceder mediante el enlace \"Lanzar curso online\".",
	EventDetailTab_Location_Message_WebCast: "Este es un evento en línea que se puede acceder mediante el enlace \"Únete ahora\".",
	EventDetailTab_Link_Video: "Video de lanzamiento",
	EventDetailTab_Link_Video_Remark: "*Al iniciar el vídeo se abrirá una nueva ventana. Será redirigido a esta página una vez que la ventana se cierre.",
	EventDetailTab_Link_WebBased: "Lanzar WBT",
	EventDetailTab_Link_WebBased_Remark: "*El lanzamiento del WBT abrirá una nueva ventana. Será redirigido a esta página una vez que la ventana esté cerrada.",
	EventDetailTab_Link_WebCast: "Unirse ahora",
	EventDetailTab_Link_WebCast_Remark: "*Unirse ahora enlace estará activo 30 minutos antes del inicio del evento.",
	EventDetailTab_Title_Status: "Estado",
	EventDetailTab_Title_Duration: "Duración (horas)",
	EventDetailTab_Title_Trainers: "Formador(es)",
	EventDetailTab_Title_Language: "Lengua",
	EventDetailTab_Title_Score: "Puntuación (%)",
	EventDetailTab_Title_Calendar: "Añadir al calendario",
	EventDetailTab_Title_Certificate: "Descargar certificado",
	EventDetailTab_Title_Messages: "Mensajes",
	EventDetailTab_Title_StartDate: "Fecha de inicio",
	EventDetailTab_Title_AvailableFrom: "Disponible en",
	EventDetailTab_Title_Time: "Hora",
	EventDetailTab_Title_EndDate: "Fecha de finalización",
	EventDetailTab_Title_ExpiresOn: "Expira el",
	EventDetailTab_Title_Room: "Aula",
	EventDetailTab_Title_Method: "Método",
	EventDetailTab_Title_Link: "Enlace*",
	EventDetailTab_Title_Location: "Ubicación",
	AttachmentTab_EmptyScreenDescription: "No hay adjuntos para este evento.",
	AttachmentTab_Columns_Download: "Descargas",
	AttachmentTab_Description: "Aquí encontrarás información importante para ayudarte con este curso",
	EvaluationTab_Annotation: "Valoramos tus comentarios y nos encantaría saber de ti para ver cómo nos está yendo. Por favor, tómate el tiempo para completar la siguiente evaluación para ayudarnos a mejorar continuamente nuestro servicio. Gracias.",
	EvaluationTab_Notice: "Las calificaciones son las siguientes",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Por favor, responda todas las preguntas obligatorias antes de enviar su evaluación del evento",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Sus comentarios han sido enviados con éxito",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Fallo en la presentación de la evaluación",
	TrainingCatalogueLayout_ExportButtonText: "Exportar",
	EvaluationTab_FeedbackHasBeenGiven_Message: "La evaluación se completó o ha expirado.",
	EventDetailTab_Link_CopiedTooltip: "Copiado al portapapeles",
	EventDetailTab_QRCode_ExplanationText_WBT: "Escanear para lanzar el WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Escanear para iniciar el vídeo",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Análisis para iniciar la transmisión web",
	Footer_TrainingCoure_Headline: "Cursos de formación impartidos por Bosch",
	Footer_TrainingCourse_Content: "Como una marca de renombre mundial y con más de un siglo de experiencia en innovación, Bosch ha establecido los estándares a nivel mundial como proveedor de sistemas de automoción, piezas y soluciones técnicas. Gran parte de nuestra fuerza proviene de nuestra estrecha colaboración con los fabricantes de vehículos, para quienes diseñamos, desarrollamos y producimos sistemas y componentes de última generación en todo el mundo. En consecuencia, Bosch ha consolidado una sólida historia en productos de posventa de automóviles y soporte con una amplia cartera de piezas, soluciones de diagnóstico y servicios de taller. El aprendizaje y el desarrollo son claves para el éxito y esta filosofía se basa en una amplia gama de cursos de formación, en todas las áreas de la tecnología automotriz, los sistemas de vehículos y la experiencia de diagnóstico.",
	SearchField_NoRecordsFound: "No se encontraron registros",
	TrainingCatalogue_FreeTextSearch: "Texto Libre",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Buscar",
	CheckoutLayout_WholeSalerGroup: "Elija un distribuidor",
	CreditInsufficientModal_Message: "No tiene crédito suficiente para utilizar este método de pago. Por favor, inténtelo de nuevo con otro método de pago.",
	ShoppingCart_Required_Credits: "Precio total",
	MaintainingInfo_Message1: "El sitio web está en mantenimiento.",
	MaintainingInfo_Message2: "Volveremos pronto.",
	CountryValidationModal_Message: "No has podido entrar en [CountryA] porque tu cuenta ha sido creada en [CountryB].",
	CountryValidationModal_ButtonText: "Continuar con [Country]",
	EventTable_LoginRequired: "Inicie sesión para registrarse",
	TrainingPartTableLayout_Date: "Fecha",
	TrainingPartTableLayout_StartTime: "Hora de inicio",
	TrainingPartTableLayout_EndTime: "Hora de finalización",
	TrainingPartTableLayout_Duration: "Duración (horas)",
	TrainingPartTableLayout_TrainingMethod: "Método de formación",
	MyCoursesTableContents_ActiveHeader: "Cursos activos",
	MyCoursesTableContents_ActiveTabHeader: "Activo",
	MyCoursesTableContents_ScheduledHeader: "Cursos programados",
	MyCoursesTableContents_ScheduledTabHeader: "Programados",
	MyCoursesTableContents_OnlineHeader: "Cursos online",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Cursos cancelados",
	MyCoursesTableContents_CancelledTabHeader: "Cancelado",
	OnlineCoursesTable_Title_Progress: "Progreso",
	ScheduledCoursesTable_Title_Date: "Fecha (días)",
	CoursesTable_Title_Completed: "Completado",
	Footer_TrainingProgram: "Programas de formación",
	Breadcrumb_TrainingProgram: "Programas de formación",
	TrainingProgram_Title_Table: "Título",
	TrainingProgram_TrainingProgram: "Programas de formación",
	TrainingProgram_TargetGroup: "Grupo objetivo",
	TrainingProgram_MaxLength: "Max. Duración",
	TrainingProgram_Information: "Información",
	TrainingProgram_Detail: "Detalles",
	TrainingProgram_Modules: "Módulos",
	TrainingProgram_Course: "Cursos",
	TrainingProgram_PartNumber: "Número de parte",
	TrainingProgram_Max_Length: "Duración máxima",
	TrainingProgram_Duration: "Duración",
	TrainingProgram_Exam: "Examen",
	TrainingProgram_Assign_Popup: "Inscripción en el programa de formación",
	TrainingProgram_Student: "Estudiante",
	TrainingProgram_Assign: "Asignar",
	TrainingProgram_Popup_Remove_Title: "Cancelación de la formación",
	TrainingProgram_Description: "Descripción",
	TrainingProgram_Days: "Días",
	TrainingProgram_Type: "Tipo",
	TrainingProgram_Action: "Acción",
	TrainingProgram_Enrolled: "Ya ha sido inscrito en este programa de formación",
	TrainingProgram_SelfAssign_Enrolled: "Ya está asignado a este programa de formación",
	TrainingProgram_Warning: "Por favor, inscriba a otro estudiante o seleccione otro programa de formación",
	TrainingProgram_Workshop: "Taller",
	TrainingProgram_Durations: "Años",
	TrainingProgram_Duration_DaysLabel: "Días",
	TrainingProgram_HasExam_Yes: "Sí",
	TrainingProgram_HasExam_No: "No",
	AssignModal_SuccessModal_Header: "¡Enhorabuena!",
	AssignModal_SuccessModal_Message: "Se ha inscrito con éxito en este programa de formación.",
	AssignModal_SelfAssign_SuccessModal_Message: "Ha sido asignado con éxito al programa de formación.",
	Dashboard_MyTraningProgram: "Mi programa de formación",
	Dashboard_MyTraningProgram_Title: "Título",
	Dashboard_MyTraningProgram_Status: "Estado",
	Dashboard_MyTraningProgram_Table_View: "Ver",
	Dashboard_MyTraningProgram_Register: "Registro",
	Dashboard_MyTraningProgram_Records: "Registros históricos",
	Dashboard_MyTrainingProgram_In_Progress: "En curso",
	Dashboard_MyTrainingProgram_Accredited: "Acreditado",
	Dashboard_Program_Modal_Withdraw: "Retirado",
	Dashboard_Program_Modal_Grade: "Grado",
	Dashboard_MyTrainingProgram_Registered: "Registrado",
	Dashboard_MyTrainingProgram_Completedd: "Completado",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Sí",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "No",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Por favor, tenga en cuenta que retirarse significa que se retirará del programa de formación completo, no sólo de un curso de formación individual.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "¿Aún desea retirarse del programa de formación?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Pendiente",
	WithdrawProgramModal_Withdraw_Success_Message: "¡Se ha retirado con éxito del programa de formación!",
	Common_Warning_Title: "¡Advertencia!",
	Common_Error_Title: "Ups, algo ha salido mal",
	AddEventFromCatalogToCartModal_AddToCartButton: "Añadir a la cesta",
	AddEventFromCatalogToCartModal_HoursText: "Horas",
	AddElearningToCartSuccessModal_Message: "Se ha añadido a su cesta de la compra",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "El participante seleccionado se ha inscrito en este curso, o el curso ya se ha añadido a la cesta.",
	EventDetailTab_SubjectForChange_Message: "Asunto a cambiar",
	Dashboard_MyTrainingProgram_Status_Failed: "Fallado",
	Dashboard_MyTrainingProgram_Status_Passed: "Aprobado",
	Dashboard_MyTrainingProgram_Status_Merit: "Mérito",
	Dashboard_MyTrainingProgram_Status_Distinction: "Distinción",
	Dashboard_Employee_Courses: "Cursos",
	Dashboard_Employee_Program: "Programa de formación",
	Dashboard_Employee_WaitList: "Lista de espera",
	Dashboard_Employee_Account: "Cuenta",
	Course_Price_Free: "Gratis",
	Dashboard_Employee_Account_Update_Success_Message: "Ha actualizado con éxito la información de sus empleados",
	Dashboard_Supplier: "Proveedor",
	Event_Detail_Comment: "Actualmente no hay mensajes",
	DownLoad_PDF: "Descargar PDF",
	TrainingCatalogue_Currency: "Divisas",
	My_Wallet: "Mi cartera",
	Wallet: "Cartera",
	Credits_Balance: "Saldo de créditos",
	MyFullCalendar_Today: "Hoy",
	Header_Available_Languages: "Idiomas disponibles",
	Header_CurrentRegion: "Actualmente se encuentra en",
	Header_ChangeRegions: "¿Quieres cambiar el país?",
	Header_Anonymous: "Servicio de formación",
	Header_Anonymous_Login: "Iniciar sesión/Registrarse",
	Header_RegisterCourse: "Registrar curso",
	Homepage_Start: "Empezar",
	Homepage_Login: "Login",
	MainPage_Title: "La mejor solución de taller",
	MainPage_Content: "Tu taller, tu elección.",
	MainPage_ImageTitle: "Mobility Aftermarket \n Soluciones de formación",
	MainPage_IntroContent: "Permite al taller moderno trabajar de forma profesional, fiable y eficiente dentro de los procedimientos de diagnóstico, solución de problemas, reparaciones y mantenimiento de todo tipo de vehículos.",
	BoschNews_TrainingNews: "Training News",
	Service_Assist_Workshop: "Mejore su experiencia con Workshop Service Assist",
	Enhance_Title_CDM3_v2: "¿Qué puedo hacer con la aplicación?",
	Enhance_Body_CDM3_Item_1: "Reserve cursos y mantenga su viaje de aprendizaje",
	Enhance_Body_CDM3_Item_2: "Descargar material de formación y certificados de cursos",
	Enhance_Body_CDM3_Item_3: "Lanzamiento de cursos de eLearning",
	Enhance_Body_CDM3_Item_4: "Acceda a varios servicios de automoción dentro de la aplicación móvil",
	Enhance_Body_CDM3_Item_5: "Transmisión de vídeo en directo con Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Herramienta gratuita para el escaneo automático de vehículos",
	Training_Stories: "Historias de formación",
	SettingsMyAccount_JoinCompanyButton: "Unirse a la empresa",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Unirse a la empresa",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Por favor, introduzca su código de activación a continuación",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Nota! Debe ponerse en contacto con el administrador de su empresa para obtener un código de activación.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Su activación se ha procesado correctamente y su cuenta ahora está vinculada a su empresa.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Haga clic en Continuar para entrar en el sitio web.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line3: "Continuar",
	ContinueButton: "Continuar",
	WelcomeBatsModal_Title: "Su cuenta está actualmente en revisión!",
	WelcomeBatsModal_ActivationLink: "¡Haga clic aquí si tiene un código de activación para unirse a una empresa!",
	WelcomeBatsModal_ActivationLinkNotes: "Nota! Necesita ponerse en contacto con el administrador de su empresa para obtener un código de activación.",
	AccountInReviewModal_Description_Paragraph1: "Gracias por registrar una cuenta personal en Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Nuestro personal de formación dedicado actualmente está revisando y configurando su cuenta. Recibirás la confirmación final por correo, una vez que tu cuenta haya sido configurada correctamente.",
	AccountInReviewModal_CloseButton: "¡Está bien, déjame entrar!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "No pudimos validar su código de activación ni su dirección de correo electrónico.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Por favor, inténtelo de nuevo o póngase en contacto con el administrador de su empresa para obtener ayuda.",
	TryAgain_Button: "Probar de nuevo",
	Error_Title: "¡Vaya, algo salió mal!",
	SettingsMyProfileActivationSuccessModal_Title: "Éxito!",
	AccountInReviewModal_Description_Paragraph3: "Mientras tanto, no dude en navegar por nuestro sitio web con acceso limitado.",
	AccountInReviewModal_Description_Paragraph4: "Si tiene alguna pregunta, o si su cuenta no se procesa dentro de 48 horas excluyendo fines de semana y días festivos, puede ponerse en contacto con nosotros [aquí]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Un poco de orientación para ayudarle...",
	Support_FAQ_Headline: "Preguntas frecuentes",
	Support_FAQs_section1_title: "General",
	Support_FAQs_section1_question1: "¿Qué es Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions es una plataforma que proporciona una visión general completa de los cursos técnicos y permite reservar y gestionar los cursos.",
	Support_FAQs_section1_question2: "¿Puedo usar mi cuenta existente de Bosch (singleKey ID) para iniciar sesión en Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Sí, es accesible con todas las cuentas creadas con \"Mi ID de Bosch\".",
	Support_FAQs_section1_question3: "¿Puedo usar una cuenta creada para Bosch Training Solutions con otros servicios o aplicaciones de Bosch?",
	Support_FAQs_section1_question3_answer1: "Sí, puedes usar tu ID personal de Bosch también para una variedad de otras aplicaciones de Bosch - tanto profesionales como privadas, como e-bike o smart home.",
	Support_FAQs_section2_title: "Formación",
	Support_FAQs_section2_question1: "¿Cómo puedo reservar un curso?",
	Support_FAQs_section2_question1_answer1: "Inicie sesión con su cuenta existente O registre una cuenta en el portal. Ir a \"Servicios de formación\" O \"Calendario de eventos\" seleccione una formación y haga clic en el botón Añadir a la tarjeta.",
	Support_FAQs_section2_question2: "No hay una fecha de formación adecuada disponible. ¿Qué voy a hacer?",
	Support_FAQs_section2_question2_answer1: "Usted tiene la posibilidad de ponerse en una lista de espera \"general\" (sin seleccionar una fecha) y nuestro equipo le informará tan pronto como esté disponible un nuevo curso.",
	Support_FAQs_section2_question3: "¿Dónde puedo encontrar un resumen de mis cursos reservados?",
	Support_FAQs_section2_question3_answer1: "Puede encontrar una descripción completa de todos los cursos reservados y completados después de iniciar sesión en su panel de control.",
	Support_FAQs_section3_title: "Ajustes",
	Support_FAQs_section3_question1: "¿Cómo puedo cambiar mi correo electrónico y contraseña?",
	Support_FAQs_section3_question1_answer1: "Necesita iniciar sesión en el portal y cambiar a su perfil personal. (Dentro de su panel de control puede encontrar la información de su perfil o si hace clic en el siguiente botón en el encabezado )",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Ahí tienes la posibilidad de cambiar los detalles de tu ID de Bosch",
	Support_FAQs_section3_question2: "¿Qué puedo hacer cuando olvido mi contraseña?",
	Support_FAQs_section3_question2_answer1: "Por favor, utilice el botón de inicio de sesión/ registro y rellene su dirección de correo electrónico",
	Support_FAQs_section3_question2_answer2: "Si hace clic en continuar tiene la posibilidad de hacer clic en \"Olvidé la contraseña\"",
	Support_FAQs_section3_question3: "¿Cómo configurar una cuenta de taller?",
	Support_FAQs_section3_question3_answer1: "Si desea obtener un puesto de taller, póngase en contacto con su Administración de Formación local, p. ej., puede utilizar el formulario de contacto en el sitio web.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Elija su país / región",
	MaintenanceBanner_Message_1: "El portal Bosch Automotive Training Solutions se actualizará en [maintenance_schedule]. Se espera que la actualización dure 30 minutos. El Portal no estará disponible durante la actualización.",
	MaintenanceBanner_Message_2: "nPara más asistencia, póngase en contacto con nosotros por correo electrónico [support_email]",
	MaintenancePage_Message_1: "El portal de Bosch Automotive Training Solutions se está actualizando",
	MaintenancePage_Message_2: "Esperamos que el Portal esté disponible de nuevo en [maintenance_online_date] en [maintenance_online_time]",
	MenuHeader_Support: "Soporte",
	Course_Detail_No_Prerequisites: "No hay requisitos previos",
	TableCell_Hours: "Horas",
	Dashboard_MenuItem_Dashboard: "Panel de control",
	Dashboard_MenuItem_Curricula: "Certificaciones",
	Dashboard_MenuItem_Certificates: "Certificados",
	Dashboard_MenuItem_Team: "Equipo",
	Dashboard_MenuItem_Workshops: "Talleres",
	Dashboard_MenuItem_External: "Externo",
	Dashboard_MenuItem_Wallet: "Cartera",
	Dashboard_MenuItem_Profile: "Perfil",
	Dashboard_Headline_YourUpcomingCourses: "Próximos cursos",
	Dashboard_Headline_YourELearningCourses: "Tus cursos de eLearning",
	Dashboard_Headline_TeamOverview: "Visión general del equipo",
	Dashboard_Headline_YourOverview: "Visión general",
	Dashboard_Headline_YourWishlist: "Lista de deseos",
	Dashboard_Headline_ActivePrograms: "Programas activos",
	Dashboard_Headline_CompletedPrograms: "Programas terminados",
	Dashboard_Headline_YourCertificates: "Certificados",
	Dashboard_Headline_Transactions: "Transacciones",
	Dashboard_Table_NoRecordsToDisplay: "No hay registro para mostrar",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "Horas en línea",
	Dashboard_ELearningCourses_TableHeader_Title: "Título",
	Dashboard_ELearningCourses_TableHeader_Duration: "Duración (horas)",
	Dashboard_ELearningCourses_ActionButton_View: "Vista",
	Dashboard_UpcomingCourses_TableHeader_Title: "Título",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Fecha [Días]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Ubicación",
	Dashboard_UpcomingCourses_ActionButton_View: "Vista",
	Dashboard_YourOverview_TableHeader_Title: "Título",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Fecha / Duración",
	Dashboard_YourOverview_TableHeader_Location: "Ubicación",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Vista",
	Dashboard_YourWishlist_TableHeader_Title: "Título",
	Dashboard_YourWishlist_TableHeader_Action: "Acción",
	Dashboard_YourWishlist_ViewAllRecords: "Ver todos los registros",
	Dashboard_TeamOverview_TableHeader_Employee: "Empleada",
	Dashboard_TeamOverview_TableHeader_Title: "Título",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Fecha / Duración",
	Dashboard_TeamOverview_TableHeader_Location: "Ubicación",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Vista",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Buscar",
	Dashboard_ActiveProgram_TableHeader_Title: "Título",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Vista",
	Dashboard_CompletedProgram_TableHeader_Title: "Título",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Vista",
	Dashboard_Certificates_TableHeader_Title: "Título",
	Dashboard_Certificates_TableHeader_Date: "Fechar",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Acción",
	Dashboard_Certificates_ActionButton_Download: "Descargas",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Número de factura",
	Dashboard_Transactions_TableHeader_Description: "Descripción",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Forma de pago",
	Dashboard_Transactions_TableHeader_Date: "Fechar",
	Dashboard_Transactions_TableHeader_Amount: "Importe",
	Dashboard_Transactions_ActionButton_Download: "Descargas",
	Breadcrumb_MyTraining: "MyTraining",
	Breadcrumb_MyTraining_CourseDetails: "Detalles del curso",
	Breadcrumb_MyTraining_Curricula: "Certificaciones",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Detalles del programa de formación",
	Breadcrumb_MyTraining_Team: "Equipo",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Ver empleado",
	MyTraining_Headerbar_CourseDetails: "Course Details",
	MyTraining_Headerbar_TrainingProgramDetails: "Detalles del Programa de formación",
	TrainingProgramDetails_Label_Status: "Status",
	TrainingProgramDetails_Label_OverallProgress: "Progreso general",
	TrainingProgramDetails_ProgramItems_Headline: "Elementos del programa",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Título",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Grado",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Fechar",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Vista",
	TrainingProgramDetails_Status_InProgress: "En curso",
	TrainingProgramDetails_Status_Completed: "Terminado",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Duración",
	CourseDetails_Label_Trainer: "Entrenador",
	CourseDetails_Label_Language: "Lengua",
	CourseDetails_Label_Score: "Puntuación:",
	CourseDetails_Label_StartDate: "Fecha de inicio",
	CourseDetails_Label_EndDate: "Fecha de finalización",
	CourseDetails_Label_Venue: "Lugar de celebración",
	CourseDetails_Label_Address: "Dirección",
	CourseDetails_Label_Grade: "Grado",
	CourseDetails_Action_Withdraw: "Retirarse",
	CourseDetails_Action_Evaluate: "Evaluar curso",
	CourseDetails_Action_Evaluate_Description: "¡Por favor evalúe este curso para generar su certificado!",
	CourseDetails_Action_DownloadCertificate: "Descargar certificado",
	CourseDetails_Action_JoinWebcast: "Unirse a Webcast",
	CourseDetails_Action_StartCourse: "Inicio Curso",
	CourseDetails_Action_RestartCourse: "Reiniciar curso",
	CourseDetails_Action_RepeatCourse_Description: "¡Repita y pase este curso para descargar su certificado!",
	CourseDetails_PartSpecificDetails_Headline: "Detalles específicos de la parte",
	CourseDetails_PartSpecificDetails_Tab_Part: "Parte",
	CourseDetails_PartSpecificDetails_Description: "Descripción",
	CourseDetails_PartSpecificDetails_StartDate: "Fecha de inicio",
	CourseDetails_PartSpecificDetails_EndDate: "Fecha de finalización",
	CourseDetails_PartSpecificDetails_StartTime: "Hora de inicio",
	CourseDetails_PartSpecificDetails_EndTime: "Hora final",
	CourseDetails_PartSpecificDetails_Method: "Método",
	Dashboard_Team_TableHeader_Name: "Nombre",
	Dashboard_Team_TableHeader_Role: "Papel",
	Dashboard_Team_TableHeader_Email: "Correo electrónico",
	Dashboard_Team_TableHeader_PersonalAccount: "Cuenta personal",
	Dashboard_Team_ActionButton_Select: "Seleccionar",
	Dashboard_Team_PersonalAccount_Activated: "Activado",
	Dashboard_Team_PersonalAccount_NotActivated: "No activado",
	Dashboard_Team_ActionButton_AddMember: "Añadir miembro",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Buscar",
	Dashboard_External_TableHeader_Name: "Nombre",
	Dashboard_External_ActionButton_AddNew: "Añadir nuevo",
	Dashboard_External_Headline: "Acceso externo aprobado",
	Dashboard_Workshops_Headline: "Talleres",
	Dashboard_Workshops_TableHeader_Company: "Empresa",
	Dashboard_Workshops_TableHeader_Address: "Dirección",
	Dashboard_Workshops_TableHeader_Postcode: "Código postal",
	Dashboard_Workshops_TableHeader_City: "Ciudad",
	Dashboard_Workshops_ActionButton_Select: "Seleccionar",
	Wallet_ServiceCredits: "Service Credits",
	Wallet_Headline_CreditBalance: "Saldo acreedor",
	Wallet_Headline_CreditActivity: "Actividad crediticia",
	Wallet_Headline_BillingTransactions: "Transacciones de facturación",
	Wallet_BillingTransaction_TableHeader_Order: "Orden",
	Wallet_BillingTransaction_TableHeader_Date: "Fechar",
	Wallet_BillingTransaction_TableHeader_Amount: "Importe",
	Wallet_BillingTransaction_TableHeader_Action: "Acción",
	Wallet_CreditActivity_TableHeader_Date: "Fecha",
	Wallet_CreditActivity_TableHeader_Description: "Descripción",
	Wallet_CreditActivity_TableHeader_User: "Usuario",
	Wallet_CreditActivity_TableHeader_Amount: "Cantidad",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, todos los derechos reservados",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Seguridad del producto (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovaciones, patentes y licencias",
	FooterAdditionalLinks_Navigation_Logistics: "Compras y logística",
	MenuHeader_MyTraining: "Mi formación",
	MenuHeader_TrainingServices: "Servicios de formación",
	MenuHeader_OurLocations: "Nuestras ubicaciones",
	MenuHeader_ContactsUs: "Contacta con nosotros",
	MenuHeader_Logout: "Cierre de sesión",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "My Bosch Id",
	MyProfile_AccountDeletion: "Eliminación de la cuenta",
	MyProfile_Edit: "Cambiar correo electrónico o editar contraseña",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Editar perfil de usuario",
	BackButton: "Volver",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Esperamos su llamada y estaremos encantados de ayudarle. Estamos disponibles para usted las 24 horas, los 7 días de la semana.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Introduzca los caracteres correctos, como se indica en el cuadro anterior.",
	ContactUs_Email_MandatoryNotice_V3: "Rellene todos los campos necesarios.",
	ContactUs_Email_SendButton: "Enviar",
	Breadcrumb_TrainingCenters: "Centros de Formación",
	Training_Centres_Book_Btn: "Reservar un curso",
	Training_centres_booking_code: "Código de reserva",
	Training_centers_header_bar_title: "Nuestros centros de formación",
	Training_centers_filter_item: "Centro",
	TeamEdit_PersonalAccountToolTop: "Habilitar esta función permite al empleado acceder a este sitio web. El usuario podrá crear una cuenta personal y formar parte de su cuenta corporativa. Una vez habilitado, puedes enviar una invitación con un código de activación al empleado. La invitación se enviará a la dirección de correo electrónico.",
	TeamEdit_Update: "Habilitar la función de administrador de la empresa proporciona automáticamente a este empleado acceso completo a su cuenta corporativa. Habilitar esta función requiere que el usuario cierre sesión y vuelva al sitio web, si el usuario ya ha iniciado sesión.",
	TeamEdit_PersonalAccount: "Permitir la cuenta personal de Bosch Training Solutions en la web",
	TeamEdit_SendInvitation: "Enviar invitación",
	TeamEdit_Deactivate: "Desactivar",
	TeamEdit_Telephone: "Telefono",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Los móviles deben ser números",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "El teléfono debe ser un número",
	TeamEdit_Activate: "Activo",
	TeamEdit_NotActivated: "Not Activate",
	TeamEdit_StatusPopup: "El estado se mostrará como Active' si el usuario ha creado con éxito una cuenta personal. Si el estado se establece como No activo', significa que el usuario no ha completado la invitación.",
	Training_Catalog_Courses: "Cursos",
	Training_Catalog_Course: "Curso",
	Reset_Filter: "Reset Filter",
	TrainingCatalogue_CourseCatalogue: "Catálogo de cursos",
	CheckoutHeader_CartItemCount_Text: "Usted tiene [count] artículo en su carrito de compras",
	CheckoutHeader_CartItemsCount_Text: "Usted tiene [count] artículos en su carrito de compras",
	ShoppingCart_PriceItem: "Precio del artículo",
	ShoppingCartItemParticipants_AddParticipant_Button: "Añadir participantes",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotal",
	ShoppingCartTotalPrice_TaxesLabel: "Impuestos",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Incluye el IVA de [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Métodos de pago en línea disponibles",
	ShoppingCartItemRow_VenueFeeLabel: "Tasa por lugar de residencia [fee_amount] por participante",
	ShoppingCartItemRow_OnlineLabel: "En línea",
	AddParticipantsModal_Title: "Añadir participante",
	AddParticipantsModal_SelectEmployeeLabel: "Añadir participante",
	AddParticipantsModal_SelectWorkshopLabel: "Select Workshop",
	ShoppingCartTotalPrice_ContinueButtonText: "Continuar con el proceso de pago",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "¡El participante no ha sido añadido!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Tienes uno o más cursos en tu carrito de compras sin un participante.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Por favor, agregue un participante para continuar la compra.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Editar participantes ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nombre",
	AddParticipantsModal_ParticipantTable_Email: "Correo electrónico",
	AddParticipantsModal_ParticipantTable_Action: "Acción",
	AddParticipantsModal_ParticipantTable_NoParticipant: "No hay ningún participante...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Dirección de correo electrónico no configurada en el participante seleccionado",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "El participante seleccionado no tiene una dirección de correo electrónico configurada en su perfil. La dirección de correo electrónico es obligatoria para inscribirse en un curso de formación.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Añadir dirección de correo electrónico para continuar o cancelar para seleccionar otro participante.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Añadir correo electrónico",
	CancelButton: "Cancelar",
	AddParticipantsModal_AddEmployeeButtonText: "Agregar empleado",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Estás a punto de borrar un curso de tu carrito",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Usted está tratando de eliminar un curso con participantes añadidos de su carrito.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Haga clic en \"Continuar\" si desea proceder o haga clic en \"Cancelar\".",
	ShoppingCartItemHeader_Participants: "Participante(s)",
	ShoppingCartItemHeader_ParticipantPrice: "Precio por asiento",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Mostrar en [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Mostrar en Créditos",
	ShoppingCart_CreditsPriceUnit: "Créditos",
	CheckOutHeader_Step1_Question: "¿Cómo le gustaría pagar?",
	CheckOutHeader_Step2_Question: "Revisar su pedido",
	CheckOutHeader_Step3_Question: "Completar su pedido",
	CheckOutHeader_Step4_Question: "¡Tu reserva fue un éxito!",
	CheckOutHeader_Step_Payment: "Pago",
	CheckOutHeader_Step_Review: "Revisión",
	CheckOutHeader_Step_Place_Order: "Tramitar pedido",
	CheckOutRightPanel_Review_Order_Btn: "Revisar pedido",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Tramitar pedido",
	CheckoutRightPanelContent_OrderSummary_Title: "Resumen del pedido",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Editar carrito de compras",
	ShoppingCart_Participants: "Participantes",
	CheckOutStepPayment_Payment_Methods_Title: "Seleccionar método de pago",
	CheckOutStepPayment_Credit_Title: "Crédito o débito",
	CheckOutStepPayment_Wholesaler_Title: "Mayorista",
	CheckOutStepPayment_ServiceCredit_Title: "Service Credits",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Usted tiene [available_credit] Créditos",
	CheckOutStepPayment_BoschAccount_Title: "Cuenta Bosch",
	Checkout_Second_PaymentMethod_Headline: "Forma de pago",
	CheckOutStepReview_Edit: "Editar",
	CheckOutStepReview_Terms_Label: "Términos, condiciones y política de cancelación",
	CheckOutStepReview_Terms_Text: "He leído, entendido y acepto los [términos, condiciones y política de cancelación] de la venta.",
	SelectSecondPaymentMethodModal_Title: "Seleccione el método de pago secundario",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Pago [amount]",
	StepPaymentWholesaler_Title: "Mayorista selecto",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Aceptar los Términos y Condiciones",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Por favor, lea y acepte nuestros términos, condiciones y política de cancelación antes de continuar.",
	CheckoutDescription_ThankYou: "¡Gracias por enviar un pedido a Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Nuestros bits y bytes ya han comenzado a procesar su pedido. Mientras tanto, por favor mantenga el motor en reposo.",
	CheckoutDesctiption_Text: "Le enviaremos una confirmación final por correo con todos los detalles de su reserva.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Estás a punto de eliminar el taller seleccionado",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Al eliminar el taller seleccionado, todos los participantes añadidos se restablecerán. Este cambio afectará a todos los cursos de tu carrito de compras.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Haga clic en \"Continuar\" si desea proceder o haga clic en \"Cancelar\" para detener esta acción.",
	CheckOutStepPayment_Insufficient_Credit_Message: "No tienes suficientes créditos. Su saldo de crédito es [disponible] Créditos, pero necesita [cart_total_credits] Créditos para continuar el proceso de pago. Por favor, seleccione otro método de pago o eliminar artículos de su carrito de compras.",
	Training_centers_filter_items: "Centros",
	Training_Programs_Filter_Item: "Programas",
	Training_Program_Filter_Item: "Programar",
	Training_Program_Year: "Año(s)",
	Training_Program_Detail_Title: "Programas de Cursos de formación",
	AccountButton_Workshops_MenuItem: "Talleres",
	AccountButton_Wallet_MenuItem: "Cartera",
	Header_MyBoschProfile_Header: "MyBosch Perfil",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Disponible",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Añadir/Editar participante",
	ShoppingCart_CreditPriceUnit: "Crédito",
	Common_IncludedVAT: "Incl. IVA",
	TrainingCatalogue_Length: "Longitud",
	TrainingCatalogue_Method: "Método",
	Course_Price_Credits: "Créditos",
	Catalogue_Grid_Register_Button: "Registrarse",
	Catalogue_Credit_Tilte: "Haga clic aquí para ver los precios en Créditos",
	Catalogue_Price_Tilte: "Haga clic aquí para ver los precios en EUR",
	Catalogue_Grid_Vat: "IVA excluido.",
	EventCalendar_Seats: "Asientos",
	MenuHeader_Calendar: "Calendario",
	MenuHeader_Catalog: "Catálogo",
	TableCell_Minutes: "Minutos",
	ListFilter_NoResult: "Ningún resultado",
	Course_Description: "Curso Descripción",
	Training_course_detail: "Social Media Basics",
	Training_course_detail_add_waiting: "Añadir a la lista general de espera",
	Register_now_btn: "Regístrate ahora !",
	Register_now_btn_add_event_to_cart: "Regístrate ahora !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Ver todos los registros",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Ver menos",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Ver todos los registros",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Ver menos",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Ver todos los registros",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Ver menos",
	TeamCreate_TitleError: "Se requiere título",
	Dashboard_Headerbar_BookCourse: "Reservar",
	Dashboard_Headerbar_ExitProxy: "Exit Proxy",
	Dashboard_Headerbar_EvaluateCourse: "Evaluar curso",
	Dashboard_Headerbar_EvaluateCourse_Note: "¡Por favor evalúe este curso para generar su certificado!",
	Dashboard_Headerbar_DownloadCertificate: "Download Certificate",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Retirarse",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Retirarse",
	Dashboard_Headerbar_WelcomeMessage: "Bienvenido a su panel de aprendizaje",
	Dashboard_Headerbar_ProxingAs: "Proxy como",
	Dashboard_TrainingProgram_Status_Completed: "Terminado",
	Dashboard_TrainingProgram_Status_InProgress: "En curso",
	External_SelectWholesalerModal_Title: "Añadir acceso de usuario externo",
	External_SelectWholesalerModal_Company_Label: "Empresa",
	External_SelectWholesalerModal_Outlet_Label: "Tomacorriente",
	External_SelectWholesalerModal_Company_Placeholder: "Por favor seleccione",
	External_SelectWholesalerModal_Outlet_Placeholder: "Por favor seleccione",
	External_SelectWholesalerModal_ApplyButton: "Aplicar",
	External_SelectWholesalerModal_CancelButton: "Cancelar",
	External_RemoveWholesalerModal_ConfirmLabel: "Confirmación",
	External_SelectWholesalerModal_ConfirmMessage: "¿Estás seguro de que quieres quitar este mayorista?",
	External_SelectWholesalerModal_YesButton: "Sí",
	External_SelectWholesalerModal_NoButton: "No",
	Training_program_team_member: "Miembro del equipo",
	Training_program_assign_training_program: "Asignar programa de formación",
	Assign_modal_student_confirmation_text1: "Usted está a punto de asignar [Training Program Name] a su viaje de aprendizaje.",
	Assign_modal_student_confirmation_text2: "¿Te gustaría seguir adelante?",
	MyFullCalendar_Jan: "Jan",
	MyFullCalendar_Feb: "Febrero",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Abr",
	MyFullCalendar_May: "Mayo",
	MyFullCalendar_Jun: "Junio",
	MyFullCalendar_Jul: "Julio",
	MyFullCalendar_Aug: "Agosto",
	MyFullCalendar_Sep: "Septiembre",
	MyFullCalendar_Oct: "Octubre",
	MyFullCalendar_Nov: "Noviembre",
	MyFullCalendar_Dec: "Diciembre",
	MenuHeader_CourseCatalog: "Catálogo de cursos",
	MenuHeader_Event: "Calendario de eventos",
	MenuHeader_Program: "Programa de traning",
	MenuHeader_Services: "Servicios de formación",
	MenuHeader_Log_out: "Cerrar sesión",
	Filter_Location: "Ubicación",
	TeamEdit_UpgradeToCompanyAdministrator: "Actualizar a Administrador de la compañía",
	TeamEdit_PersonalWebAccountStatusIs: "El estado de la cuenta web personal es",
	Header_CreateNewEmployee: "Crear un nuevo empleado",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "¡No hay asientos disponibles!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "No hay asientos disponibles en el evento seleccionado.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Seleccione otro evento o elimine un participante ya agregado para continuar.",
	Common_SuccessModal_Title: "¡Enhorabuena!",
	TrainingProgramDetails_Merit_StatusText: "Mérito",
	TrainingProgramDetails_Resit_StatusText: "volver a sentarse",
	EmployeeProfile_UserExistErrorMessage: "¡La cuenta ya existe!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Pago fallido. Vuelve a intentarlo o utiliza otro método de pago.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "¡Ha desactivado con éxito al empleado seleccionado!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "¡Salvado!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Se han guardado los cambios.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "No es posible retirarse en los cursos de eLearning. Póngase en contacto con nosotros si necesita más detalles.",
	Dashboard_Team_PersonalAccount_Pending: "Pendiente",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "¡Empleado creado!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "La creación de su empleado se ha completado con éxito.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Esta configuración le permite restringir que un empleado se registre en eventos de formación. Solo los administradores de la empresa podrán registrar al empleado en eventos de formación cuando esta configuración no se haya seleccionado.",
	Training_catalog_sort_by: "Ordenar por",
	CourseDetail_EvaluationRestriction_Title: "¡La evaluación de la formación no es posible!",
	CourseDetail_EvaluationRestriction_Message: "Las evaluaciones de la formación sólo pueden ser presentadas por el propio alumno. \nDígale al estudiante que inicie sesión en Bosch Automotive Training Solutions y complete la evaluación de la formación.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Si el estudiante aún no tiene una cuenta, se puede compartir una invitación en la configuración del perfil de usuario del miembro del equipo.",
	Breadcrumb_Download_Page: "Descargar",
	Download_Table_Category_Cell: "Categoría",
	Download_Table_Title_Cell: "Título",
	Download_File_Filter_Item: "Archivo",
	Download_File_Filter_Items: "Archivos",
	Download_Header: "Área de descarga",
	DownloadArea_Button_Download: "Descargar",
	Popup_Title_Error: "Error",
	Popup_Title_Success: "Éxito",
	Popup_Title_Info: "Información",
	Popup_Title_Warning: "¡Advertencia!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Las siguientes inscripciones a posibles eventos ya existentes no se cancelarán y deberán cancelarse individualmente.",
	TrainingProgram_PDF_Export: "Exportación de PDF",
	TrainingProgram_PDF_Export_Participant: "Participante:",
	TrainingProgram_PDF_Export_Status: "Estado:",
	TrainingProgram_PDF_Export_Progress: "Progreso general:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Título de la formación",
	TrainingProgram_PDF_Export_TableHeader_Status: "Estado",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Calificación",
	TrainingProgram_PDF_Export_TableHeader_Date: "Fecha",
	AccountButton_CompanyChange_MenuItem: "Cambio de empresa",
	SelectCompanyModal_Title: "Seleccionar Empresa",
	CloseButton: "Cerca",
	Change_Button: "Cambiar",
	ChangeManagerModal_Content: "Seleccionar usuario",
	ChangeCompanyModal_HeadOffice: "(Oficina central)",
	SelectCompanyModal_Content: "Seleccione Empresa para iniciar sesión...",
	AlertSuccess_Title: "Éxito",
	CheckOutStepPayment_SecondaryPayment_Title: "Seleccionar método de pago secundario",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Agregar método de pago secundario",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Agregue un método de pago secundario a continuación o seleccione otro método de pago arriba.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "No tiene suficientes créditos de servicio en su cuenta para pagar los artículos de su carrito. Para continuar utilizando el método de pago seleccionado, deberás pagar la diferencia. La diferencia es [difference_amount] (sin IVA).",
	ShoppingCartTotalPrice_TotalInCredit: "Precio total en créditos",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Tus créditos disponibles",
	ShoppingCartTotalPrice_YourDifference: "Diferencia",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal excluido impuesto",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal incl. impuesto",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Método de pago secundario",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Editar método de pago",
	Checkout_Second_PaymentMethod: "Segundo método de pago",
	Breadcrumb_TrainingCourseDetails: "Detalles del curso de formación",
	DownloadArea_FailedToDownload_ErrorMessage: "No se pudo descargar el documento. Inténtelo de nuevo o comuníquese con el administrador de su empresa para obtener ayuda.",
	DownloadArea_FileNotAvailable_ErrorMessage: "El archivo de descarga no está disponible. Inténtelo de nuevo o comuníquese con el administrador de su empresa para obtener ayuda.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Prohibida la descarga de archivos. Inténtelo de nuevo o comuníquese con el administrador de su empresa para obtener ayuda.",
	SSO_Redirecting_Message: "Estás redirigiendo a Bosch Training",
	SSO_Redirecting_ParameterErrorMessage: "No se especifica el país y/o el idioma. Inténtelo de nuevo o comuníquese con el administrador de su empresa para obtener ayuda.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Solicitudes de inscripción no aceptadas.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Esto puede deberse a estar dentro del umbral de días de inscripción del curso para uno o más de los cursos. Utilice el área 'Contáctenos' si tiene alguna pregunta o solicitud.",
	TimeSlotPicker_DateFormat_Title: "Formato de fecha",
	UpdateAccountSettingsConfirmationModal_Title: "¡Estás a punto de cambiar el formato de fecha!",
	UpdateAccountSettingsConfirmationModal_Message1: "El formato de fecha se mostrará según su selección y se puede cambiar en cualquier momento.",
	UpdateAccountSettingsConfirmationModal_Message2: "¿Desea continuar?",
	ProfileSummary_Settings_Title: "Ajustes",
	UpdateAccountSettings_DateFormat_Title: "Configuración de formato de fecha",
	UpdateAccountSettings_DateFormat_ErrorMessage: "No se pudo actualizar el formato de fecha.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "La configuración del formato de fecha se ha actualizado correctamente.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Descarga certificado",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Certificado programa de formación",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "No se ha podido descargar el documento. Por favor, inténtelo de nuevo o póngase en contacto con el administrador de su empresa para obtener ayuda.",
	CourseWithdrawalModal_PendingWithdraw_Title: "La cancelación está pendiente de aprobación, ya  ha excedido el umbral de cancelación, y el asistente recibirá más información por correo electrónico, una vez que la solicitud haya sido aprobada/ rechazada.",
	CourseWithdrawalModal_PendingWithdraw_Message1: "El plazo para cancelar su asistencia ha expirado y requiere un procesamiento manual por parte de nuestra administración",
	CourseWithdrawalModal_PendingWithdraw_Message2: "La confirmación final será enviada por email",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Por favor consulte  nuestra [política de cancelación] para tener más detalles.",
	TrainingCourse_Course_NotAvailable_Title: "El curso de formación no está disponible",
	TrainingCourse_Course_NotAvailable_Message: "El curso de formación no está disponible. Por favor, inténtelo de nuevo o póngase en contacto con el administrador de su empresa para obtener ayuda.",
	TrainingCourse_NotAvailable_Message_1: "Usted está accediendo a un curso de formación que no está disponible.",
	TrainingCourse_NotAvailable_Message_2: "Por favor, inténtelo de nuevo o acceda a [cursos de formación] para dicover más cursos de formación.",
	LanguageSelection_SelectALanguage: "Seleccione un idioma",
	LanguageSelection_SelectYourCountry: "Selecciones su país",
	Checkout_PaymentMethod_Headline: "Método de pago",
	Download_Categories_Catalogue: "Catálogo",
	Download_Categories_Calendar: "Calendario",
	Download_Categories_General: "General",
	Download_Categories_Information: "Información",
	Download_Categories_Userguides: "Guías de usuario",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Üpolítica de cancelación",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Cancelar",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Continuar",
	Duration_Seconds: "Segundos",
	Duration_Day: "Día",
	Duration_Hour: "Hora",
	Duration_Minute: "Minuto",
	Duration_Second: "Segundo",
	EventCalendar_ExternalLink_Tooltips: "Compra a través de [externalRegistrationName] n/a",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Cancelar",
	EventCalendar_ExternalLink_Continue: "Continuar",
	EventCalendar_ExternalLinkModal_Title: "Estás a punto de ser redirigido...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "El evento seleccionado se vende a través de [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Continuar le redirigirá a un sitio web externo.",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "¿Quiere seguir adelante?",
	Team_ContactPerson_FirstName_Tooltips: "Los nombres de los estudiantes solo pueden ser modificados por el equipo de administración de formación de Bosch. Por favor, póngase en contacto con nosotros a través del formulario de contacto en este sitio web para obtener más ayuda.",
	Team_ContactPerson_LastName_Tooltips: "Los nombres de los estudiantes solo pueden ser modificados por el equipo de administración de formación de Bosch. Por favor, póngase en contacto con nosotros a través del formulario de contacto en este sitio web para obtener más ayuda.",
	Withdraw_ExternalLink_Cancel: "Cancelar",
	Withdraw_ExternalLink_Confirm: "Confirmar",
	Withdraw_ExternalLinkModal_Title: "Usted está a punto de ser redirigido",
	Withdraw_ExternalLinkModal_Message: "Las cancelaciones para este curso son manejados por un proveedor externo.",
	Withdraw_ExternalLinkModal_Confirmation: "Al hacer clic en \"Confirmar\" se abrirá una nueva pestaña y redirigir a un sitio web externo.",
	Button_AddToWaitingList_Tooltips: "Añadir a la lista de espera",
	Button_AddToCart_Tooltips: "Añadir al carrito",
	Button_TrainingCourseDetail_Tooltips: "Ver detalles",
	Button_TrainingProgramDetail_Tooltips: "Ver detalles",
	Button_AssignTrainingProgram_Tooltips: "Asignar programa de formación",
	Button_DeleteFromCart_Tooltips: "Eliminar del carrito",
	Button_RemoveParticipant_Tooltips: "Cancelar participante",
	Button_DownloadBillingTransaction_Tooltips: "Descargar",
	Button_RemoveExternal_Tooltips: "Quitar",
	Breadcrumb_TrainingServices: "Servicios de Formación",
	Breadcrumb_Support: "Soporte",
	Breadcrumb_FAQs: "Preguntas frecuentes",
	Breadcrumb_TrainingProgramDetail: "Detalle del Programa de Formación",
	Breadcrumb_MyTraining_MyTrainingEvents: "Mis eventos de formación",
	Breadcrumb_MyTraining_External: "Externo",
	Breadcrumb_MyTraining_Wallet: "Cartera",
	Breadcrumb_MyTraining_Workshop: "Taller",
	Breadcrumb_MyTraining_Workshop_Team: "Equipo",
	Breadcrumb_MyTraining_Team_Profile: "Perfil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Editar perfil",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Creación de empleados",
	SideNavigation_Title: "Soluciones de formación",
	SideNavigation_AccountButton_RegisterOrLogin: "Registrarse / Iniciar sesión",
	SideNavigation_Language: "Idioma",
	SideNavigation_SelectLanguage: "Seleccionar idioma",
	SideNavigation_AccountButton_Logout: "Cierre de sesión",
	SideNavigation_ConntactUs: "Contáctenos",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Información corporativa",
	FooterAdditionalLinks_Navigation_LegalNotice: "Notificación legal",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Aviso de protección de datos",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Configuración de privacidad",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Términos y condiciones",
	Common_Version_Text: "Versión: {{versión}}",
	Content_Header_Dashboard_MyTrainingEvents: "Mis eventos de formación",
	Content_Header_Dashboard_MyTrainingPrograms: "Mis programas de formación",
	Content_Header_Dashboard_Team: "Equipo",
	Content_Header_Dashboard_Workshops: "Talleres",
	Content_Header_Dashboard_External: "Externo",
	Content_Header_Dashboard_Wallet: "Cartera",
	Content_Header_Dashboard_MyProfile: "Mi perfil",
	RedeemVoucher_RemoveButton: "Quitar",
	RedeemVoucher_ApplyButton: "Aplicar",
	Checkout_VoucherApplied_Label: "Tu código",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Código de cupón no válido",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "El código de cupón que se aplicó no es válido.",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Por favor, inténtelo de nuevo o continuar sin código de cupón.",
	RedeemVoucherModal_CancelButton: "Cancelar",
	RedeemVoucherModal_TryAgainButton: "Intentarlo de nuevo",
	CreditsConfirmationModal_ContinueButton: "Continuar",
	CreditsConfirmationModal_CancelButton: "Cancelar",
	CreditsConfirmationModal_Title: "Se ha aplicado un cupón",
	CreditsConfirmationModal_Message_1: "El pago con créditos de servicio no es posible cuando se ha aplicado un cupón de descuento a su carrito de compras.",
	CreditsConfirmationModal_Message_2: "Haga clic en 'Cancelar' para volver o haga clic en 'Continuar' para eliminar el cupón de su carrito.",
	TrainingCourseCatalog_PricePerSeat: "Precio por plaza",
	TrainingCourseCatalog_Duration: "Duración:",
	Homepage_Title_BoschTrainingSolutions: "Bosch Training Solutions"
};