import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";
import { AppDispatch, RootState } from "../redux-toolkit/store";
import { AccountSettingsModel } from "../models/accountSettings/AccountSettings.model";
import { http } from "./HttpService";

export const getAccountSettingsAsync = createAsyncThunk<
  ResponseResultBase<AccountSettingsModel> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("accountSettings/getAccountSetting", async (ciamId, thunkApi) => {
  try {
    let response = await http.get<ResponseResultBase<AccountSettingsModel>>(
      `/api/v1/accountSetting?ciamId=${ciamId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const createOrUpdateAccountSettingsAsync = createAsyncThunk<
  ResponseResultBase<AccountSettingsModel> | null,
  AccountSettingsModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("accountSettings/createOrUpdateAccountSetting", async (req, thunkApi) => {
  try {
    let response = await http.post<ResponseResultBase<AccountSettingsModel>>(
      `/api/v1/accountSetting`,
      req
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});
