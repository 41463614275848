import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { http } from "../services/HttpService";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";
import { AppDispatch, RootState } from "./store";
import {
  contactTypes,
  titulationQueryFields,
  titulations,
} from "../models/dashboard/Dashboard";
import {
  ReqeustGetMyTrainingEventsModel,
  ReqeustWidthdrawEventModel,
  RequestGetEventEnrollmentSummaryListModel,
  RequestGetTrainingEventModel,
} from "../models/dashboard/request.GetMyTrainingEvents.model";
import {
  TrainingEvent,
  TrainingCourse,
  TrainingModule,
  TrainingProgram,
} from "../models/TrainingEvent";
import {
  requestGetTransactionInvoice,
  requestGetTransactions,
  TransactionModel,
} from "../models/transactions/Transaction";
import {
  EnrollmentDetails,
  GetEventEnrollmentSummaryListResponse,
} from "../models/EventDetail";
import { RequestGetEnrollmentDetailsModel } from "../models/dashboard/request.GetEnrollmentDetails.model";
import { EventCertificate } from "../models/EventCertificate";
import { RequestGetCertificateModel } from "../models/dashboard/request.GetCertificate.model";
import { getAssignedTrainingProgramsByStudentAsync } from "./trainingProgramReducer";
import { RequestGetSignedLaunchLinkModel } from "../models/trainingCourse/request.getSignedLaunchLink.model";
import { CommonService } from "../services/Common";
import {
  isEnrollmentValid,
  isEnrollmentValidByEventEnrollment,
} from "../hooks/useEnrollmentStatus";
import { RequestGetEnrollmentsFilterPropertiesModel } from "../models/dashboard/request.GetEnrollmentsFilterProperties.model";
import { GetEnrollmentsFilterPropertiesResponse } from "../models/EnrollmentFilterProperties";
import { RequestGetTrainingCourses } from "../models/trainingCourse/request.getTrainingCourses.model";
import { httpNoneSecure } from "../services/HttpServiceNoneSecure";

interface TrainingProgramState {
  trainingPrograms: TrainingProgram[];
  selectedTrainingProgram: TrainingProgram | null;
  activeModule: TrainingModule | null;
}

interface BreadcrumsDashboard {
  key: any;
  content: string;
  link: boolean;
  href?: string;
}

interface CourseDetailState {
  enrollmentId: number;
  isElearning: boolean;
  trainingCourseId: number;
  trainingEventd: number;
  trainingCourseType: string;
  trainingCourseName: string;
  enrollmentDetail: EnrollmentDetails | null;
  trainingEvent: TrainingEvent | null;
  trainingCourse: TrainingCourse | null;
}

interface DashboardState {
  trainingEvents: TrainingEvent[] | null;
  isForceReloadMyTrainingEvents: boolean;
  myCoursesActiveTab: number;
  trainingProgramState: TrainingProgramState;
  eventEnrollmentSummaryList: GetEventEnrollmentSummaryListResponse | null;
  isForceEventEnrollmentSummaryList: boolean;
  enrollmentDetail: EnrollmentDetails | null;
  enrollmentDetailOrigin: EnrollmentDetails | null;
  enrollmentId: number;
  dashboardBreadcrums: BreadcrumsDashboard[];
  transactions: TransactionModel[];
  courseDetailState: CourseDetailState | null;
  enrollmentsFilterProperties: GetEnrollmentsFilterPropertiesResponse | null;
  trainingCourses: TrainingCourse[] | null;
}

const initialState: DashboardState = {
  enrollmentDetail: null,
  enrollmentDetailOrigin: null,
  enrollmentId: 0,
  trainingEvents: null,
  isForceReloadMyTrainingEvents: true,
  eventEnrollmentSummaryList: null,
  isForceEventEnrollmentSummaryList: true,
  enrollmentsFilterProperties: null,
  myCoursesActiveTab: 0,
  trainingProgramState: {
    trainingPrograms: [],
    activeModule: null,
    selectedTrainingProgram: null,
  },
  dashboardBreadcrums: [],
  transactions: [],
  courseDetailState: {
    enrollmentId: 0,
    isElearning: false,
    trainingCourseId: 0,
    trainingEventd: 0,
    trainingCourseType: "",
    trainingCourseName: "",
    enrollmentDetail: null,
    trainingEvent: null,
    trainingCourse: null,
  },
  trainingCourses: [],
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setEnrollmentDetail: (state, action) => {
      return {
        ...state,
        enrollmentDetail: action?.payload || null,
        enrollmentId: action?.payload ? action?.payload.eventDetails?.enrollmentId : 0
      };
    },
    setEnrollmentDetailOrigin: (state, action) => {
      return {
        ...state,
        enrollmentDetailOrigin: action?.payload || null,
      };
    },
    setEnrollmentId: (state, action) => {
      return {
        ...state,
        enrollmentId: action?.payload || null,
      };
    },
    setCourseDetailState: (state, action) => {
      return {
        ...state,
        courseDetailState: action?.payload || null,
      };
    },
    switchMyCoursesActiveTab: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        myCoursesActiveTab: action?.payload || 0,
      };
    },
    updateMyCourses: (state, action: any) => {
      return {
        ...state,
        trainingEvents: action?.payload || [],
      };
    },
    setTrainingCourses: (state, action: any) => {
      return {
        ...state,
        trainingCourses: action?.payload || [],
      };
    },
    updateSelectedProgram: (
      state,
      action: PayloadAction<TrainingProgram | null>
    ) => {
      return {
        ...state,
        trainingProgramState: {
          ...state.trainingProgramState,
          selectedTrainingProgram: action?.payload,
        },
      };
    },
    updateActiveModule: (state, action: PayloadAction<TrainingModule>) => {
      return {
        ...state,
        trainingProgramState: {
          ...state.trainingProgramState,
          activeModule: action?.payload,
        },
      };
    },
    updateDashboardBreadcrums: (
      state,
      action: PayloadAction<BreadcrumsDashboard[]>
    ) => {
      return {
        ...state,
        dashboardBreadcrums: action.payload,
      };
    },
    resetMyTrainingEvents: (state, action: any) => {
      return {
        ...state,
        trainingEvents: null,
        isForceReloadMyTrainingEvents: true,
      };
    },
    resetEventEnrollmentSummaryList: (state, action: any) => {
      return {
        ...state,
        eventEnrollmentSummaryList: null,
        isForceReloadMyTrainingEvents: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyTrainingEventsAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          const trainingEvents = payload.dataObject as TrainingEvent[];
          return {
            ...state,
            trainingEvents: trainingEvents.filter((x) => isEnrollmentValid(x)),
            isForceReloadMyTrainingEvents: false,
          };
        } else if (payload != null && payload.httpStatusCode === 401) {
          return {
            ...state,
            trainingEvents: null,
          };
        }
      })
      .addCase(getTrainingCoursesAsync.fulfilled, (state, { payload }) => {
        if (payload?.dataObject !== null && payload?.httpStatusCode === 200) {
          const trainingCourses = payload?.dataObject as TrainingCourse[];
          return {
            ...state,
            trainingCourses: trainingCourses,
          };
        } else {
          return {
            ...state,
            trainingCourses: [],
          };
        }
      })
      .addCase(
        getEventEnrollmentSummaryListAsync.fulfilled,
        (state, { payload }) => {
          if (payload != null && payload?.httpStatusCode === 200) {
            const eventEnrollmentSummaryListResponse =
              payload?.dataObject as GetEventEnrollmentSummaryListResponse;
            return {
              ...state,
              eventEnrollmentSummaryList: {
                items: eventEnrollmentSummaryListResponse.items.filter((x) =>
                  isEnrollmentValidByEventEnrollment(x)
                ),
                totalPages: eventEnrollmentSummaryListResponse?.totalPages,
                currentPage: eventEnrollmentSummaryListResponse?.currentPage,
              },
              isForceEventEnrollmentSummaryList: false,
            };
          } else {
            return {
              ...state,
              eventEnrollmentSummaryList: null,
            };
          }
        }
      )
      .addCase(
        getEnrollmentFilterPropertiesAsync.fulfilled,
        (state, { payload }) => {
          if (payload != null && payload?.httpStatusCode === 200) {
            const enrollmentsFilterPropertiesResponse =
              payload?.dataObject as GetEnrollmentsFilterPropertiesResponse;
            return {
              ...state,
              enrollmentsFilterProperties: {
                filterCategories:
                  enrollmentsFilterPropertiesResponse.filterCategories,
                filterTrainingVenues:
                  enrollmentsFilterPropertiesResponse.filterTrainingVenues,
                filterStatuses:
                  enrollmentsFilterPropertiesResponse.filterStatuses,
              },
            };
          } else {
            return {
              ...state,
              enrollmentsFilterProperties: null,
            };
          }
        }
      )
      .addCase(
        getAssignedTrainingProgramsByStudentAsync.fulfilled,
        (state, { payload }) => {
          if (!!payload) {
            const trainingPrograms = payload as TrainingProgram[];
            return {
              ...state,
              trainingProgramState: {
                ...state.trainingProgramState,
                trainingPrograms: trainingPrograms,
                activeModule: state?.trainingProgramState?.activeModule,
                selectedTrainingProgram:
                  state?.trainingProgramState?.selectedTrainingProgram,
              },
            };
          }
        }
      )
      .addCase(getTransactionsAsync.fulfilled, (state, { payload }) => {
        if (!!payload) {
          const transactions = payload.dataObject as TransactionModel[];
          return {
            ...state,
            transactions: transactions,
          };
        }
      });
  },
});

export const getTitulationsAsync = createAsyncThunk<
  ResponseResultBase<titulations[]> | null,
  titulationQueryFields,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Contacts/GetTitulations",
  async (accountInfo: titulationQueryFields, thunkApi) => {
    try {
      const model = {
        ciamId: accountInfo.ciamId,
        customerId: accountInfo.customerId,
        country: accountInfo.countryCode,
        language: accountInfo.language,
      };

      let response = await http.post<ResponseResultBase<titulations[]>>(
        `/api/v1/Contacts/GetTitulations`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getSignedLaunchLinkAsync = createAsyncThunk<
  ResponseResultBase<string> | null,
  RequestGetSignedLaunchLinkModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "RusticiListener/GetSignedLaunchLink",
  async (req: RequestGetSignedLaunchLinkModel, thunkApi) => {
    try {
      let response = await CommonService.getSignedLaunchLinkAsync(req);

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getContactTypesAsync = createAsyncThunk<
  ResponseResultBase<contactTypes[]> | null,
  titulationQueryFields,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Contacts/GetContactTypes",
  async (accountInfo: titulationQueryFields, thunkApi) => {
    try {
      const model = {
        ciamId: accountInfo.ciamId,
        customerId: accountInfo.customerId,
        country: accountInfo.countryCode,
        language: accountInfo.language,
      };
      let response = await http.post<ResponseResultBase<contactTypes[]>>(
        `/api/v1/Contacts/GetContactTypes`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getMyTrainingEventsAsync = createAsyncThunk<
  ResponseResultBase<TrainingEvent[]> | null,
  ReqeustGetMyTrainingEventsModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "TrainingEvents/GetMyTrainingEvents",
  async (req: ReqeustGetMyTrainingEventsModel, thunkApi) => {
    try {
      const model = {
        employeeId: +req.employeeId,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
        timeZoneId: req.timeZoneId,
        ciamId: req.ciamId,
      };
      let response = await http.post<ResponseResultBase<TrainingEvent[]>>(
        `/api/v1/TrainingEvents/GetMyTrainingEvents`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingEventAsync = createAsyncThunk<
  ResponseResultBase<TrainingEvent> | null,
  RequestGetTrainingEventModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "TrainingEvents/GetTrainingEvent",
  async (req: RequestGetTrainingEventModel, thunkApi) => {
    try {
      const model = {
        ciamId: req.ciamId,
        ciamEmail: req.ciamEmail,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
        timeZoneId: req.timeZoneId,
        eventId: req.eventId,
      };
      let response = await http.post<ResponseResultBase<TrainingEvent>>(
        `/api/v1/TrainingEvents/GetTrainingEvent`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);
export const getEventEnrollmentSummaryListAsync = createAsyncThunk<
  ResponseResultBase<GetEventEnrollmentSummaryListResponse> | null,
  RequestGetEventEnrollmentSummaryListModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Enrollments/GetEventEnrollmentSummaryList",
  async (req: RequestGetEventEnrollmentSummaryListModel, thunkApi) => {
    try {
      const model = {
        ciamId: req.ciamId,
        ciamEmail: req.ciamEmail,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
        timeZoneId: req.timeZoneId,
        studentId: req.studentId,
        currentPage: req.currentPage,
        pageSize: req.pageSize,
        categories: req.categories ?? [],
        statuses: req.statuses ?? [],
        trainingVenues: req.trainingVenues ?? [],
        contactPersons: req.contactPersons ?? [],
      };
      let response = await http.post<
        ResponseResultBase<GetEventEnrollmentSummaryListResponse>
      >(`/api/v1/Enrollments/GetEventEnrollmentSummaryList`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getEnrollmentFilterPropertiesAsync = createAsyncThunk<
  ResponseResultBase<GetEnrollmentsFilterPropertiesResponse> | null,
  RequestGetEnrollmentsFilterPropertiesModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Enrollments/GetEnrollmentsFilterProperties",
  async (req: RequestGetEnrollmentsFilterPropertiesModel, thunkApi) => {
    try {
      const model = {
        ciamId: req.ciamId,
        ciamEmail: req.ciamEmail,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
        timeZoneId: req.timeZoneId,
      };
      let response = await http.post<
        ResponseResultBase<GetEnrollmentsFilterPropertiesResponse>
      >(`/api/v1/Enrollments/GetEnrollmentsFilterProperties`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getEnrollmentDetailsAsync = createAsyncThunk<
  ResponseResultBase<EnrollmentDetails> | null,
  RequestGetEnrollmentDetailsModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Enrollments/GetEnrollmentDetails",
  async (req: RequestGetEnrollmentDetailsModel, thunkApi) => {
    try {
      const model = {
        enrollmentId: req.enrollmentId,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
        timeZoneId: req.timeZoneId,
      };
      let response = await http.post<ResponseResultBase<EnrollmentDetails>>(
        `/api/v1/Enrollments/GetEnrollmentDetails`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getEventCertificateAsync = createAsyncThunk<
  ResponseResultBase<EventCertificate> | null,
  RequestGetCertificateModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Enrollments/GetCertificate",
  async (req: RequestGetCertificateModel, thunkApi) => {
    try {
      const model = {
        ciamId: req.ciamId,
        enrollmentId: req.enrollmentId,
        customerId: req.customerId,
        country: req.country,
        language: req.language,
        timeZoneId: req.timeZoneId,
      };
      let response = await http.post<ResponseResultBase<EventCertificate>>(
        `/api/v1/Enrollments/GetCertificate`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const widthdrawEventAsync = createAsyncThunk<
  ResponseResultBase<any> | null,
  ReqeustWidthdrawEventModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "TrainingEvents/widthdrawEvent",
  async (req: ReqeustWidthdrawEventModel, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<any>>(
        `/api/v1/Enrollments/WidthdrawEvent`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTransactionsAsync = createAsyncThunk<
  ResponseResultBase<TransactionModel[]> | null,
  requestGetTransactions,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("Payment/GetTransactions", async (req: requestGetTransactions, thunkApi) => {
  try {
    let response = await http.post<ResponseResultBase<TransactionModel[]>>(
      `/api/v1/Payment/GetTransactions`,
      {
        countryCode: req.countryCode,
        ciamId: req.ciamId,
        customerId: req.customerId,
        language: req.language,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getTransactionInvoiceAsync = createAsyncThunk<
  ResponseResultBase<TransactionModel> | null,
  requestGetTransactionInvoice,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Payment/GetTransactionInvoice",
  async (req: requestGetTransactionInvoice, thunkApi) => {
    try {
      let response = await http.post<ResponseResultBase<TransactionModel>>(
        `/api/v1/Payment/GetTransactionInvoice`,
        {
          transactionId: req.transactionId,
          countryCode: req.countryCode,
          ciamId: req.ciamId,
          customerId: req.customerId,
          language: req.language,
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTrainingCoursesAsync = createAsyncThunk<
  ResponseResultBase<TrainingCourse[]> | null,
  RequestGetTrainingCourses,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "trainingCourse/getTrainingCourses",
  async (req: RequestGetTrainingCourses, thunkApi) => {
    try {
      const model = {
        country: req.countryCode,
        ciamId: req.ciamId ?? "",
        customerId: req.customerId ?? 0,
        language: req.language,
        timeZoneId: req.timeZoneId,
      };
      let response = await httpNoneSecure.post<
        ResponseResultBase<TrainingCourse[]>
      >(`/api/v1/Courses/country`, model);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const selectMyTrainingEvents = (rootState: RootState) =>
  rootState.dashBoard.trainingEvents;
export const selectEnrollmentDetail = (rootState: RootState) =>
  rootState.dashBoard.enrollmentDetail;
export const selectEnrollmentDetailOrigin = (rootState: RootState) =>
  rootState.dashBoard.enrollmentDetailOrigin;
export const selectEnrollmentId = (rootState: RootState) =>
  rootState.dashBoard.enrollmentId;
export const selectEventEnrollmentSummaryList = (rootState: RootState) =>
  rootState.dashBoard.eventEnrollmentSummaryList;
export const selectMyCoursesActiveTab = (rootState: RootState) =>
  rootState.dashBoard.myCoursesActiveTab;
export const selectTrainingPrograms = (rootState: RootState) =>
  rootState.dashBoard.trainingProgramState?.trainingPrograms;
export const selectSelectedTrainingProgram = (rootState: RootState) =>
  rootState.dashBoard.trainingProgramState?.selectedTrainingProgram;
export const selectActiveModule = (rootState: RootState) =>
  rootState.dashBoard.trainingProgramState?.activeModule;
export const selectDashboardBreadcrum = (rootState: RootState) =>
  rootState.dashBoard.dashboardBreadcrums;
export const selectTransactions = (rootState: RootState) =>
  rootState.dashBoard.transactions;
export const selectCourseDetailState = (rootState: RootState) =>
  rootState.dashBoard.courseDetailState;
export const selectEnrollmentsFilterProperties = (rootState: RootState) =>
  rootState.dashBoard.enrollmentsFilterProperties;
export const selectTrainingCourses = (rootState: RootState) =>
  rootState.dashBoard.trainingCourses;

export const selectIsForceReloadMyTrainingEvents = (rootState: RootState) =>
  rootState.dashBoard.isForceReloadMyTrainingEvents;

export const {
  updateDashboardBreadcrums,
  switchMyCoursesActiveTab,
  updateMyCourses,
  updateSelectedProgram,
  updateActiveModule,
  setEnrollmentDetail,
  setEnrollmentId,
  setCourseDetailState,
  setTrainingCourses,
  resetMyTrainingEvents,
  resetEventEnrollmentSummaryList,
  setEnrollmentDetailOrigin
} = DashboardSlice.actions;
export default DashboardSlice.reducer;
