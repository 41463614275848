export const TRANSLATIONS_IT_IT = {
	LandingPage_Title: "Benvenuti in Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Seleziona il tuo Paese",
	Header_ContactUs: "Contatti",
	Header_Search: "Cerca",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Accedi o crea il tuo Account",
	Header_LogIn: "Accedi",
	Header_Cart: "Carrello",
	Header_ShoppingCart: "Carrello",
	Header_LogOut: "Esci",
	Header_ChangeDetails: "Modifica i dati",
	Footer_ExploreOurTraining: "Esplora la nostra formazione",
	Footer_TrainingAtYourFingertips: "Formazione a portata di mano",
	Footer_DontMissAnyStory: "Non perdere nessuna storia",
	Footer_ExploreOurStories: "Esplora le nostre storie",
	Footer_GetInTouchWithUs: "Resta in contatto con noi",
	Footer_SendUsAMessage: "Inviaci una richiesta",
	Footer_ToContactForm: "Modulo di contatto",
	Footer_InventedForLife: "Tecnologia per la vita",
	Footer_GeneralContactInfo: "Informazioni generali di contatto",
	Footer_ProductSecurity_Psirt: "Product Security (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Brevetti & Licenze",
	Footer_PurchasingLogistics: "Acquisti e logistica",
	Footer_CorporateInformation: "Informazioni aziendali",
	Footer_LegalNotice: "Nota legale",
	Footer_DataProtectionNotice: "Informativa sulla protezione dei dati",
	Footer_PrivacySettings: "Impostazioni della Privacy",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "© Robert Bosch S.p.A. Societa' Unipersonale 2025,  tutti i diritti sono riservati",
	Footer_StillLookingForSomething: "Stai cercando qualcosa",
	Footer_TrainingServices: "Catalogo corsi",
	Footer_Courses: "Corsi",
	Footer_Programs: "Programmi",
	Footer_EventsCalendar: "Calendario eventi",
	Footer_TrainingCenters: "Centri di formazione",
	Footer_Dashboard: "Panoramica",
	Footer_MyDashboard: "La mia panoramica",
	Footer_ShoppingCart: "Carrello",
	Footer_YourShoppingCart: "Il tuo carrello della spesa",
	Footer_Administration: "Amministrazione",
	Footer_Employees: "Dipendenti",
	Footer_Wholesalers: "Rivenditori",
	Footer_Transactions: "Transazioni",
	Footer_Workshops: "Officine",
	Footer_WorkLikeABoschApplyNow: "Work",
	Footer_BoschGlobalCareers: "Bosch Global Careers",
	Footer_PurchasingAndLogistics: "Acquisti e logistica",
	Footer_BecomeABusinessPartner: "Diventa un partner commerciale",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Come evolve la nostra formazione per aiutare il tuo business",
	Footer_Language: "Seleziona il tuo paese",
	MenuPopup_MyCart: "Il mio carrello",
	Stories_OurTrainingStories: "Le nostre storie sulla formazione",
	Stories_ConnectingExperts: "Connettere esperti ed appassionati sognatori e operatori  innovatori ed influencer: esplora e sperimenta",
	Stories_TopStories: "Le migliori storie",
	Stories_AtHome: "Home",
	Stories_Careers: "Carriere",
	Stories_History: "Storia",
	Stories_IndustryAndTrades: "Industria e mestieri",
	Stories_Mobility: "Mobilità",
	Stories_Research: "Ricerca",
	Stories_Sustainability: "Sostenibilità",
	Stories_AllStories: "Tutte le storie",
	Stories_BlogATrainersDay: "BLOG | Una giornata da formatore",
	Stories_ADayinLifeOfATrainer: "Un giorno nella vita di un formatore",
	Stories_BoschServiceTraining: "Bosch Service Training",
	Stories_StoryWhatDrivesYouDrivesUs: "FORMAZIONE | Ciò che guida te, guida noi",
	Stories_StoryHowCanWeBenefitYourBusiness: "FORMAZIONE | Come possiamo supportare la tua attività",
	Stories_HowOurTrainingEvolves: "Come evolve la nostra formazione",
	Stories_AModernTrainingApproach: "Il mondo della formazione online",
	Stories_StoryAVirtualOnlineTrainingWorld: "FORMAZIONE | Le tue opportunità di formazione online",
	Stories_SubscribeToRSSFeed: "Iscriviti al feed RSS",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Chi siamo",
	Homepage_AutomotiveAftermarket: "Automotive Aftermarket",
	Homepage_GetInTouch: "Resta in contatto",
	Homepage_LatestNews: "Scopri perché siamo guidati dalla sete di conoscenza",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Le nostre posizioni aperte",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Ciò che ti guida guida anche noi",
	BoschServiceTraining_BoschServiceTraining: "Bosch Service Training",
	BoschServiceTraining_ShareThisOn: "Condividi questo su",
	BoschServiceTraining_TheChallenge: "La sfida: padroneggiare le nuove tecnologie",
	BoschServiceTraining_WorldwidePresence: "Presenza mondiale",
	BoschServiceTraining_TechnicalTraining: "Corsi di formazione tecnica Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Le offerte e le opportunità che noi di Bosch offriamo",
	BoschServiceTraining_TechnicalCoursesDesc: "Ci sono corsi di formazione adatti per ogni livello di utenti, sia principianti che professionisti. L'offerta comprende corsi di formazione di un giorno e corsi di formazione su più giorni. I corsi di formazione sono proposti in relazione a diverse aree tecniche con diversi percorsi formativi (come i sistemi elettrici, iniezione diesel ed iniezione benzina). Nei corsi di formazione tecnica, Bosch trasferisce un vasto know-how su tutti i sistemi standard Bosch dei veicoli. Vengono discussi anche i sistemi di altri produttori. Il contenuto è appositamente adattato alle esigenze dei dipendenti dell'officina.",
	BoschServiceTraining_TrainedEmployeesDesc: "I dipendenti formati sono un ovvio vantaggio per le officine automobilistiche perché i veicoli di oggi stanno diventando sempre più complessi e le nuove tecnologie presentano costantemente nuove sfide. Solo le competenze necessarie consentono ai dipendenti dell'officina di eseguire interventi di manutenzione e riparazione diagnostici sui modelli di veicoli attuali in modo efficiente ed economico",
	BoschServiceTraining_RegardlessOfHow: "Indipendentemente da quanto siano bravi ed esperti gli specialisti automobilistici, ci sono sempre situazioni in cui entreranno in contatto con nuovi sistemi tecnologici del veicolo o guasti sconosciuti. A questo proposito, l'analisi e la risoluzione dei problemi possono richiedere molto tempo o potrebbero non fornire risultati di successo. Nel peggiore dei casi i clienti devono fare a meno del proprio veicolo molto più a lungo del previsto.",
	BoschServiceTraining_ItIsPrecisely: "È proprio a questo punto che Bosch mette in atto il suo Service Training: migliorare la qualità del servizio e velocizzare i processi. L'attuale formazione di Bosch fornisce supporto per individuare i problemi più rapidamente riparando più veicoli contemporaneamente e riducendo i tempi di attesa dei clienti Pertanto, le procedure sistematiche che si apprendono in Bosch garantiscono un lavoro più rapido e produttivo.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Da oltre 125 anni Bosch è stato il fornitore preferito per equipaggiamenti originali, parti, componenti e sistemi per molti dei principali produttori di automobili in tutto il mondo. Questa esperienza deriva dalla conoscenza unica delle ultime tecnologie che funge da base per il supporto Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "I Bosch Service Training Center sono presenti in tutto il mondo: dalla Spagna alla Turchia, dal Sud Africa, dall'Australia al Brasile, Bosch offre ai propri dipendenti una formazione orientata alla pratica, sempre in linea con le tecnologie più avanzate.",
	BoschServiceTraining_AgileCompany: "Azienda \"agile\"",
	BoschServiceTraining_Collaboration: "Collaborazione",
	BoschServiceTraining_ConnectedMobility: "Mobilità connessa",
	BoschServiceTraining_ArtificialIntelligence: "Intelligenza artificiale",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Leader di pensiero nel dialogo",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnosi ed apparecchiature di prova",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Fondamenti di tecnologia automobilistica",
	BoschServiceTraining_Icon_Gasoline: "Benzina",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Azioni alternative",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Elettricità ed Elettronica",
	BoschServiceTraining_Icon_BodyAndComfort: "Telaio e confort",
	BoschServiceTraining_Icon_ChassisAndBraking: "Telaio e sistema frenante",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Trasmissioni ed azionamenti",
	BoschServiceTraining_Icon_CommercialVehicles: "Veicoli commerciali",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Corsi di formazione online",
	BoschServiceTraining_Icon_BusinessSkills: "Competenze aziendali",
	BoschServiceTraining_Icon_Accreditations: "Accreditamenti",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programmi di formazione",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "Nome e cognome formatore",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Un giorno nella vita del formatore",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Ogni giorno è un'avventura",
	ADayInTheLifeOfATrainer_Summary: "Riepilogo",
	ADayInTheLifeOfATrainer_TheDayJourney: "Il viaggio del giorno",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Ogni giorno è una giornata impegnativa ma è sempre un'esperienza gratificante. Il piacere di formare gli studenti sapendo che posseggono la chiave del nostro futuro è una grande responsabilità ma allo stesso tempo è molto eccitante. Vedere gli studenti crescere ad ogni lezione su base giornaliera e settimanale ci dimostra che stiamo lavorando per l'obiettivo comune di migliorare le loro conoscenze incorporando la moderna tecnologia che guida il futuro!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Alla fine di ogni giorno ci si siede e si riflette sulle giornate di lavoro. I giorni passano così in fretta, ma questa è la prova in sé che ciò che stiamo ottenendo non è solo gratificante per gli studenti ma anche per noi come formatori",
	ADayInTheLifeOfATrainer_WeCanGoHome: "A fine giornata potremo tornare a casa soddisfatti di aver fatto un ottimo lavoro!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Inizio",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Preparazione e verifiche finali del materiale formativo. Controlla le e-mail importanti",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Inizio formazione",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Benvenuto del partecipante. Presentazione del programma giornaliero. Conoscere il gruppo comprendendo le dinamiche di ogni individuo.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teoria",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "È necessaria una certa interazione con il gruppo per rendere la formazione il più interessante possibile. Assicurati che ci siano compiti intrecciati con la teoria per coinvolgere ulteriormente il gruppo",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Pausa pranzo",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Pratica",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "La formazione prosegue in laboratorio con alcune attività pratiche. In qualità di formatore, un requisito fondamentale è essere sempre presente e disponibile per ogni evenienza ed orientamento",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Riepilogo",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Ritorno in aula per un brief, per discutere sulla giornata di formazione. Rispondere a tutte le domande che potrebbero sorgere. Iniziare la preparazione per le prossime sessioni di formazione.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Ultimo giorno",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Ai partecipanti vengono consegnati gli attestati di partecipazione",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Home",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Come possiamo avvantaggiare la tua attività",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Come si evolve la nostra formazione",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Siamo in continua evoluzione per garantire una formazione di qualità e aggiornata",
	BoschTrainingBenefits_TheBoschServiceTraining: "Il Bosch Service Training Center desidera assisterti nello sviluppo della tua attività attraverso il suo portafoglio ampio e altamente pertinente di formazione tecnica/aziendale e corsi di perfezionamento con qualifiche certificate. Le pagine seguenti forniscono una panoramica del nostro programma di formazione e delle attuali opportunità di formazione continua. Non vediamo l'ora di incontrare te e i tuoi dipendenti ai nostri corsi di formazione e seminari",
	BoschTrainingBenefits_LongStandingExperience: "Esperienza pluriennale e vasto know-how di tutti i sistemi e nel settore della tecnologia automobilistica",
	BoschTrainingBenefits_QuickAndEfficient: "Soluzioni rapide ed efficienti per molti problemi",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "I vantaggi ed i benefit",
	BoschTrainingBenefits_LMS: "Learning Management System - Bosch Training solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions copre l'intera gamma delle aree automobilistiche. Specializzati in aree specifiche oppure amplia le tue competenze con le offerte di formazione di Bosch",
	BoschTrainingBenefits_AcquiredKnowledge: "Conoscenze acquisite Manutenzione delle strutture e lavori di assistenza.",
	BoschTrainingBenefits_TheHighDegree: "L'alto livello di contenuti pratici e i piccoli gruppi garantiscono un apprendimento mirato",
	BoschTrainingBenefits_ParticipationInTraining: "La partecipazione alla formazione aumenta la motivazione e la soddisfazione dei dipendenti e le qualifiche supplementari convincono i clienti",
	BoschTrainingBenefits_IncreasedSales: "Aumento delle vendite grazie alla conoscenza del sistema cross-make",
	BoschTrainingBenefits_MethodicalProcedures: "Le procedure metodiche riducono i tempi di attesa per i clienti",
	BoschTrainingBenefits_HighlyQualified: "Istruttori altamente qualificati trasmettono procedure sistematiche",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identificazione della soluzione formativa adatta a te o al tuo business",
	BoschTrainingBenefits_DirectOnlineBooking: "Prenotazione diretta online e gestione dei corsi per te o per i tuoi dipendenti",
	BoschTrainingBenefits_OnlineAccess: "Accesso online alla documentazione utilizzata per l'apprendimento",
	BoschTrainingBenefits_AccessToYourOwn: "Accesso alla cronologia dei tuoi corsi ed ai tuoi certificati di formazione",
	BoschTrainingBenefits_ToolsForManagement: "Strumenti per la gestione del proprio sviluppo professionale",
	BoschTrainingBenefits_ManagerHasFull: "Il titolare ha una panoramica completa sui corsi dei propri dipendenti all'interno del sistema",
	BoschTrainingBenefits_Access24hours: "Accesso 24 ore 7 giorni su 7",
	ModernTrainingApproach_KnowledgeIsKey: "La formazione è un fattore chiave di successo per qualsiasi officina, ecco perché Bosch offre un programma completo di sviluppo per la formazione professionale. Ciò consente ai partecipanti di acquisire le competenze al momento giusto, necessarie per essere in grado di mantenere e riparare i veicoli dei clienti in modo professionale. Bosch ha aggiunto alla formazione in presenza esistente anche la formazione online progettando sessioni di formazione specifiche per lo streaming",
	ModernTrainingApproach_ANewLearning: "Una nuova opportunità di apprendimento",
	ModernTrainingApproach_YourOnlineTraining: "Le tue opportunità di formazione online",
	ModernTrainingApproach_RecentlyTheWorld: "Il mondo sta cambiando e per questo è sempre più importante rimanere aggiornati con la formazione Bosch",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Il mondo della formazione online",
	ModernTrainingApproach_NewTeachingMethod: "Nuovo metodo di insegnamento",
	ModernTrainingApproach_InAdditionTo: "Oltre alla formazione in aula esistente, abbiamo incluso nel programma di formazione anche la formazione online in diretta streaming. I nostri formatori sono in grado di trasferire i contenuti sia teorici che pratici anche nell'aula virtuale. Come di consueto, riceverai molte informazioni sui sistemi del veicolo e sugli attinenti prodotti Bosch e avrai la possibilità di fare domande e ricevere risposte direttamente dal formatore in diretta. Per la formazione online è necessaria una connessione internet stabile.",
	ModernTrainingApproach_BoschWebcastingHeader: "Formazione online Bosch: innovativa e pratica",
	ModernTrainingApproach_BoschWebcastingText: "Per partecipare alla formazione online, il partecipante deve prima collegarsi. Poco prima della sessione viene inviato un codice di accesso individuale per abilitare il login. La formazione è gestita dal formatore e le domande possono essere inviate in qualsiasi momento tramite la funzione di chat dal vivo.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Il supplemento digitale alla formazione in aula",
	ModernTrainingApproach_TheDigitalSupplementText: "Formazione online e in aula si completano perfettamente, in entrambi i casi un formatore qualificato guida la formazione. I corsi online sono adatti per argomenti che possono essere trasmessi digitalmente senza la necessità di lavorare sul veicolo, ad esempio, conoscere alcune caratteristiche ESI[tronic] di un nuovo sistema di assistenza alla guida. Quando si tratta invece di lavoro pratico su una tecnologia del veicolo, la formazione in presenza è ancora la prima scelta.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Formazione in streaming, un'opportunità",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Il tuo impegno? Solo il tempo della formazione stessa. Nessun viaggio da e verso la sede della formazione o pernottamenti necessari.",
	ModernTrainingApproach_BookAndProfitHeading: "Iscriviti dal portale BATS",
	ModernTrainingApproach_BookAndProfitText: "I corsi di formazione online possono essere prenotati sul nostro sito web proprio come gli altri nostri corsi di formazione ed offerte di apprendimento.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Formazione online e formazione in aula Bosch a confronto",
	ModernTrainingApproach_Webcasting: "Online",
	ModernTrainingApproach_Classroom: "Aula",
	ModernTrainingApproach_WebcastingP1: "Possibilità di collegarsi da qualsiasi luogo, purchè la connessione sia stabile",
	ModernTrainingApproach_WebcastingP2: "Nessun viaggio da e verso la sede della formazione",
	ModernTrainingApproach_WebcastingP3: "Ideale per singoli argomenti specifici e concisi",
	ModernTrainingApproach_WebcastingP4: "I titolari possono assegnare la formazione per i propri dipendenti ovunque e in qualsiasi momento",
	ModernTrainingApproach_WebcastingP5: "Viene richiesto il 50-70% di tempo in meno rispetto ai corsi di formazione in aula",
	ModernTrainingApproach_ClassroomTrainingP1: "Numero massimo di partecipanti limitato e variabile a seconda della tipologia del corso",
	ModernTrainingApproach_ClassroomTrainingP2: "Spese di viaggio vitto e alloggio per singolo partecipante",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideale quando si tratta di ottimizzare la collaborazione o l'apprendimento direttamente su un veicolo",
	ModernTrainingApproach_ClassroomTrainingP4: "Esercitazioni pratiche edirettamente sul veicolo",
	ModernTrainingApproach_ClassroomTrainingP5: "Il formatore può meglio adattare gli argomenti da insegnare in base alle effettive esigenze dei partecipanti",
	ModernTrainingApproach_OnlineTrainingOfferings: "Offerte di formazione online:",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Oggi l'apprendimento indipendente tramite computer è parte integrante del trasferimento di conoscenze. La libertà di scegliere tempo e luogo, la ripetibilità illimitata, la velocità individuale di apprendimento ed i contenuti didattici interattivi rendono questo metodo uno strumento di studio indispensabile",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Ogni corso online è stato strutturato con cura. Oltre a fornire un'ampia base di conoscenze, questi corsi possono fungere da preparazione efficace per i programmi di formazione che si svolgono presso i centri di formazione Bosch",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "La simulazione diagnostica virtuale offre all'utente possibilità di gioco di ruolo per le situazioni quotidiane dell'officina, tipo il flusso di lavoro necessario per una procedura di risoluzione dei problemi utilizzando i dispositivi diagnostici Bosch in un'officina virtuale",
	ModernTrainingApproach_Advantages: "Vantaggi",
	ModernTrainingApproach_AdvantagesP1: "Un metodo rapido ed efficiente per la formazione",
	ModernTrainingApproach_AdvantagesP2: "Apprendimento dinamico",
	ModernTrainingApproach_AdvantagesP3: "Può essere frequentato tutte le volte che si desidera",
	ModernTrainingApproach_AdvantagesP4: "Tempi di trasferimento ridotti al minimo",
	ModernTrainingApproach_AdvantagesP5: "Rapido apprendimento individuale",
	ModernTrainingApproach_AdvantagesP6: "Tempo per lo studio flessibile",
	ModernTrainingApproach_AdvantagesP7: "Ripetibilità",
	ModernTrainingApproach_AdvantagesP8: "Nessun costo di viaggio",
	ModernTrainingApproach_AdvantagesP9: "ecc.",
	Dashboard_CoursesViewAndManageYourCourses: "CORSI | Vedi e gestisci i tuoi corsi",
	Dashboard_MyCourses: "I miei corsi",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAMMA DI FORMAZIONE | Visualizza il tuo programma di formazione",
	Dashboard_MyTrainingProgram: "Il mio programma di formazione",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Visualizza e gestisci il tuo portafoglio di formazione online",
	Dashboard_MyELearning: "Il mio eLearning",
	Dashboard_AccountManageYourAccount: "ACCOUNT | Gestisci il tuo Account",
	Dashboard_MyAccount: "Il mio Account",
	Dashboard_MyDashboard: "Panoramica",
	Dashboard_ManageYourAccount: "Gestisci il tuo Account",
	Dashboard_Workshops: "Officine",
	Dashboard_AdministrationManageYourWorkshop: "AMMINISTRAZIONE | Gestisci la tua officina",
	Dashboard_AdministrationManageYourTeams: "AMMINISTRAZIONE | Gestisci i tuoi teams",
	Dashboard_Administration: "Amministrazione",
	Dashboard_ViewYourCourses: "Visualizza i tuoi corsi",
	Dashboard_ViewYourTrainingPrograms: "Visualizza i tuoi programmi di formazione",
	Dashboard_TrainingPrograms: "Programmi di formazione",
	Dashboard_ViewAndEditYourDetails: "Visualizza e modifica i duoi dati",
	Dashboard_ViewYourELearningCourses: "Visualizza i tuoi corsi in eLearning",
	Dashboard_Pending: "In attesa",
	Dashboard_RequiresAdministratorSignOff: "(è richiesta la disconnessione dell'amministratore)",
	Dashboard_HistoricalRecords: "Dati storici",
	Dashboard_Registered: "Registrato",
	Dashboard_WaitingList: "Lista di attesa",
	Dashboard_RecommendedForYou: "Consigliato per te",
	Dashboard_AccountDetails: "Dati dell'Account",
	Dashboard_ChangeProfileImage: "Cambia l'immagine del profilo",
	Dashboard_Title: "Titolo",
	Dashboard_Role: "Ruolo",
	Dashboard_FirstName: "Nome",
	Dashboard_LastName: "Cognome",
	Dashboard_Telephone: "Telefono",
	Dashboard_Mobile: "Cellulare",
	Dashboard_Email: "E-mail",
	Dashboard_Language: "Lingua",
	Dashboard_Save: "Salva",
	Dashboard_Student: "Studente",
	Dashboard_WorkshopTrainingAdministrator: "Responsabile della formazione in officina",
	Dashboard_WholesalerTrainingAdministrator: "Distributore amministratore della formazione",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Amministratore dell'azienda",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Gestisci le tue officine...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Gestisci i tuoi dipendenti...",
	Dashboard_CompanyAdministration_Workshop: "Officina",
	Dashboard_CompanyAdministration_Address: "Indirizzo",
	Dashboard_CompanyAdministration_Zip: "C.A.P.",
	Dashboard_CompanyAdministration_City: "Città",
	Dashboard_CompanyAdministration_Manage: "Gestisci",
	Dashboard_AdministrationSection_AdministrationTitle: "Amministrazione",
	Dashboard_AdministrationSection_ManageTheTeam: "Gestisci il team",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "AMMINISTRAZIONE | Gestisci i tuoi dipendenti",
	Dashboard_AdministrationSection_Employees: "Dipendenti",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "AMMINISTRAZIONE | Gestisci i tuoi distributori",
	Dashboard_AdministrationSection_Wholesalers: "Distributori",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "AMMINISTRAZIONE | Gestisci le tue transazioni",
	Dashboard_AdministrationSection_Transactions: "Transazioni",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Amministrazione del dipendente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Gestisci i tuoi dipendenti...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registra",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Registrazioni",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Modifica",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Dipendente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Aggiungi nuovo",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Crea",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrato",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "In attesa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(è richiesta la disconnessione dell'amministratore)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Dati storici",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Lista di attesa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Elenco attuale dei dipendenti",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Gestione del dipendente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nome",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Account del dipendente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Visualizza e modifica i dati del dipendente...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Configurazione",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Abilita account personale Bosch Automotive Training Solutions",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Aggiornamento proxy amministrazione dei dipendenti dell'officina",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Consenti al dipendente di registrarsi agli eventi di formazione",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Invia e-mail di invito",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Disabilita Account",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Salva",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Invita",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Modifica",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Gestione distributore",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Gestisci i tuoi distributori...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Elenco distributori abilitati",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nome",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Nome",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Cancella",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Crea",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Creazione di un nuovo account dipendente",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Crea dati per nuovi dipendenti...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Aggiungi un nuovo amministratore per l'approvazione del distributore",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Crea dati per nuovi distributori...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Seleziona il distributore approvato dal menu a tendina...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transazioni",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Visualizza e gestisci le tue transazioni...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Indietro",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Numero fattura",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Descrizione",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Metodo di pagamento",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Data",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Importo",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Download",
	TrainingCentres_TrainingCentres: "Centri di formazione",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "I nostri centri di formazione sono dislocati in tutto il mondo",
	TrainingCentres_CountryCategory: "Categoria Paese",
	TrainingCentres_CityCategory: "Categoria città",
	TrainingCentres_Country: "Paese",
	TrainingCentres_City: "Città",
	TrainingCatalogue_TrainingCatalogue: "Catalogo corsi di formazione",
	TrainingCatalogue_LearningAtTheHighestLevel: "Apprendimento al livello più alto",
	TrainingCatalogue_StartDate: "Data inizio",
	TrainingCatalogue_EndDate: "Data fine",
	TrainingCatalogue_TrainingName: "Nome del corso",
	TrainingCatalogue_Language: "Lingua",
	TrainingCatalogue_Days: "Giorni",
	TrainingCatalogue_Seats: "Posti disponibili",
	TrainingCatalogue_Price: "Costo (IVA inclusa)",
	TrainingCatalogue_Location: "Sede",
	TrainingCatalogue_PartNumber: "Codice",
	TrainingCatalogue_TrainingCourse: "Corso di formazione",
	TrainingCatalogue_Category: "Categoria",
	TrainingCatalogue_TrainingCategory: "Categoria formazione",
	TrainingCatalogue_CourseName: "Nome del corso",
	TrainingCatalogue_TotalDays: "Giorni totali",
	TrainingCatalogue_Venue: "Sede",
	TrainingCatalogue_Details: "Dati",
	TrainingCatalogue_Prerequisites: "Prerequisiti",
	TrainingCatalogue_CourseType: "Tipo di corso",
	TrainingCatalogue_LearningMethod: "Modalità di erogazione",
	TrainingCatalogue_AddToWaitingList: "Aggiungi alla lista di attesa",
	ShoppingCart_ShoppingCart: "Carrello",
	ShoppingCart_ReviewAndManage: "Rivedi e gestisci le tue iscrizioni",
	ShoppingCart_YouHaveAddedItem: "Hai aggiunto i seguenti articoli al carrello",
	ShoppingCart_Item: "Articolo",
	ShoppingCart_Quantity: "Quantità",
	ShoppingCart_PricePerItem: "Prezzo del singolo articolo",
	ShoppingCart_TotalPrice: "Costo totale",
	ShoppingCart_VatIsIncluded: "Escluso IVA",
	ShoppingCart_Redeem: "Se non hai credito clicca qui per riscattarlo",
	ShoppingCart_Purchase: "Se non hai credito clicca qui per acquistare",
	ShoppingCart_Checkout: "Conferma ed esci",
	ShoppingCart_ContinueShopping: "Continua l'acquisto",
	ShoppingCart_TotalPriceInclVat: "Costo totale (inclusa IVA)",
	ShoppingCart_Apply: "Applica",
	ShoppingCart_ShoppingCartEmpty: "Il tuo carrello è vuoto",
	ShoppingCart_VatIncluded: "IVA (inclusa)",
	ShoppingCart_ManageYourShopping: "Gestisci i tuoi acquisti",
	ShoppingCart_Participant: "Partecipante",
	ShoppingCart_Complete: "Completa",
	ShoppingCart_NetPrice: "Prezzo netto",
	ShoppingCart_Workshop: "Officina",
	CheckOut_PreviewYourOrder: "Anteprima del tuo ordine",
	CheckOut_YourShoppingCart: "Carrello",
	CheckOut_Preview: "Anteprima",
	CheckOut_Payment: "Pagamento",
	CheckOut_Summary: "Riepilogo",
	CheckOut_Back: "Indietro",
	CheckOut_PleaseReadAndAgreeOurTerms: "Si prega di leggere ed accettare i nostri Termini e le Condizioni",
	CheckOut_IHaveReadTheTermsAndCondition: "Ho letto i [Termini e le Condizioni] e li capisco e li accetto",
	CheckOut_YesIAgree: "Sì - Accetto i Termini e le Condizioni",
	CheckOut_Continue: "Continua",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Congratulazioni! Abbiamo ricevuto il tuo ordine",
	CheckOut_OrderConfirmation: "Riceverai la conferma finale dell'ordine una volta che la tua richiesta sarà stata elaborata con successo",
	CheckOut_WeWillContactYouDirectly: "Ti contatteremo direttamente nel caso avessimo domande relative alla tua richiesta",
	CheckOut_AddToCart: "Aggiungi al carrello",
	CheckOut_CreditCart: "Carta di credito",
	CheckOut_DirectDebit: "Carta di debito",
	CheckOut_TrainingCredits: "Crediti formativi",
	CheckOut_PreferredWholesaler: "Distributore preferito",
	EventCalendar_EventCalendar: "Calendario eventi",
	EventCalendar_TrainingEventsForAllYourNeeds: "Eventi formativi per tutte le tue esigenze",
	TrainingCourse_TrainingCourse: "Catalogo corsi di formazione",
	TrainingCourse_LearningAtTheHighestLevel: "Apprendimento al livello più alto",
	TrainingProgram_Title: "Catalogo programmi di formazione",
	TrainingProgram_SubTitle: "Learning at the highest Level",
	AccountDeactivationModal_Title: "Sei sicuro di voler disattivare questo Account?",
	AccountDeactivationModal_Line1: "La disattivazione di questo account è permanente e rimuoverà tutto il contenuto, comprese le transazioni di richiesta di servizi, i saldi dei membri del team e le impostazioni del profilo",
	AccountDeactivationModal_Line2Part1: "Se questo risulta essere all'interno di un periodo di abbonamento attivo, fare riferimento alla nostra policy di cancellazione nei nostri",
	AccountDeactivationModal_Line2Part2: "Termini e Condizioni",
	AccountDeactivationModal_Line2Part3: "prima di inviare la tua richiesta",
	AccountDeactivationModal_Line3: "Sei sicuro di voler inviare una richiesta di disattivazione per questo Account?",
	AccountDeactivationModal_Cancel: "Cancella",
	AccountDeactivationModal_Send: "Invia",
	CurrentUserAccountDeactivationModal_Title: "Sei sicuro di voler disattivare il tuo Account?",
	CurrentUserAccountDeactivationModal_Line1: "La disattivazione di questo account è permanente e rimuoverà tutto il contenuto, comprese le transazioni di richiesta di servizi, i saldi dei membri del team e le impostazioni del profilo",
	CurrentUserAccountDeactivationModal_Line2Part1: "Se sei all'interno di un periodo di abbonamento attivo, fai riferimento alla nostra policy di cancellazione nei nostri",
	CurrentUserAccountDeactivationModal_Line3: "Sei sicuro di voler inviare una richiesta di disattivazione per questo Account?",
	CurrentUserAccountDeactivationModal_Yes: "Sì",
	CurrentUserAccountDeactivationModal_No: "No",
	CourseWithdrawalModal_Title: "Cancellazione del corso",
	CourseWithdrawalModal_Line1: "Sei sicuro di voler recedere dal corso?  Riceverai una conferma via e-mail una volta che la tua richiesta di recesso sarà stata elaborata. In caso di domande, ti contatteremo direttamente",
	CourseWithdrawalModal_Line2Part1: "Si prega di leggere la nostra",
	CourseWithdrawalModal_Line2Part2Link: "Policy di cancellazione nei nostri [ Termini e Condizioni ]",
	CourseWithdrawalModal_Line2Part3: "CourseWithdrawalModal_Line2Part3",
	CourseWithdrawalModal_Yes: "Recesso",
	CourseWithdrawalModal_No: "Cancella",
	Settings_Settings: "Impostazioni",
	Settings_ManageYourSettings: "Gestisci le tue impostazioni",
	Settings_Profile: "Profilo",
	Settings_MyBoschGlobalIdLoginInformation: "Informazioni sul mio Bosch Global ID Login",
	Settings_Edit: "Modifica",
	Settings_CompanyDetails: "Dati dell'azienda",
	Settings_RequestAccountDeletion: "Richiedi cancellazione Account",
	Settings_AccountDeletionText: "Se elimini il tuo Account, tutti i tuoi dati (inclusi indirizzo e dati di pagamento) verranno cancellati e non saranno più disponibili",
	Settings_DeleteAccountRequestConfirmation: "Conferma richiesta di cancellazione Account",
	Settings_DeleteAccountRequestConfirmationText: "Abbiamo ricevuto la tua richiesta di cancellazione dell'Account e ti contatteremo via e-mail o telefono per la conferma finale",
	RecommendedHotels_RecommendedHotels: "Hotel consigliati",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "I nostri centri di formazione sono dislocati in tutto il mondo",
	Error_Common: "Qualcosa è andato storto!",
	Error_404: "La pagina che hai richiesto non esiste",
	Error_401: "Non hai accesso a questa pagina",
	Error_500: "Il server segnala un errore",
	Dashboard_Courses_Table_Course: "Corso",
	Dashboard_Courses_Table_Date: "Data [durata]",
	Dashboard_Courses_Table_Venue: "Sede",
	Dashboard_Courses_Table_TrainingType: "Tipo formazione",
	Dashboard_Courses_Table_Action: "Azione",
	Dashboard_Courses_Table_TasksToComplete: "Hai le seguenti attività da completare",
	Dashboard_Courses_Table_Withdraw: "Recesso",
	Dashboard_Courses_Table_Remove: "Rimuovere",
	Dashboard_Courses_Table_View: "Visualizza",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Nessun dato da visualizzare",
	Dashboard_Courses_Table_CompletionDate: "Data completamento",
	Dashboard_Courses_Table_Actions_Assignments: "Assegnazioni",
	Dashboard_Courses_Table_Actions_Certification: "Certificazione",
	Dashboard_Courses_Table_Actions_Evaluations: "Valutazioni",
	Dashboard_Courses_Table_Actions_Assessments: "Valutazioni",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Aggiungi al calendario",
	Dashboard_Courses_Table_CourseDetails_Task: "Compiti",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Scadenza",
	ContactUs_Headline: "Contatti",
	ContactUs_HowCanWeHelp: "In cosa ti possiamo aiutare?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Telefono",
	ContactUs_LetterTab: "Posta ordinaria",
	ContactUs_Phone_Headline: "Chiamaci!",
	ContactUs_Phone_Description: "Saremo felici di supportarti",
	ContactUs_Phone_PhoneNumber: "Si prega di utilizzare il modulo di contatto per inviarci un messaggio",
	ContactUs_Phone_ChargeNotice: "Le tariffe variano a seconda del fornitore di servizi e del Paese",
	ContactUs_Letter_Headline: "Inviaci una richiesta",
	ContactUs_Letter_Description: "Hai domande, richieste o suggerimenti? Restiamo in attesa di eventuali commenti e/o richieste",
	ContactUs_Email_Headline: "Inviaci un'e-mail",
	ContactUs_Email_Description: "Inviaci il tuo messaggio compilando il modulo sottostante, verrai contattato al più presto!",
	ContactUs_Email_InputName: "Nome*",
	ContactUs_Email_InputCompany: "Azienda",
	ContactUs_Email_InputCountry: "Paese*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Testo",
	ContactUs_Email_DataProtection: "Informativa sulla protezione dei dati*",
	ContactUs_Email_DataProtectionConfirm: "Ho letto l'Informativa sulla Privacy.*",
	ContactUs_Email_SecurityCheck: "Controllo di sicurezza",
	ContactUs_Email_SecurityCodeConfirm: "Per favore utilizza i caratteri corretti che vedi nella casella sopra",
	ContactUs_Email_SecurityCodeInvalid: "La risposta \"Captcha\" che hai inserito non è corretta",
	ContactUs_Email_MandatoryNotice: "Si prega di compilare i campi obbligatori",
	ContactUs_Email_SuccessMessage: "Il tuo messaggio è stato inviato",
	ContactUs_Email_TakenNoteDataProtection1: "Ho letto l'",
	ContactUs_Email_TakenNoteDataProtection2: "Informativa sulla Privacy*",
	ContactUs_Email_SubmitButton: "Invio",
	ContactUs_Email_FieldErrorMessage: "Si prega di compilare i campi obbligatori",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Il tuo Account è attualmente in fase di revisione!",
	AccountInReviewModal_Paragraph1: "Grazie per aver registrato un Account personale su Bosch Automotive Training Solutions",
	AccountInReviewModal_Paragraph2: "Il nostro personale dedicato alla formazione sta attualmente rivedendo e configurando il tuo Account, non appena sarà stato configurato correttamente riceverai una conferma via e-mail",
	AccountInReviewModal_Paragraph3: "Nel frattempo non esitare a navigare nel nostro sito Web anche se con accesso limitato",
	AccountInReviewModal_Paragraph4: "In caso di domande o se il tuo Account non verrà elaborato entro 48 ore, esclusi fine settimana e giorni festivi, non esitare a contattarci",
	AccountInReviewModal_ContactUsLink: "Cantattaci",
	AccountInReviewModal_OKButton: "OK, procedi!",
	Header_YesButton: "Sì",
	Header_NoButton: "No",
	Header_LogOutText: "Vuoi disconnetterti?",
	Footer_EnquiryText: "Restiamo in attesa della tua richiesta",
	Footer_Telephone: "Telefono",
	Footer_TermsAndConditions: "Termini e Condizioni",
	Footer_HowOurTrainingEvolves: "Come evolve la nostra formazione",
	FooterSearch_SearchFieldPlaceholder: "Cerca",
	TrainingCatalogue_Action: "Azione",
	TrainingCatalogue_Duration: "Durata",
	TrainingCatalogue_Information: "Informazione",
	Settings_PersonalDetails: "Dati personali",
	Reset_All_Filters: "Togli tutti i filtri",
	EventTable_Days: "Giorni",
	SearchField_AllResults: "Tutti i risultati",
	TrainingCentresLayout_ViewDetails: "Visualizza dati",
	Dropdown_Placeholder: "Scegli...",
	WaitlistPopup_SelectButtonText: "Seleziona",
	WaitlistPopup_StartDate: "Data d'inizio",
	WaitlistPopup_EndDate: "Data di fine",
	WaitlistPopup_Language: "Lingua",
	WaitlistPopup_City: "Città",
	WaitlistPopup_Workshop: "Officina",
	WaitlistPopup_Student: "Studente",
	WaitlistPopup_PreferredLocation: "Località preferita",
	WaitlistPopup_SignUpCourseWaitingList: "Iscriviti alla lista d'attesa del corso",
	WaitlistPopup_SignUpWithoutSelectingDate: "Iscriviti alla lista d'attesa senza selezionare una data",
	WaitlistPopup_GeneralWaitList: "Lista d'attesa generale",
	WatinglistPopup_CourseLabel: "Corso",
	Common_ContinueButton: "Continua",
	UserAccountDeactivationModal_ConfirmLabel: "Conferma",
	Filter_FilterLabel: "Filtro",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Sei già in lista d'attesa, vuoi rimuovere la tua registrazione esistente e farne una nuova?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Sì, rimuovi",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "No",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Cancellazione del corso",
	WaitinglistPopup_SignUpButtonText: "Iscriviti",
	MyAccount_FirstNameRequied: "Occorre inserire il nome",
	MyAccount_LastNameRequired: "Occorre inserire il cognome",
	MyAccount_RoleRequired: "Occorre inserire il ruolo",
	MyAccount_MobileRequired: "Occorre inserire il numero di cellulare",
	MyAccount_EmailRequired: "Occorre inserire un indirizzo e-mail",
	MyAccount_LanguageRequired: "Occorre inserire la lingua",
	MyAccount_UpdateSuccessMessage: "I dati del tuo Account sono stati aggiornati correttamente!",
	MyAccount_UpdateSuccessModal_ButtonText: "Chiudi",
	Infor_CorporateInformation_Header: "Informazioni aziendali",
	Infor_LegalNotice_Header: "Note Legali",
	Infor_PrivacyPolicy_Header: "Informativa sulla protezione dei dati (Informativa Privacy)",
	CreateEmployeeAccountModification_FormValidation_Role: "Occorre inserire il ruolo",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Occorre inserire il nome",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Occorre inserire il cognome",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Occorre inserire il numero di cellulare",
	CreateEmployeeAccountModification_FormValidation_Email: "Occorre inserire un indirizzo e-mail",
	CreateEmployeeAccountModification_FormValidation_Language: "Occorre inserire la lingua",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Invito di partecipazione a Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Hai aggiunto con successo un nuovo collaboratore al tuo team!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Vuoi che condividiamo un invito e-mail con il tuo nuovo collaboratore del team con le istruzioni su come creare il proprio Account personale per Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "No",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Sì, inoltra l'invito",
	ShoppingCart_Venue_Fee: "Fee centro di formazione",
	ShoppingCart_Trainning_Name: "Nome del corso",
	ShoppingCart_Available_Credits: "Crediti disponibili",
	ConfirmItsMeModal_Hi: "Ciao",
	ConfirmItsMeModal_Description1: "Hai completato con successo l'invito condiviso dall'amministratore della tua azienda per creare il tuo Account personale su Bosch Automotive Training Solutions",
	ConfirmItsMeModal_Description2: "Prima di continuare, conferma o aggiorna i dati del tuo Account",
	ConfirmItsMeModal_Description3: "Dopo aver confermato, ti verrà richiesto di accedere nuovamente per completare la creazione del tuo Account",
	ConfirmItsMeModal_CompanyDetails: "Dati aziendali",
	ConfirmItsMeModal_YourDetails: "I tuoi dati",
	ConfirmItsMeModal_YourDetails_Title: "Titolo",
	ConfirmItsMeModal_YourDetails_Function: "Funzione",
	ConfirmItsMeModal_YourDetails_Name: "Nome",
	ConfirmItsMeModal_YourDetails_Mobile: "Cellulare",
	ConfirmItsMeModal_YourDetails_Email: "E-mail",
	ConfirmItsMeModal_YourDetails_Language: "Lingua",
	ConfirmItsMeModal_ConfirmButton: "Conferma",
	SelectWholesalerModal_WholesalerGroupLabel: "Distributore",
	SelectWholesalerModal_WholesalerBranchLabel: "Ricambista",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Si prega di selezionare",
	ApplyButton: "Applica",
	SelectWholesalerModal_Title: "Scegli il tuo distributore",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Vuoi rimuovere la tua registrazione?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "La tua registrazione è stata rimossa con successo!",
	WaitlistPopup_AddToWaitListSuccess: "La tua registrazione è stata aggiunta con successo!",
	ErrorCommon_Header: "ERRORE",
	CommonButton_Close: "Chiudi",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Chiudi questa finestra per continuare",
	EmbeddedVideoPlayer_StartButtonText: "Inizia",
	EmbeddedVideoPlayer_RefresherButtonText: "Aggiorna",
	DeactivateWholesalerModal_ConfirmMessage: "Sei sicuro di voler rimuovere questo distributore?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Invio e-mail d'invito riuscito!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Invia di nuovo",
	ContactUs_Mobile_IT: "+39 02 3696 2064",
	ContactUs_Address_IT: "Robert Bosch S.p.A. Società Unipersonale,Via M.A. Colonna 35,20149 Milano",
	ConfirmItsMeModal_CompanyDetails_Name: "Ragione Sociale",
	ConfirmItsMeModal_CompanyDetails_Address: "Indirizzo",
	ConfirmItsMeModal_CompanyDetails_Postcode: "CAP",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefono",
	ConfirmItsMeModal_CompanyDetails_Email: "E-mail",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Il recesso dal corso è andato a buon fine!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "La creazione dell'Account non è riuscita. L'Account esiste già!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "L'aggiornamento dell'Account non è riuscito. L'Account esiste già!",
	Footer_ExploreTrainingStories: "Scopri le nostre storie",
	Breadcrumb_Home: "Home",
	Breadcrumb_Contact: "Contatti",
	Breadcrumb_Stories: "Storie",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "Un giorno nella vita del formatore",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch Training Benefits",
	Breadcrumb_Stories_TrainingApproach: "Un approccio moderno alla formazione",
	Breadcrumb_Settings: "Impostazioni",
	Breadcrumb_ShoppingCart: "Carrello",
	Breadcrumb_Checkout: "Conferma ordine",
	Breadcrumb_OrderSummary: "Totale dell'ordine",
	Breadcrumb_Exception: "Eccezione",
	Breadcrumb_Dashboard: "Panoramica",
	Breadcrumb_MyCourses: "I miei corsi",
	Breadcrumb_MyELearning: "My eLearning",
	Breadcrumb_CompanyAdministration: "Amministrazione aziendale",
	Breadcrumb_EmployeeManagement: "Gestione dei dipendenti",
	Breadcrumb_EmployeeAccount: "Account del dipendente",
	Breadcrumb_EmployeeCreation: "Inserimento dei dipendenti",
	Breadcrumb_Administration: "Amministrazione",
	Breadcrumb_EmployeeAdministration: "Amministrazione dei dipendenti",
	Breadcrumb_WholesalerManagement: "Gestione del distributore",
	Breadcrumb_NewWholesalerAccountCreation: "Creazione nuovo Account rivenditore",
	Breadcrumb_Transactions: "Transazioni",
	Breadcrumb_MyAccount: "Il mio Account",
	Breadcrumb_TrainingCentres: "Centri di formazione",
	Breadcrumb_EventCalendar: "Calendario eventi",
	Breadcrumb_TrainingCourseCatalogue: "Catalogo corsi di formazione",
	Breadcrumb_RecommendedHotels: "Hotel consigliati",
	Breadcrumb_CorporateInformation: "Informazioni aziendali",
	Breadcrumb_LegalNotice: "Note Legali",
	Breadcrumb_DataProtectionNotice: "Informativa sulla protezione dei dati",
	Breadcrumb_PrivacyPolicy: "Informativa Privacy",
	Breadcrumb_TermCondition: "Termini e Condizioni",
	Breadcrumb_Terms: "Termini",
	Breadcrumb_PrivacyStatement: "Informativa Privacy",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Fornitore",
	Breadcrumb_RegisterAccount: "Registrazione Account",
	Stories_ExploreMore: "Esplora di più",
	CheckOutLayout_ShoppingCartHeader: "Carrello",
	CheckOutLayout_AddedItemCartMessage: "Hai aggiunto quanto segue al tuo carrello",
	CheckOutLayout_CheckYourCartMessage: "Controlla il tuo carrello",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Cerca dipendenti",
	CheckOutLayout_OrderWasNotPlaced_Message: "Spiacente! Il tuo ordine non è stato elaborato",
	CheckOutLayout_RefusalReason: "Motivo del rifiuto",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Riprova con un metodo di pagamento diverso",
	CheckOutLayout_SubmittingApiFailed_Message: "Chiamata API non riuscita",
	CartItem_ChooseWorkshop_Message: "Si prega di selezionare l'officina per scegliere il partecipante",
	CartItem_ChooseParticipant_Message: "Seleziona il partecipante per continuare",
	CompanyAdministrationTable_SearchBox_Placeholder: "Cerca officine",
	CartItem_RemoveCartItem_ConfirmText: "Vuoi rimuovere questo articolo dal carrello?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funzione",
	AddToCartSuccessModal_Message: "Il tuo carrello è stato aggiornato!",
	AddToCartSuccessModal_ContinueButtonText: "Aggiungi altro",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Conferma",
	AddVoucher_Header: "Aggiungi voucher",
	AddVoucher_InputPlaceholder: "Inserisci il codice",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Sfortunatamente, al momento non abbiamo eventi programmati per questo corso di formazione, registrati in lista d'attesa e ti informeremo non appena un evento sarà disponibile",
	ADYEN_ERR_2_: "La transazione è stata rifiutata",
	ADYEN_ERR_3_: "Errore di riferimento",
	ADYEN_ERR_4_: "La transazione non è andata a buon fine a causa di un errore verificatosi da parte dell'acquirente",
	ADYEN_ERR_5_: "La carta utilizzata per la transazione è bloccata, quindi inutilizzabile",
	ADYEN_ERR_6_: "La carta utilizzata per la transazione è scaduta, pertanto è inutilizzabile",
	ADYEN_ERR_7_: "Si è verificata una mancata corrispondenza dell'importo durante il processo di transazione",
	ADYEN_ERR_8_: "Il numero di carta specificato è errato o non valido",
	ADYEN_ERR_9_: "Non è possibile contattare la banca dell'acquirente per autorizzare la transazione",
	ADYEN_ERR_10_: "La banca dell'acquirente non supporta o non consente questo tipo di transazione",
	ADYEN_ERR_11_: "L'autenticazione 3D Secure non è stata eseguita o non è stata eseguita correttamente",
	ADYEN_ERR_12_: "La carta non ha abbastanza soldi per coprire l'importo da pagare, questo problema potrebbe verificarsi a causa di un limite di importo pagabile mensilmente sulla tua carta, prova a utilizzare un'altra carta come una MasterCard o contatta la tua banca per ripristinare il limite mensile della tua carta",
	ADYEN_ERR_14_: "Possibile truffa",
	ADYEN_ERR_15_: "La transazione è stata annullata",
	ADYEN_ERR_16_: "L'acquirente ha annullato la transazione prima di completarla",
	ADYEN_ERR_17_: "Il PIN specificato è errato o non valido",
	ADYEN_ERR_18_: "L'acquirente ha specificato un PIN errato più di tre volte di seguito",
	ADYEN_ERR_19_: "Non è possibile convalidare il numero PIN specificato",
	ADYEN_ERR_20_: "Possibile truffa",
	ADYEN_ERR_21_: "La transazione non è stata inviata correttamente per l'elaborazione",
	ADYEN_ERR_22_: "Il controllo del rischio ha contrassegnato la transazione come fraudolenta (punteggio di rischio >= 100) pertanto l'operazione viene annullata",
	ADYEN_ERR_23_: "I seguenti codici rifiutati sono associati a questo motivo di rifiuto: transazione non consentita all'emittente/titolare della carta",
	ADYEN_ERR_24_: "Il CVC specificato (codice di sicurezza della carta) non è valido",
	ADYEN_ERR_25_: "I seguenti codici di rifiuto sono associati a questo motivo di rifiuto: carta non valida in questo Paese",
	ADYEN_ERR_26_: "\"R1: revoca dell'ordine di autorizzazione\" / \"R3: revoca dell'ordine di tutte le autorizzazioni\" / \"R0: stop payment order\"",
	ADYEN_ERR_27_: "Questa risposta mappa tutti quei codici di risposta che non possono essere mappati in modo affidabile. Ciò rende più facile distinguere i rifiuti generici (ad esempio, la risposta Mastercard \"05: non onorare\") da quelli più specifici",
	ADYEN_ERR_28_: "L'importo di prelievo consentito per la carta dell'acquirente è stato superato",
	ADYEN_ERR_29_: "Il numero di prelievi consentiti per la carta dell'acquirente è stato superato",
	ADYEN_ERR_31_: "L'emittente ha segnalato la transazione come sospetta frode",
	ADYEN_ERR_32_: "I dati dell'indirizzo inseriti dall'acquirente non sono corretti",
	ADYEN_ERR_33_: "La banca dell'acquirente richiede all'acquirente di inserire un PIN online",
	ADYEN_ERR_34_: "La banca dell'acquirente richiede un conto corrente per completare l'acquisto",
	ADYEN_ERR_35_: "La banca dell'acquirente richiede un conto di risparmio per completare l'acquisto",
	ADYEN_ERR_36_: "La banca dell'acquirente richiede all'acquirente di inserire un PIN mobile",
	ADYEN_ERR_37_: "L'acquirente ha abbandonato la transazione dopo aver tentato un pagamento contactless e gli è stato chiesto di provare un metodo di inserimento della carta diverso (PIN o strisciamento)",
	ADYEN_ERR_38_: "L'emittente ha rifiutato la richiesta di esenzione dall'autenticazione e richiede l'autenticazione per la transazione.",
	ADYEN_ERR_39_: "L'emittente o lo schema non è stato in grado di comunicare l'esito tramite RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Il nome non deve contenere caratteri speciali (esempio: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Il nome deve contenere al massimo 74 caratteri",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Il cognome non deve contenere caratteri speciali (esempio: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Il cognome deve contenere al massimo 74 caratteri",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Il numero di cellulare non deve contenere caratteri speciali (esempio: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Il numero di cellulare deve contenere al massimo 44 caratteri",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Il numero di telefono non deve contenere caratteri speciali (esempio: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Il numero di telefono deve contenere al massimo 44 caratteri",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Indirizzo e-mail non valido",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "l'indirizzo e-mail deve deve contenere al massimo 99 caratteri",
	ConfirmGeolocationModal_ConfirmButton: "Continua",
	ConfirmGeolocationModal_CancelButton: "Cambia",
	ConfirmGeolocationModal_CurrentCountrySelection: "Il paese attualmente selezionato è:",
	ConfirmGeolocationModal_AskChangeCountry: "Vuoi rimanere su questo sito o cambiare la preferenza del tuo Paese?",
	Addtocart_Modal_AddToCartFailTitle: "Partecipante già registrato a questo corso",
	Addtocart_Modal_AlreadyInCart: "Partecipante già registrato a questo evento",
	TrainingCatalogue_DateRange: "Intervallo di date",
	TrainingCatalogue_DateRange_placeholder: "Seleziona un intervallo di date per il filtro",
	Dashboard_MyPendingCourses: "I miei corsi in attesa",
	Dashboard_MyWaitingList: "La mia lista di prenotazione",
	Dashboard_LatestNews: "Ultime news",
	SettingsMainPage_EditAccountButton: "Modifica Account",
	SettingsMainPage_EditBoschId: "Modifica SingleKey ID",
	EventInfoView_EventDetailTab: "Dati dell'evento",
	EventInfoView_AssignmentsTab: "Incarichi",
	EventInfoView_EvaluationTab: "Valutazione",
	EventInfoView_AttachmentTab: "Allegati",
	EventInfoView_CourseDescriptionTab: "Descrizione",
	EventInfoView_DescriptionTab_General: "Generale",
	EventInfoView_DescriptionTab_PartLabel: "Sezione",
	AssignmentTab_Description: "Qui troverai tutte le informazioni necessarie per completare questo corso",
	AssignmentTab_Columns_Download: "Scarica",
	AssignmentTab_Columns_Upload: "Carica",
	AssignmentTab_Columns_TaskComplete: "Azione completata",
	EventDetailTab_Location_Message_Video: "Questo è un evento online a cui è possibile accedere utilizzando il collegamento \"Avvia video\".",
	EventDetailTab_Location_Message_WebBased: "Questo è un evento online a cui è possibile accedere utilizzando il collegamento \"Avvia WBT\".",
	EventDetailTab_Location_Message_WebCast: "Questo è un evento online a cui è possibile accedere utilizzando il collegamento \"Partecipa ora\".",
	EventDetailTab_Link_Video: "Avvia il video",
	EventDetailTab_Link_Video_Remark: "*Avviando il video si aprirà una nuova finestra, verrai reindirizzato a questa pagina una volta chiusa la finestra",
	EventDetailTab_Link_WebBased: "Avvia il WBT",
	EventDetailTab_Link_WebBased_Remark: "*Avviando il WBT si aprirà una nuova finestra, verrai reindirizzato a questa pagina una volta chiusa la finestra",
	EventDetailTab_Link_WebCast: "Partecipa ora",
	EventDetailTab_Link_WebCast_Remark: "Il collegamento \"Partecipa ora\" sarà attivo 30 minuti prima dell'inizio dell'evento",
	EventDetailTab_Title_Status: "Stato",
	EventDetailTab_Title_Duration: "Durata (ore)",
	EventDetailTab_Title_Trainers: "Formatore(i)",
	EventDetailTab_Title_Language: "Lingua",
	EventDetailTab_Title_Score: "Punteggio (%)",
	EventDetailTab_Title_Calendar: "Aggiungi al calendario",
	EventDetailTab_Title_Certificate: "Scarica attestato",
	EventDetailTab_Title_Messages: "Messaggi",
	EventDetailTab_Title_StartDate: "Data d'inizio",
	EventDetailTab_Title_AvailableFrom: "Disponibile dal",
	EventDetailTab_Title_Time: "Orario",
	EventDetailTab_Title_EndDate: "Data di fine",
	EventDetailTab_Title_ExpiresOn: "Scade il",
	EventDetailTab_Title_Room: "Stanza",
	EventDetailTab_Title_Method: "Metodo",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Luogo",
	AttachmentTab_EmptyScreenDescription: "Non ci sono allegati per questo evento",
	AttachmentTab_Columns_Download: "Scarica",
	AttachmentTab_Description: "Qui troverai alcune informazioni importanti per aiutarti con questo corso",
	EvaluationTab_Annotation: "Apprezziamo il tuo feedback e ci piacerebbe avere la tua opinione per sapere come stiamo andando. Ti preghiamo di dedicare un po' di tempo a compilare la seguente valutazione per aiutarci a migliorare continuamente il nostro servizio. Grazie!",
	EvaluationTab_Notice: "Le valutazioni sono le seguenti",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Rispondi a tutte le domande obbligatorie prima di inviare la valutazione dell'evento",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Il tuo feedback è stato inviato con successo",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "L'invio della valutazione non è riuscito.",
	TrainingCatalogueLayout_ExportButtonText: "Esporta",
	EvaluationTab_FeedbackHasBeenGiven_Message: "La valutazione è stata completata o è scaduta",
	EventDetailTab_Link_CopiedTooltip: "Copiato negli appunti",
	EventDetailTab_QRCode_ExplanationText_WBT: "Scansiona per avviare WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Scansiona per avviare il video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Scansiona per avviare il Webcast",
	Footer_TrainingCoure_Headline: "Corsi di formazione offerti da Bosch",
	Footer_TrainingCourse_Content: "In qualità di Superbrand di fama mondiale e con oltre un secolo di esperienza nell'innovazione, Bosch ha stabilito gli standard a livello globale come fornitore di sistemi automobilistici, componenti e soluzioni tecniche. Gran parte della nostra forza deriva dalle nostre strette collaborazioni con i produttori di veicoli, per i quali progettiamo, sviluppiamo e produciamo sistemi e componenti all'avanguardia in tutto il mondo. Di conseguenza, Bosch ha consolidato una solida storia nei prodotti per il mercato dei ricambi automobilistici e nel supporto con un portafoglio diversificato di parti, soluzioni diagnostiche e servizi per l'officina. L'apprendimento e lo sviluppo sono chiavi sicure del successo e questa filosofia è supportata da un'ampia gamma di corsi di formazione, in tutte le aree della tecnologia automobilistica, dei sistemi dei veicoli e delle competenze diagnostiche.",
	SearchField_NoRecordsFound: "Nessun dato trovato",
	TrainingCatalogue_FreeTextSearch: "Testo libero",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Cerca",
	CheckoutLayout_WholeSalerGroup: "Seleziona gruppo rivenditore",
	CreditInsufficientModal_Message: "Non disponi di credito sufficiente per utilizzare questo metodo di pagamento, riprova con un altro metodo di pagamento",
	ShoppingCart_Required_Credits: "Costo totale",
	MaintainingInfo_Message1: "La pagina Web è in manutenzione",
	MaintainingInfo_Message2: "Torneremo presto!",
	CountryValidationModal_Message: "Non puoi accedere a [CountryA] se il tuo Account è stato creato in [CountryB]",
	CountryValidationModal_ButtonText: "Continua con [Country]",
	EventTable_LoginRequired: "Accedi per registrarti",
	TrainingPartTableLayout_Date: "Data",
	TrainingPartTableLayout_StartTime: "Ora d'inizio",
	TrainingPartTableLayout_EndTime: "Tempo scaduto",
	TrainingPartTableLayout_Duration: "Durata (ore)",
	TrainingPartTableLayout_TrainingMethod: "Modalità di formazione",
	MyCoursesTableContents_ActiveHeader: "Corsi attivi",
	MyCoursesTableContents_ActiveTabHeader: "Attivo",
	MyCoursesTableContents_ScheduledHeader: "Corsi programmati",
	MyCoursesTableContents_ScheduledTabHeader: "Programmato",
	MyCoursesTableContents_OnlineHeader: "Corsi online",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Corsi annullati",
	MyCoursesTableContents_CancelledTabHeader: "Annullato",
	OnlineCoursesTable_Title_Progress: "In lavorazione",
	ScheduledCoursesTable_Title_Date: "Data [giorni]",
	CoursesTable_Title_Completed: "Completato",
	Footer_TrainingProgram: "Programmi di formazione",
	Breadcrumb_TrainingProgram: "Programmi di formazione",
	TrainingProgram_Title_Table: "Titolo",
	TrainingProgram_TrainingProgram: "Programmi di formazione",
	TrainingProgram_TargetGroup: "Gruppo di appartenenza",
	TrainingProgram_MaxLength: "Durata complessiva",
	TrainingProgram_Information: "Informazioni",
	TrainingProgram_Detail: "Dettagli",
	TrainingProgram_Modules: "Moduli",
	TrainingProgram_Course: "Corsi",
	TrainingProgram_PartNumber: "Codice",
	TrainingProgram_Max_Length: "Durata complessiva",
	TrainingProgram_Duration: "Durata",
	TrainingProgram_Exam: "Esame",
	TrainingProgram_Assign_Popup: "Registrazione al programma di formazione",
	TrainingProgram_Student: "Partecipante",
	TrainingProgram_Assign: "Assegnato",
	TrainingProgram_Popup_Remove_Title: "Vuoi cancellare il corso?",
	TrainingProgram_Description: "Descrizione",
	TrainingProgram_Days: "Giorni",
	TrainingProgram_Type: "Tipo",
	TrainingProgram_Action: "Azione",
	TrainingProgram_Enrolled: "Sei già stato iscritto a questo programma di formazione",
	TrainingProgram_SelfAssign_Enrolled: "Risulti già assegnato a questo programma di formazione",
	TrainingProgram_Warning: "Registra un altro partecipante o seleziona un programma di formazione diverso",
	TrainingProgram_Workshop: "Officina",
	TrainingProgram_Durations: "anni",
	TrainingProgram_Duration_DaysLabel: "giorni",
	TrainingProgram_HasExam_Yes: "Si",
	TrainingProgram_HasExam_No: "No",
	AssignModal_SuccessModal_Header: "Congratulazioni!",
	AssignModal_SuccessModal_Message: "Sei stato iscritto con successo a questo programma di formazione",
	AssignModal_SelfAssign_SuccessModal_Message: "Sei stato assegnato con successo a questo programma di formazione",
	Dashboard_MyTraningProgram: "Il mio programma di formazione",
	Dashboard_MyTraningProgram_Title: "Titolo",
	Dashboard_MyTraningProgram_Status: "Stato",
	Dashboard_MyTraningProgram_Table_View: "Panoramica",
	Dashboard_MyTraningProgram_Register: "Registrazione",
	Dashboard_MyTraningProgram_Records: "Storico",
	Dashboard_MyTrainingProgram_In_Progress: "In corso",
	Dashboard_MyTrainingProgram_Accredited: "Accreditato",
	Dashboard_Program_Modal_Withdraw: "Disdetta",
	Dashboard_Program_Modal_Grade: "Grado",
	Dashboard_MyTrainingProgram_Registered: "Registrato",
	Dashboard_MyTrainingProgram_Completedd: "Completato",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Sì",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "No",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Tieni presente che la disdetta significa che ti ritirerai dal programma di formazione completo, non solo dal singolo corso di formazione",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Desideri comunque ritirarti dal programma di formazione?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "In sospeso",
	WithdrawProgramModal_Withdraw_Success_Message: "Ti sei ritirato con successo dal programma di formazione",
	Common_Warning_Title: "Attenzione!",
	Common_Error_Title: "Oooooopppsss, qualcosa non ha funzionato!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Aggiungi al carrello",
	AddEventFromCatalogToCartModal_HoursText: "ore",
	AddElearningToCartSuccessModal_Message: "è stato aggiunto al carrello",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Il partecipante selezionato è stato registrato a questo corso, oppure il corso era già stato aggiunto al carrello",
	EventDetailTab_SubjectForChange_Message: "Soggetto a modifiche",
	Dashboard_MyTrainingProgram_Status_Failed: "Fallito",
	Dashboard_MyTrainingProgram_Status_Passed: "Passato",
	Dashboard_MyTrainingProgram_Status_Merit: "Merito",
	Dashboard_MyTrainingProgram_Status_Distinction: "Distinzione",
	Dashboard_Employee_Courses: "Corsi",
	Dashboard_Employee_Program: "Programmi di formazione",
	Dashboard_Employee_WaitList: "Lista d'attesa",
	Dashboard_Employee_Account: "Account",
	Course_Price_Free: "Incl.nella fee",
	Dashboard_Employee_Account_Update_Success_Message: "Hai aggiornato con successo i dati del tuo dipendente!",
	Dashboard_Supplier: "Rivenditore",
	Event_Detail_Comment: "Al momento non ci sono messaggi",
	DownLoad_PDF: "Scarica PDF",
	TrainingCatalogue_Currency: "Valuta",
	My_Wallet: "I miei crediti",
	Wallet: "Crediti",
	Credits_Balance: "Saldo dei crediti",
	MyFullCalendar_Today: "Oggi",
	Header_Available_Languages: "Lingua disponibile",
	Header_CurrentRegion: "Attualmente sei in",
	Header_ChangeRegions: "Vuoi cambiare Paese?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Login/Registrazione",
	Header_RegisterCourse: "Iscriviti al corso",
	Homepage_Start: "Registrati",
	Homepage_Login: "Login",
	MainPage_Title: "La soluzione moderna per l'officina",
	MainPage_Content: "La tua officina, la tua scelta",
	MainPage_ImageTitle: "Bosch Automotive Training Solutions",
	MainPage_IntroContent: "Consente all'officina moderna di lavorare in modo professionale, affidabile ed efficiente nell'ambito di procedure diagnostiche, risoluzione dei problemi, riparazioni e assistenza di tutti i tipi di veicoli.",
	BoschNews_TrainingNews: "Notizie sui corsi",
	Service_Assist_Workshop: "Migliora la tua esperienza con Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Cosa posso fare con l'app?",
	Enhance_Body_CDM3_Item_1: "Iscriviti ai corsi di formazione",
	Enhance_Body_CDM3_Item_2: "Scarica gli attestati dei corsi",
	Enhance_Body_CDM3_Item_3: "Avvia i corsi eLearning",
	Enhance_Body_CDM3_Item_4: "Accedi a vari servizi automobilistici",
	Enhance_Body_CDM3_Item_5: "Acquista e avvia una sessione di Diagnosi Remota",
	Enhance_Body_CDM3_Item_6: "Trova i dati principali di un veicolo scansionando la targa o il VIN",
	Training_Stories: "Esperienze di formazione",
	SettingsMyAccount_JoinCompanyButton: "Collega l'azienda",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Collega l'azienda",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Inserisciil tuo codice di attivazione qui sotto",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Nota! È necessario contattare l'amministratore della tua azienda per ottenere un codice di attivazione.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "La tua attivazione è stata elaborata con successo e il tuo account è ora collegato alla tua azienda.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Clicca su Continua per accedere alla pagina web",
	ContinueButton: "Continua",
	WelcomeBatsModal_Title: "Il tuo account è attualmente in revisione!",
	WelcomeBatsModal_ActivationLink: "Clicca qui se hai un codice di attivazione per entrare a far parte di un'azienda!",
	WelcomeBatsModal_ActivationLinkNotes: "Attenzione! È necessario contattare l'amministratore dell'azienda per ottenere un codice di attivazione.",
	AccountInReviewModal_Description_Paragraph1: "Grazie per aver registrato un account personale su Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Il nostro personale dedicato alla formazione sta attualmente rivedendo e configurando il tuo account. Riceverai la conferma finale via mail, una volta che il tuo account è stato configurato con successo.",
	AccountInReviewModal_CloseButton: "Okay! Fammi entrare!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Non è stato possibile di convalidare il codice di attivazione e l'indirizzo e-mail.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Riprova o contatta l'amministratore della tua azienda per assistenza.",
	TryAgain_Button: "Riprova",
	Error_Title: "Oops, qualcosa è andato storto!",
	SettingsMyProfileActivationSuccessModal_Title: "Successo!",
	AccountInReviewModal_Description_Paragraph3: "Nel frattempo, non esitate a navigare nel nostro sito Web con accesso limitato.",
	AccountInReviewModal_Description_Paragraph4: "In caso di domande o se il tuo account non viene elaborato entro 48 ore esclusi i fine settimana e i giorni festivi, puoi contattarci [qui]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Qualche indicazione per aiutarti",
	Support_FAQ_Headline: "Domande frequenti",
	Support_FAQs_section1_title: "Generale",
	Support_FAQs_section1_question1: "Che cos'è Bosch Training Solutions",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions è una piattaforma che fornisce una panoramica completa sui corsi di formazione tecnica e consente di prenotare e gestire corsi di formazione",
	Support_FAQs_section1_question2: "Posso utilizzare il mio account Bosch esistente (SingleKey ID) per accedere a Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Sì, è accessibile con tutti gli account creati con \"SingleKey ID\"",
	Support_FAQs_section1_question3: "Posso utilizzare un account creato per Bosch Training Solutions con altri servizi o applicazioni Bosch?",
	Support_FAQs_section1_question3_answer1: "Sì, puoi utilizzare il tuo SingleKey ID personale anche per una varietà di altre applicazioni Bosch, sia professionali che private, come l'e-bike o la casa smart.",
	Support_FAQs_section2_title: "Formazione",
	Support_FAQs_section2_question1: "Come prenotare un corso ?",
	Support_FAQs_section2_question1_answer1: "Accedi con il tuo account esistente OPPURE registra un account nel portale. Vai a \"Servizi di formazione\" OPPURE \"Calendario eventi\" seleziona una formazione e fai clic su aggiungi",
	Support_FAQs_section2_question2: "Non è disponibile alcuna data di formazione adeguata. Cosa posso fare?",
	Support_FAQs_section2_question2_answer1: "Hai la possibilità di inserirti in una lista d'attesa \"generale\" (senza selezionare una data) e il nostro team ti informerà non appena sarà disponibile un nuovo corso di formazione.",
	Support_FAQs_section2_question3: "Dove posso trovare una panoramica dei miei corsi prenotati?",
	Support_FAQs_section2_question3_answer1: "Puoi trovare una panoramica completa di tutti i corsi prenotati e completati nella tua dashboard una volta effettuato il login.",
	Support_FAQs_section3_title: "Impostazioni",
	Support_FAQs_section3_question1: "Come modificare la mia e-mail e password?",
	Support_FAQs_section3_question1_answer1: "Devi entrare nel portale ed accedere al tuo profilo personale: all'interno della tua Dashboard puoi trovare le informazioni del tuo profilo o se fai clic sul seguente tasto nell'intestazione",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Lì hai la possibilità di modificare i dettagli del tuo SingleKey ID.",
	Support_FAQs_section3_question2: "Cosa fare se dimentico la mia password?",
	Support_FAQs_section3_question2_answer1: "Utilizza il tasto di accesso/registrazione e inserisci il tuo indirizzo e-mail",
	Support_FAQs_section3_question2_answer2: "Se clicchi su continua hai la possibilità di fare clic su \"Password dimenticata\"",
	Support_FAQs_section3_question3: "Come creare un account di un'officina?",
	Support_FAQs_section3_question3_answer1: "Per ottenere un ruolo per l'officina, contatta il tuo amministratore locale per la formazione, ad es. è possibile utilizzare il modulo di contatto sul sito web.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Scegli il tuo paese / regione",
	MaintenanceBanner_Message_1: "Il portale Bosch Automotive Training Solutions verrà aggiornato il [maintenance_schedule]. L'aggiornamento dovrebbe richiedere 30 minuti. Il Portale non sarà disponibile durante l'aggiornamento.",
	MaintenanceBanner_Message_2: "Per ulteriore assistenza, contattaci via e-mail [support_email]",
	MaintenancePage_Message_1: "Il portale Bosch Automotive Training Solutions è attualmente in fase di aggiornamento",
	MaintenancePage_Message_2: "Prevediamo che il Portale sarà nuovamente disponibile il [maintenance_online_date] alle [maintenance_online_time]",
	MenuHeader_Support: "Assistenza",
	Course_Detail_No_Prerequisites: "Nessun prerequisito",
	TableCell_Hours: "Ore",
	Dashboard_MenuItem_Dashboard: "Dashboard",
	Dashboard_MenuItem_Curricula: "Programmi",
	Dashboard_MenuItem_Certificates: "Attestati",
	Dashboard_MenuItem_Team: "Team",
	Dashboard_MenuItem_Workshops: "Officine",
	Dashboard_MenuItem_External: "Delegati",
	Dashboard_MenuItem_Wallet: "Crediti",
	Dashboard_MenuItem_Profile: "Profilo",
	Dashboard_Headline_YourUpcomingCourses: "I tuoi prossimi corsi",
	Dashboard_Headline_YourELearningCourses: "I tuoi corsi eLearning",
	Dashboard_Headline_TeamOverview: "Panoramica del team",
	Dashboard_Headline_YourOverview: "Panoramica",
	Dashboard_Headline_YourWishlist: "La tua lista d'attesa",
	Dashboard_Headline_ActivePrograms: "Programmi attivi",
	Dashboard_Headline_CompletedPrograms: "Programmi completati",
	Dashboard_Headline_YourCertificates: "I tuoi attestati",
	Dashboard_Headline_Transactions: "Transazioni",
	Dashboard_Table_NoRecordsToDisplay: "Nessuna riga da visualizzare",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "ore",
	Dashboard_ELearningCourses_TableHeader_Title: "Titolo",
	Dashboard_ELearningCourses_TableHeader_Duration: "Durata (ore)",
	Dashboard_ELearningCourses_ActionButton_View: "Visualizzazione",
	Dashboard_UpcomingCourses_TableHeader_Title: "Titolo",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Data (giorni)",
	Dashboard_UpcomingCourses_TableHeader_Location: "Luogo",
	Dashboard_UpcomingCourses_ActionButton_View: "Visualizzazione",
	Dashboard_YourOverview_TableHeader_Title: "Titolo",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Data / Durata",
	Dashboard_YourOverview_TableHeader_Location: "Luogo",
	Dashboard_YourOverview_TableHeader_Status: "Stato",
	Dashboard_YourOverview_ActionButton_View: "Visualizzazione",
	Dashboard_YourWishlist_TableHeader_Title: "Titolo",
	Dashboard_YourWishlist_TableHeader_Action: "Azione",
	Dashboard_YourWishlist_ViewAllRecords: "Visualizza tutte le righe",
	Dashboard_TeamOverview_TableHeader_Employee: "Dipendente",
	Dashboard_TeamOverview_TableHeader_Title: "Titolo",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Data / Durata",
	Dashboard_TeamOverview_TableHeader_Location: "Luogo",
	Dashboard_TeamOverview_TableHeader_Status: "Stato",
	Dashboard_TeamOverview_ActionButton_View: "Visualizzazione",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Cerca",
	Dashboard_ActiveProgram_TableHeader_Title: "Titolo",
	Dashboard_ActiveProgram_TableHeader_Status: "Stato",
	Dashboard_ActiveProgram_ActionButton_View: "Visualizzazione",
	Dashboard_CompletedProgram_TableHeader_Title: "Titolo",
	Dashboard_CompletedProgram_TableHeader_Status: "Stato",
	Dashboard_CompletedProgram_ActionButton_View: "Visualizzazione",
	Dashboard_Certificates_TableHeader_Title: "Titolo",
	Dashboard_Certificates_TableHeader_Date: "Data",
	Dashboard_Certificates_TableHeader_Status: "Stato",
	Dashboard_Certificates_TableHeader_Action: "Azione",
	Dashboard_Certificates_ActionButton_Download: "Download",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Numero di fattura",
	Dashboard_Transactions_TableHeader_Description: "Descrizione",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Metodo di pagamento",
	Dashboard_Transactions_TableHeader_Date: "Data",
	Dashboard_Transactions_TableHeader_Amount: "Quantità",
	Dashboard_Transactions_ActionButton_Download: "Download",
	Breadcrumb_MyTraining: "La mia formazione",
	Breadcrumb_MyTraining_CourseDetails: "Dettagli del corso",
	Breadcrumb_MyTraining_Curricula: "Programmi",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Dettagli del programma di formazione",
	Breadcrumb_MyTraining_Team: "Team",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Visualizza dipendente",
	MyTraining_Headerbar_CourseDetails: "Dettagli del corso",
	MyTraining_Headerbar_TrainingProgramDetails: "Dettagli del programma di formazione",
	TrainingProgramDetails_Label_Status: "Stato",
	TrainingProgramDetails_Label_OverallProgress: "Progressi generali",
	TrainingProgramDetails_ProgramItems_Headline: "Elementi del programma",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Titolo",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Stato",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Grado",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Data",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Visualizzazione",
	TrainingProgramDetails_Status_InProgress: "In corso",
	TrainingProgramDetails_Status_Completed: "Completato",
	CourseDetails_Label_Status: "Stato",
	CourseDetails_Label_Duration: "Durata",
	CourseDetails_Label_Trainer: "Formatore",
	CourseDetails_Label_Language: "Lingua",
	CourseDetails_Label_Score: "Punteggio",
	CourseDetails_Label_StartDate: "Data inizio",
	CourseDetails_Label_EndDate: "Data fine",
	CourseDetails_Label_Venue: "Sede",
	CourseDetails_Label_Address: "Indirizzo",
	CourseDetails_Label_Grade: "Grado",
	CourseDetails_Action_Withdraw: "Recesso",
	CourseDetails_Action_Evaluate: "Valuta corso",
	CourseDetails_Action_Evaluate_Description: "Si prega di valutare questo corso per generare l'attestato!",
	CourseDetails_Action_DownloadCertificate: "Scarica attestato",
	CourseDetails_Action_JoinWebcast: "Partecipa al webcast",
	CourseDetails_Action_StartCourse: "Inizia il corso",
	CourseDetails_Action_RestartCourse: "Riavvia il corso",
	CourseDetails_Action_RepeatCourse_Description: "Si prega di ripetere e superare questo corso per scaricare l'attestato!",
	CourseDetails_PartSpecificDetails_Headline: "Dettagli specifici di questa parte",
	CourseDetails_PartSpecificDetails_Tab_Part: "Parte",
	CourseDetails_PartSpecificDetails_Description: "Descrizione",
	CourseDetails_PartSpecificDetails_StartDate: "Data inizio",
	CourseDetails_PartSpecificDetails_EndDate: "Data fine",
	CourseDetails_PartSpecificDetails_StartTime: "Ora inizio",
	CourseDetails_PartSpecificDetails_EndTime: "Ora fine",
	CourseDetails_PartSpecificDetails_Method: "Metodo",
	Dashboard_Team_TableHeader_Name: "Nome",
	Dashboard_Team_TableHeader_Role: "Ruolo",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Account personale",
	Dashboard_Team_ActionButton_Select: "Seleziona",
	Dashboard_Team_PersonalAccount_Activated: "Attivato",
	Dashboard_Team_PersonalAccount_NotActivated: "Non attivato",
	Dashboard_Team_ActionButton_AddMember: "Aggiungi collaboratore",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Cerca",
	Dashboard_External_TableHeader_Name: "Nome",
	Dashboard_External_ActionButton_AddNew: "Aggiungi nuovo",
	Dashboard_External_Headline: "Accesso esterno approvato",
	Dashboard_Workshops_Headline: "Officine",
	Dashboard_Workshops_TableHeader_Company: "Azienda",
	Dashboard_Workshops_TableHeader_Address: "Indirizzo",
	Dashboard_Workshops_TableHeader_Postcode: "CAP",
	Dashboard_Workshops_TableHeader_City: "Città",
	Dashboard_Workshops_ActionButton_Select: "Seleziona",
	Wallet_ServiceCredits: "Crediti di servizio",
	Wallet_Headline_CreditBalance: "Saldo a credito",
	Wallet_Headline_CreditActivity: "Attività creditizia",
	Wallet_Headline_BillingTransactions: "Operazioni di fatturazione",
	Wallet_BillingTransaction_TableHeader_Order: "Ordine",
	Wallet_BillingTransaction_TableHeader_Date: "Data",
	Wallet_BillingTransaction_TableHeader_Amount: "Importo",
	Wallet_BillingTransaction_TableHeader_Action: "Azione",
	Wallet_CreditActivity_TableHeader_Date: "Data",
	Wallet_CreditActivity_TableHeader_Description: "Descrizione",
	Wallet_CreditActivity_TableHeader_User: "User",
	Wallet_CreditActivity_TableHeader_Amount: "Importo",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, all rights reserved",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Sicurezza del prodotto (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovazioni, brevetti e licenze",
	FooterAdditionalLinks_Navigation_Logistics: "Acquisti e logistica",
	MenuHeader_MyTraining: "La mia formazione",
	MenuHeader_TrainingServices: "Corsi e programmi",
	MenuHeader_OurLocations: "Le nostre sedi",
	MenuHeader_ContactsUs: "Contatti",
	MenuHeader_Logout: "Logout",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "My SingleKey ID",
	MyProfile_AccountDeletion: "Cancellazione dell'account",
	MyProfile_Edit: "Cambia email o modifica password",
	MyProfile_Profile: "Il mio profilo",
	MyProfile_EditUserProfile: "Modifica profilo utente",
	BackButton: "Indietro",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Attendiamo la vostra chiamata e saremo lieti di aiutarvi.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Inserisci i caratteri corretti come indicato nella casella in alto (inserisci anche gli spazi tra i caratteri che vedi)",
	ContactUs_Email_MandatoryNotice_V3: "Compila tutti i campi richiesti.",
	ContactUs_Email_SendButton: "Invia",
	Breadcrumb_TrainingCenters: "Centri di formazione",
	Training_Centres_Book_Btn: "Prenota un corso",
	Training_centres_booking_code: "Codice di prenotazione",
	Training_centers_header_bar_title: "I nostri centri di formazione",
	Training_centers_filter_item: "Centro",
	TeamEdit_PersonalAccountToolTop: "L'abilitazione di questa funzione consente al dipendente di accedere a questo sito web. L'utente potrà creare un account personale e far parte del tuo account aziendale. Una volta abilitato, puoi inviare un invito con un codice di attivazione al dipendente. L'invito verrà inviato all'indirizzo email.",
	TeamEdit_Update: "L'abilitazione della funzione di amministratore aziendale fornisce automaticamente a questo dipendente l'accesso completo al tuo account aziendale. L'abilitazione di questa funzione richiede che l'utente si disconnetta e rientri nel sito web, se l'utente ha già effettuato l'accesso.",
	TeamEdit_PersonalAccount: "Consenti l'account Web Bosch Training Solutions personale",
	TeamEdit_SendInvitation: "Inviare invito",
	TeamEdit_Deactivate: "Disattivare",
	TeamEdit_Telephone: "Telefono",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "I cellulari devono essere numeri",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "I telefoni devono essere numeri",
	TeamEdit_Activate: "Attivare",
	TeamEdit_NotActivated: "Non attivare",
	TeamEdit_StatusPopup: "Lo stato verrà mostrato come \"Attivo\" se l'utente ha creato correttamente un account personale. Se lo stato è impostato su \"Non attivo\", significa che l'utente non ha completato il tuo invito.",
	Training_Catalog_Courses: "Corsi",
	Training_Catalog_Course: "Corso",
	Reset_Filter: "Reimposta filtro",
	TrainingCatalogue_CourseCatalogue: "Catalogo corsi",
	CheckoutHeader_CartItemCount_Text: "Hai [count] articoli nel tuo carrello",
	CheckoutHeader_CartItemsCount_Text: "Hai [count] articoli nel tuo carrello",
	ShoppingCart_PriceItem: "Prezzo dell'articolo",
	ShoppingCartItemParticipants_AddParticipant_Button: "Aggiungi partecipanti",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotale",
	ShoppingCartTotalPrice_TaxesLabel: "Tasse",
	ShoppingCartTotalPrice_TotalLabel: "Totale",
	ShoppingCartTotalPrice_IncludedVATLabel: "Include l'IVA of [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Disponibile modalità di pagamento online",
	ShoppingCartItemRow_VenueFeeLabel: "Tariffa della sede [fee_amount] per partecipante",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Aggiungi partecipante",
	AddParticipantsModal_SelectEmployeeLabel: "Aggiungi partecipante",
	AddParticipantsModal_SelectWorkshopLabel: "Seleziona officina",
	ShoppingCartTotalPrice_ContinueButtonText: "Procedi all'ordine",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Il partecipante non è stato aggiunto!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Hai uno o più corsi nel tuo carrello senza partecipanti.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Aggiungi un partecipante per continuare il checkout.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Modifica partecipanti ([num_partecipants])",
	AddParticipantsModal_ParticipantTable_Name: "Nome",
	AddParticipantsModal_ParticipantTable_Email: "E-mail",
	AddParticipantsModal_ParticipantTable_Action: "Azione",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Nessun partecipante ...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Indirizzo e-mail non configurato del partecipante selezionato",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Il partecipante selezionato non ha un indirizzo e-mail configurato nel proprio profilo. L'indirizzo e-mail è obbligatorio per l'iscrizione a un corso di formazione.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Aggiungi l'indirizzo e-mail per continuare o annulla per selezionare un altro partecipante.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Aggiungi e-mail",
	CancelButton: "Cancella",
	AddParticipantsModal_AddEmployeeButtonText: "Aggiungi dipendente",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Stai per eliminare un corso dal tuo carrello",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Stai tentando di rimuovere dal carrello un corso con partecipanti aggiunti.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Fare clic su \"Continua\" se si desidera procedere o fare clic su \"Annulla\".",
	ShoppingCartItemHeader_Participants: "Partecipante/i",
	ShoppingCartItemHeader_ParticipantPrice: "Costo a persona",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Mostra in [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Mostra nei crediti",
	ShoppingCart_CreditsPriceUnit: "Crediti",
	CheckOutHeader_Step1_Question: "Come vuoi pagare ?",
	CheckOutHeader_Step2_Question: "Verifica il tuo ordine",
	CheckOutHeader_Step3_Question: "Completa il tuo ordine",
	CheckOutHeader_Step4_Question: "La tua prenotazione è andata a buon fine!",
	CheckOutHeader_Step_Payment: "Pagamento",
	CheckOutHeader_Step_Review: "Verifica",
	CheckOutHeader_Step_Place_Order: "Invia ordine",
	CheckOutRightPanel_Review_Order_Btn: "Verifica ordine",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Invia ordine",
	CheckoutRightPanelContent_OrderSummary_Title: "Riepilogo ordine",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Modifica carrello",
	ShoppingCart_Participants: "Partecipanti",
	CheckOutStepPayment_Payment_Methods_Title: "Seleziona la modalità di pagamento (se non presente chiedi al tuo distributore o ricambista di riferimento oppure attraverso la sezione “Contatti”)",
	CheckOutStepPayment_Credit_Title: "Credito o debito",
	CheckOutStepPayment_Wholesaler_Title: "Rivenditore",
	CheckOutStepPayment_ServiceCredit_Title: "Crediti di servizio",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Hai [available_credits] crediti",
	CheckOutStepPayment_BoschAccount_Title: "Account Bosch",
	Checkout_Second_PaymentMethod_Headline: "Modalità di pagamento",
	CheckOutStepReview_Edit: "Modifica",
	CheckOutStepReview_Terms_Label: "Termini, condizioni e policy di cancellazione",
	CheckOutStepReview_Terms_Text: "Ho letto, compreso e accetto i [terms, conditions and cancellation policy] di vendita.",
	SelectSecondPaymentMethodModal_Title: "Seleziona la seconda modalità di pagamento",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Paga [amount]",
	StepPaymentWholesaler_Title: "Seleziona rivenditore",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Accetta Termini e Condizioni",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Si prega di leggere e accettare i nostri termini, condizioni e policy di cancellazione prima di continuare.",
	CheckoutDescription_ThankYou: "Grazie per aver inviato un ordine su Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Abbiamo già iniziato a processare il tuo ordine. Resta in attesa!",
	CheckoutDesctiption_Text: "Ti invieremo una conferma finale via mail con tutti i dettagli della tua prenotazione.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Stai per rimuovere l'officina selezionata",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Rimuovendo l'officina selezionata, tutti i partecipanti aggiunti verranno cancellati. Questa modifica interesserà tutti i corsi nel tuo carrello.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Fare clic su \"Continua\" se si desidera procedere o fare clic su \"Annulla\" per interrompere questa azione.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Hai crediti insufficienti. Il tuo saldo di credito è di [credito_disponibile] crediti, ma hai bisogno di [crediti_totale_carta] crediti per continuare il pagamento. Seleziona un altro metodo di pagamento o rimuovi gli articoli dal carrello.",
	Training_centers_filter_items: "Centri",
	Training_Programs_Filter_Item: "Programmi",
	Training_Program_Filter_Item: "Programma",
	Training_Program_Year: "Anno/i",
	Training_Program_Detail_Title: "Programmi dei corsi di formazione",
	AccountButton_Workshops_MenuItem: "Officine",
	AccountButton_Wallet_MenuItem: "Crediti",
	Header_MyBoschProfile_Header: "Il mio profilo Bosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Disponibile",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Aggiungi/modifica participante",
	ShoppingCart_CreditPriceUnit: "Credito",
	Common_IncludedVAT: "incl.IVA",
	TrainingCatalogue_Length: "Durata",
	TrainingCatalogue_Method: "Metodo",
	Course_Price_Credits: "Crediti",
	Catalogue_Grid_Register_Button: "Registrati",
	Catalogue_Credit_Tilte: "Clicca qui per vedere i prezzi in crediti",
	Catalogue_Price_Tilte: "Clicca qui per vedere i prezzi in euro",
	Catalogue_Grid_Vat: "IVA escl.",
	EventCalendar_Seats: "Posti",
	MenuHeader_Calendar: "Calendario",
	MenuHeader_Catalog: "Catalogo",
	TableCell_Minutes: "Minuti",
	ListFilter_NoResult: "Nessun risultato",
	Course_Description: "Descrizione corso",
	Training_course_detail: "Social Media Basics",
	Training_course_detail_add_waiting: "Aggiungi alla lista d'attesa generale",
	Register_now_btn: "Registrati adesso!",
	Register_now_btn_add_event_to_cart: "Registrati adesso!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Visualizza tutte le registrazioni",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Vedi meno",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Visualizza tutte le registrazioni",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Vedi meno",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Visualizza tutte le registrazioni",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Vedi meno",
	TeamCreate_TitleError: "E' richiesto un titolo",
	Dashboard_Headerbar_BookCourse: "Prenota un corso",
	Dashboard_Headerbar_ExitProxy: "Abbandona delega",
	Dashboard_Headerbar_EvaluateCourse: "Valuta corso",
	Dashboard_Headerbar_EvaluateCourse_Note: "Valuta questo corso per generare il tuo attestato!",
	Dashboard_Headerbar_DownloadCertificate: "Scarica attestato",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Cancella",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Cancella",
	Dashboard_Headerbar_WelcomeMessage: "Benvenuto sulla piattaforma per la tua formazione",
	Dashboard_Headerbar_ProxingAs: "Delegato di",
	Dashboard_TrainingProgram_Status_Completed: "Completato",
	Dashboard_TrainingProgram_Status_InProgress: "In corso",
	External_SelectWholesalerModal_Title: "Aggiungi accesso utente esterno",
	External_SelectWholesalerModal_Company_Label: "Azienda",
	External_SelectWholesalerModal_Outlet_Label: "Centro di Formazione",
	External_SelectWholesalerModal_Company_Placeholder: "Seleziona",
	External_SelectWholesalerModal_Outlet_Placeholder: "Seleziona",
	External_SelectWholesalerModal_ApplyButton: "Applica",
	External_SelectWholesalerModal_CancelButton: "Cancella",
	External_RemoveWholesalerModal_ConfirmLabel: "Conferma",
	External_SelectWholesalerModal_ConfirmMessage: "Sei sicuro di voler eliminare questo distributore?",
	External_SelectWholesalerModal_YesButton: "Si",
	External_SelectWholesalerModal_NoButton: "No",
	Training_program_team_member: "Membro del team",
	Training_program_assign_training_program: "Assegna programma di formazione",
	Assign_modal_student_confirmation_text1: "Stai per assegnare [Training Program Name] al tuo percorso di formazione.",
	Assign_modal_student_confirmation_text2: "Vuoi continuare ?",
	MyFullCalendar_Jan: "Gennaio",
	MyFullCalendar_Feb: "Febbraio",
	MyFullCalendar_Mar: "Marzo",
	MyFullCalendar_Apr: "Aprile",
	MyFullCalendar_May: "Maggio",
	MyFullCalendar_Jun: "Giugno",
	MyFullCalendar_Jul: "Luglio",
	MyFullCalendar_Aug: "Agosto",
	MyFullCalendar_Sep: "Settembre",
	MyFullCalendar_Oct: "Ottobre",
	MyFullCalendar_Nov: "Novembre",
	MyFullCalendar_Dec: "Dicembre",
	MenuHeader_CourseCatalog: "Catalogo corsi",
	MenuHeader_Event: "Calendario eventi",
	MenuHeader_Program: "Programma di formazione",
	MenuHeader_Services: "Servizi di formazione",
	MenuHeader_Log_out: "Esci",
	Filter_Location: "Luogo",
	TeamEdit_UpgradeToCompanyAdministrator: "Assegna il ruolo di amministratore",
	TeamEdit_PersonalWebAccountStatusIs: "Lo stato dell'account web personale è",
	Header_CreateNewEmployee: "Crea nuovo collaboratore",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Non ci sono posti disponibili",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Non ci sono posti disponibili per l'evento selezionato",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Seleziona un altro evento o rimuovi un partecipante già aggiunto per continuare",
	Common_SuccessModal_Title: "Congratulazioni!",
	TrainingProgramDetails_Merit_StatusText: "Merito",
	TrainingProgramDetails_Resit_StatusText: "Ripeti",
	EmployeeProfile_UserExistErrorMessage: "L'account è già esistente!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Pagamento fallito. Riprova o utilizza un altro metodo di pagamento.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Il collaboratore selezionato è stato disattivato con successo!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Salvato!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Le modifiche sono state salvate",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Non è possibile recedere dai corsi eLearning. Contattaci se hai bisogno di maggiori dettagli.",
	Dashboard_Team_PersonalAccount_Pending: "In attesa",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Dipendente creato!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "La creazione del tuo dipendente è stata completata con successo.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Questa impostazione consente di impedire ad un collaboratore di registrarsi agli eventi di formazione. Solo gli amministratori dell'azienda saranno in grado di registrare il collaboratore agli eventi di formazione quando questa impostazione non è stata selezionata.",
	Training_catalog_sort_by: "Ordina per",
	CourseDetail_EvaluationRestriction_Title: "Valutazione del corso non possibile!",
	CourseDetail_EvaluationRestriction_Message: "La valutazione del corso può essere inviata solo dal partecipante stesso. \nChiedere al partecipante di accedere a Bosch Automotive Training Solutions e completare la valutazione della formazione.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Se il partecipante non dispone già di un account, è possibile condividere un invito nelle impostazioni del profilo utente del membro del team.",
	Breadcrumb_Download_Page: "Download",
	Download_Table_Category_Cell: "Categoria",
	Download_Table_Title_Cell: "Titolo",
	Download_File_Filter_Item: "File",
	Download_File_Filter_Items: "Files",
	Download_Header: "Area download",
	DownloadArea_Button_Download: "Download",
	Popup_Title_Error: "Errore",
	Popup_Title_Success: "Successo",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Attenzione!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "A seguito di eventuali registrazioni di eventi già esistenti, non saranno annullate, e dovranno essere cancellate singolarmente.",
	TrainingProgram_PDF_Export: "Esporta PDF",
	TrainingProgram_PDF_Export_Participant: "Partecipante:",
	TrainingProgram_PDF_Export_Status: "Stato:",
	TrainingProgram_PDF_Export_Progress: "Progresso complessivo:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Nome del corso",
	TrainingProgram_PDF_Export_TableHeader_Status: "Stato",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Grado",
	TrainingProgram_PDF_Export_TableHeader_Date: "Data",
	AccountButton_CompanyChange_MenuItem: "Cambio di azienda",
	SelectCompanyModal_Title: "Seleziona azienda",
	CloseButton: "Chiudi",
	Change_Button: "Cambia",
	ChangeManagerModal_Content: "Seleziona utente",
	ChangeCompanyModal_HeadOffice: "(Sede centrale)",
	SelectCompanyModal_Content: "Si prega di selezionare l'azienda per il login",
	AlertSuccess_Title: "Successo",
	CheckOutStepPayment_SecondaryPayment_Title: "Seleziona il secondo metodo di pagamento",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Aggiungi il secondo metodo di pagamento",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Si prega di aggiungere il secondo metodo di pagamento qui sotto oppure di  selezionare sopra un altro metodo di pagamento.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Hai crediti di servizio insufficienti sul tuo conto per pagare gli articoli del carrello. Per continuare a utilizzare il metodo di pagamento selezionato, è necessario pagare la differenza. La differenza è [difference_amount] (excl. vat)",
	ShoppingCartTotalPrice_TotalInCredit: "Prezzo totale in crediti",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Disponibilità crediti",
	ShoppingCartTotalPrice_YourDifference: "Differenza",
	ShoppingCartTotalPrice_SubtotalExTax: "Totale parziale IVA escl.",
	ShoppingCartTotalPrice_SubtotalInTax: "Totale parziale IVA incl.",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Secondo metodo di pagamento",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Modificare il metodo di pagamento",
	Checkout_Second_PaymentMethod: "Secondo metodo di pagamento",
	Breadcrumb_TrainingCourseDetails: "Dettagli del corso",
	DownloadArea_FailedToDownload_ErrorMessage: "Il download del documento non è riuscito. Si prega di riprovare o contattare l'amministratore per assistenza.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Il file di download non è disponibile. Si prega di riprovare o contattare l'amministratore per assistenza.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Il file di download non è accessibile. Si prega di riprovare o contattare l'amministratore per assistenza.",
	SSO_Redirecting_Message: "Stai per essere indirizzato al portale della formazione Bosch",
	SSO_Redirecting_ParameterErrorMessage: "Paese e/o lingua non sono specificati. Riprova o contatta l'amministratore per ricevere assistenza.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Richiesta/e di iscrizione non accettata.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Questo potrebbe essere dovuto al fatto di non rientrare nei giorni limite di registrazione per uno o più corsi. Utilizza l'area \"Contattaci\" se hai domande o richieste.",
	TimeSlotPicker_DateFormat_Title: "Formato data",
	UpdateAccountSettingsConfirmationModal_Title: "Stai per cambiare il formato della data!",
	UpdateAccountSettingsConfirmationModal_Message1: "Il formato della data verrà visualizzato in base alla selezione e potrà essere modificato in qualsiasi momento.",
	UpdateAccountSettingsConfirmationModal_Message2: "Vuoi continuare?",
	ProfileSummary_Settings_Title: "Impostazioni",
	UpdateAccountSettings_DateFormat_Title: "Impostazioni del formato della data",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Impossibile aggiornare il formato della data.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "L'impostazione del formato della data è stata aggiornata correttamente.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Scarica attestato",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Attestato programma di formazione",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Impossibile scaricare il documento. Riprova o contatta l'amministratore per ricevere assistenza.",
	CourseWithdrawalModal_PendingWithdraw_Title: "Il recesso è in attesa. Se il recesso ha superato la soglia di cancellazione, l'utente riceverà ulteriori informazioni via email, una volta approvata/respinta la richiesta.",
	CourseWithdrawalModal_PendingWithdraw_Message1: "La soglia di cancellazione per questo corso è stata superata e richiede l'intervento dell'amministrazione.",
	CourseWithdrawalModal_PendingWithdraw_Message2: "La conferma definitiva verrà inviata via email",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Per maggiori dettagli si prega di fare riferimento alla nostra [cancellation policy].",
	TrainingCourse_Course_NotAvailable_Title: "Il corso non è disponibile.",
	TrainingCourse_Course_NotAvailable_Message: "Il corso non è disponibile. Riprova o contatta l'amministratore per ricevere assistenza.",
	TrainingCourse_NotAvailable_Message_1: "Stai cercando di visualizzare un corso che non è disponibile.",
	TrainingCourse_NotAvailable_Message_2: "Torna al nostro [training catalog] per scoprire tutti i corsi disponibili.",
	LanguageSelection_SelectALanguage: "Seleziona lingua",
	LanguageSelection_SelectYourCountry: "Seleziona il tuo paese",
	Checkout_PaymentMethod_Headline: "Modalità di pagamento",
	Download_Categories_Catalogue: "Catalogo",
	Download_Categories_Calendar: "Calendario",
	Download_Categories_General: "Varie",
	Download_Categories_Information: "Informazioni",
	Download_Categories_Userguides: "Manuale utente",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Policy di cancellazione",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Cancella",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Continua",
	Duration_Seconds: "Secondi",
	Duration_Day: "Giorno",
	Duration_Hour: "Ora",
	Duration_Minute: "Minuto",
	Duration_Second: "Secondo",
	EventCalendar_ExternalLink_Tooltips: "Acquista tramite [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Annulla",
	EventCalendar_ExternalLink_Continue: "Continua",
	EventCalendar_ExternalLinkModal_Title: "Stai per essere indirizzato..",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "L'evento selezionato viene venduto tramite [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Continuando sarai indirizzato ad un sito web esterno",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Vuoi continuare?",
	Team_ContactPerson_FirstName_Tooltips: "I nomi degli utenti possono essere modificati solo dal team della formazione Bosch. Vi preghiamo di contattarci tramite il modulo di contatto su questo sito per ulteriore supporto.",
	Team_ContactPerson_LastName_Tooltips: "I nomi degli utenti possono essere modificati solo dal team della formazione Bosch. Vi preghiamo di contattarci tramite il modulo di contatto su questo sito per ulteriore supporto.",
	Withdraw_ExternalLink_Cancel: "Annulla",
	Withdraw_ExternalLink_Confirm: "Conferma",
	Withdraw_ExternalLinkModal_Title: "Stai per essere indirizzato",
	Withdraw_ExternalLinkModal_Message: "La revoca da questo corso è gestita da un fornitore esterno.",
	Withdraw_ExternalLinkModal_Confirmation: "Cliccando su \"Conferma\" si aprirà una nuova scheda e verrai reindirizzato ad un sito Web esterno.",
	Button_AddToWaitingList_Tooltips: "Aggiungi alla lista d'attesa",
	Button_AddToCart_Tooltips: "Aggiungi al carrello",
	Button_TrainingCourseDetail_Tooltips: "Visualizza dettagli",
	Button_TrainingProgramDetail_Tooltips: "Visualizza dettagli",
	Button_AssignTrainingProgram_Tooltips: "Assegna il programma di formazione",
	Button_DeleteFromCart_Tooltips: "Elimina dal carrello",
	Button_RemoveParticipant_Tooltips: "Rimuovi partecipante",
	Button_DownloadBillingTransaction_Tooltips: "Scarica",
	Button_RemoveExternal_Tooltips: "Rimuovi",
	Breadcrumb_TrainingServices: "Corsi e programmi",
	Breadcrumb_Support: "Assistenza",
	Breadcrumb_FAQs: "FAQ",
	Breadcrumb_TrainingProgramDetail: "Dettagli del programma di formazione",
	Breadcrumb_MyTraining_MyTrainingEvents: "I miei corsi di formazione",
	Breadcrumb_MyTraining_External: "Delegati",
	Breadcrumb_MyTraining_Wallet: "Crediti",
	Breadcrumb_MyTraining_Workshop: "Officina",
	Breadcrumb_MyTraining_Workshop_Team: "Team",
	Breadcrumb_MyTraining_Team_Profile: "Profilo",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Modifica profilo",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Crea collaboratore",
	SideNavigation_Title: "Soluzioni per la formazione",
	SideNavigation_AccountButton_RegisterOrLogin: "Registrati/Login",
	SideNavigation_Language: "Lingua",
	SideNavigation_SelectLanguage: "Seleziona lingua",
	SideNavigation_AccountButton_Logout: "Logout",
	SideNavigation_ConntactUs: "Contattaci",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Informazioni aziendali",
	FooterAdditionalLinks_Navigation_LegalNotice: "Nota legale",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Informativa sulla protezione dei dati",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Impostazioni della Privacy",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Termini & condizioni",
	Common_Version_Text: "Versione: {{version}}",
	Content_Header_Dashboard_MyTrainingEvents: "I miei corsi di formazione",
	Content_Header_Dashboard_MyTrainingPrograms: "I miei programmi di formazione",
	Content_Header_Dashboard_Team: "Team",
	Content_Header_Dashboard_Workshops: "Officine",
	Content_Header_Dashboard_External: "Delegati",
	Content_Header_Dashboard_Wallet: "Crediti",
	Content_Header_Dashboard_MyProfile: "Il mio profilo",
	RedeemVoucher_RemoveButton: "Rimuovi",
	RedeemVoucher_ApplyButton: "Applica",
	Checkout_VoucherApplied_Label: "Il tuo codice",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Codice voucher non valido",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Il codice voucher utilizzato non è valido",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Si prega di riprovare o di continuare senza codice voucher",
	RedeemVoucherModal_CancelButton: "Annulla",
	RedeemVoucherModal_TryAgainButton: "Riprova",
	CreditsConfirmationModal_ContinueButton: "Continua",
	CreditsConfirmationModal_CancelButton: "Annulla",
	CreditsConfirmationModal_Title: "E' stato utilizzato un voucher",
	CreditsConfirmationModal_Message_1: "Il pagamento con crediti di servizio non è possibile quando al carrello è stato applicato un buono sconto",
	CreditsConfirmationModal_Message_2: "Clicca su \"Annulla\" per tornare indietro o clicca su \"Continua\" per rimuovere il voucher dal carrello.",
	TrainingCourseCatalog_PricePerSeat: "Prezzo a partecipante",
	TrainingCourseCatalog_Duration: "Durata:",
	Homepage_Title_BoschTrainingSolutions: "Bosch Training Solutions",
	ConsentMarketing_Title: "Marketing e News",
	ConsentMarketing_ConfirmButton: "Conferma",
	ConsentMarketing_CancelButton: "Annulla",
	ConsentMarketing_Subscribe: "Iscriviti",
	ConsentMarketing_Unsubscribe: "Annulla l'iscrizione",
	ConsentMarketing_SubscribeSuccessfully: "Ora sei iscritto",
	ConsentMarketing_UnsubscribeSuccessfully: "La tua iscrizione è stata annullata",
	TrainingCourseCatalog_Courses: "Corsi:",
	TrainingCourseCatalog_Courses_Info: "Corsi",
	Course_Elearning_Series_Detail_Title: "Serie di e-Learning",
	Course_Elearning_Series_Detail_Label_Date: "Data",
	Course_Elearning_Series_Course_List: "Elementi del corso"
};