export const TRANSLATIONS_SV_SE = {
	LandingPage_Title: "Välkommen till Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Välj din region / ditt land",
	Header_ContactUs: "Kontakta oss",
	Header_Search: "Sök",
	Header_Menu: "Meny",
	Header_LogInOrCreateYourAccount: "Logga in eller skapa ditt konto",
	Header_LogIn: "Logga in",
	Header_Cart: "Kundvagn",
	Header_ShoppingCart: "Kundvagn",
	Header_LogOut: "Logga ut",
	Header_ChangeDetails: "Ändra uppgifter",
	Footer_ExploreOurTraining: "Utforska våra utbildningar",
	Footer_TrainingAtYourFingertips: "Utbildningar som bara är ett klick bort",
	Footer_DontMissAnyStory: "Missa ingen berättelse",
	Footer_ExploreOurStories: "Utforska våra berättelser",
	Footer_GetInTouchWithUs: "Kontakta oss",
	Footer_SendUsAMessage: "Skicka ett meddelande till oss",
	Footer_ToContactForm: "Till kontaktformuläret",
	Footer_InventedForLife: "Invented for life",
	Footer_GeneralContactInfo: "Allmän kontaktinformation",
	Footer_ProductSecurity_Psirt: "Produktsäkerhet (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innovationspatent och licenser",
	Footer_PurchasingLogistics: "Inköp och logistik",
	Footer_CorporateInformation: "Företagsinformation",
	Footer_LegalNotice: "Rättsligt meddelande",
	Footer_DataProtectionNotice: "Dataskyddsmeddelande",
	Footer_PrivacySettings: "Sekretessinställningar",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "Robert Bosch AB, alla rättigheter är förbehållna",
	Footer_StillLookingForSomething: "Letar du fortfarande efter något?",
	Footer_TrainingServices: "Service Training",
	Footer_Courses: "Kurs",
	Footer_Programs: "Program",
	Footer_EventsCalendar: "Schemalagda kurser",
	Footer_TrainingCenters: "Utbildningscenter",
	Footer_Dashboard: "Dashboard",
	Footer_MyDashboard: "Min dashboard",
	Footer_ShoppingCart: "Kundvagn",
	Footer_YourShoppingCart: "Din kundvagn",
	Footer_Administration: "Administration",
	Footer_Employees: "Anställda",
	Footer_Wholesalers: "Grossister",
	Footer_Transactions: "Transaktioner",
	Footer_Workshops: "Verkstäder",
	Footer_WorkLikeABoschApplyNow: "Lediga tjänster  - Ansök nu.",
	Footer_BoschGlobalCareers: "Bosch Global Karriär",
	Footer_PurchasingAndLogistics: "Inköp och logistik",
	Footer_BecomeABusinessPartner: "Bli en affärspartner.",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Hur vi utvecklar kurser för att hjälpa ditt företag",
	Footer_Language: "Välj land",
	MenuPopup_MyCart: "Min kundvagn",
	Stories_OurTrainingStories: "Våra kursberättelser",
	Stories_ConnectingExperts: "Sammanför experter och entusiaster, drömmare och de som agerar, innovatörer och influencers – utforska och upplev",
	Stories_TopStories: "Mest lästa artiklar",
	Stories_AtHome: "Hemma",
	Stories_Careers: "Karriär",
	Stories_History: "Historia",
	Stories_IndustryAndTrades: "Industri och handel",
	Stories_Mobility: "Mobilitet",
	Stories_Research: "Forskning",
	Stories_Sustainability: "Hållbarhet",
	Stories_AllStories: "Alla berättelser",
	Stories_BlogATrainersDay: "BLOGG | En kursledares dag",
	Stories_ADayinLifeOfATrainer: "En dag i en kursledares liv",
	Stories_BoschServiceTraining: "Bosch Serviceutbildning",
	Stories_StoryWhatDrivesYouDrivesUs: "HISTORIA | Det som driver dig, driver oss",
	Stories_StoryHowCanWeBenefitYourBusiness: "HISTORIA | Hur kan vi hjälpa ditt företag",
	Stories_HowOurTrainingEvolves: "Så är upplägget på våra utbildningar",
	Stories_AModernTrainingApproach: "Utbildningsvärlden online",
	Stories_StoryAVirtualOnlineTrainingWorld: "HISTORIA | Dina onlineutbildningsmöjligheter",
	Stories_SubscribeToRSSFeed: "Prenumerera på RSS-flöde",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Om oss",
	Homepage_AutomotiveAftermarket: "Mobility Aftermarket",
	Homepage_GetInTouch: "Kontakta oss",
	Homepage_LatestNews: "Läs om varför vi drivs av kunskapstörst",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Lediga tjänster hos oss",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Det som driver dig, driver också oss",
	BoschServiceTraining_BoschServiceTraining: "Bosch Serviceutbildning",
	BoschServiceTraining_ShareThisOn: "Dela detta på",
	BoschServiceTraining_TheChallenge: "Utmaningen: Att bemästra ny teknik",
	BoschServiceTraining_WorldwidePresence: "Global närvaro",
	BoschServiceTraining_TechnicalTraining: "Tekniska kurser från Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "De erbjudanden och möjligheter som vi på Bosch erbjuder",
	BoschServiceTraining_TechnicalCoursesDesc: "Det finns lämpliga utbildningar för varje målgrupp – såväl nybörjare som proffs. Erbjudandet inkluderar endagsutbildningar och utbildningar över flera dagar. Utbildningarna erbjuds inom olika teknikområden och som utbildningsserier (som bensininsprutning dieselinsprutning elsystem). I de tekniska utbildningarna förmedlar Bosch omfattande kunskaper om alla standardfordonssystem från Bosch, men även system från andra tillverkare diskuteras i utbildningarna. Innehållet är speciellt anpassat till de krav som de anställda på verkstaden har.",
	BoschServiceTraining_TrainedEmployeesDesc: "Utbildade medarbetare är en självklar fördel för bilverkstäderna. Eftersom dagens fordon blir allt mer komplexa och innehåller ny teknik innebär ständigt nya utmaningar. Endast den nödvändiga kompetensen gör att de anställda på verkstaden kan utföra diagnostiskt underhåll och reparationsarbeten på de senaste fordonsmodellerna på ett effektivt och kostnadseffektivt sätt.",
	BoschServiceTraining_RegardlessOfHow: "Oavsett hur duktiga och erfarna fordonsspecialisterna är finns det alltid situationer där de stöter på ny fordonsteknik, system eller okända fel. I sådana situationer kan felsökning och problemlösning bli extra tidskrävande och man kanske inte lyckas lösa problemet. I värsta fall tvingas kunderna vara utan sitt fordon betydligt längre än förväntat.",
	BoschServiceTraining_ItIsPrecisely: "Det är precis här som Bosch serviceutbildning gör skillnad: genom att förbättra kvaliteten på servicen och snabba upp processerna. Bosch nuvarande utbildning ger stöd i att lokalisera problem snabbare, reparera fler fordon på samma tid och minska väntetiderna för dina kunder. Därför säkerställer de systematiska procedurer som du lär dig på Bosch ett snabbare och mer produktivt arbete.",
	BoschServiceTraining_WorldwidePresenceDesc1: "I mer än 125 år har Bosch varit den föredragna leverantören av originalutrustning, komponenter och system till många ledande biltillverkare världen över. Denna erfarenhet kombineras med unik kunskap om den senaste tekniken som fungerar som grunden för Boschs support.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Bosch Service Training Centers finns överallt i världen: från Spanien till Turkiet, Sydafrika, Australien till Brasilien – Bosch erbjuder sina anställda praktikinriktad utbildning, utbildning alltid är linje med den senaste tekniken.",
	BoschServiceTraining_AgileCompany: "Agilt företag",
	BoschServiceTraining_Collaboration: "Samarbete",
	BoschServiceTraining_ConnectedMobility: "Uppkopplad mobilitet",
	BoschServiceTraining_ArtificialIntelligence: "Artificiell intelligens",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Tankeledare i dialog",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnostik och testutrustning",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Grundläggande fordonsteknik",
	BoschServiceTraining_Icon_Gasoline: "Bensin",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Alternativa drivkällor",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "El och Elektronik",
	BoschServiceTraining_Icon_BodyAndComfort: "Kaross och komfort",
	BoschServiceTraining_Icon_ChassisAndBraking: "Chassi och Broms",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Transmission och Drivlina",
	BoschServiceTraining_Icon_CommercialVehicles: "Kommersiella Fordon",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Utbildningar Online",
	BoschServiceTraining_Icon_BusinessSkills: "Affärsmannaskap",
	BoschServiceTraining_Icon_Accreditations: "Ackrediteringar",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Utbildningsprogram",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "TrainerFirstNameLastName",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "A day in the life of a trainer",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Everyday is an adventure",
	ADayInTheLifeOfATrainer_Summary: "Summary",
	ADayInTheLifeOfATrainer_TheDayJourney: "The day's journey",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Everyday is a busy day but it is always a rewarding experience. The pleasure of training students knowing that they hold the key to our future is a responsibilty but at the same time so exciting. Seeing the students growing with each lesson on a daily and weekly basis proves to us that we are working towards a comon goal to improve their knowledge whilst incorporating the mordern technology that drives the future!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "At the end of each day we have a sit down and reflect on the days work. The days go so quickly but this is proof itself that what we are achieving is not only rewarding for the students but for us as trainers too.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "We can go home at the end of day satisfied that we have done a great job!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Start",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Förberedelse och slutlig kontroll av utbildningsmaterial. Stäm av viktig E-post",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Utbildningen startar",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Deltagarna är välkomna. Presentation av dagens agenda. Lära känna gruppen genom att förstå dynamiken från varje person.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teori",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "En viss omfattning av interaktion med gruppen är nödvändig för att göra utbildningen så intressant som möjligt. Säkerställa att det finns uppgifter kopplade med teorin för att ytterligare engagera gruppen.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Lunch rast",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Praktik",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "Utbildningen fortsätter i vagnhallen med några praktiska uppgifter. Som utbildare är det ett krav att alltid vara närvarande och tillgänglig för eventuella behov av vägledning.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Sammanfattning",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Åter till teorisalen för kort information och diskutera dagens utbildning. Besvara eventuella frågor som kan komma upp. Starta förberedelserna för nästa utbildningsdel.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Sista dagen",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Närvarocertifikaten presenteras för deltagarna",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Hem",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "How can we help benefit your business",
	BoschTrainingBenefits_HowOurTrainingEvolves: "How our training evolves",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "We are constantly evolving to ensure we deliver quality up-to-date-training",
	BoschTrainingBenefits_TheBoschServiceTraining: "The Bosch Service Training Center would like to assist you in developing your business through its extensive and highly relevant portfolio of technical/business training and further education courses with certified qualifications. The following pages provide you with an overview of our training program as well asthe current further education opportunities. We look forward to meeting you and your employees at our training courses and seminars.",
	BoschTrainingBenefits_LongStandingExperience: "Long-standing experience with and extensive know-how of all systems and in thea area of automobile technology.",
	BoschTrainingBenefits_QuickAndEfficient: "Quick and efficient solutions for many problems.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "The Advantages and benefits",
	BoschTrainingBenefits_LMS: "Learning Management System - Bosch Training solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training Solutions täcker hela utbudet inom fordonsområdet. Specialicera dig inom specifika områden eller bredda din kompetens med utbildningserbjudanden från Bosch",
	BoschTrainingBenefits_AcquiredKnowledge: "Acquired knowledge facilities repair maintenance and service work.",
	BoschTrainingBenefits_TheHighDegree: "The high degree of practical content and small groups guarantee target-oriented learning",
	BoschTrainingBenefits_ParticipationInTraining: "Participation in training increases employee motivation and satisfaction and supplemental qualifications convince customers",
	BoschTrainingBenefits_IncreasedSales: "Increased sales through cross-make system knowledge",
	BoschTrainingBenefits_MethodicalProcedures: "Methodical procedures reduce the wait time for customers",
	BoschTrainingBenefits_HighlyQualified: "Highly qualified instructors convey systematic procedures",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identifiering av vilken utbildningslösning som passar dig och ditt företag",
	BoschTrainingBenefits_DirectOnlineBooking: "Direktbokning online och hantering av kurser antingen för dig själv eller dina anställda",
	BoschTrainingBenefits_OnlineAccess: "Online-tillgång till lärandedokumentation",
	BoschTrainingBenefits_AccessToYourOwn: "Kom åt din egen utbildningshistorik och certifikat",
	BoschTrainingBenefits_ToolsForManagement: "Verktyg för hantering av din karriärutveckling",
	BoschTrainingBenefits_ManagerHasFull: "Chefen har full överblick över sina anställdas kurser inne i systemet",
	BoschTrainingBenefits_Access24hours: "Tillgång dygnet runt, veckans alla dagar",
	ModernTrainingApproach_KnowledgeIsKey: "Kunskap är en viktig framgångsfaktor för alla verkstäder. Det är därför som Bosch erbjuder ett omfattande utvecklingsprogram för professionell utbildning. Detta gör det möjligt för releverna att förvärva exakt rätt kunskap i rätt tid och på rätt plats – kunskap som krävs för att kunna underhålla och reparera kundens fordon på ett professionellt sätt. Bosch har lagt till webbsändning som en ny modul som ingår i det e-learningpaket som redan erbjuds. De bestårav serviceutbildningssessioner utformade speciellt för streaming.",
	ModernTrainingApproach_ANewLearning: "En ny möjlighet till lärande",
	ModernTrainingApproach_YourOnlineTraining: "Dina onlineutbildningsmöjligheter",
	ModernTrainingApproach_RecentlyTheWorld: "Nyligen har världen förändrats, se vad vi gör för att hjälpa",
	ModernTrainingApproach_TheOnlineTrainingWorld: "Utbildningsvärlden online",
	ModernTrainingApproach_NewTeachingMethod: "Ny undervisningsmetod",
	ModernTrainingApproach_InAdditionTo: "Utöver den befintliga klassrumsutbildningen har vi inkluderat live webbutsänd utbildning i utbildningsprogrammet. Vår kursledare står till ditt förfogande med omfattande teoretisk kunskap i det virtuella klassrummet eller med praktiska exempel. Som vanligt får du mycket information om fordonssystem och motsvarande Bosch-produkter. Naturligtvis har du också möjlighet att ställa frågor. Dessa frågor kommer att besvaras direkt i livesändningen. Livesändningen varar mellan 60 och 120 minuter. Du behöver ha en stabil internetuppkoppling. Sök efter en lämplig webbsändning direkt i vår utbildningskatalog genom att ange \Webcast\ i sökfältet.",
	ModernTrainingApproach_BoschWebcastingHeader: "Bosch webbsändningar: Innovativt och praktiskt",
	ModernTrainingApproach_BoschWebcastingText: "För att delta i en Bosch webcast måste deltagaren först ringa in. En individuell åtkomstkod skickas inför sessionen för att möjliggöra inloggning. Upp till 200 deltagare kan delta samtidigt från valfritt antal platser. Utbildningen leds av en kursledare och frågor kan skickas in när som helst via livechattfunktionen.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Det digitala komplementet till klassrumsutbildningar",
	ModernTrainingApproach_TheDigitalSupplementText: "Webcasting och klassrumsutbildningar kompletterar varandra perfekt. I båda fallen leds leder en kvalificerad kursledare utbildningen. Webbsändningar är lämpliga för ämnen som kan överföras digitalt utan att behöva arbeta på fordonet. Till exempel att lära sig vissa ESI[tronic]-funktioner i ett nytt förarassistanssystem. När det gäller effektivt lagarbete på verkstaden eller praktiskt arbete med en fordonsteknik är utbildning och träning där man är på plats fysiskt fortfarande förstahandsvalet.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "Streaming kan vara billigare",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Kostnaderna per deltagarna är vanligtvis lägre för webbsändningar än för en jämförbar klassrumskurs. Anledningen är att fler deltagare kan dra nytta av dem och att arbetsinsatsen i samband med en e-kurs är betydligt lägre (inga resor till eller från kurslokalen och mindre tidsåtgång).",
	ModernTrainingApproach_BookAndProfitHeading: "Boka och dra fördel",
	ModernTrainingApproach_BookAndProfitText: "Webbsändningar kan bokas på vår webbplats precis som våra andra kurser och utbildningserbjudanden. Du har valet att antingen betala den individuella avgiften för varje webbsändning eller teckna en årsprenumeration på ett visst antal webbsändningar.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Bosch Webcasting och klassrumsutbildning – en jämförelse",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Klassrum",
	ModernTrainingApproach_WebcastingP1: "Upp till 200 deltagare kan delta samtidigt oavsett var i världen de befinner sig",
	ModernTrainingApproach_WebcastingP2: "Låg komplexitet och relativt låga kostnader inga resor behövs",
	ModernTrainingApproach_WebcastingP3: "Perfekt för specifika och koncisa enskilda ämnen",
	ModernTrainingApproach_WebcastingP4: "Workshops kan visa en webbsändning för sina anställda var och när som helst",
	ModernTrainingApproach_WebcastingP5: "Mellan 50-70 % mindre tid krävs jämfört med klassrumsutbildningar",
	ModernTrainingApproach_ClassroomTrainingP1: "Upp till 16 deltagare träffas samtidigt",
	ModernTrainingApproach_ClassroomTrainingP2: "Resekostnader och generellt högre kostnader per person",
	ModernTrainingApproach_ClassroomTrainingP3: "Perfekt för att optimera samarbete eller när du ska lära dig direkt på ett fordon",
	ModernTrainingApproach_ClassroomTrainingP4: "Praktiska övningar och interaktion med kursledaren",
	ModernTrainingApproach_ClassroomTrainingP5: "Kursledaren kan bättre anpassa kunskapen som ska läras ut utifrån deltagarnas faktiska behov",
	ModernTrainingApproach_OnlineTrainingOfferings: "Erbjudanden, onlinekurser (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Idag är självständigt lärande vid datorn en integrerad del av kunskapsöverföringen. Friheten att välja tid och plats obegränsad repeterbarhet individuell inlärningshastighet och interaktivt lärandeinnehåll gör denna metod till ett oumbärligt studieverktyg.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Varje webbaserat utbildningsmoment (WBT) har strukturerats noggrant. Förutom att ge en bred kunskapsbas kan dessa kurser fungera som effektiva förberedelser för utbildningsprogram på Bosch utbildningscenter.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "Den virtuella diagnostiska simuleringen erbjuder användaren möjligheter till rollspel för vardagliga situationer på verkstaden, som arbetsflödet för en felsökningsprocedur med Bosch diagnostikenheter i en virtuell verkstad.",
	ModernTrainingApproach_Advantages: "Fördelar",
	ModernTrainingApproach_AdvantagesP1: "En snabb och effektiv metod för att förmedla kunskap",
	ModernTrainingApproach_AdvantagesP2: "Varierat lärande",
	ModernTrainingApproach_AdvantagesP3: "Kan användas så ofta som önskas",
	ModernTrainingApproach_AdvantagesP4: "Minimal tid och pengar",
	ModernTrainingApproach_AdvantagesP5: "Individuell inlärningshastighet",
	ModernTrainingApproach_AdvantagesP6: "Flexibel studietid",
	ModernTrainingApproach_AdvantagesP7: "Möjlighet att repetera",
	ModernTrainingApproach_AdvantagesP8: "Inga resekostnad",
	ModernTrainingApproach_AdvantagesP9: "Inga kapacitets- eller schemaläggningskonflikter",
	Dashboard_CoursesViewAndManageYourCourses: "KURSER | Visa och hantera dina kurser",
	Dashboard_MyCourses: "Mina kurser",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "UTBILDNINGSPROGRAM | Se dina utbildningsprogram",
	Dashboard_MyTrainingProgram: "Mitt utbildningsprogram",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Visa och hantera din eLearning-portfölj",
	Dashboard_MyELearning: "Min eLearning",
	Dashboard_AccountManageYourAccount: "KONTO | Hantera ditt konto",
	Dashboard_MyAccount: "Mitt konto",
	Dashboard_MyDashboard: "Dashboard",
	Dashboard_ManageYourAccount: "Hantera ditt konto",
	Dashboard_Workshops: "Verkstäder",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRATION | Hantera din verkstad",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRATION | Hantera dina team",
	Dashboard_Administration: "Administration",
	Dashboard_ViewYourCourses: "Se dina kurser",
	Dashboard_ViewYourTrainingPrograms: "Se dina utbildningsprogram",
	Dashboard_TrainingPrograms: "Utbildningsprogram",
	Dashboard_ViewAndEditYourDetails: "Visa och redigera dina uppgifter",
	Dashboard_ViewYourELearningCourses: "Visa dina e-learningkurser",
	Dashboard_Pending: "Väntande",
	Dashboard_RequiresAdministratorSignOff: "(kräver admin-godkännande)",
	Dashboard_HistoricalRecords: "Historik",
	Dashboard_Registered: "Registrerad",
	Dashboard_WaitingList: "Väntelista",
	Dashboard_RecommendedForYou: "Rekommenderat för dig",
	Dashboard_AccountDetails: "Kontouppgifter",
	Dashboard_ChangeProfileImage: "byt profilbild",
	Dashboard_Title: "Rubrik",
	Dashboard_Role: "Roll",
	Dashboard_FirstName: "Förnamn",
	Dashboard_LastName: "Efternamn",
	Dashboard_Telephone: "Telefon",
	Dashboard_Mobile: "Mobil",
	Dashboard_Email: "E-post",
	Dashboard_Language: "Språk",
	Dashboard_Save: "Spara",
	Dashboard_Student: "Student",
	Dashboard_WorkshopTrainingAdministrator: "Administratör verkstadsutbildning",
	Dashboard_WholesalerTrainingAdministrator: "Administratör grossistutbildning",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Företagsadministration",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Hantera dina verkstäder...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Hantera dina anställda...",
	Dashboard_CompanyAdministration_Workshop: "Verkstad",
	Dashboard_CompanyAdministration_Address: "Adress",
	Dashboard_CompanyAdministration_Zip: "Zip",
	Dashboard_CompanyAdministration_City: "Ort",
	Dashboard_CompanyAdministration_Manage: "Hantera",
	Dashboard_AdministrationSection_AdministrationTitle: "Administration",
	Dashboard_AdministrationSection_ManageTheTeam: "Hantera teamet",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRATION | Hantera dina anställda",
	Dashboard_AdministrationSection_Employees: "Anställda",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRATION | Hantera dina grossister",
	Dashboard_AdministrationSection_Wholesalers: "Grossister",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRATION | Hantera dina transaktioner",
	Dashboard_AdministrationSection_Transactions: "Transaktioner",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Personaladministration",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Hantera dina anställda...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registrera",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Historik",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Redigera",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Anställd",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Lägg till",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Skapa",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrerad",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Väntande",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(kräver admin-godkännande)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Historiska kurser",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Väntelista",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Aktuell personallista",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Personalledning",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Namn",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Medarbetarkonto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Visa och redigera personaluppgifter...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Inställningar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Tillåt personligt konto för Bosch Automotive Training Solutions",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Uppgradera anställd till företagsadministratör",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Ge dina anställda möjlighet att anmäla sig till kurser",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Skicka e-postinbjudan",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Inaktivera konto",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Spara",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Skicka",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Redigera",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Grossisthantering",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Hantera dina grossister...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Lista över godkända grossister",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Namn",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Ta bort",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Skapa",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Lägg till",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Skapa nytt medarbetarkonto",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Skapa detaljer för nya medarbetare...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Lägg till ny godkänd grossistadministratör",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Skapa detaljer för nya grossister...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Välj godkänd grossist från rullgardinsmenyn...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transaktioner",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Visa och hantera dina transaktioner...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Tillbaka",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Fakturanummer",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Beskrivning",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Betalningsmetod",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Datum",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Antal",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Hämta",
	TrainingCentres_TrainingCentres: "Utbildningscenter",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Våra utbildningscenter finns över hela världen",
	TrainingCentres_CountryCategory: "Landskategori",
	TrainingCentres_CityCategory: "Stads-/ortkategori",
	TrainingCentres_Country: "Land",
	TrainingCentres_City: "Ort",
	TrainingCatalogue_TrainingCatalogue: "Kurskatalog",
	TrainingCatalogue_LearningAtTheHighestLevel: "Lärande på högsta nivå",
	TrainingCatalogue_StartDate: "Startdatum",
	TrainingCatalogue_EndDate: "Slutdatum",
	TrainingCatalogue_TrainingName: "Utbildningens namn",
	TrainingCatalogue_Language: "Språk",
	TrainingCatalogue_Days: "Dagar",
	TrainingCatalogue_Seats: "Kursplatser",
	TrainingCatalogue_Price: "Pris",
	TrainingCatalogue_Location: "Plats",
	TrainingCatalogue_PartNumber: "Artikelnummer",
	TrainingCatalogue_TrainingCourse: "Kurs",
	TrainingCatalogue_Category: "Kategori",
	TrainingCatalogue_TrainingCategory: "Utbildningskategori",
	TrainingCatalogue_CourseName: "Utbildningens namn",
	TrainingCatalogue_TotalDays: "Totalt antal dagar",
	TrainingCatalogue_Venue: "Plats",
	TrainingCatalogue_Details: "Information",
	TrainingCatalogue_Prerequisites: "Förutsättningar",
	TrainingCatalogue_CourseType: "Kurstyp",
	TrainingCatalogue_LearningMethod: "Inlärningsmetod",
	TrainingCatalogue_AddToWaitingList: "Lägg till på väntelista",
	ShoppingCart_ShoppingCart: "Kundvagn",
	ShoppingCart_ReviewAndManage: "Granska och hantera dina prenumerationer",
	ShoppingCart_YouHaveAddedItem: "Du har lagt till följande artiklar i din kundvagn",
	ShoppingCart_Item: "Artikel",
	ShoppingCart_Quantity: "Kvantitet",
	ShoppingCart_PricePerItem: "Pris per artikel",
	ShoppingCart_TotalPrice: "Totalbelopp",
	ShoppingCart_VatIsIncluded: "Exkl. moms",
	ShoppingCart_Redeem: "Om du har kredit, klicka här för att lösa in den",
	ShoppingCart_Purchase: "Om du inte har någon kredit, klicka här för att köpa",
	ShoppingCart_Checkout: "Kassan",
	ShoppingCart_ContinueShopping: "Fortsätt handla",
	ShoppingCart_TotalPriceInclVat: "Totalpris inkl. moms",
	ShoppingCart_Apply: "Tillämpa",
	ShoppingCart_ShoppingCartEmpty: "Din kundvagn är tom",
	ShoppingCart_VatIncluded: "Moms",
	ShoppingCart_ManageYourShopping: "Hantera din kundvagn",
	ShoppingCart_Participant: "Deltagare",
	ShoppingCart_Complete: "Komplett",
	ShoppingCart_NetPrice: "Nettopris",
	ShoppingCart_Workshop: "Verkstad",
	CheckOut_PreviewYourOrder: "Orderöversikt",
	CheckOut_YourShoppingCart: "Kundvagn",
	CheckOut_Preview: "Förhandsgranska",
	CheckOut_Payment: "Betalning",
	CheckOut_Summary: "Sammanfattning",
	CheckOut_Back: "Tillbaka",
	CheckOut_PleaseReadAndAgreeOurTerms: "Vänligen läs och godkänn våra allmänna villkor",
	CheckOut_IHaveReadTheTermsAndCondition: "Jag har läst de [allmänna villkoren] och förstår och accepterar dem",
	CheckOut_YesIAgree: "Ja - jag godkänner de allmänna villkoren",
	CheckOut_Continue: "Fortsätt",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Grattis! Vi har tagit emot din beställning",
	CheckOut_OrderConfirmation: "Du kommer att få en slutlig orderbekräftelse när din förfrågan har behandlats framgångsrikt",
	CheckOut_WeWillContactYouDirectly: "Vi kommer att kontakta dig direkt om vi har några frågor om din begäran",
	CheckOut_AddToCart: "Lägg till i kundvagnen",
	CheckOut_CreditCart: "Kreditkort",
	CheckOut_DirectDebit: "Betalkort",
	CheckOut_TrainingCredits: "Utbildningspoäng",
	CheckOut_PreferredWholesaler: "Föredragen grossist",
	EventCalendar_EventCalendar: "Evenemangskalender",
	EventCalendar_TrainingEventsForAllYourNeeds: "Utbildningar för alla dina behov",
	TrainingCourse_TrainingCourse: "Kurskatalog",
	TrainingCourse_LearningAtTheHighestLevel: "Lärande på högsta nivå",
	TrainingProgram_Title: "Katalog utbildningsprogram",
	TrainingProgram_SubTitle: "Lärande på högsta nivå",
	AccountDeactivationModal_Title: "Är du säker på att du vill inaktivera det här kontot?",
	AccountDeactivationModal_Line1: "Inaktiveringen av detta konto är permanent och innebär att allt innehåll tas bort, inklusive service, förfrågningar, transaktioner och saldon för teammedlemmar och profilinställningar.",
	AccountDeactivationModal_Line2Part1: "Om det är under en aktiv prenumerationsperiod, se vår uppsägningspolicy i vår",
	AccountDeactivationModal_Line2Part2: "Allmänna villkor",
	AccountDeactivationModal_Line2Part3: "innan du skickar in din förfrågning.",
	AccountDeactivationModal_Line3: "Är du säker på att du vill skicka en begäran om att inaktivera det här kontot?",
	AccountDeactivationModal_Cancel: "Avbryt",
	AccountDeactivationModal_Send: "Skicka",
	CurrentUserAccountDeactivationModal_Title: "Är du säker på att du vill inaktivera ditt konto?",
	CurrentUserAccountDeactivationModal_Line1: "Inaktivering av ditt konto är permanent och kommer att ta bort allt innehåll, inklusive service, förfrågningar, transaktioner och saldon för teammedlemmar och profilinställningar.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Om du befinner dig i en aktiv prenumerationsperiod, se vår uppsägningspolicy i vår",
	CurrentUserAccountDeactivationModal_Line3: "Är du säker på att du vill skicka en begäran om att inaktivera ditt konto?",
	CurrentUserAccountDeactivationModal_Yes: "Ja",
	CurrentUserAccountDeactivationModal_No: "Nej",
	CourseWithdrawalModal_Title: "Din uppsägning är inte slutgiltig innan den har godkänts av vår utbildningsadministration.",
	CourseWithdrawalModal_Line1: "Du kommer att få en slutlig bekräftelse via e-post när din uppsägning har godkänts. Om vi har några frågor kommer vi att kontakta dig direkt. Är du säker på att du vill skicka in din begäran om inaktivering av kontot?",
	CourseWithdrawalModal_Line2Part1: "Vänligen läs våra",
	CourseWithdrawalModal_Line2Part2Link: "Avbokningsregler",
	CourseWithdrawalModal_Line2Part3: "Fortsätta med begäran om inaktivering av kontot?",
	CourseWithdrawalModal_Yes: "Ja",
	CourseWithdrawalModal_No: "Nej",
	Settings_Settings: "Inställningar",
	Settings_ManageYourSettings: "Hantera dina inställningar",
	Settings_Profile: "Profil",
	Settings_MyBoschGlobalIdLoginInformation: "My Bosch Globalt ID Inloggningsinformation",
	Settings_Edit: "Redigera",
	Settings_CompanyDetails: "Företagsinformation",
	Settings_RequestAccountDeletion: "Begär radering av konto",
	Settings_AccountDeletionText: "Om du tar bort ditt konto kommer alla dina uppgifter (inklusive din adress och betalningsdata) att raderas och kommer inte längre att vara tillgängliga.",
	Settings_DeleteAccountRequestConfirmation: "Bekräftelse på begäran om borttagning av konto",
	Settings_DeleteAccountRequestConfirmationText: "Vi har tagit emot din begäran om borttagning av ditt konto och kommer att kontakta dig via e-post eller telefon för slutlig bekräftelse",
	RecommendedHotels_RecommendedHotels: "Rekommenderade hotell",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Våra utbildningscenter finns över hela världen",
	Error_Common: "Något gick fel!",
	Error_404: "Sidan du besökte finns inte",
	Error_401: "Du har inte åtkomst till den här sidan",
	Error_500: "Servern rapporterar ett fel",
	Dashboard_Courses_Table_Course: "Utbildning",
	Dashboard_Courses_Table_Date: "Datum [Dagar]",
	Dashboard_Courses_Table_Venue: "Utbildningsort",
	Dashboard_Courses_Table_TrainingType: "Utbildning",
	Dashboard_Courses_Table_Action: "Valinnat",
	Dashboard_Courses_Table_TasksToComplete: "Sinun on suoritettava loppuun seuraavat toimenpiteet",
	Dashboard_Courses_Table_Withdraw: "Peruuta",
	Dashboard_Courses_Table_Remove: "Poista",
	Dashboard_Courses_Table_View: "Översikt",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Inga historik att visa",
	Dashboard_Courses_Table_CompletionDate: "Slutdatum",
	Dashboard_Courses_Table_Actions_Assignments: "Uppgifter",
	Dashboard_Courses_Table_Actions_Certification: "Certifiering",
	Dashboard_Courses_Table_Actions_Evaluations: "Utvärderingar",
	Dashboard_Courses_Table_Actions_Assessments: "Bedömningar",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Lägg till i kalender",
	Dashboard_Courses_Table_CourseDetails_Task: "Uppgift",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Förfallodatum",
	ContactUs_Headline: "Kontakta oss",
	ContactUs_HowCanWeHelp: "Hur kan vi hjälpa dig?",
	ContactUs_EmailTab: "E-post",
	ContactUs_PhoneTab: "Telefon",
	ContactUs_LetterTab: "Brev",
	ContactUs_Phone_Headline: "Ring oss!",
	ContactUs_Phone_Description: "Vi ser fram emot erat samtal och är glada att kunna hjälpa dig.",
	ContactUs_Phone_PhoneNumber: "Använd kontaktformuläret för att skicka oss ett meddelande",
	ContactUs_Phone_ChargeNotice: "Avgifterna varierar beroende på din tjänsteleverantör och land",
	ContactUs_Letter_Headline: "Skicka ett brev!",
	ContactUs_Letter_Description: "Har du några frågor, förfrågningar eller förslag? Vi ser fram emot din återkoppling.",
	ContactUs_Email_Headline: "Skicka en E-post",
	ContactUs_Email_Description: "Skicka ditt meddelande genom att fylla i formuläret nedan. Våra kundrådgivare kommer att bli glada när ni kontaktar oss",
	ContactUs_Email_InputName: "Namn*",
	ContactUs_Email_InputCompany: "Företag",
	ContactUs_Email_InputCountry: "Land*",
	ContactUs_Email_InputEmail: "E-post*",
	ContactUs_Email_InputMessage: "Meddelande*",
	ContactUs_Email_DataProtection: "Dataskyddsförordning*",
	ContactUs_Email_DataProtectionConfirm: "Jag har löst Integritetspolicyn*",
	ContactUs_Email_SecurityCheck: "Säkerhetskontroll",
	ContactUs_Email_SecurityCodeConfirm: "Ange de korrekta tecknen du ser i fältet ovan",
	ContactUs_Email_SecurityCodeInvalid: "Angivet svar med Captcha text är inte korrekt",
	ContactUs_Email_MandatoryNotice: "Vänligen fyll i de obligatoriska fälten",
	ContactUs_Email_SuccessMessage: "Ditt meddelande har skickats",
	ContactUs_Email_TakenNoteDataProtection1: "Jag har läst",
	ContactUs_Email_TakenNoteDataProtection2: "Integritetspolicyn",
	ContactUs_Email_SubmitButton: "Skicka",
	ContactUs_Email_FieldErrorMessage: "Vänligen fyll i de obligatoriska fälten",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "Ditt konto är för närvarande under granskning!",
	AccountInReviewModal_Paragraph1: "Tack för att du registrerat ett personligt konto hos Bosch Automotive Training Solutions",
	AccountInReviewModal_Paragraph2: "Våra dedikerade utbildare granskar och konfigurerar för närvarande erat konto",
	AccountInReviewModal_Paragraph3: "Under tiden, använd gärna möjligheten att surfa runt på våran hemsida med begränsad tillgång",
	AccountInReviewModal_Paragraph4: "Om ni har några frågor eller om erat konto inte blir bearbetat inom 48 timmar (helgdagar undantagna) så kan ni kontakta oss",
	AccountInReviewModal_ContactUsLink: "här",
	AccountInReviewModal_OKButton: "Okay, släpp in mig!",
	Header_YesButton: "Ja",
	Header_NoButton: "Nej",
	Header_LogOutText: "Vill du logga ut?",
	Footer_EnquiryText: "Vi ser fram emot eran förfrågan",
	Footer_Telephone: "Telefon",
	Footer_TermsAndConditions: "Villkor",
	Footer_HowOurTrainingEvolves: "Hur våra utbildningar utvecklas",
	FooterSearch_SearchFieldPlaceholder: "Sök",
	TrainingCatalogue_Action: "Action",
	TrainingCatalogue_Duration: "Varaktighet",
	TrainingCatalogue_Information: "Information",
	Settings_PersonalDetails: "Personliga uppgifter",
	Reset_All_Filters: "Återställ alla filter",
	SearchField_AllResults: "Alla resultat",
	TrainingCentresLayout_ViewDetails: "Visa detaljer",
	Dropdown_Placeholder: "Välja",
	WaitlistPopup_SelectButtonText: "Välj",
	WaitlistPopup_StartDate: "Startdatum",
	WaitlistPopup_EndDate: "Slutdatum",
	WaitlistPopup_Language: "Språk",
	WaitlistPopup_City: "Stad",
	WaitlistPopup_Workshop: "Verkstad",
	WaitlistPopup_Student: "Student",
	WaitlistPopup_PreferredLocation: "Föredragen ort",
	WaitlistPopup_SignUpCourseWaitingList: "Anmälan till väntelista för utbildning",
	WaitlistPopup_SignUpWithoutSelectingDate: "Anmälan till väntelista utan valt datum",
	WaitlistPopup_GeneralWaitList: "Allmän väntelista",
	WatinglistPopup_CourseLabel: "Utbildning",
	Common_ContinueButton: "Fortsätt",
	UserAccountDeactivationModal_ConfirmLabel: "Bekräfta",
	Filter_FilterLabel: "Filter",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Du finns redan på väntelistan, vill du ta bort din existerande registrering och göra en ny?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Ja, ta bort",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Nej, avboka",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Avbokning utbildning",
	WaitinglistPopup_SignUpButtonText: "Anmälan",
	MyAccount_FirstNameRequied: "Förnamn är obligatoriskt",
	MyAccount_LastNameRequired: "Efternamn är obligatoriskt",
	MyAccount_RoleRequired: "Roll är obligatoriskt",
	MyAccount_MobileRequired: "Mobilnummer är obligatoriskt",
	MyAccount_EmailRequired: "E-post är obligatoriskt",
	MyAccount_LanguageRequired: "Språk är obligatoriskt",
	MyAccount_UpdateSuccessMessage: "Dina kontodetaljer har blivit uppdaterade!",
	MyAccount_UpdateSuccessModal_ButtonText: "Ja, stäng fönstret",
	Infor_CorporateInformation_Header: "Företagsinformation",
	Infor_LegalNotice_Header: "Rättsligt meddelande",
	Infor_PrivacyPolicy_Header: "Dataskyddsförordning (integritetspolicy)",
	CreateEmployeeAccountModification_FormValidation_Role: "Roll är obligatoriskt",
	CreateEmployeeAccountModification_FormValidation_Firstname: "Förnamn är obligatoriskt",
	CreateEmployeeAccountModification_FormValidation_Lastname: "Efternamn är obligatoriskt",
	CreateEmployeeAccountModification_FormValidation_Mobile: "Mobilnummer är obligatoriskt",
	CreateEmployeeAccountModification_FormValidation_Email: "E-post är obligatoriskt",
	CreateEmployeeAccountModification_FormValidation_Language: "Språk är obligatoriskt",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Inbjudan till Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Du har lagts till som ny medlem i ditt team!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Vill du att vi skall skicka en E-post inbjudan till eran nya team medlem med instruktion om hur de skapar ett personliga konto för Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Nej",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Ja, skicka en inbjudan",
	ShoppingCart_Venue_Fee: "Avgift mötesplats",
	ShoppingCart_Trainning_Name: "Typ av utbildning",
	ShoppingCart_Available_Credits: "Tillgängliga tickets",
	ConfirmItsMeModal_Hi: "Hej",
	ConfirmItsMeModal_Description1: "Du har slutfört inbjudan som delats av ditt företags administratör för att skapa ditt eget personliga konto hos Bosch Automotive Training Solutions",
	ConfirmItsMeModal_Description2: "Innan du fortsätter, vänligen bekräfta eller uppdatera dina kontodetaljer",
	ConfirmItsMeModal_Description3: "Efter bekräftelsen kommer du behöva att logga in igen för att slutföra skapandet av kontot",
	ConfirmItsMeModal_CompanyDetails: "Företagsuppgifter",
	ConfirmItsMeModal_YourDetails: "Dina detaljer",
	ConfirmItsMeModal_YourDetails_Title: "Titel",
	ConfirmItsMeModal_YourDetails_Function: "Funktion",
	ConfirmItsMeModal_YourDetails_Name: "Namn",
	ConfirmItsMeModal_YourDetails_Mobile: "Mobilnummer",
	ConfirmItsMeModal_YourDetails_Email: "E-post",
	ConfirmItsMeModal_YourDetails_Language: "Språk",
	ConfirmItsMeModal_ConfirmButton: "Bekräfta",
	SelectWholesalerModal_WholesalerGroupLabel: "Grossist / Återförsäljare",
	SelectWholesalerModal_WholesalerBranchLabel: "Butik",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Välj",
	ApplyButton: "Tillämpa",
	SelectWholesalerModal_Title: "Välj din grossist / återförsäljare",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Vill du ta bort din registrering?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "Din registrering är borttagen!",
	WaitlistPopup_AddToWaitListSuccess: "Din registrering är tillagd!",
	ErrorCommon_Header: "Fel",
	CommonButton_Close: "Stäng",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Stäng detta fönster för att fortsätta!",
	EmbeddedVideoPlayer_StartButtonText: "Start",
	EmbeddedVideoPlayer_RefresherButtonText: "Uppdatera",
	DeactivateWholesalerModal_ConfirmMessage: "Är du säker på att du vill ta bort denna grossisten?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Skicka inbjudningsmail lyckades!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Bjud in igen",
	ContactUs_Mobile_SE: "+46 8 750 15 00",
	ContactUs_Address_SE: "Robert Bosch AB,Isafjordsgatan 15,164 26 Kista,Sverige",
	ConfirmItsMeModal_CompanyDetails_Name: "Företagsnamn",
	ConfirmItsMeModal_CompanyDetails_Address: "Adress",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Postnummer",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefon",
	ConfirmItsMeModal_CompanyDetails_Email: "E-post",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Avbokningen lyckades",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "Det gick inte att skapa konto. Kontot finns redan!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "Uppdatering av konto misslyckades. Kontot finns redan!",
	Footer_ExploreTrainingStories: "Utforska alla våra kursberättelser",
	Breadcrumb_Home: "Hem",
	Breadcrumb_Contact: "Kontakt",
	Breadcrumb_Stories: "Berättelser",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "En arbetsdag för en utbildare",
	Breadcrumb_Stories_BoschTrainingBenefits: "Bosch utbildningsfördelar",
	Breadcrumb_Stories_TrainingApproach: "Ett modernt utbildningssätt",
	Breadcrumb_Settings: "inställningar",
	Breadcrumb_ShoppingCart: "Kundvagn",
	Breadcrumb_Checkout: "Checka ut",
	Breadcrumb_OrderSummary: "Ordersammanfattning",
	Breadcrumb_Exception: "Undantag",
	Breadcrumb_Dashboard: "Instrumentpanel",
	Breadcrumb_MyCourses: "Mina kurser",
	Breadcrumb_MyELearning: "Min eLearning",
	Breadcrumb_CompanyAdministration: "Företagsadministration",
	Breadcrumb_EmployeeManagement: "Hantering av anställda",
	Breadcrumb_EmployeeAccount: "Medarbetarkonto",
	Breadcrumb_EmployeeCreation: "Medarbetarskapande",
	Breadcrumb_Administration: "Administrering",
	Breadcrumb_EmployeeAdministration: "Administrering av anställda",
	Breadcrumb_WholesalerManagement: "Handtering av Leverantörer",
	Breadcrumb_NewWholesalerAccountCreation: "Skapa nytt leverantörskonto",
	Breadcrumb_Transactions: "Transaktioner",
	Breadcrumb_MyAccount: "Mitt konto",
	Breadcrumb_TrainingCentres: "Utbildningscenter",
	Breadcrumb_EventCalendar: "Utbildningskalender",
	Breadcrumb_TrainingCourseCatalogue: "Utbildningskatalog",
	Breadcrumb_RecommendedHotels: "Rekommenderade hotell",
	Breadcrumb_CorporateInformation: "Leverantör",
	Breadcrumb_LegalNotice: "Juridisk information",
	Breadcrumb_DataProtectionNotice: "Sekretessförklaring",
	Breadcrumb_PrivacyPolicy: "Sekretessförklaring",
	Breadcrumb_TermCondition: "Villkor för användning",
	Breadcrumb_Terms: "Villkor",
	Breadcrumb_PrivacyStatement: "Sekretessförklaring",
	Breadcrumb_Cookies: "Kakor",
	Breadcrumb_Provider: "Leverantör",
	Breadcrumb_RegisterAccount: "Registrera konto",
	Stories_ExploreMore: "Utforska mer",
	CheckOutLayout_ShoppingCartHeader: "Kundvagn",
	CheckOutLayout_AddedItemCartMessage: "Du har lagt till följande i din kundvagn",
	CheckOutLayout_CheckYourCartMessage: "Kontrollera din kundvagn",
	ModernTrainingApproach_DateText: "2/28/2022 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Sök anställda",
	CheckOutLayout_OrderWasNotPlaced_Message: "Ursäkta! Din beställning gjordes inte",
	CheckOutLayout_RefusalReason: "Orsak för avslag",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Betalning misslyckades. Försök igen med en annan betalningsmetod",
	CheckOutLayout_SubmittingApiFailed_Message: "Det gick inte att skicka in API",
	CartItem_ChooseWorkshop_Message: "Börja med att välja en verkstad",
	CartItem_ChooseParticipant_Message: "Välj deltagare",
	CompanyAdministrationTable_SearchBox_Placeholder: "Sök verkstäder",
	CartItem_RemoveCartItem_ConfirmText: "Vill du ta bort den här artikeln ur kundvagnen?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Funktion",
	AddToCartSuccessModal_Message: "Din kundvagn har uppdaterats!",
	AddToCartSuccessModal_ContinueButtonText: "Fortsätt att handla",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Min kundvagn",
	AddVoucher_Header: "Spara",
	AddVoucher_InputPlaceholder: "Ange din kod och spara",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Tyvärr har vi inga schemalagda evenemang för denna utbildning just nu. Vänligen registrera dig på väntelistan, så informerar vi dig när ett evenemang blir tillgängligt.",
	ADYEN_ERR_2_: "Transaktionen har inte accepterats",
	ADYEN_ERR_3_: "Hänvisningsfel",
	ADYEN_ERR_4_: "Transaktionen gick inte igenom på grund av ett fel som inträffade på förfrågarens/avsändarens sida.",
	ADYEN_ERR_5_: "Kortet som används för transaktionen är blockerat, därför oanvändbart.",
	ADYEN_ERR_6_: "Kortet som användes för transaktionen har gått ut. Därför är det oanvändbart.",
	ADYEN_ERR_7_: "En beloppsmatchning uppstod under transaktionsprocessen.",
	ADYEN_ERR_8_: "Det angivna kortnumret är felaktigt eller ogiltigt.",
	ADYEN_ERR_9_: "Det är inte möjligt att kontakta kundens bank för att godkänna transaktionen.",
	ADYEN_ERR_10_: "Kundens bank stöder inte eller tillåter inte denna typ av transaktioner.",
	ADYEN_ERR_11_: "3D Secure autentisering utfördes inte, eller så genomfördes den inte framgångsrikt.",
	ADYEN_ERR_12_: "Kortet har inte tillräckligt med pengar för att täcka det belopp som ska betalas.",
	ADYEN_ERR_14_: "Möjligt bedrägeri.",
	ADYEN_ERR_15_: "Transaktionen avbröts.",
	ADYEN_ERR_16_: "Köparen avbröt transaktionen innan den slutfördes.",
	ADYEN_ERR_17_: "Den angivna PIN -koden är felaktig eller ogiltig.",
	ADYEN_ERR_18_: "Köparen angav en felaktig PIN -kod fler än tre gånger i rad.",
	ADYEN_ERR_19_: "Det är inte möjligt att validera det angivna PIN-numret.",
	ADYEN_ERR_20_: "Möjligt bedrägeri.",
	ADYEN_ERR_21_: "Transaktionen skickades inte korrekt för behandling.",
	ADYEN_ERR_22_: "Riskkontrollen markerade transaktionen som bedräglig (riskpoäng> = 100); därför avbryts operationen.",
	ADYEN_ERR_23_: "Följande avvisade koder mappas till denna vägran: Transaktionen är inte tillåten för emittenten/korthållaren",
	ADYEN_ERR_24_: "Den angivna CVC (kortets säkerhetskod) är ogiltig.",
	ADYEN_ERR_25_: "Följande avslagskoder är mappade till denna vägran: Ogiltigt kort i detta land",
	ADYEN_ERR_26_: "R1: Återkallelse av auktoriseringsorder / \"R3: Återkallande av alla behörighetsorder\" / \"R0: Stoppa betalningsorder\"",
	ADYEN_ERR_27_: "Detta svar kartlägger alla de svarskoder som inte kan kartläggas på ett tillförlitligt sätt. Detta gör det lättare att berätta generiska nedgångar (till exempel Mastercard \"05: Do not honor\" svar) från mer specifika.",
	ADYEN_ERR_28_: "Det tillåtna uttagsbeloppet för Köparens kort har överskridit.",
	ADYEN_ERR_29_: "Antalet uttag som tillåts för Köparens kort har överskridits",
	ADYEN_ERR_31_: "Emittenten/Förfrågaren rapporterade transaktionen som misstänkt bedrägeri.",
	ADYEN_ERR_32_: "Adressen som Köparen angav är felaktig.",
	ADYEN_ERR_33_: "Köparens bank kräver att Köparen anger en online-PIN.",
	ADYEN_ERR_34_: "Köparens bank kräver ett bankkonto för att slutföra köpet.",
	ADYEN_ERR_35_: "Köparens bank kräver ett sparkonto för att slutföra köpet.",
	ADYEN_ERR_36_: "Köparens bank kräver att Köparen  anger en mobil PIN-kod.",
	ADYEN_ERR_37_: "Köparen övergav transaktionen efter att ha försökt en kontaktlös betalning och uppmanades att prova en annan kortinmatningsmetod (PIN eller svep).",
	ADYEN_ERR_38_: "Utgivaren avvisade begäran om undantag från autentisering och kräver autentisering för transaktionen. Försök igen med 3D Secure.",
	ADYEN_ERR_39_: "Emittenten/Förfrågaren eller systemet kunde inte kommunicera resultatet via RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "Förnamn får inte innehålla specialtecken (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "Förnamn måste vara mindre än 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "Efternamnet får inte innehålla specialtecken (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "Efternamnet måste vara mindre än 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "Mobilnummer får inte innehålla specialtecken (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "Mobilnumret måste vara mindre än 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "Telefonnumret får inte innehålla specialtecken (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "Telefonnumret måste vara mindre än 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "E-postadressen är ogiltig",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "E-post måste vara mindre än 100",
	ConfirmGeolocationModal_ConfirmButton: "Fortsätta",
	ConfirmGeolocationModal_CancelButton: "Förändra",
	ConfirmGeolocationModal_CurrentCountrySelection: "Ditt nuvarande val av land är:",
	ConfirmGeolocationModal_AskChangeCountry: "Vill du stanna kvar på den här sidan eller ändra ditt land?",
	Addtocart_Modal_AddToCartFailTitle: "Det gick inte att lägga till deltagare",
	Addtocart_Modal_AlreadyInCart: "Deltagare har redan registrerat sig till detta kurset",
	TrainingCatalogue_DateRange: "Datum intervall",
	TrainingCatalogue_DateRange_placeholder: "Välj datumintervall",
	Dashboard_MyPendingCourses: "Mina väntande kurser",
	Dashboard_MyWaitingList: "Min väntelista",
	Dashboard_LatestNews: "Senaste nyheter",
	SettingsMainPage_EditAccountButton: "Ändra kontoinställningar",
	SettingsMainPage_EditBoschId: "Ändra Bosch kontot",
	EventInfoView_EventDetailTab: "Evenemangsdetaljer",
	EventInfoView_AssignmentsTab: "Uppgifter",
	EventInfoView_EvaluationTab: "Utvärdering",
	EventInfoView_AttachmentTab: "Bilagor",
	EventInfoView_CourseDescriptionTab: "Beskrivning",
	EventInfoView_DescriptionTab_General: "Generell",
	EventInfoView_DescriptionTab_PartLabel: "Del",
	AssignmentTab_Description: "Här hittar du all information du behöver för att genomföra denna kurs.",
	AssignmentTab_Columns_Download: "Nerladdning",
	AssignmentTab_Columns_Upload: "Uppladdning",
	AssignmentTab_Columns_TaskComplete: "Uppgift slutförd",
	EventDetailTab_Location_Message_Video: "Detta är ett onlineevenemang som kan nås genom att använda länken \"Launch Video\".",
	EventDetailTab_Location_Message_WebBased: "Detta är ett onlineevenemang som kan nås genom att använda länken \"Launch WBT\".",
	EventDetailTab_Location_Message_WebCast: "Detta är ett onlineevenemang som kan nås genom att använda länken \"Gå med nu\".",
	EventDetailTab_Link_Video: "Starta video",
	EventDetailTab_Link_Video_Remark: "*Om du startar videon öppnas ett nytt fönster. Du kommer att omdirigeras tillbaka till den här sidan när fönstret stängs.",
	EventDetailTab_Link_WebBased: "Starta WBT",
	EventDetailTab_Link_WebBased_Remark: "*Om du startar WBT öppnas ett nytt fönster. Du kommer att omdirigeras tillbaka till den här sidan när fönstret stängs.",
	EventDetailTab_Link_WebCast: "Anslut nu",
	EventDetailTab_Link_WebCast_Remark: "*Anslut nu-länken kommer att vara aktiv 30 minuter före evenemangets start.",
	EventDetailTab_Title_Status: "Status",
	EventDetailTab_Title_Duration: "Varaktighet (timmar)",
	EventDetailTab_Title_Trainers: "Utbildare",
	EventDetailTab_Title_Language: "Språk",
	EventDetailTab_Title_Score: "Testresultat (%)",
	EventDetailTab_Title_Calendar: "Lägg till i kalender",
	EventDetailTab_Title_Certificate: "Ladda ner certifikat",
	EventDetailTab_Title_Messages: "Meddelanden",
	EventDetailTab_Title_StartDate: "Start datum",
	EventDetailTab_Title_AvailableFrom: "Tillgänglig från",
	EventDetailTab_Title_Time: "Tid",
	EventDetailTab_Title_EndDate: "Slut datum",
	EventDetailTab_Title_ExpiresOn: "Går ut den",
	EventDetailTab_Title_Room: "Rum",
	EventDetailTab_Title_Method: "Metod",
	EventDetailTab_Title_Link: "Länk*",
	EventDetailTab_Title_Location: "Plats",
	AttachmentTab_EmptyScreenDescription: "Det finns inga bilagor för denna händelse.",
	AttachmentTab_Columns_Download: "Nerladdning",
	AttachmentTab_Description: "Här hittar du viktig information som hjälper dig med denna kurs",
	EvaluationTab_Annotation: "Vi uppskattar din feedback och vill gärna höra från dig hur vi ligger till. Ta dig tid att fylla i följande utvärdering för att hjälpa oss att kontinuerligt förbättra vår service till dig. Tack.",
	EvaluationTab_Notice: "Betygen är följande",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Vänligen svara på alla obligatoriska frågor innan du skickar in din evenemangsutvärdering",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Din feedback har skickats",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "Utvärderingsinlämningen misslyckades",
	TrainingCatalogueLayout_ExportButtonText: "Exportera",
	EvaluationTab_FeedbackHasBeenGiven_Message: "Utvärderingen slutfördes eller har löpt ut.",
	EventDetailTab_Link_CopiedTooltip: "Kopierade till Urklipp",
	EventDetailTab_QRCode_ExplanationText_WBT: "Skanna för att starta WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Skanna för att starta Video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Skanna för att starta webbsändning",
	Footer_TrainingCoure_Headline: "Utbildningar som tillhandahålls av Bosch",
	Footer_TrainingCourse_Content: "Som ett världskänt supermärke och med över ett sekels erfarenhet av innovation har Bosch satt standarden globalt som leverantör av fordonssystem, delar och tekniska lösningar. Mycket av vår styrka kommer från våra nära samarbeten med fordonstillverkare, för vilka vi designar, utvecklar och producerar toppmoderna system och komponenter över hela världen. Följaktligen har Bosch befäst en stark historia inom eftermarknadsprodukter för fordon och support med en mångsidig portfölj av delar, diagnostiska lösningar och verkstadstjänster. Lärande och utveckling är säkra nycklar till framgång och denna filosofi stöds av ett brett utbud av utbildningar inom alla områden av fordonsteknik, fordonssystem och diagnostikexpertis.",
	SearchField_NoRecordsFound: "Inga uppgifter funna",
	TrainingCatalogue_FreeTextSearch: "Fri text",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Sök",
	CheckoutLayout_WholeSalerGroup: "Välj Grossistgrupp",
	CreditInsufficientModal_Message: "Du har inte tillräckligt med kredit för att kunna använda den här betalningsmetoden. Försök igen med en annan betalningsmetod.",
	ShoppingCart_Required_Credits: "Totalbelopp",
	MaintainingInfo_Message1: "Webbplatsen är i underhållsläge.",
	MaintainingInfo_Message2: "Vi återkommer snart",
	CountryValidationModal_Message: "Du kunde inte logga in på [CountryA] eftersom ditt konto har skapats i [CountryB].",
	CountryValidationModal_ButtonText: "Fortsätt till [Country]",
	EventTable_LoginRequired: "Logga in för att registrera dig",
	TrainingPartTableLayout_Date: "Datum",
	TrainingPartTableLayout_StartTime: "Starttid",
	TrainingPartTableLayout_EndTime: "Sluttid",
	TrainingPartTableLayout_Duration: "Varaktighet (timmar)",
	TrainingPartTableLayout_TrainingMethod: "Utbildningsmetod",
	MyCoursesTableContents_ActiveHeader: "Aktiva utbildningar",
	MyCoursesTableContents_ActiveTabHeader: "Aktiva",
	MyCoursesTableContents_ScheduledHeader: "Schemalagda utbildningar",
	MyCoursesTableContents_ScheduledTabHeader: "Schemalagt",
	MyCoursesTableContents_OnlineHeader: "Onlineutbildning",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Inställda utbildningar",
	MyCoursesTableContents_CancelledTabHeader: "Inställt",
	OnlineCoursesTable_Title_Progress: "Framsteg",
	ScheduledCoursesTable_Title_Date: "Datum [dagar]",
	CoursesTable_Title_Completed: "Avslutad",
	Footer_TrainingProgram: "Utbildningsprogram",
	Breadcrumb_TrainingProgram: "Utbildningsprogram",
	TrainingProgram_Title_Table: "Titel",
	TrainingProgram_TrainingProgram: "Utbildningsprogram",
	TrainingProgram_TargetGroup: "Målgrupp",
	TrainingProgram_MaxLength: "Max. Längd",
	TrainingProgram_Information: "Information",
	TrainingProgram_Detail: "Detaljer",
	TrainingProgram_Modules: "Moduler",
	TrainingProgram_Course: "Kurser",
	TrainingProgram_PartNumber: "Artikelnummer",
	TrainingProgram_Max_Length: "Längd",
	TrainingProgram_Duration: "Varaktighet",
	TrainingProgram_Exam: "Exam",
	TrainingProgram_Assign_Popup: "Anmäl dig till utbildningsprogram",
	TrainingProgram_Student: "Studerande",
	TrainingProgram_Assign: "Tilldela",
	TrainingProgram_Popup_Remove_Title: "Avbokning av utbildning?",
	TrainingProgram_Description: "Beskrivning",
	TrainingProgram_Days: "Dagar",
	TrainingProgram_Type: "Typ",
	TrainingProgram_Action: "Handling",
	TrainingProgram_Enrolled: "Är redan anmäld till detta utbildningsprogram",
	TrainingProgram_SelfAssign_Enrolled: "Du har redan blivit tilldelad detta utbildningsprogram",
	TrainingProgram_Warning: "Registrera en annan kursdeltagare eller välj ett annat utbildningsprogram.",
	TrainingProgram_Workshop: "Verkstad",
	TrainingProgram_Durations: "År",
	TrainingProgram_Duration_DaysLabel: "Dagar",
	TrainingProgram_HasExam_Yes: "Ja",
	TrainingProgram_HasExam_No: "Nej",
	AssignModal_SuccessModal_Header: "Grattis!",
	AssignModal_SuccessModal_Message: "har blivit anmäld till detta utbildningsprogram framgångsrikt",
	AssignModal_SelfAssign_SuccessModal_Message: "Du har framgångsrikt blivit tilldelad utbildningsprogrammet.",
	Dashboard_MyTraningProgram: "Mitt utbildningssprogram",
	Dashboard_MyTraningProgram_Title: "Titel",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Se",
	Dashboard_MyTraningProgram_Register: "Registrera",
	Dashboard_MyTraningProgram_Records: "Historiska rekord",
	Dashboard_MyTrainingProgram_In_Progress: "Pågående",
	Dashboard_MyTrainingProgram_Accredited: "Ackrediterad",
	Dashboard_Program_Modal_Withdraw: "Avbokad",
	Dashboard_Program_Modal_Grade: "Betyg",
	Dashboard_MyTrainingProgram_Registered: "Registrerad",
	Dashboard_MyTrainingProgram_Completedd: "Avslutad",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Ja",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Nej",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Var medveten om att en avbokning innebär att du kommer att avboka dig från hela utbildningsprogrammet, inte bara från en individuell utbildning.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Vill du fortfarande avboka utbildningen?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "I väntan på",
	WithdrawProgramModal_Withdraw_Success_Message: "Din avbokning av utbildningsprogrammet är genomförd",
	Common_Warning_Title: "Varning!",
	Common_Error_Title: "Ups, något gick fel!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Lägg till i kundvagn",
	AddEventFromCatalogToCartModal_HoursText: "timmar",
	AddElearningToCartSuccessModal_Message: "har lagts till i din varukorg!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "Den valda deltagaren har registrerats på denna utbildning, eller så har utbildningen redan lagts till i kundvagnen.",
	EventDetailTab_SubjectForChange_Message: "Med reservation för förändring",
	Dashboard_MyTrainingProgram_Status_Failed: "Inte passerat",
	Dashboard_MyTrainingProgram_Status_Passed: "Passerat",
	Dashboard_Employee_Courses: "Kurser",
	Dashboard_Employee_Program: "Utbildningsprogram",
	Dashboard_Employee_WaitList: "Väntelista",
	Dashboard_Employee_Account: "Konto",
	Course_Price_Free: "Gratis",
	Dashboard_Employee_Account_Update_Success_Message: "Du har framgångsrikt uppdaterat din personalinformation!",
	Dashboard_Supplier: "Levernatör",
	Event_Detail_Comment: "Det finns för närvarande inga meddelanden",
	TrainingCatalogue_Currency: "Valuta",
	My_Wallet: "Min Plånbok",
	Wallet: "Plånbok",
	Credits_Balance: "Krediter Saldo",
	MyFullCalendar_Today: "Idag",
	Header_Available_Languages: "Språk",
	Header_CurrentRegion: "Du är i",
	Header_ChangeRegions: "Byta land?",
	Header_Anonymous: "Service Training",
	Header_Anonymous_Login: "Logga in / Registrera dig",
	Header_RegisterCourse: "Anmälning",
	Homepage_Start: "Komma igång",
	Homepage_Login: "Logga in",
	MainPage_Title: "Den ultimata verkstadslösningen",
	MainPage_Content: "Din verkstad, ditt val",
	MainPage_ImageTitle: "Bosch Automotive \n Training Solutions",
	MainPage_IntroContent: "Gör det möjligt för den moderna verkstaden att arbeta professionellt, tillförlitligt och effektivt med diagnostiska procedurer, felsökning, reparationer och service av alla typer av fordon.",
	BoschNews_TrainingNews: "Nyheter",
	Service_Assist_Workshop: "Förbättra din upplevelse med Workshop Service Assist",
	Enhance_Title_CDM3_v2: "Vad kan jag göra med appen?",
	Enhance_Body_CDM3_Item_1: "Boka kurser och upprätthåll din läranderesa",
	Enhance_Body_CDM3_Item_2: "Ladda ner utbildningsmaterial och kurscertifikat",
	Enhance_Body_CDM3_Item_3: "Starta eLearning-kurser",
	Enhance_Body_CDM3_Item_4: "Få tillgång till olika fordonstjänster i en mobilapplikation",
	Enhance_Body_CDM3_Item_5: "Videoöverföring live med Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Gratis verktyg för automatisk fordonsskanning",
	Training_Stories: "Kurs berättelser",
	SettingsMyAccount_JoinCompanyButton: "Gå med i företaget",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Gå med i företaget",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Ange din aktiveringskod nedan",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Obs! Du måste kontakta din företagsadministratör för att få en aktiveringskod.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Din aktivering har behandlats och ditt konto är nu kopplat till ditt företag.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Klicka på Fortsätt för att komma in på webbplatsen.",
	ContinueButton: "Fortsätt",
	WelcomeBatsModal_Title: "Ditt konto granskas för närvarande!",
	WelcomeBatsModal_ActivationLink: "Klicka här om du har en aktiveringskod för att gå med i ett företag!",
	WelcomeBatsModal_ActivationLinkNotes: "Obs! Du måste kontakta din företagsadministratör för att få en aktiveringskod.",
	AccountInReviewModal_Description_Paragraph1: "Tack för att du registrerar ett personligt konto på Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Ditt konto konfigureras och granskas av vår personal. Du får bekräftelse via e-post när ditt konto är klart.",
	AccountInReviewModal_CloseButton: "Okej släpp in mig!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Vi kunde inte validera din aktiveringskod och e-postadress.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Försök igen eller kontakta din företagsadministratör för hjälp.",
	TryAgain_Button: "Försök igen",
	Error_Title: "Hoppsan! Något gick fel!",
	SettingsMyProfileActivationSuccessModal_Title: "Framgång!",
	AccountInReviewModal_Description_Paragraph3: "Under tiden är du välkommen att surfa på vår webbplats med begränsad åtkomst.",
	AccountInReviewModal_Description_Paragraph4: "Om du har några frågor, eller om ditt konto inte behandlas inom 48 timmar exklusive helger och helgdagar, kan du kontakta oss [här]",
	Support_SubHeadline: "Lite vägledning för att hjälpa dig...",
	Support_FAQ_Headline: "Vanliga frågor",
	Support_FAQs_section1_title: "Allmän",
	Support_FAQs_section1_question1: "Vad är Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions är en plattform som ger en omfattande översikt över tekniska utbildningar och gör det möjligt att boka och hantera utbildningar.",
	Support_FAQs_section1_question2: "Kan jag använda mitt befintliga Bosch-konto (singleKey ID) för att logga in på Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Ja, det är tillgängligt med alla konton som skapats med \"Mitt Bosch ID\".",
	Support_FAQs_section1_question3: "Kan jag använda ett konto som skapats för Bosch Training Solutions med andra Bosch-tjänster eller applikationer?",
	Support_FAQs_section1_question3_answer1: "Ja, du kan använda ditt personliga Bosch-ID även för en mängd andra Bosch-applikationer - både professionellt och privat, som e-bike eller smarta hem.",
	Support_FAQs_section2_title: "Utbildning",
	Support_FAQs_section2_question1: "Hur bokar jag en kurs?",
	Support_FAQs_section2_question1_answer1: "Logga in med ditt befintliga konto ELLER registrera ett konto i portalen. Gå till \"Utbildningstjänster\" ELLER \"Evenemangskalender\" välj en kurs och klicka på knappen Lägg till på kort.",
	Support_FAQs_section2_question2: "Inget lämpligt kursdatum finns tillgängligt. Vad kan jag göra?",
	Support_FAQs_section2_question2_answer1: "Du har möjlighet att sätta dig själv på en \"allmän\" väntelista (utan att välja ett datum) och vårt team kommer att informera dig så snart det finns ett nytt kursdatum.",
	Support_FAQs_section2_question3: "Var hittar jag en översikt över mina bokade kurser?",
	Support_FAQs_section2_question3_answer1: "Du kan hitta en komplett översikt över alla bokade och genomförda kurser efter inloggning i din instrumentpanel (Dashboard).",
	Support_FAQs_section3_title: "Inställningar",
	Support_FAQs_section3_question1: "Hur kan jag ändra min e-post och lösenord?",
	Support_FAQs_section3_question1_answer1: "Du måste logga in på portalen och byta till din personliga profil. (Inuti din instrumentpanel (Dashboard) kan du hitta din profilinformation eller om du klickar på följande knapp i rubriken",
	Support_FAQs_section3_question1_answer3: "Där har du möjlighet att ändra detaljerna för ditt Bosch ID",
	Support_FAQs_section3_question2: "Vad kan jag göra när jag har glömt mitt lösenord?",
	Support_FAQs_section3_question2_answer1: "Använd inloggnings-/registreringsknappen och fyll i din e-postadress",
	Support_FAQs_section3_question2_answer2: "Om du klickar på fortsätt har du möjlighet att klicka på \"Glömt lösenord\"",
	Support_FAQs_section3_question3: "Hur skapar man ett verkstadskonto?",
	Support_FAQs_section3_question3_answer1: "Om du vill kunna få en verkstadsroll kontakta din lokala utbildningsadministration t.ex. du kan använda kontaktformuläret på hemsidan.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Välj land/region",
	MaintenanceBanner_Message_1: "Bosch Automotive Training Solutions Portal kommer att uppdateras på [maintenance_schedule]. Uppdateringen förväntas ta 30 minuter. Portalen kommer inte att vara tillgänglig under uppdateringen.",
	MaintenanceBanner_Message_2: "\För ytterligare hjälp, vänligen kontakta oss via e-post [support_email]",
	MaintenancePage_Message_1: "Bosch Automotive Training Solutions-portalen uppdateras för närvarande",
	MaintenancePage_Message_2: "Vi förväntar oss att portalen kommer att vara tillgänglig igen på [maintenance_online_date] på [maintenance_online_time]",
	Course_Detail_No_Prerequisites: "Inga förkunskaper",
	TableCell_Hours: "Timmar",
	Dashboard_MenuItem_Dashboard: "instrumentpanel",
	Dashboard_MenuItem_Curricula: "Läroplaner",
	Dashboard_MenuItem_Certificates: "Certifikat",
	Dashboard_MenuItem_Workshops: "Verkstäder",
	Dashboard_MenuItem_External: "Extern",
	Dashboard_MenuItem_Wallet: "Plånbok",
	Dashboard_MenuItem_Profile: "Profil",
	Dashboard_Headline_YourUpcomingCourses: "Dina kommande kurser",
	Dashboard_Headline_YourELearningCourses: "Dina eLearning-kurser",
	Dashboard_Headline_TeamOverview: "Team översikt",
	Dashboard_Headline_YourOverview: "Din översikt",
	Dashboard_Headline_YourWishlist: "Din önskelista",
	Dashboard_Headline_ActivePrograms: "Aktiva program",
	Dashboard_Headline_CompletedPrograms: "Genomförda program",
	Dashboard_Headline_YourCertificates: "Dina certifikat",
	Dashboard_Headline_Transactions: "Transaktioner",
	Dashboard_Table_NoRecordsToDisplay: "Ingen post att visa",
	Dashboard_TableColumn_Duration_Hours: "timmar",
	Dashboard_ELearningCourses_TableHeader_Title: "Titel",
	Dashboard_ELearningCourses_TableHeader_Duration: "Varaktighet (timmar)",
	Dashboard_ELearningCourses_ActionButton_View: "Se",
	Dashboard_UpcomingCourses_TableHeader_Title: "Titel",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Datum [dagar]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Plats",
	Dashboard_UpcomingCourses_ActionButton_View: "Se",
	Dashboard_YourOverview_TableHeader_Title: "Titel",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Datum / Varaktighet",
	Dashboard_YourOverview_TableHeader_Location: "Plats",
	Dashboard_YourOverview_ActionButton_View: "Se",
	Dashboard_YourWishlist_TableHeader_Title: "Titel",
	Dashboard_YourWishlist_TableHeader_Action: "Handling",
	Dashboard_YourWishlist_ViewAllRecords: "Visa alla poster",
	Dashboard_TeamOverview_TableHeader_Employee: "Anställd",
	Dashboard_TeamOverview_TableHeader_Title: "Titel",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Datum / Varaktighet",
	Dashboard_TeamOverview_TableHeader_Location: "Plats",
	Dashboard_TeamOverview_ActionButton_View: "Se",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Sök",
	Dashboard_ActiveProgram_TableHeader_Title: "Titel",
	Dashboard_ActiveProgram_TableHeader_Status: "Titel",
	Dashboard_ActiveProgram_ActionButton_View: "Se",
	Dashboard_CompletedProgram_TableHeader_Title: "Titel",
	Dashboard_CompletedProgram_ActionButton_View: "Se",
	Dashboard_Certificates_TableHeader_Title: "Titel",
	Dashboard_Certificates_TableHeader_Date: "Datum",
	Dashboard_Certificates_TableHeader_Action: "Handling",
	Dashboard_Certificates_ActionButton_Download: "Ladda ner",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Fakturanummer",
	Dashboard_Transactions_TableHeader_Description: "Beskrivning",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Betalningsmetod",
	Dashboard_Transactions_TableHeader_Date: "Datum",
	Dashboard_Transactions_TableHeader_Amount: "Belopp",
	Dashboard_Transactions_ActionButton_Download: "Ladda ner",
	Breadcrumb_MyTraining_CourseDetails: "Kursinnehåll",
	Breadcrumb_MyTraining_Curricula: "Läroplaner",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Detaljer om kursprogram",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Visa medarbetare",
	MyTraining_Headerbar_CourseDetails: "kursinnehåll",
	MyTraining_Headerbar_TrainingProgramDetails: "Information om utbildningsprogram",
	TrainingProgramDetails_Label_OverallProgress: "Generell framgång",
	TrainingProgramDetails_ProgramItems_Headline: "Programpunkter",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Titel",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Betyg",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Datum",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Se",
	TrainingProgramDetails_Status_InProgress: "Pågående",
	TrainingProgramDetails_Status_Completed: "Avslutad",
	CourseDetails_Label_Duration: "Varaktighet",
	CourseDetails_Label_Trainer: "Utbildare",
	CourseDetails_Label_Language: "Språk",
	CourseDetails_Label_Score: "Resultat",
	CourseDetails_Label_StartDate: "Startdatum",
	CourseDetails_Label_EndDate: "Slutdatum",
	CourseDetails_Label_Venue: "Mötesplats",
	CourseDetails_Label_Address: "Adress",
	CourseDetails_Label_Grade: "Betyg",
	CourseDetails_Action_Withdraw: "Dra tillbaka",
	CourseDetails_Action_Evaluate: "Utvärdera kursen",
	CourseDetails_Action_Evaluate_Description: "Vänligen utvärdera denna kurs för att generera ditt certifikat!",
	CourseDetails_Action_DownloadCertificate: "Ladda ner certifikat",
	CourseDetails_Action_JoinWebcast: "Gå med i webbsändning",
	CourseDetails_Action_StartCourse: "Starta kurs",
	CourseDetails_Action_RestartCourse: "Starta om kursen",
	CourseDetails_Action_RepeatCourse_Description: "Vänligen upprepa och klara den här kursen för att ladda ner ditt certifikat!",
	CourseDetails_PartSpecificDetails_Headline: "Delspecifika uppgifter",
	CourseDetails_PartSpecificDetails_Tab_Part: "Del",
	CourseDetails_PartSpecificDetails_Description: "Beskrivning",
	CourseDetails_PartSpecificDetails_StartDate: "Startdatum",
	CourseDetails_PartSpecificDetails_EndDate: "Slutdatum",
	CourseDetails_PartSpecificDetails_StartTime: "Starttid",
	CourseDetails_PartSpecificDetails_EndTime: "Sluttid",
	CourseDetails_PartSpecificDetails_Method: "Metod",
	Dashboard_Team_TableHeader_Name: "Namn",
	Dashboard_Team_TableHeader_Role: "Roll",
	Dashboard_Team_TableHeader_Email: "E-post",
	Dashboard_Team_TableHeader_PersonalAccount: "Personligt konto",
	Dashboard_Team_ActionButton_Select: "Välj",
	Dashboard_Team_PersonalAccount_Activated: "Aktiverad",
	Dashboard_Team_PersonalAccount_NotActivated: "Inte aktiverad",
	Dashboard_Team_ActionButton_AddMember: "Lägg till medlem",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Sök",
	Dashboard_External_TableHeader_Name: "Namn",
	Dashboard_External_ActionButton_AddNew: "Lägg till ny",
	Dashboard_External_Headline: "Godkänd extern åtkomst",
	Dashboard_Workshops_Headline: "Verkstäder",
	Dashboard_Workshops_TableHeader_Company: "Företag",
	Dashboard_Workshops_TableHeader_Address: "Adress",
	Dashboard_Workshops_TableHeader_Postcode: "Postnummer",
	Dashboard_Workshops_TableHeader_City: "Stad",
	Dashboard_Workshops_ActionButton_Select: "Välj",
	Wallet_ServiceCredits: "Servicekrediter",
	Wallet_Headline_CreditBalance: "Kreditsaldo",
	Wallet_Headline_CreditActivity: "Kreditaktiviteter",
	Wallet_Headline_BillingTransactions: "Faktureringstransaktioner",
	Wallet_BillingTransaction_TableHeader_Order: "Beställa",
	Wallet_BillingTransaction_TableHeader_Date: "Datum",
	Wallet_BillingTransaction_TableHeader_Amount: "Belopp",
	Wallet_BillingTransaction_TableHeader_Action: "Handling",
	Wallet_CreditActivity_TableHeader_Date: "Datum",
	Wallet_CreditActivity_TableHeader_Description: "Beskrivning",
	Wallet_CreditActivity_TableHeader_User: "Användare",
	Wallet_CreditActivity_TableHeader_Amount: "Belopp",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, alla rättigheter reserverade",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Produktsäkerhet (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovationer, patent och licenser",
	FooterAdditionalLinks_Navigation_Logistics: "Inköp och logistik",
	MenuHeader_MyTraining: "Min utbildning",
	MenuHeader_TrainingServices: "Utbildningstjänster",
	MenuHeader_OurLocations: "Våra platser",
	MenuHeader_ContactsUs: "Kontakta oss",
	MenuHeader_Logout: "Logga ut",
	MyProfile_MyBoschId: "Mitt Bosch-ID",
	MyProfile_AccountDeletion: "Radering av konto",
	MyProfile_Edit: "Ändra e-post eller redigera lösenord",
	MyProfile_EditUserProfile: "Redigera användarprofil",
	BackButton: "Tillbaka",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Vi ser fram emot ditt samtal och hjälper dig gärna. Vi finns tillgängliga för dig 24 timmar om dygnet, 7 dagar i veckan",
	ContactUs_Email_SecurityCodeConfirm_V3: "Ange rätt tecken, enligt rutan ovan.",
	ContactUs_Email_MandatoryNotice_V3: "Fyll i alla obligatoriska fält.",
	ContactUs_Email_SendButton: "Skicka",
	Breadcrumb_TrainingCenters: "Utbildningscenter",
	Training_Centres_Book_Btn: "Boka en kurs",
	Training_centres_booking_code: "Bokningskod",
	Training_centers_header_bar_title: "Våra utbildningscenter",
	TeamEdit_PersonalAccountToolTop: "Genom att aktivera den här funktionen får medarbetaren tillgång till denna webbplats. Användaren kommer att kunna skapa ett personligt konto och vara en del av ditt företagskonto. När den är aktiverad kan du skicka en inbjudan med en aktiveringskod till medarbetaren. Inbjudan kommer att skickas till e-postadressen.",
	TeamEdit_Update: "Aktivering av företagsadministratörsfunktionen ger automatiskt den här medarbetaren full åtkomst till ditt företagskonto. Aktivering av den här funktionen kräver att användaren loggar ut och loggar in igen på webbplatsen, om användaren redan är inloggad.",
	TeamEdit_PersonalAccount: "Tillåt personligt Bosch Training Solutions webbkonto",
	TeamEdit_SendInvitation: "Skicka inbjudan",
	TeamEdit_Deactivate: "Avaktivera",
	TeamEdit_Telephone: "Telefon",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Mobil måste vara nummer",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telefon måste vara nummer",
	TeamEdit_Activate: "Aktiva",
	TeamEdit_NotActivated: "Inte aktivera",
	TeamEdit_StatusPopup: "Statusen kommer att visas som \"Aktiv\" om användaren har skapat ett personligt konto. Om statusen är inställd som \"Inte aktiv\" betyder det att användaren inte har slutfört din inbjudan.",
	Training_Catalog_Courses: "Kurser",
	Training_Catalog_Course: "Kurs",
	Reset_Filter: "Återställ filter",
	TrainingCatalogue_CourseCatalogue: "Kurskatalog",
	CheckoutHeader_CartItemCount_Text: "Du har [count] vara i din kundvagn",
	CheckoutHeader_CartItemsCount_Text: "Du har [count] vara i din kundvagn",
	ShoppingCart_PriceItem: "Varupris",
	ShoppingCartItemParticipants_AddParticipant_Button: "Lägg till deltagare",
	ShoppingCartTotalPrice_SubTotalLabel: "Delsumma",
	ShoppingCartTotalPrice_TaxesLabel: "Skatter",
	ShoppingCartTotalPrice_IncludedVATLabel: "Inkluderar moms på [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Tillgängliga betalningsmetoder online",
	ShoppingCartItemRow_VenueFeeLabel: "Lokalavgift [fee_amount] per deltagare",
	AddParticipantsModal_Title: "Lägg till deltagare",
	AddParticipantsModal_SelectEmployeeLabel: "Lägg till deltagare",
	AddParticipantsModal_SelectWorkshopLabel: "Välj Verkstad",
	ShoppingCartTotalPrice_ContinueButtonText: "Fortsätt till kassan",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "Deltagare är inte tillagd!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Du har en eller flera kurser i din varukorg utan deltagare.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Vänligen lägg till en deltagare för att fortsätta utcheckningen.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Redigera deltagare ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Namn",
	AddParticipantsModal_ParticipantTable_Email: "E-post",
	AddParticipantsModal_ParticipantTable_Action: "Åtgärd",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Inga deltagare...",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "E-postadressen är inte konfigurerad för vald deltagare",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "Den valda deltagaren har ingen e-postadress konfigurerad i sin profil. E-postadress är obligatorisk för registrering till en utbildning.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Lägg till e-postadress för att fortsätta eller avbryt för att välja en annan deltagare.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Lägg till e-post",
	CancelButton: "Avbryt",
	AddParticipantsModal_AddEmployeeButtonText: "Lägg till anställd",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Du håller på att ta bort en kurs från din kundvagn",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Du försöker ta bort en kurs med tillagda deltagare från din kundvagn.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Klicka på \"Fortsätt\" om du vill fortsätta eller klicka på \"Avbryt\".",
	ShoppingCartItemHeader_Participants: "Deltagare",
	ShoppingCartItemHeader_ParticipantPrice: "Pris per sittplats",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Visa i [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Visa i krediter",
	ShoppingCart_CreditsPriceUnit: "Krediter",
	CheckOutHeader_Step1_Question: "Hur vill du betala?",
	CheckOutHeader_Step2_Question: "Granska din beställning",
	CheckOutHeader_Step3_Question: "Complete your order",
	CheckOutHeader_Step4_Question: "Din bokning lyckades!",
	CheckOutHeader_Step_Payment: "Betalning",
	CheckOutHeader_Step_Review: "Granska",
	CheckOutHeader_Step_Place_Order: "Beställa",
	CheckOutRightPanel_Review_Order_Btn: "Granska beställning",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Beställa",
	CheckoutRightPanelContent_OrderSummary_Title: "Beställningssammanfattning",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Redigera kundvagn",
	ShoppingCart_Participants: "Deltagare",
	CheckOutStepPayment_Payment_Methods_Title: "Välj betalsätt",
	CheckOutStepPayment_Credit_Title: "Kredit eller debitering",
	CheckOutStepPayment_Wholesaler_Title: "Grossist",
	CheckOutStepPayment_ServiceCredit_Title: "Servicekrediter",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Du har krediter [available_credit]",
	CheckOutStepPayment_BoschAccount_Title: "Bosch konto",
	Checkout_Second_PaymentMethod_Headline: "Betalningsmetod",
	CheckOutStepReview_Edit: "Redigera",
	CheckOutStepReview_Terms_Label: "Regler, villkor och avbokningsregler",
	CheckOutStepReview_Terms_Text: "Jag har läst, förstått och godkänner [villkoren och avbokningspolicyn] för försäljningen.",
	SelectSecondPaymentMethodModal_Title: "Välj sekundär betalningsmetod",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Betala [amount]",
	StepPaymentWholesaler_Title: "Välj grossist",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Acceptera villkoren",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Läs och acceptera våra villkor och avbokningspolicy innan du fortsätter.",
	CheckoutDescription_ThankYou: "Tack för att du skickade en beställning på Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Våra bitar och bytes har redan börjat behandla din beställning. Håll din motor på tomgång under tiden.",
	CheckoutDesctiption_Text: "Vi skickar dig en slutlig bekräftelse via e-post inklusive alla detaljer om din bokning.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Du håller på att ta bort den valda verkstaden",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Genom att ta bort den valda verkstaden kommer alla tillagda deltagare att återställas. Denna ändring kommer att påverka alla kurser i din kundvagn.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Klicka på \"Fortsätt\" om du vill fortsätta eller klicka på \"Avbryt\" för att avbryta den här åtgärden.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Du har otillräckliga krediter. Ditt kreditsaldo är [Tillgänglig_kredit] Krediter, men du behöver [vagnens_totala_krediter] Krediter för att fortsätta i kassan. Välj en annan betalningsmetod eller ta bort varor från din kundvagn.",
	Training_Programs_Filter_Item: "Program",
	Training_Program_Filter_Item: "Program",
	Training_Program_Year: "År",
	Training_Program_Detail_Title: "Utbildningsprogram",
	AccountButton_Workshops_MenuItem: "Verkstäder",
	AccountButton_Wallet_MenuItem: "Plånbok",
	Header_MyBoschProfile_Header: "MyBosch-profil",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Tillgängliga",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Lägg till/redigera deltagare",
	ShoppingCart_CreditPriceUnit: "Kredit",
	Common_IncludedVAT: "Inkl.moms",
	TrainingCatalogue_Length: "Längd",
	TrainingCatalogue_Method: "Metod",
	Course_Price_Credits: "Krediter",
	Catalogue_Grid_Register_Button: "Registrera",
	Catalogue_Credit_Tilte: "Klicka här för att se priser i krediter",
	Catalogue_Price_Tilte: "Klicka här för att se priser i EUR",
	Catalogue_Grid_Vat: "Exkl.moms",
	EventCalendar_Seats: "Sittplatser",
	MenuHeader_Calendar: "Kalender",
	MenuHeader_Catalog: "Katalog",
	TableCell_Minutes: "Minuter",
	ListFilter_NoResult: "Inget resultat",
	Course_Description: "Kursbeskrivning",
	Training_course_detail: "Grunderna i sociala medier",
	Training_course_detail_add_waiting: "Lägg till allmän väntelista",
	Register_now_btn: "Registrera nu !",
	Register_now_btn_add_event_to_cart: "Registrera nu !",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Visa alla poster",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Se färre",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Visa alla poster",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Se färre",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Visa alla poster",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Se färre",
	TeamCreate_TitleError: "Titel krävs",
	Dashboard_Headerbar_BookCourse: "Boka kurs",
	Dashboard_Headerbar_ExitProxy: "Avsluta proxy",
	Dashboard_Headerbar_EvaluateCourse: "Utvärdera kurs",
	Dashboard_Headerbar_EvaluateCourse_Note: "Vänligen utvärdera denna kurs för att generera ditt certifikat!",
	Dashboard_Headerbar_DownloadCertificate: "Ladda ner certifikat",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Dra tillbaka",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Dra tillbaka",
	Dashboard_Headerbar_WelcomeMessage: "Välkommen till din inlärningsinstrumentpanel",
	Dashboard_Headerbar_ProxingAs: "Proxying som",
	Dashboard_TrainingProgram_Status_Completed: "Avslutad",
	Dashboard_TrainingProgram_Status_InProgress: "Pågående",
	External_SelectWholesalerModal_Title: "Lägg till extern användaråtkomst",
	External_SelectWholesalerModal_Company_Label: "Företag",
	External_SelectWholesalerModal_Company_Placeholder: "Vänligen välj",
	External_SelectWholesalerModal_Outlet_Placeholder: "Vänligen välj",
	External_SelectWholesalerModal_ApplyButton: "Tillämpa",
	External_SelectWholesalerModal_CancelButton: "Avbryt",
	External_RemoveWholesalerModal_ConfirmLabel: "Bekräftelse",
	External_SelectWholesalerModal_ConfirmMessage: "Är du säker på att du vill ta bort den här grossisten?",
	External_SelectWholesalerModal_YesButton: "Ja",
	External_SelectWholesalerModal_NoButton: "Nej",
	Training_program_team_member: "Teammedlem",
	Training_program_assign_training_program: "Tilldela ett utbildningsprogram",
	Assign_modal_student_confirmation_text1: "Du är på väg att tilldela [Training Program Name] till din läranderesa.",
	Assign_modal_student_confirmation_text2: "Vill du fortsätta?",
	MyFullCalendar_Oct: "Okt",
	MenuHeader_CourseCatalog: "Kurskatalog",
	MenuHeader_Event: "Händelsekalender",
	MenuHeader_Program: "Utbildningsprogram",
	MenuHeader_Services: "Utbildningstjänster",
	MenuHeader_Log_out: "Logga ut",
	Filter_Location: "Plats",
	TeamEdit_UpgradeToCompanyAdministrator: "Uppgradera till företagsadministratör",
	TeamEdit_PersonalWebAccountStatusIs: "Personligt webbkonto status är",
	Header_CreateNewEmployee: "Skapa ny anställd",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Inga platser tillgängliga!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Det finns inga lediga platser på det valda evenemanget.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Vänligen välj ett annat event eller ta bort en redan tillagd deltagare för att fortsätta.",
	Common_SuccessModal_Title: "Grattis!",
	TrainingProgramDetails_Resit_StatusText: "Gör om",
	EmployeeProfile_UserExistErrorMessage: "Kontot finns redan!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Betalning misslyckades. Försök igen eller använd en annan betalningsmetod.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Du har framgångsrikt avaktiverat den valda medarbetaren!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Sparad!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Dina ändringar har sparats.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "Återkallelse är inte möjligt på eLearning-kurser. Kontakta oss om ytterligare information behövs.",
	Dashboard_Team_PersonalAccount_Pending: "I väntan på",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Anställd skapad!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "Ditt medarbetarskapande har slutförts.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Den här inställningen gör att du kan begränsa en anställd från att registrera sig till kurser. Endast företagsadministratörer kommer att kunna registrera medarbetare till kurser när denna inställning har avmarkerats.",
	Training_catalog_sort_by: "Sortera på",
	CourseDetail_EvaluationRestriction_Title: "Utvärdering av utbildningen är inte möjlig!",
	CourseDetail_EvaluationRestriction_Message: "Utbildningsutvärderingar kan endast lämnas in av studenten själv. Vänligen instruera studenten att logga in på Bosch Automotive Training Solutions och slutföra utbildningsutvärderingen.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Om eleven inte redan har ett konto kan en inbjudan delas i användarprofilinställningarna för teammedlemmen.",
	Breadcrumb_Download_Page: "Ladda ner",
	Download_Table_Category_Cell: "Kategori",
	Download_Table_Title_Cell: "Titel",
	Download_File_Filter_Item: "Fil",
	Download_File_Filter_Items: "Filer",
	Download_Header: "Nedladdningsområde",
	DownloadArea_Button_Download: "Ladda ner",
	Popup_Title_Error: "Fel",
	Popup_Title_Success: "Framgång",
	Popup_Title_Info: "Info",
	Popup_Title_Warning: "Varning!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Följande potentiella evenemangsregistreringar finns redan, kommer inte att avbrytas och måste avbokas individuellt.",
	TrainingProgram_PDF_Export: "PDF -export",
	TrainingProgram_PDF_Export_Participant: "Deltagare:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Generell framgång:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Utbildningstitel",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Kvalitet",
	TrainingProgram_PDF_Export_TableHeader_Date: "Datum",
	AccountButton_CompanyChange_MenuItem: "Företagsförändring",
	SelectCompanyModal_Title: "Välj företag",
	CloseButton: "Stänga",
	Change_Button: "Förändra",
	ChangeManagerModal_Content: "Välj användare",
	ChangeCompanyModal_HeadOffice: "(Huvudkontor)",
	SelectCompanyModal_Content: "Välj företag för att logga in ...",
	AlertSuccess_Title: "Framgång",
	CheckOutStepPayment_SecondaryPayment_Title: "Välj sekundär betalningsmetod",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Lägg till sekundär betalningsmetod",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Lägg till sekundär betalningsmetod nedan eller välj en annan betalningsmetod ovan.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Du har otillräckliga servicekrediter på ditt konto för att betala för dina artiklar i kundvagnen. För att fortsätta använda den valda betalningsmetoden måste du betala skillnaden. Skillnaden är [difference_amount] (exklusiv moms).",
	ShoppingCartTotalPrice_TotalInCredit: "Totalt pris i krediter",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Dina tillgängliga krediter",
	ShoppingCartTotalPrice_YourDifference: "Skillnad",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal exc. moms",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal inkl. moms",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Sekundär betalningsmetod",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Redigera betalningsmetod",
	Checkout_Second_PaymentMethod: "Andra betalningsmetoden",
	Breadcrumb_TrainingCourseDetails: "Uppgifter om utbildningen",
	DownloadArea_FailedToDownload_ErrorMessage: "Det har misslyckats med att ladda ner dokument. Försök igen eller kontakta din företagsadministratör för hjälp.",
	DownloadArea_FileNotAvailable_ErrorMessage: "Nedladdningsfil är inte tillgänglig. Försök igen eller kontakta din företagsadministratör för hjälp.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "Nedladdningsfilen är förbjuden. Försök igen eller kontakta din företagsadministratör för hjälp.",
	SSO_Redirecting_Message: "Du omdirigeras till Bosch Training portal...",
	SSO_Redirecting_ParameterErrorMessage: "Land och/eller språk anges inte. Försök igen eller kontakta din företagsadministratör för hjälp.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Anmälan om registrering accepterades inte.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Detta kan bero på att man ligger inom kursens anmälningströskeldagar för en eller flera av kurserna. Använd fältet \"Kontakta oss\" om du har några frågor eller önskemål.",
	TimeSlotPicker_DateFormat_Title: "Datumformat",
	UpdateAccountSettingsConfirmationModal_Title: "Du håller på att ändra datumformatet!",
	UpdateAccountSettingsConfirmationModal_Message1: "Datumformatet kommer att visas enligt ditt val och kan ändras när som helst.",
	UpdateAccountSettingsConfirmationModal_Message2: "Vill du fortsätta?",
	ProfileSummary_Settings_Title: "inställningar",
	UpdateAccountSettings_DateFormat_Title: "Inställningar för datumformat",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Det gick inte att uppdatera datumformatet.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "Inställningen för datumformat har uppdaterats.",
	Dashboard_Headerbar_TrainingProgram_DownloadCertificate: "Ladda ner certifikat",
	Dashboard_TrainingProgram_DownloadCertificate_Title: "Certifikat för utbildningsprogram",
	Dashboard_TrainingProgram_DownloadCertificate_ErrorMessage: "Det gick inte att ladda ner dokumentet. Försök igen eller kontakta din företagsadministratör för hjälp.",
	CourseWithdrawalModal_PendingWithdraw_Message1: "Väntande uttag är tillämpligt, att uttaget har överskridit tröskeln för avbokning och att studenten kommer att få ytterligare information via e-post, när begäran har godkänts/avvisats.",
	CourseWithdrawalModal_PendingWithdraw_Message3: "Se vår [avbokningspolicy] för ytterligare information.",
	TrainingCourse_Course_NotAvailable_Title: "Kursen är inte tillgänglig.",
	TrainingCourse_Course_NotAvailable_Message: "Kursen är inte tillgänglig. Försök igen eller kontakta din företagsadministratör för hjälp.",
	TrainingCourse_NotAvailable_Message_1: "Du kommer åt en utbildning som inte är tillgänglig.",
	TrainingCourse_NotAvailable_Message_2: "Försök igen eller gå till [training catalog] för att hitta fler kurser.",
	LanguageSelection_SelectALanguage: "Välj språk",
	LanguageSelection_SelectYourCountry: "Välj land",
	Checkout_PaymentMethod_Headline: "Betalsätt",
	Download_Categories_Catalogue: "Katalog",
	Download_Categories_Calendar: "Kalender",
	Download_Categories_General: "Generell",
	Download_Categories_Information: "Information",
	Download_Categories_Userguides: "Bruksanvisningar",
	CourseWithdrawalModal_PendingWithdraw_CancellationPolicy: "Regler, villkor och avbokningsregler",
	CourseWithdrawalModal_PendingWithdraw_CancelButton: "Avbryt",
	CourseWithdrawalModal_PendingWithdraw_ContinueButton: "Fortsätt",
	Duration_Seconds: "Sekunder",
	Duration_Day: "Dag",
	Duration_Hour: "Timme",
	Duration_Minute: "Minut",
	Duration_Second: "Sekund",
	EventCalendar_ExternalLink_Tooltips: "Köp via [externalRegistrationName]",
	EventCalendar_ExternalLink_Seat_NA: "n/a",
	EventCalendar_ExternalLink_Cancel: "Annullera",
	EventCalendar_ExternalLink_Continue: "Fortsätt",
	EventCalendar_ExternalLinkModal_Title: "Du är på väg att bli omdirigerad...",
	EventCalendar_ExternalLinkModal_ExternalRegistrationName: "Det valda evenemanget säljs via [externalRegistrationName].",
	EventCalendar_ExternalLinkModal_ContinueRedirectMessage: "Om du fortsätter omdirigeras du till en extern webbplats",
	EventCalendar_ExternalLinkModal_ContinueRedirectConfirm: "Vill du fortsätta?",
	Team_ContactPerson_FirstName_Tooltips: "Elevnamn kan bara ändras av Bosch kursadministration. Vänligen kontakta oss via kontaktformuläret på denna webbplats för ytterligare support.",
	Team_ContactPerson_LastName_Tooltips: "Elevnamn kan bara ändras av Bosch kursadministration. Vänligen kontakta oss via kontaktformuläret på denna webbplats för ytterligare support.",
	Withdraw_ExternalLink_Cancel: "Annullera",
	Withdraw_ExternalLink_Confirm: "Bekräfta",
	Withdraw_ExternalLinkModal_Title: "Du är på väg att bli omdirigerad",
	Withdraw_ExternalLinkModal_Message: "Uttag för denna kurs hanteras av en extern leverantör",
	Withdraw_ExternalLinkModal_Confirmation: "Genom att klicka på \"Bekräfta\" öppnas en ny flik och omdirigerar dig till en extern webbplats.",
	Button_AddToWaitingList_Tooltips: "Lägg till väntelista",
	Button_AddToCart_Tooltips: "Lägg till i kundvagn",
	Button_TrainingCourseDetail_Tooltips: "Se detaljer",
	Button_TrainingProgramDetail_Tooltips: "Se detaljer",
	Button_AssignTrainingProgram_Tooltips: "Tilldela utbildningsprogram",
	Button_DeleteFromCart_Tooltips: "Ta bort från kundvagn",
	Button_RemoveParticipant_Tooltips: "Ta bort deltagare",
	Button_DownloadBillingTransaction_Tooltips: "Ladda ner",
	Button_RemoveExternal_Tooltips: "Ta bort",
	Breadcrumb_TrainingServices: "Utbildnings Service",
	Breadcrumb_Support: "Support",
	Breadcrumb_FAQs: "FAQ",
	Breadcrumb_TrainingProgramDetail: "Utbildnings programs detaljer",
	Breadcrumb_MyTraining_MyTrainingEvents: "Mina Utbildnings tillfällen",
	Breadcrumb_MyTraining_External: "Extern",
	Breadcrumb_MyTraining_Wallet: "Plånbok",
	Breadcrumb_MyTraining_Workshop: "Verkstad",
	Breadcrumb_MyTraining_Workshop_Team: "Team",
	Breadcrumb_MyTraining_Team_Profile: "Profil",
	Breadcrumb_MyTraining_Workshop_Team_EditProfile: "Redigera Profil",
	Breadcrumb_MyTraining_Workshop_Team_CreateEmployee: "Lägg till medarbetare",
	SideNavigation_Title: "Training Solutions",
	SideNavigation_AccountButton_RegisterOrLogin: "Registrera /logga in",
	SideNavigation_Language: "Språk",
	SideNavigation_SelectLanguage: "Välj Språk",
	SideNavigation_AccountButton_Logout: "Logga ut",
	SideNavigation_ConntactUs: "Kontakta oss",
	FooterAdditionalLinks_Navigation_CorporateInformation: "Företags information",
	FooterAdditionalLinks_Navigation_LegalNotice: "Juridiskt meddelande",
	FooterAdditionalLinks_Navigation_DataProtectionNotice: "Data skydds meddelande",
	FooterAdditionalLinks_Navigation_PrivacySettings: "Dina inställningar",
	FooterAdditionalLinks_Navigation_TermAndCondition: "Villkor",
	Common_Version_Text: "Version",
	Content_Header_Dashboard_MyTrainingEvents: "Mina Utbildningstillfällen",
	Content_Header_Dashboard_MyTrainingPrograms: "Mitt Utbildnings program",
	Content_Header_Dashboard_Team: "Team",
	Content_Header_Dashboard_Workshops: "Verkstad",
	Content_Header_Dashboard_External: "Extern",
	Content_Header_Dashboard_Wallet: "Plånbok",
	Content_Header_Dashboard_MyProfile: "Min Profil",
	RedeemVoucher_RemoveButton: "Ta Bort",
	RedeemVoucher_ApplyButton: "Använd",
	Checkout_VoucherApplied_Label: "Din Kod",
	RedeemVoucherModal_InvalidVoucherModal_Title: "Ogiltig Voucher kod",
	RedeemVoucherModal_InvalidVoucherModal_Message1: "Använd Voucher kod är ogiltig",
	RedeemVoucherModal_InvalidVoucherModal_Message2: "Försök igen eller fortsätt utan Voucher kod",
	RedeemVoucherModal_CancelButton: "Avsluta",
	RedeemVoucherModal_TryAgainButton: "Försök på nytt",
	CreditsConfirmationModal_ContinueButton: "Fortsätt",
	CreditsConfirmationModal_CancelButton: "Avsluta",
	CreditsConfirmationModal_Title: "En Voucher har använts",
	CreditsConfirmationModal_Message_1: "Betalning med Service Credits är inte möjlig när du använt en Voucher med rabatt i din kundvagn",
	CreditsConfirmationModal_Message_2: "Klicka Avsluta för att gå tillbaka eller klicka  Fortsätt för att ta bort Voutcher från kundvagnen",
	TrainingCourseCatalog_PricePerSeat: "Pris per plats",
	TrainingCourseCatalog_Duration: "Varaktighet:",
	Homepage_Title_BoschTrainingSolutions: "Bosch Training Solutions",
	ConsentMarketing_Title: "Marknadsföring och nyheter",
	ConsentMarketing_ConfirmButton: "Bekräfta",
	ConsentMarketing_CancelButton: "Avboka",
	ConsentMarketing_Subscribe: "Prenumerera",
	ConsentMarketing_Unsubscribe: "Avsluta prenumeration",
	ConsentMarketing_SubscribeSuccessfully: "Du har nu blivit bokad",
	ConsentMarketing_UnsubscribeSuccessfully: "Du har nu blivit avbokad",
	TrainingCourseCatalog_Courses: "Kurser:",
	TrainingCourseCatalog_Courses_Info: "Kurser",
	Course_Elearning_Series_Detail_Title: "e-lärande serie",
	Course_Elearning_Series_Detail_Label_Date: "Datum",
	Course_Elearning_Series_Course_List: "Kursämnen"
};