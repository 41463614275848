import { User, UserProfile } from "oidc-client-ts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppDispatch } from "../../redux-toolkit/store";
import {
  Account,
  ContactPerson,
  CreateUpdateAccountInput,
} from "../../models/Account";
import {
  getAccountSimpleAsync,
  createAccount,
  setAccountImpersonationList,
  setOnSubWorkshopBranch,
  updateAccount,
  selectAcceptMarketingConsent,
  selectHasCustomerDecided,
} from "../../redux-toolkit/accountReducer";
import {
  selectAuthState,
  selectCountryCode,
  selectLanguage,
  selectMainContentPinned,
  selectRedirectURLCallback,
  setOpenSideNav,
  setRedirectURLCallback,
} from "../../redux-toolkit/authReducer";
import { AppDispatch } from "../../redux-toolkit/store";
import { AuthService } from "../../services/AuthService";
import { useTranslation } from "react-i18next";
import "../../localization/i18n";
import "./signInCallback.css";
import { AccountInReviewModal } from "../../components/views/Modal/AccountInReviewModal";
import {
  getContactPersonByGuidAsync,
  mapPersonalUserAccount,
  resetContactPersons,
} from "../../redux-toolkit/contactReducer";
import { ConfirmItsMeModal } from "../../components/views/Modal/ConfirmItsMeModal";
import {
  GetContactPersonByGuidRequestModel,
  MapPersonalUserAccountModel,
} from "../../models/ContactPerson.Model";
import SelectCompanyModal from "../../Common-components/CompanyModal/SelectCompanyModal";
import ResponseResultBase from "../../models/ResponseResultBase";
import { resetTrainingCourses } from "../../redux-toolkit/trainingCourseReducer";
import { resetTrainingEvents } from "../../redux-toolkit/trainingEventReducer";
import { resetTrainingPrograms } from "../../redux-toolkit/trainingProgramReducer";
import { resetTrainingCenters } from "../../redux-toolkit/trainingCenterReducer";
import { updateForcedToHideNotificationBanner } from "../../redux-toolkit/maintenanceReducer";
import { CommonHelper } from "../../helpers/CommonHelper";
import { UserProfileConstants } from "../../helpers/UserProfileConstants";
import { Constants } from "../../helpers/Constants";
import ConsentMarketingModal from "../../features/signinCallback/consentMarketingModal/ConsentMarketingModal";

const SignInCallback = () => {
  const authService: AuthService = AuthService.getInstance();
  const { t } = useTranslation();
  const [status, setStatus] = useState("");
  const [openAccountReviewModal, setOpenAccountReviewModal] =
    useState<boolean>(false);
  const [openConfirmByMeModal, setOpenConfirmByMeModal] =
    useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [ciamId, setCiamId] = useState<string>("");
  const [studentGuid, setStudentGuid] = useState<string>("");
  const [ciamEmail, setCiamEmail] = useState<string>("");
  const [contactPerson, setContactPerson] = useState<ContactPerson | null>(
    null
  );
  const [ciamAccount, setCiamAccount] = useState<User | null>(null);
  const [studentAccount, setStudentAccount] = useState<Account | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectCompanyModal, setSelectCompanyModal] = useState<boolean>(false);
  const authState = useSelector(selectAuthState);

  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const redirectURLCallback = useSelector(selectRedirectURLCallback);
  const isMainContentPinned = useSelector(selectMainContentPinned);

  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const dispatch = useDispatch();

  const [consentMarketingCallback, setConsentMarkingCallback] =
    useState<Function>(() => () => {});
  const [isShowConsentMarketingModal, setShowConsentMarketingModal] =
    useState<boolean>();

  const isAcceptConsentMarketing = useSelector(selectAcceptMarketingConsent);
  const isHasCustomerDecided = useSelector(selectHasCustomerDecided);
  const [consentMarketingMessage, setConsentMarketingMessage] = useState("");

  useEffect(() => {
    if (!isMainContentPinned) dispatch(setOpenSideNav(false));
  }, []);

  const closeAccountReviewModal = () => {
    setOpenAccountReviewModal(false);
    setTimeout(() => {
      window.location.href = "/";
    }, 200);
  };

  const userConfirmItsMe = async () => {
    setOpenConfirmByMeModal(false);
    setIsLoading(true);
    const requestObj: MapPersonalUserAccountModel = {
      ciamId: ciamId,
      customerId: 0,
      country: "",
      salesRegion: "",
      language: "",
      timeZoneId: "",
      studentGuid: studentGuid,
      ciamEmail: ciamEmail,
    };
    let response = await dispatchReduxToolkit(
      mapPersonalUserAccount(requestObj)
    );
    if (mapPersonalUserAccount.fulfilled.match(response)) {
      setIsLoading(false);
      await authService.logoutAsync();
    }
    if (mapPersonalUserAccount.rejected.match(response)) {
      setIsLoading(false);
      setStatus(t("Error_Common"));
    }
  };

  const createNewAccount = async (resp: User, ciamid: string) => {
    const profile = resp.profile;
    const countryCodeLower = profile.countrycode as string;

    const account: CreateUpdateAccountInput = {
      ciamId: profile.ciam_id as string,
      customerId: 0,
      country: authState?.originCountryCode ?? authState.countryCode ?? "",
      salesRegion: "",
      language: authState?.language ?? "",
      timeZoneId: authState?.timeZoneId ?? "",
      ciamEmail: profile?.email ?? "",
      customerNumber: 0,
      companyPhone:
        !!profile.company && !!(profile.company as any).phone
          ? (profile.company as any).phone
          : "",
      companyEmail: "",
      companyName:
        !!profile.company && !!(profile.company as any).companyName
          ? (profile.company as any).companyName
          : "",
      companyAddress:
        !!profile.company && !!(profile.company as any).address1
          ? (profile.company as any).address1
          : "",
      companyCity:
        !!profile.company && !!(profile.company as any).city
          ? (profile.company as any).city
          : "",
      companyPostCode:
        !!profile.company && !!(profile.company as any).zipCode
          ? (profile.company as any).zipCode
          : "",
      contactFirstName: profile?.given_name ?? "",
      contactLastName: profile?.family_name ?? "",
      ciamRole: (profile.company as any)?.position
        ? (profile.company as any).position
        : "",
      vat: (profile.company as any)?.taxNumber
        ? (profile.company as any).taxNumber.substring(0, 25)
        : "",
      termAndConditions: CommonHelper.getUserProfileAttribute(
        UserProfileConstants.PROFILE_ATTRIBUTE_TERMS_AND_CONDITIONS,
        countryCodeLower,
        profile
      ),
      mobile: profile?.mobile ? (profile?.mobile as string) : "",
    };

    let response = await dispatchReduxToolkit(createAccount(account));
    if (createAccount.fulfilled.match(response)) {
      if (!!response.payload && response.payload.success) {
        setStudentAccount(response.payload.dataObject);
      }
      return true;
    }
    if (createAccount.rejected.match(response)) {
      return false;
    }
  };

  /* const updateAccountAsync = async (resp: User, existingUser: Account) => {
    let account = Object.assign({}, existingUser);
    let resUpdateAccount = await dispatchReduxToolkit(
      updateSignInAccount(account)
    );
    if (updateSignInAccount.rejected.match(resUpdateAccount)) {
      setStatus(t("Error_Common"));
    }
  }; */

  const handleGuidRedirect = async (
    guid: string,
    ciamId: string,
    ciamEmail: string
  ) => {
    const getContactByGuidRequestModel: GetContactPersonByGuidRequestModel = {
      guid: guid,
      country: authState?.countryCode ?? "",
      language: authState?.language ?? "",
    };
    let contactPersonResp = await dispatchReduxToolkit(
      getContactPersonByGuidAsync(getContactByGuidRequestModel)
    );
    if (getContactPersonByGuidAsync.fulfilled.match(contactPersonResp)) {
      if (!!contactPersonResp.payload && contactPersonResp.payload.success) {
        setCiamId(ciamId);
        setCiamEmail(ciamEmail);
        setStudentGuid(guid);
        setContactPerson(contactPersonResp.payload.dataObject);
        setOpenConfirmByMeModal(true);
      } else if (
        !!contactPersonResp.payload &&
        contactPersonResp.payload.httpStatusCode === 404
      ) {
        setIsError(true);
      } else {
        setIsError(true);
      }
    }
  };

  const getAccountSimple = async (ciamid: string) => {
    let response = await dispatchReduxToolkit(getAccountSimpleAsync(ciamid));
    if (getAccountSimpleAsync.fulfilled.match(response)) {
      if (!!response.payload && response.payload.success) {
        return response.payload;
      } else if (
        !!response.payload &&
        response.payload.httpStatusCode === 404
      ) {
        return null;
      }
    }
    return undefined;
  };

  const mapKeyCloakInfoToUserObject = (keycloakProfile: UserProfile) => {
    const {
      given_name: contactFirstName,
      family_name: contactLastName,
      mobile,
      email: contactEmail,
      company: {
        companyName,
        country: companyCountry,
        city,
        zipCode: postCode,
        taxNumber: vat,
        phone: companyPhone,
        address1,
        address2,
        position: ciamRole,
      },
    } = keycloakProfile as any;

    return {
      contactFirstName,
      contactLastName,
      mobile,
      contactEmail,
      companyName,
      companyCountry,
      city,
      postCode,
      vat,
      companyPhone,
      address1,
      address2,
      ciamRole,
    };
  };

  const findUpdatedKeyCloakFields = (
    keycloakProfile: UserProfile,
    currentProfile: Account
  ): Account | null => {
    let needUpdateFields: Record<string, string | number> = {};

    const currentProfileAsAny = currentProfile as any;

    const necessaryInfoFromKeycloakAsArray = Object.entries(
      mapKeyCloakInfoToUserObject(keycloakProfile)
    );

    for (const prop of necessaryInfoFromKeycloakAsArray) {
      const [key, value] = prop;

      if (!key) continue;

      if ((currentProfileAsAny[key] ?? "") !== (value ?? "")) {
        needUpdateFields = {
          ...needUpdateFields,
          [key]: value,
        };
      }
    }

    return Object.keys(needUpdateFields).length === 0
      ? null
      : {
          ...currentProfile,
          ...needUpdateFields,
        };
  };

  const handleSignInCallback = async (user: User) => {
    //Set Ciam Account
    setCiamAccount(user);

    //Get Account
    let account = await getAccountSimple(user.profile.ciam_id as string);

    //If Account Exist
    if (account !== null && account !== undefined) {
      const payload = account as ResponseResultBase<Account>;

      dispatch(setAccountImpersonationList(payload));
      setStudentAccount(account.dataObject);

      if (account?.dataObject?.isActive) {
        setCiamId(account.dataObject.ciamId as string);

        if (!account?.dataObject?.hasCustomerDecided) {
          setShowConsentMarketingModal(true);
        }

        if (
          !!account?.dataObject?.impersonationList &&
          account?.dataObject?.impersonationList.length > 1
        ) {
          setIsLoading(false);
          setSelectCompanyModal(true);
        } else {
          setTimeout(() => {
            window.location.href = "/";
          }, 200);
        }
      } else {
        setIsLoading(false);
        if (user.state && (user.state as any).guid.length > 0)
          await handleGuidRedirect(
            (user.state as any).guid,
            user?.profile?.ciam_id as string,
            user?.profile?.email ?? ""
          );
        else {
          
          setOpenAccountReviewModal(true);

          if (!account?.dataObject?.hasCustomerDecided) {
            setShowConsentMarketingModal(true);
            consentMarketingCallback(() => {      
            });
          }
        }
      }
    }

    // Reset reloaded status
    forceToReloadData();
    // reset force to hide notification banner
    dispatchReduxToolkit(updateForcedToHideNotificationBanner(false));

    /**
     * START: Update current account if any changes on Keycloak
     */
    if (account?.dataObject) {
      const accountNeedToBeSyncWithKeycloak = findUpdatedKeyCloakFields(
        user?.profile,
        account?.dataObject
      );

      const profle = user?.profile;

      if (accountNeedToBeSyncWithKeycloak) {
        const updatedAccount: CreateUpdateAccountInput = {
          ciamId: accountNeedToBeSyncWithKeycloak?.ciamId as string,
          ciamEmail: accountNeedToBeSyncWithKeycloak?.ciamEmail ?? "",
          customerId: accountNeedToBeSyncWithKeycloak?.customerId ?? 0,
          country: authState?.countryCode ?? "",
          salesRegion: "",
          language: authState?.language ?? "",
          timeZoneId: authState?.timeZoneId ?? "",
          ciamRole: (profle?.company as any)?.position ?? "",
          contactFirstName: (profle?.given_name as string) ?? "",
          contactLastName: (profle?.family_name as string) ?? "",
          customerNumber: 0,
          companyPhone: accountNeedToBeSyncWithKeycloak?.companyPhone ?? "",
          companyEmail: accountNeedToBeSyncWithKeycloak?.companyEmail ?? "",
          companyName: accountNeedToBeSyncWithKeycloak?.companyName ?? "",
          companyAddress: accountNeedToBeSyncWithKeycloak?.address1 ?? "",
          companyCity: accountNeedToBeSyncWithKeycloak?.city ?? "",
          companyPostCode: accountNeedToBeSyncWithKeycloak?.postCode ?? "",
          dateFormat:
            accountNeedToBeSyncWithKeycloak?.dateFormat ??
            Constants.DEFAULT_DATE_FORMAT,
          mobile: accountNeedToBeSyncWithKeycloak?.mobile ?? "",
          vat: accountNeedToBeSyncWithKeycloak?.vat ?? "",
          termAndConditions: CommonHelper.getUserProfileAttribute(
            UserProfileConstants.PROFILE_ATTRIBUTE_TERMS_AND_CONDITIONS,
            accountNeedToBeSyncWithKeycloak?.countryCode?.toLowerCase() ?? "",
            user?.profile
          ),
        };

        await dispatchReduxToolkit(updateAccount(updatedAccount));
      }
    }

    /**
     * END: Update current account if any changes on Keycloak
     */

    //If account not exists
    if (account === null) {
      if (user.state && (user.state as any).guid.length > 0) {
        setIsLoading(false);
        await handleGuidRedirect(
          (user.state as any).guid,
          user.profile.ciam_id as string,
          user?.profile?.email ?? ""
        );
      } else if (!user.state) {
        let responseCreateAccount = await createNewAccount(
          user,
          user.profile.ciam_id as string
        );
        if (responseCreateAccount) {
          setIsLoading(false);
          setOpenAccountReviewModal(true);
        } else {
          setIsError(true);
        }
      }
    }
    if (account === undefined || isError) {
      setIsLoading(false);
      setStatus(t("Error_Common"));
    }
  };

  const forceToReloadData = () => {
    // Clear all training courses
    dispatchReduxToolkit(resetTrainingCourses(undefined));

    // clear all training programs
    dispatchReduxToolkit(resetTrainingPrograms(undefined));

    // clear all training event
    dispatchReduxToolkit(resetTrainingEvents(undefined));

    // clear all training centers
    dispatchReduxToolkit(resetTrainingCenters(undefined));

    // clear all contacts list
    dispatchReduxToolkit(resetContactPersons(undefined));
  };

  useEffect(() => {
    async function performLogin() {
      //var response = await dispatchReduxToolkit(loginCallbackAsync());
      //const accountService = AuthService.getInstance();
      const result = await authService.loginCallbackAsync();

      if (!!result && result.profile && !!result.profile.sub) {
        //const ciamid = result.profile.ciam_id;
        await handleSignInCallback(result);
      } else {
        setStatus(t("Error_Common"));
      }
    }

    performLogin();
  }, []);

  const handleCloseSelectCompany = (companyCiamId: string) => {
    if (ciamId !== companyCiamId) {
      dispatchReduxToolkit(setOnSubWorkshopBranch(true));
    }
    const getCompanyPromise = dispatchReduxToolkit(
      getAccountSimpleAsync(companyCiamId)
    );

    getCompanyPromise.finally(() => {
      if (
        redirectURLCallback &&
        redirectURLCallback !== "" &&
        redirectURLCallback !== null &&
        redirectURLCallback !== undefined
      ) {
        redirectToUrl(redirectURLCallback);
      } else {
        redirectToUrl("/");
      }

      /* if (!isHasCustomerDecided) {
        setShowConsentMarketingModal(true);
        setConsentMarkingCallback(() => () => {
          if (
            redirectURLCallback &&
            redirectURLCallback !== "" &&
            redirectURLCallback !== null &&
            redirectURLCallback !== undefined
          ) {
            redirectToUrl(redirectURLCallback);
          } else {
            redirectToUrl("/");
          }
        });
      } */
    });
  };

  const redirectToUrl = (redirectUrl: string) => {
    dispatchReduxToolkit(setRedirectURLCallback(""));
    window.location.href = redirectUrl;
  };

  return (
    <div>
      {!status && <div className="background-image--popup-modal"></div>}

      {selectCompanyModal && (
        <SelectCompanyModal continue={handleCloseSelectCompany} />
      )}

      <AccountInReviewModal
        isOpen={openAccountReviewModal}
        closeModel={closeAccountReviewModal}
      />
      <ConfirmItsMeModal
        isOpen={openConfirmByMeModal}
        contactPerson={contactPerson}
        account={studentAccount}
        ciamAccount={ciamAccount}
        userConfirm={userConfirmItsMe}
      />

      {isShowConsentMarketingModal ? (
        <ConsentMarketingModal
          onClose={() => {
            setShowConsentMarketingModal(false);
            consentMarketingCallback();
          }}
        />
      ) : (
        <></>
      )}

      {status && <p>Error: {status}</p>}
    </div>
  );
};

export default SignInCallback;
